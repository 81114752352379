import { useState } from 'react';
import Button from 'common/core/Button';
import { IPartnerReferralsAdd } from 'types';
import PartnerReferralsAddModal from '../PartnerReferralsModal/PartnerReferralsAddModal';

const PartnerReferralsAdd = ({ createPartnerReferral }: IPartnerReferralsAdd) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);

  const toggleOpenAddModal = () => {
    setIsOpenAddModal(!isOpenAddModal);
  };

  const handleCreatePartnerReferral = (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    timezone: string,
  ) => {
    createPartnerReferral(firstName, lastName, email, phone, timezone);
    setIsOpenAddModal(false);
  };

  return (
    <>
      <div>
        <Button onClick={toggleOpenAddModal}>Add new partner referral</Button>
      </div>
      <PartnerReferralsAddModal
        isOpenAddModal={isOpenAddModal}
        toggleOpenAddModal={toggleOpenAddModal}
        handleCreatePartnerReferral={handleCreatePartnerReferral}
      />
    </>
  );
};

export default PartnerReferralsAdd;
