import { useAdminClients } from 'hooks/admin/useAdminClients';
import Clients from './Clients';

const ClientsContainer = () => {
  const { usersList } = useAdminClients();

  return <Clients usersList={usersList} />;
};

export default ClientsContainer;
