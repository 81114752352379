import { useState } from 'react';
import ChatProgramActionsHeader from './ChatProgramActionsHeader';
import ChatProgramActionsList from './ChatProgramActionsList';
import { EActionsProgramOptions } from 'types';
import { Container } from './styles';

// DEPRECATED OLD VERSION
const ChatProgramActions = () => {
  const [actionsProgramOption, setActionsProgramOption] = useState<string>(
    EActionsProgramOptions.ALL,
  );
  const handleActionsProgramOption = (value: string) => {
    setActionsProgramOption(value);
  };

  return (
    <Container>
      <ChatProgramActionsHeader
        actionsProgramOption={actionsProgramOption}
        handleActionsProgramOption={handleActionsProgramOption}
      />
      <ChatProgramActionsList actionsProgramOption={actionsProgramOption} />
    </Container>
  );
};

export default ChatProgramActions;
