import { createSlice } from '@reduxjs/toolkit';
import { IMessageQuoted } from 'types';

const initialState: IMessageQuoted = {
  actionTracking: undefined,
};

export const quotedMessageSlice = createSlice({
  name: 'quotedMessageSlice',
  initialState,
  reducers: {
    addQuotedMessage: (state, action) => {
      state.id = action.payload.id;
      state.body = action.payload.body;
      if (action.payload.files) {
        state.files = action.payload.files;
      }
      state.userId = action.payload.userId;
      state.userIdFrom = action.payload.userIdFrom;
      state.userNameFrom = action.payload.userNameFrom;
      if (action.payload.actionTracking) {
        state.actionTracking = action.payload.actionTracking;
      }
    },
    clearQuotedMessage: (state) => {
      state.id = undefined;
      state.body = undefined;
      state.files = undefined;
      state.userId = undefined;
      state.userIdFrom = undefined;
      state.userNameFrom = undefined;
      state.actionTracking = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addQuotedMessage, clearQuotedMessage } = quotedMessageSlice.actions;

export default quotedMessageSlice.reducer;
