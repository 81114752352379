import React, { useState, useEffect } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import {
  Arrow,
  CategoryName,
  RadioCategory,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './styles';
import { IResourceCategory, IShareCategory } from 'types';

const ShareCategory = ({ category, level = 0 }: IShareCategory) => {
  const [children, setChildren] = useState<IResourceCategory[]>([]);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  useEffect(() => {
    setChildren(category.children);
  }, [category]);

  const handleChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion expanded={expanded} onChange={handleChange} elevation={0} square>
      <StyledAccordionSummary expandIcon={<Arrow />} style={{ paddingLeft: 16 * (level - 1) }}>
        {level !== 0 ? (
          <RadioCategory value={category.id} control={<Radio />} label={category.name} />
        ) : (
          <CategoryName>{category.name}</CategoryName>
        )}
      </StyledAccordionSummary>
      {children.length && (
        <StyledAccordionDetails>
          {children.map((child) =>
            child.children?.length ? (
              <ShareCategory category={child} level={level + 1} key={child.id} />
            ) : (
              <FormControlLabel
                value={child.id}
                control={<Radio />}
                label={child.name}
                key={child.id}
                style={{ paddingLeft: 16 * (level + 1) + 16 }}
              />
            ),
          )}
        </StyledAccordionDetails>
      )}
    </StyledAccordion>
  );
};

export default ShareCategory;
