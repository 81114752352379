import { styled } from '@mui/system';

export const TitleBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const TitleText = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.common.black,
}));

export const ListBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 25,
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: 25,
});

export const RowBlock = styled('div')<{ isColored: boolean }>(({ isColored }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: 36,
  marginBottom: 5,
  borderRadius: 4,
  ...(isColored && { backgroundColor: '#F9FAFC' }),
}));

export const FieldNameBlock = styled('div')({
  display: 'flex',
  width: 208,
  paddingLeft: 8,
});

export const FieldValueBlock = styled('div')({
  display: 'flex',
});

export const RowBlockColored = styled('div')({
  backgroundColor: '#F9FAFC',
});

export const FieldNameText = styled('div')({
  fontSize: 12,
  color: 'rgba(15, 15, 33, 0.6)',
});

export const FieldValueText = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.common.black,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledIcon = styled('div')({
  fontSize: 16,
  verticalAlign: 'top',
});
