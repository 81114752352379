import axios from 'axios';

export const getVoices = async (apiKey: string) => {
  const res = await axios({
    url: 'https://api.elevenlabs.io/v1/voices',
    method: 'GET',
    headers: {
      'xi-api-key': apiKey,
    },
  });
  return res.data.voices.filter((voice: any) =>
    ['cloned', 'professional'].includes(voice.category),
  );
};
