const taskTimeWorker = () => {
  let intervalTask: NodeJS.Timeout | null | unknown = null;
  let timeDuration = 0;
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = ({ data: { isTimeStarted } }) => {
    if (isTimeStarted) {
      clearInterval(intervalTask as NodeJS.Timeout);
      intervalTask = setInterval(() => {
        timeDuration += 1;
        // eslint-disable-next-line no-restricted-globals
        self.postMessage({ timeDuration });
      }, 1000);
    }

    if (!isTimeStarted) {
      clearInterval(intervalTask as NodeJS.Timeout);
      timeDuration = 0;
    }
  };
};

let codeTaskTime = taskTimeWorker.toString();
codeTaskTime = codeTaskTime.substring(codeTaskTime.indexOf('{') + 1, codeTaskTime.lastIndexOf('}'));

const blobTask = new Blob([codeTaskTime], { type: 'application/javascript' });
const task_worker_script = URL.createObjectURL(blobTask);

export default task_worker_script;
