import Dashboard from './Dashboard';
import { useContext, useEffect } from 'react';
import {
  getCoachWatchingCustomer,
  releaseCoachWatchingCustomer,
} from 'store/customers/watcherSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Context } from 'context/Context';
import { resetCurrentCustomerId } from 'store/customers/customerInfosSlice';
import { resetWeeks } from 'store/program/weekProgramSlice';
import { resetActions } from 'store/program/programActionsSlice';
import { resetSendAs } from 'store/sendAs/sendAsSlice';

const ChatContainer = () => {
  const dispatch = useAppDispatch();
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const setOpenedMyPlanDrawer = useContext(Context).setOpenedMyPlanDrawer;

  useEffect(() => {
    dispatch(getCoachWatchingCustomer());
    dispatch(resetCurrentCustomerId());
    dispatch(resetWeeks());
    dispatch(resetActions());
    dispatch(releaseCoachWatchingCustomer({ coachId: coachData?.id }));
    dispatch(resetSendAs());
    setOpenedMyPlanDrawer(false);
  }, []);

  return <Dashboard />;
};

export default ChatContainer;
