import { styled } from '@mui/system';
import { Badge, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '../../../../common/core/MenuItem';

export const BoxContainer = styled(Badge)<{ hasNewItem: boolean }>(({ hasNewItem }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#FFF',
  padding: '0px 0px 16px',
  boxShadow: '0px 2px 6px rgba(0, 2, 23, 0.075)',
  border: '1px solid #E4E7ED',
  borderRadius: 8,
  marginBottom: 15,
  ...(hasNewItem && {
    '& .MuiBadge-badge': {
      backgroundColor: 'transparent',
      // backgroundColor: '#8587E5',
      // color: '#fff',
    },
  }),
}));

export const TopBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: 40,
  marginTop: 16,
  marginBottom: 5,
});

export const ContentBlock = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
});

export const CheckboxBlock = styled('div')({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingLeft: 19,
  paddingRight: 9,
});

export const ActionTitleBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingLeft: 16,
});

export const ActionTitleIconBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '100%',
  marginRight: 5,
});

export const ActionTitleText = styled(Typography)({
  fontSize: 16,
  fontWeight: 700,
  color: '#192926',
  lineHeight: '24px',
});

export const ActionBodyText = styled(Typography)({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#192926',
  opacity: 0.6,
  paddingLeft: 16,
  paddingRight: 16,
});

export const Checkbox = styled('div')({
  color: '#000',
  padding: 0,
});

export const StyledMoreVertIcon = styled(MoreVertIcon)({
  color: 'rgba(15, 15, 33, 0.4)',
});

export const SubMenu = styled(MenuItem)({
  minWidth: 180,
});

export const SubMenuDelete = styled(SubMenu)({
  color: '#FF6D7D !important',
});
