/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import { FormControl, InputAdornment } from '@mui/material';
import Input from 'common/core/Input';
import MaskedInput from 'common/core/MaskedInput';
import { RESOURCE_FILES_DIR } from 'config';
import { youtubeParser } from 'utils/format/stringUtils';
import {
  AddTitle,
  Form,
  FormContainer,
  InputGroup,
  InputGroupWrapper,
  Picture,
  Thumb,
} from './styles';
import {
  IResourceBlock,
  EResourceBlockTypes,
  IRecipeContent,
  IResourceBlockNutritionTable,
} from 'types';
import PopupMenu from './PopupMenu';
import Attachment from './Attachment';
import TextEditor from './Editor';
import Subtitle from './Subtitle';
import HoverAdditionalBlock from './HoverAdditionalBlock';
import TimeIco from 'assets/icons/access_time.svg';
import NutritionTable from './NutritionTable';
import {
  ETypeMeal,
  Table as NutritionTableComponent,
} from '@fitmate-coach/fitmate-nutrition-table';
import { ENutritionCaloriesTarget, ESex } from '@fitmate-coach/fitmate-types';

const RecipeContent = ({ handleResourceDataChange, resourceData, isArticle }: IRecipeContent) => {
  const location = useLocation();

  const [imgUrl, setImgUrl] = useState<string>('');
  const [parameters, setParameters] = useState<Map<string, any>>(new Map());
  const [blocks, setBlocks] = useState<Array<IResourceBlockNutritionTable | IResourceBlock>>([]);
  const [isAttachmentVisible, setIsAttachmentVisible] = useState<boolean>(false);
  const [isAddNutritionTableVisible, setIsAddNutritionTableVisible] = useState<boolean>(false);
  const [isShowOldBlocks, setIsShowOldBlocks] = useState<boolean>(true);

  // TODO delete this monkey code when rosources and handouts are rewritten on HTML
  useEffect(() => {
    if (location.pathname.includes('recipe/edit')) {
      if (blocks.length === 0) {
        setIsShowOldBlocks(false);
      } else {
        setIsShowOldBlocks(true);
      }
    }
    if (location.pathname.includes('recipe') && blocks.length === 0) {
      setIsShowOldBlocks(false);
    }
  }, [location.pathname, blocks]);

  useEffect(() => {
    handleResourceDataChange((prev: any) => {
      return { ...prev, parameters: parameters, blocks: blocks };
    });
  }, [parameters, blocks]);

  useEffect(() => {
    if (resourceData?.parameters) {
      setParameters(resourceData?.parameters);
    }

    if (resourceData?.blocks?.length > 0) {
      setBlocks(resourceData?.blocks);
    }

    setImgUrl(resourceData?.picture || '');
  }, [resourceData]);

  const handleBlocksChange = (callback: React.SetStateAction<IResourceBlock[]>) => {
    setBlocks(callback);
  };

  const handleAddAttachment = () => {
    setIsAttachmentVisible((prev) => !prev);
  };
  const handleAddNutritionTable = () => {
    setIsAddNutritionTableVisible((prev) => !prev);
  };

  const handleImageUploaded = (url: string) => {
    setImgUrl(url);
    handleResourceDataChange((prev: any) => ({ ...prev, picture: url }));
  };

  const handleFormChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    handleResourceDataChange((prev: any) => {
      const copy = prev;
      copy.parameters = new Map(copy.parameters.set(event.target.name, event.target.value));
      return { ...prev, parameters: copy.parameters };
    });
  };

  const handleMaskedInputChange = (name: string, value: string) => {
    setParameters((prev) => new Map(prev.set(name, value)));
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleResourceDataChange((prev: any) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const AddSubtitle = () => {
    setBlocks((prev) => [
      ...prev,
      {
        id: v4(),
        body: '',
        url: '',
        type: EResourceBlockTypes.SUBTITLE,
      },
    ]);
  };

  const AddText = () => {
    setBlocks((prev) => [
      ...prev,
      {
        id: v4(),
        body: '',
        url: '',
        type: EResourceBlockTypes.TEXT,
      },
    ]);
  };

  const handleDelete = (key: string) => {
    setBlocks((blocks) =>
      blocks.filter((item) => {
        return key !== item.id;
      }),
    );
  };

  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const items = Array.from(blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setBlocks([...items]);
  };

  return (
    <FormContainer>
      <Form>
        {isArticle && (
          <Thumb
            data-toto="thumb"
            sx={{
              '& > div': {
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '20px',
              },
              // alignContent: 'center',
            }}
            caption="Drag image here to start uploading"
            imageUrl={imgUrl}
            handleUploaded={handleImageUploaded}
            destination={RESOURCE_FILES_DIR}
          />
        )}
        <FormControl variant="standard">
          <AddTitle
            name="title"
            sx={{
              ...(isArticle && { marginBottom: 4 }),
            }}
            placeholder="Add title"
            onChange={handleTitleChange}
            value={resourceData?.title || ''}
          />
          <InputGroupWrapper>
            {!isArticle && (
              <InputGroup>
                <MaskedInput
                  label="Prep time"
                  name="prep_time"
                  onChange={handleMaskedInputChange}
                  resourceData={resourceData}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={TimeIco} alt="clock" />
                      </InputAdornment>
                    ),
                  }}
                />
                <MaskedInput
                  label="Cook time"
                  name="cook_time"
                  onChange={handleMaskedInputChange}
                  resourceData={resourceData}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={TimeIco} alt="clock" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Input
                  label="Calories"
                  name="calories"
                  onChange={handleFormChange}
                  value={resourceData?.parameters?.get('calories') || ''}
                  inputProps={{
                    endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                  }}
                />
                <div />
              </InputGroup>
            )}
          </InputGroupWrapper>

          {isShowOldBlocks && (
            <>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
                    return (
                      <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                        {blocks.map((block, index) => {
                          return (
                            <HoverAdditionalBlock
                              deleteClickHandler={() => handleDelete(block.id!)}
                              index={index}
                              key={block.id}
                            >
                              {block.type === EResourceBlockTypes.SUBTITLE && (
                                <Subtitle
                                  block={block}
                                  handleBlocksChange={handleBlocksChange}
                                  index={index}
                                  key={block.id}
                                />
                              )}
                              {block.type === EResourceBlockTypes.TEXT && (
                                <TextEditor
                                  block={block}
                                  handleBlocksChange={handleBlocksChange}
                                  index={index}
                                  key={block.id}
                                />
                              )}
                              {block.type === EResourceBlockTypes.PICTURE && (
                                <Picture src={block.url} alt="additionalImg" key={block.id} />
                              )}
                              {block.type === EResourceBlockTypes.NUTRITION_TABLE && (
                                <NutritionTableComponent
                                  focusArea={'focusArea' in block ? block.focusArea : []}
                                  customerSex={ESex.male}
                                  type={ENutritionCaloriesTarget.refined_calorie_target}
                                  lastUpdatedAt={new Date()}
                                  targetCalories={1500}
                                />
                              )}
                              {block.type === EResourceBlockTypes.VIDEO && (
                                <iframe
                                  style={{ width: '100%', height: 350 }}
                                  src={`https://www.youtube.com/embed/${youtubeParser(block.url)}`}
                                  title="YouTube video player"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                  key={block.id}
                                />
                              )}
                            </HoverAdditionalBlock>
                          );
                        })}
                        {isAttachmentVisible && (
                          <HoverAdditionalBlock
                            deleteClickHandler={() => handleAddAttachment()}
                            index={9999}
                          >
                            <Attachment
                              handleAddAttachment={handleAddAttachment}
                              handleBlocksChange={handleBlocksChange}
                              key={9999}
                            />
                          </HoverAdditionalBlock>
                        )}
                        {isAddNutritionTableVisible && (
                          <HoverAdditionalBlock
                            deleteClickHandler={() => handleAddNutritionTable()}
                            index={9999}
                          >
                            <NutritionTable
                              handleAddNutritionTable={handleAddNutritionTable}
                              handleBlocksChange={handleBlocksChange}
                              key={9999}
                            />
                          </HoverAdditionalBlock>
                        )}
                        {droppableProvided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
              <PopupMenu
                AddSubtitle={AddSubtitle}
                AddAttachment={handleAddAttachment}
                AddText={AddText}
                AddNutritionTable={handleAddNutritionTable}
              />
            </>
          )}
        </FormControl>
      </Form>
    </FormContainer>
  );
};

export default RecipeContent;
