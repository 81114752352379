import InformationBlock from '../ChatUserInformationBlock';
import {
  getAccountEmailInfo,
  getGeneralInfo,
  getMedicalInfo,
  getOnboardingInfo,
  getOtherInfo,
  getPartnerInfos,
  getPaymentInfo,
  getFeatureFlagsInfo,
  getPartnerReferralInfos,
  getIntroCall,
  getReferralInfo,
} from './helper';
import { ClientUser } from '@fitmate-coach/fitmate-types';
import { useGetUserByUid } from 'hooks/chat/useGetUserByUid';

const ChatUserProfileViewList = ({ userData }: { userData: ClientUser | null }) => {
  const partnerReferralData = useGetUserByUid(userData?.partnerReferralUid);

  return (
    <>
      <InformationBlock
        title={`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}
        dataList={getGeneralInfo(userData)}
      />
      <InformationBlock title="Onboarding info" dataList={getOnboardingInfo(userData)} />
      <InformationBlock title="Other personal info" dataList={getOtherInfo(userData)} />
      <InformationBlock title="Payment info" dataList={getPaymentInfo(userData)} />
      <InformationBlock title="Referral info" dataList={getReferralInfo(userData)} />
      <InformationBlock title="Account details" dataList={getAccountEmailInfo(userData)} />
      <InformationBlock title="Video calls" dataList={getIntroCall(userData)} />
      <InformationBlock title="Medical informations" dataList={getMedicalInfo(userData)} />
      {userData?.partnerData && (
        <InformationBlock title="Partner informations" dataList={getPartnerInfos(userData)} />
      )}
      {partnerReferralData && (
        <InformationBlock
          title="Partner referral informations"
          dataList={getPartnerReferralInfos(partnerReferralData)}
        />
      )}
      <InformationBlock title="Feature flags" dataList={getFeatureFlagsInfo(userData)} />
    </>
  );
};

export default ChatUserProfileViewList;
