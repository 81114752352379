interface ILinearProgressWithLabel {
  progressValue: number;
  finishValue: number;
}

function LinearProgressWithLabel(props: ILinearProgressWithLabel) {
  const { progressValue, finishValue } = props;
  const percentValue = progressValue > finishValue ? 100 : (progressValue / finishValue) * 100;

  return (
    <div className="flex flex-row items-center">
      <div className="relative w-20 items-center m-2">
        <div className="overflow-hidden h-2.5 text-xs flex rounded bg-[#E4E7ED]">
          <div
            style={{ width: `${percentValue}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#6B6ADE]"
          ></div>
        </div>
      </div>
      <span className="ml-2 font-semibold text-sm">
        {progressValue}/{finishValue}
      </span>
    </div>
  );
}

export default LinearProgressWithLabel;
