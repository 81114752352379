import { useState, useEffect } from 'react';
import Modal from 'common/core/Modal';
import { dateConvertFromDatePicker } from 'utils/format/datetime';
import { ButtonBlock, ButtonCancel, ButtonSubmit, Container, NoteBlock, NoteText } from './styles';
import { IModalTimePicker } from 'types';
import { StaticTimePicker } from '@mui/x-date-pickers';
import CustomPickersActionBar from './CustomPickersActionBar';

const ModalTimePicker = ({
  isOpenModal,
  toggleOpenModal,
  note = '',
  valueInitial = 0,
  handleChange,
}: IModalTimePicker) => {
  const [date, setDate] = useState<any | string>(new Date());
  useEffect(() => {
    if (valueInitial) {
      setDate(new Date(valueInitial));
    }
  }, [valueInitial]);

  const handleChangeDate = (value: any) => {
    setDate(value);
  };

  const handleSumbit = () => {
    handleChange(dateConvertFromDatePicker(date));
    toggleOpenModal();
  };

  return (
    <Modal widthMax={360} title="" open={isOpenModal} handleClose={toggleOpenModal}>
      <Container>
        {note && (
          <NoteBlock>
            <NoteText>{note}</NoteText>
          </NoteBlock>
        )}
        <StaticTimePicker
          // autoOk
          // variant="static"
          slots={{
            previousIconButton: undefined,
            nextIconButton: undefined,
            leftArrowIcon: undefined,
            rightArrowIcon: undefined,
            actionBar: CustomPickersActionBar,
          }}
          openTo="hours"
          value={date}
          onChange={handleChangeDate}
          sx={{
            '& .MuiPickersToolbar-content': {
              backgroundColor: '#8587E5',
              padding: 4,
            },
            '& .MuiPickersToolbar-root': {
              padding: 0,
            },
            '& .MuiPickersToolbar-root .MuiTypography-root.MuiTypography-overline': {
              display: 'none',
            },
            '& .MuiPickersToolbarText-root': {
              color: 'rgba(255, 255, 255, 0.54)',
              // backgroundColor: '#8587E5',
              // padding: 4
            },
            '& .MuiPickersToolbarText-root.Mui-selected': {
              color: '#fff',
              // backgroundColor: '#8587E5',
              // padding: 4
            },
            '& .MuiTimePickerToolbar-ampmSelection': {
              marginRight: 0,
              // backgroundColor: '#8587E5',
              // padding: 4
            },
          }}
        />
        <ButtonBlock>
          <ButtonCancel onClick={toggleOpenModal} variant="outlined">
            Cancel
          </ButtonCancel>
          <ButtonSubmit onClick={handleSumbit}>OK</ButtonSubmit>
        </ButtonBlock>
      </Container>
    </Modal>
  );
};

export default ModalTimePicker;
