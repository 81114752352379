import { Typography } from '@mui/material';
import { convertToMinutes, timeConvert } from 'utils/format/convert';
import {
  ResourceCaption,
  ResourceCard,
  ResourceCardWrapper,
  ResourceImg,
  Title,
} from 'styles/resources';
import { EResourceTypes, IResources } from 'types';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const Resources = ({ resource, handleResourceEdit = () => {} }: IResources) => {
  const getVideoPreview = (url: string) => {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const videoId: string | null = urlParams.get('v');
    return 'https://i.ytimg.com/vi/' + videoId + '/mqdefault.jpg';
  };

  const editClickHandler = () => {
    handleResourceEdit(resource.id, resource.type);
  };

  const calcDuration = () => {
    return (
      convertToMinutes(resource?.parameters?.get('prep_time')) +
      convertToMinutes(resource?.parameters?.get('cook_time'))
    );
  };

  return (
    <ResourceCardWrapper onClick={editClickHandler}>
      <div style={{ position: 'relative' }}>
        <ResourceCard>
          {resource?.thumbnail && <ResourceImg src={resource.thumbnail} alt={resource.title} />}
          {resource?.type === EResourceTypes.WORKOUT && (
            <ResourceImg src={getVideoPreview(resource.blocks[1].url)} alt={resource.title} />
          )}
          {resource?.type === EResourceTypes.RECIPE && (
            <ResourceCaption>
              <Typography>{`${resource?.parameters?.get('calories')} kcal`}</Typography>
              <Typography>{timeConvert(calcDuration())}</Typography>
            </ResourceCaption>
          )}
        </ResourceCard>
      </div>
      <Title>{resource?.title}</Title>
    </ResourceCardWrapper>
  );
};

export default Resources;
