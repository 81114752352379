import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Wrapper = styled('div')`
  position: absolute;
  left: 9px;
  top: -8px;
`;

export const StyledBox = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  alignitems: center;
  justifycontent: center;
  color: rgba(15, 15, 33, 0.4);
`;
