import { styled } from '@mui/system';

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: '#EAE9EB',
}));

export const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
}));
