import { styled, Theme, keyframes } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

export const Loader = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  zIndex: 2,
  background: '#ffffff',
  opacity: 1,
}));

const hide = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const Hide = styled('div')({
  animation: `${hide} 1s linear`,
  opacity: 0,
  zIndex: -1,
});

export const EllipseWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  marginBottom: '21px',
}));

const rotating = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderCircle = styled('img')({
  animation: `${rotating} 2s linear infinite`,
});

export const StyledRefreshIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '44px',
  height: '44px',
  color: '#8587E5',
}));

export const TitleBlock = styled('div')({});

export const NoteBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: 153,
  marginTop: 10,
}));

export const TitleText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: '#000',
  lineHeight: '24px',
}));

export const NoteText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.6)',
}));
