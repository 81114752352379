import axios from 'axios';
import Button from 'common/core/Button';
import { useState } from 'react';
import Input from 'common/core/Input';
import { Base64 } from 'js-base64';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getCoachData,
  unlinkCoachTogglToken,
  updateCoachTogglToken,
} from 'store/coaches/coachAuthSlice';

const TogglForm = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const dispatch = useAppDispatch();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleConnect = async () => {
    const res = await axios({
      method: 'get',
      url: 'https://api.track.toggl.com/api/v9/me',
      headers: {
        Authorization: `Basic ${Base64.toBase64(`${email}:${password}`)}`,
      },
    });
    if (res.data.api_token && coachData?.id) {
      dispatch(
        updateCoachTogglToken({
          id: coachData.id,
          toggleApiToken: Base64.toBase64(`${res.data.api_token}:api_token`),
        }),
      );
      dispatch(getCoachData({ uid: coachData?.uid }));
    }
  };

  const unLinkAccount = () => {
    dispatch(unlinkCoachTogglToken({ id: coachData?.id }));
    dispatch(getCoachData({ uid: coachData?.uid }));
  };

  return (
    <div className="flex w-full justify-center items-center mt-10 align-middle">
      {!coachData?.toggleApiToken && (
        <div className="w-1/3 justify-center">
          <h1>Link your account to Toggl tracking system</h1>
          {!showForm && <Button onClick={() => setShowForm(true)}>Link my toogl account</Button>}
          {showForm && (
            <form>
              <h2>Connect to your toggl account</h2>
              <Input
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
              />
              <Input
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
              />
              <Button onClick={handleConnect}>Connect</Button>
            </form>
          )}
        </div>
      )}

      {coachData?.toggleApiToken && (
        <div>
          <h1>Your account is linked to Toggl tracking system</h1>
          <p>
            API token:{' '}
            <b>{Base64.fromBase64(coachData.toggleApiToken).replace(':api_token', '')}</b>
          </p>
          <Button color="error" onClick={unLinkAccount}>
            Unlink account
          </Button>
        </div>
      )}
    </div>
  );
};

export default TogglForm;
