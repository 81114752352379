import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import cn from 'mcn';
import { weekDaysOptions } from 'config';
import { ProgramActionForm } from 'types';
import { useEffect } from 'react';

const ProgramActionDays = (props: { form: UseFormReturn<ProgramActionForm, any> }) => {
  const days = useWatch({ control: props.form.control, name: 'draft.days' });
  const daysPerWeek = useWatch({ control: props.form.control, name: 'draft.daysPerWeek' });
  const firstDaySelected = useWatch({ control: props.form.control, name: 'draft.firstDay' });
  useEffect(() => {
    if (firstDaySelected && days && !days.includes(firstDaySelected)) {
      props.form.setError('draft.days', {
        message: "The 'First day' you've set isn't ticked in the 'Days for the actions'",
      });
    } else {
      props.form.clearErrors('draft.days');
    }
  }, [days, firstDaySelected]);

  if (!daysPerWeek) {
    return null;
  }

  return (
    <div>
      <div
        className={`${
          props.form.formState.errors.draft?.days ? 'border-[#FF6D7D]' : 'border-dark-2'
        } p-5 bg-[rgba(242,244,247,0.70)] border  rounded-lg gap-3 grid [grid-template-columns:max-content_1fr]`}
      >
        <div className="text-xs text-dark/60 font-medium pt-1">
          <p>Days for the actions</p>
          <p>(optional)</p>
        </div>

        <Controller
          control={props.form.control}
          name="draft.days"
          defaultValue={[]}
          rules={{ required: false }}
          render={({ field }) => (
            <div className="flex justify-evenly w-full">
              {weekDaysOptions.map((item) => {
                const checked = (field.value ?? []).includes(item.value);
                const onChange = () => {
                  if (!checked) {
                    return field.onChange([...(field.value ?? []), item.value]);
                  }
                  return field.onChange([...(field.value ?? [])].filter((x) => x !== item.value));
                };
                return (
                  <div className="flex flex-col" key={item.value}>
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      className={cn('!m-0 !p-0', [checked, '!text-primary', '!text-dark/20'])}
                      onChange={onChange}
                      checked={checked}
                    />
                    <p className="text-xs font-semibold text-dark/40">{item.short}</p>
                  </div>
                );
              })}
            </div>
          )}
        />
      </div>
      {!!props.form.formState.errors.draft?.days && (
        <p className="text-[#FF6D7D]">{props.form.formState.errors.draft?.days.message}</p>
      )}
    </div>
  );
};

export default ProgramActionDays;
