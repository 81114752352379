import React, { useState, useEffect } from 'react';
import Input from 'common/core/Input';
import { IInvitationForm } from 'types';
import logoImage from 'assets/icons/logo.svg';
import {
  ButtonBlock,
  Container,
  Element,
  ErrorText,
  LoginBlock,
  LoginText,
  Logo,
  LogoBlock,
  StyledButton,
  Wrapper,
} from '../InvitationContainer/styles';

const InvitationForm = ({ user, answerError, registerUser }: IInvitationForm) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordRepeatError, setPasswordRepeatError] = useState<string>('');

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      if (user.phone) {
        setPhone(user.phone);
      }
    }
  }, [user]);

  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setFirstNameError(e.target.value ? '' : 'Please fill firstname');
  };

  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setLastNameError(e.target.value ? '' : 'Please fill lastname');
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(e.target.value ? '' : 'Please fill password');
  };

  const handleChangePasswordRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeat(e.target.value);
    setPasswordRepeatError(e.target.value ? '' : 'Please repeat password');
  };

  const handleSubmit = () => {
    let isDataCorrect = true;
    setFirstNameError(firstName ? '' : 'Please fill firstname');
    setLastNameError(lastName ? '' : 'Please fill lastname');
    setPasswordError(password ? '' : 'Please fill password');
    setPasswordRepeatError(passwordRepeat ? '' : 'Please repeat password');

    if (firstName && lastName && password && passwordRepeat) {
      if (password.length < 6) {
        isDataCorrect = false;
        setPasswordError('Password may have length more than 6 chars.');
      }
      if (password !== passwordRepeat) {
        isDataCorrect = false;
        setPasswordRepeatError('Repeat password correctly');
      }
    } else {
      isDataCorrect = false;
    }

    if (isDataCorrect) {
      registerUser(user?.id, password, { firstName, lastName, phone, email });
    }
  };

  return (
    <Wrapper>
      <Container>
        <LogoBlock>
          <Logo src={logoImage} alt="" />
        </LogoBlock>
        <LoginBlock>
          <LoginText>Invitation</LoginText>
        </LoginBlock>
        {answerError && (
          <Element>
            <ErrorText>{answerError}</ErrorText>
          </Element>
        )}
        <Element>
          <Input
            error={!!firstNameError}
            onChange={handleChangeFirstName}
            value={firstName}
            label="First Name"
            helperText={firstNameError}
            type="text"
            fullWidth
          />
        </Element>
        <Element>
          <Input
            error={!!lastNameError}
            onChange={handleChangeLastName}
            value={lastName}
            label="Last Name"
            helperText={lastNameError}
            type="text"
            fullWidth
          />
        </Element>
        <Element>
          <Input
            onChange={handleChangePhone}
            value={phone}
            label="Phone number"
            type="text"
            fullWidth
          />
        </Element>
        <Element>
          <Input
            error={!!passwordError}
            onChange={handleChangePassword}
            value={password}
            label="Password"
            helperText={passwordError}
            type="password"
            autoComplete="off"
            fullWidth
          />
        </Element>
        <Element>
          <Input
            error={!!passwordRepeatError}
            onChange={handleChangePasswordRepeat}
            value={passwordRepeat}
            label="Repeat password"
            helperText={passwordRepeatError}
            type="password"
            fullWidth
          />
        </Element>
        <ButtonBlock>
          <StyledButton
            onClick={handleSubmit}
            fullWidth={true}
            disabled={!firstName || !lastName || !password || !passwordRepeat}
          >
            Continue
          </StyledButton>
        </ButtonBlock>
      </Container>
    </Wrapper>
  );
};

export default InvitationForm;
