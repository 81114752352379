import { Grid, MenuItem, Select } from '@mui/material';
import { Title } from '../../QuestionBlock/styles';
import { ETypeTextFieldFormat } from 'types';

const UnitBlock = ({ id, questionHandler, unit }: any) => {
  const handleChange = (e: any) => {
    questionHandler(id, 'unit', e.target.value as string);
    questionHandler(id, 'textFieldFormat', { type: ETypeTextFieldFormat.TEXT_WITH_UNIT });
  };
  return (
    <Grid container>
      <Grid item xs={2}>
        <Title>Unit</Title>
      </Grid>
      <Grid item xs={6}>
        <Select
          variant="outlined"
          fullWidth
          value={unit.value}
          onChange={handleChange}
          inputProps={{
            name: 'unit',
            id: 'unit',
          }}
        >
          <MenuItem value="oz">oz</MenuItem>
          <MenuItem value="mins">minutes</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default UnitBlock;
