import { styled } from '@mui/system';

export const Root = styled('div')({
  fontSize: 14,
  fontWeight: 500,
  minWidth: 142,
  marginLeft: 4,
  marginRight: 4,
  textTransform: 'none',
});

export const Img = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  cursor: 'pointer',
});

export const ThumbArea = styled('div')<{ isImageUploading: boolean }>(({ isImageUploading }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  background: '#F9FAFC',
  border: '1px dashed #8587E5',
  boxSizing: 'border-box',
  borderRadius: 8,
  position: 'relative',
  justifyContent: isImageUploading ? 'center' : 'flex-start',
}));

export const Span = styled('span')({
  width: 219,
  fontSize: 14,
  color: '#8587E5',
  height: 51,
  marginTop: 12,
  justifyContent: 'space-around',
});

export const FileInput = styled('input')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  opacity: 0,
  cursor: 'pointer',
});
