import React, { useEffect } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AssistantIcon from '@mui/icons-material/Assistant';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import BookIcon from '@mui/icons-material/Book';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import { Content, DrawerHeader, MenuButton, Root, StyledDrawer, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useTheme,
} from '@mui/material';
import { useAuthLogout } from 'hooks/auth/useAuthLogout';
import { useAppDispatch } from 'hooks';
import { fetchTeams } from 'store/coaches/coachesSlice';
import { IChildren } from 'types';
import logoImage from 'assets/icons/logo.svg';

const Admin = ({ children }: IChildren) => {
  const logout = useAuthLogout();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchTeams());
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigate = (path: string) => {
    setOpen(false);
    navigate(path);
  };

  return (
    <Root>
      <AppBar position="static">
        <Toolbar>
          <MenuButton
            onClick={handleDrawerOpen}
            edge="start"
            color="inherit"
            aria-label="menu"
            size="large"
          >
            <MenuIcon />
          </MenuButton>
          <Button color="inherit" style={{ position: 'absolute', right: '50px' }} onClick={logout}>
            <ExitToAppIcon />
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <StyledDrawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          paper: {
            width: 240,
          },
        }}
      >
        <DrawerHeader>
          <img
            src={logoImage}
            onClick={() => handleNavigate('/coaches')}
            onKeyDown={() => handleNavigate('/coaches')}
            alt=""
          />
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListSubheader>Coaches</ListSubheader>
          <ListItem button onClick={() => handleNavigate('/teams')}>
            <ListItemIcon>
              <GroupWorkIcon />
            </ListItemIcon>
            <ListItemText primary="Teams" />
          </ListItem>
          <ListItem button onClick={() => handleNavigate('/coaches')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Coaches" />
          </ListItem>
          <Divider />
          <ListSubheader>Program / Plans</ListSubheader>
          <ListItem button onClick={() => handleNavigate('/focus-areas')}>
            <ListItemIcon>
              <CenterFocusWeakIcon />
            </ListItemIcon>
            <ListItemText primary="Focus Areas" />
          </ListItem>
          <ListItem button onClick={() => handleNavigate('/actions')}>
            <ListItemIcon>
              <AssistantIcon />
            </ListItemIcon>
            <ListItemText primary="Actions" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleNavigate('/clients')}>
            <ListItemIcon>
              <PeopleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Clients" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleNavigate('/resources')}>
            <ListItemIcon>
              <CollectionsBookmarkIcon />
            </ListItemIcon>
            <ListItemText primary="Resources" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleNavigate('/handouts')}>
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="Handouts" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleNavigate('/surveys')}>
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText primary="Surveys" />
          </ListItem>
          <Divider />
          <ListItem />
          <ListItem button onClick={() => handleNavigate('/partnerReferrals')}>
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Partners referal" />
          </ListItem>
        </List>
      </StyledDrawer>

      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </Root>
  );
};

export default Admin;
