import { styled } from '@mui/system';
import { Tabs } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 32px',
}));

export const HeaderBlock = styled('div')(({ theme }) => ({
  paddingTop: 20,
}));

export const TabsWrapper = styled(Tabs)(({ theme }) => ({
  marginBottom: '36px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTab-root': {
    minWidth: '123px',
    minHeight: '40px',
    marginRight: '10px',
    color: 'rgba(15, 15, 33, 0.4)',
    fontWeight: 400,
    fontSize: '16px',
    textTransform: 'none',
  },
  '& .Mui-selected': {
    backgroundColor: '#52CDB1',
    borderRadius: '51px',
    color: '#FFFFFF',
    fontWeight: 500,
  },
}));

export const TitleText = styled('div')(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: '#000',
}));
