import { TFirePlan } from 'types';
import { formatDateTime } from 'utils/format/datetime';

const ChatProgramWeekPlan = (props: { index: number } & TFirePlan) => {
  return (
    <li>
      <span className="font-semibold pr-2">Program week {props.index}</span>
      <span>{formatDateTime(props.startDate.toMillis())}</span>
      <span className="px-2">-</span>
      <span>{formatDateTime(props.endDate.toMillis())}</span>
    </li>
  );
};

export default ChatProgramWeekPlan;
