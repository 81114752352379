import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import { deleteTeam, resetDeleteTeam } from 'store/coaches/coachesSlice';
import { IDialogDeleteTeam } from 'types';
import Button from 'common/core/Button';

const DialogDeleteTeam = ({ open }: IDialogDeleteTeam) => {
  const dispatch = useAppDispatch();
  const { teamToDelete } = useAppSelector((state) => state.coaches);

  const handleClose = () => {
    dispatch(resetDeleteTeam());
  };

  const handleConfirm = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(deleteTeam({ id: teamToDelete }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete team?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By deleting a team, this team will no longer be allocated to new customers or used for
          conversation with existing customers.
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          You should be 100% sure you have a team not assigned to any customers before deleting this
          team.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="error">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteTeam;
