import React, { useState, useEffect } from 'react';
import { FormControl } from '@mui/material';
import Modal from 'common/core/Modal';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  addBodyProfile,
  toggleEditStartBodyProfile,
  updateStartBodyProfile,
} from 'store/customers/bodyProfileSlice';
import { IChatProgramProfileEditStartModal } from 'types';
import { Container, InputBlock, StyledInput, Wrapper } from '../commonStyles';
import { IBodyProfile } from '@fitmate-coach/fitmate-types';
import { Timestamp } from 'firebase/firestore';
import { toast } from 'sonner';

const ChatProgramProfileEditStartModal = ({
  isOpenEditStartModal,
}: IChatProgramProfileEditStartModal) => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { profiles } = useAppSelector((state) => state.bodyProfile);
  const [bodyProfileStart, setBodyProfileStart] = useState<IBodyProfile | null>();
  const [weight, setWeight] = useState<string>('');
  const [weightError, setWeightError] = useState<string>('');

  useEffect(() => {
    if (bodyProfileStart) {
      setWeight(bodyProfileStart.weight ? bodyProfileStart.weight.toString() : '');
      setWeightError('');
    } else {
      setWeight('');
      setWeightError('');
    }
  }, [bodyProfileStart, customer]);

  useEffect(() => {
    if (profiles && profiles.length > 0) {
      setBodyProfileStart(profiles[profiles.length - 1]);
    } else {
      setBodyProfileStart(null);
    }
  }, [profiles]);

  const handleWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '0' || value === '' || Number(value)) {
      setWeight(value);
    }
  };

  const handleSubmit = () => {
    if (bodyProfileStart) {
      dispatch(
        updateStartBodyProfile({
          id: bodyProfileStart?.id,
          weight: Number(weight) ?? null,
          userId: customer?.id,
          date:
            bodyProfileStart?.createdAt instanceof Timestamp
              ? bodyProfileStart?.createdAt.toDate()
              : null,
          selfFeeling: bodyProfileStart?.selfFeeling,
          energyLevel: bodyProfileStart?.energyLevel,
        }),
      );
    } else {
      dispatch(
        addBodyProfile({
          weight: Number(weight) ?? null,
          userId: customer?.id,
          selfFeeling: null,
          bodyWaist: null,
          heartRate: null,
          energyLevel: null,
        }),
      );
    }
    dispatch(toggleEditStartBodyProfile());
    toast.success('Successfully updated');
  };

  return (
    <Modal
      widthMax={320}
      title="Update start weight"
      open={isOpenEditStartModal}
      handleClose={() => dispatch(toggleEditStartBodyProfile())}
      handleSubmit={handleSubmit}
      textBtnClose="Cancel"
      textBtnSubmit="Save"
    >
      <Wrapper>
        <Container>
          <InputBlock>
            <FormControl variant="standard">
              <StyledInput
                error={!!weightError}
                onChange={handleWeight}
                value={weight}
                label="Weight (lb)"
                helperText={weightError}
                type="text"
                fullWidth
              />
            </FormControl>
          </InputBlock>
        </Container>
      </Wrapper>
    </Modal>
  );
};

export default ChatProgramProfileEditStartModal;
