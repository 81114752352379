import { InputLabel, SelectChangeEvent } from '@mui/material';
import { Label, LinkQuestionContainer, StyledFormControl, StyledSelect } from './styles';
import { INextQuestion } from 'types';

const NextQuestion = ({
  id,
  nextQuestionId,
  blocks,
  questionHandler,
  optionHandler,
  answerId,
  isRadio,
  error,
  isDisabled,
}: INextQuestion) => {
  const handler = (e: SelectChangeEvent<unknown>) => {
    const value: string = e.target.value as string;
    if (isRadio) {
      if (optionHandler) {
        optionHandler(id, 'nextQuestionId', value, answerId || null, error || false);
      }
    } else {
      if (questionHandler) {
        questionHandler(id, 'defaultNextQuestionId', value || null);
      }
    }
  };

  return (
    <LinkQuestionContainer>
      {!isRadio && <Label>Next question</Label>}
      <StyledFormControl variant="outlined">
        <InputLabel htmlFor="nextQuestion">Next question</InputLabel>
        <StyledSelect
          // variant="standard"
          native
          disabled={isDisabled}
          onChange={(e) => handler(e)}
          label="Next question"
          value={nextQuestionId || undefined}
          inputProps={{
            name: 'nextQuestion',
            id: 'nextQuestion',
          }}
        >
          <option aria-label="None" />
          {blocks
            .filter((item) => item.id !== id)
            .map(({ id, title }) => (
              <option key={id} value={id}>
                {title.value}
              </option>
            ))}
        </StyledSelect>
      </StyledFormControl>
    </LinkQuestionContainer>
  );
};

export default NextQuestion;
