import { styled } from '@mui/system';
import Button from '../../../../../../common/core/Button';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 10,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 10,
}));

export const ButtonBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
}));

export const ButtonOutlined = styled('div')(({ theme }) => ({
  color: 'rgba(15, 15, 33, 0.6)',
  fontWeight: 600,
  border: '1px solid rgba(15, 15, 33, 0.6)',
}));
