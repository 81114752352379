import CircularProgressWithLabel from 'common/core/CircularProgressWithLabel';

const GoalProgressPreview = (props: { progress?: number; finnish?: number }) => {
  return (
    <div className="flex flex-col">
      <label className="text-left text-sm font-semibold pb-3">Progress</label>
      <div className="flex gap-4">
        <CircularProgressWithLabel
          progressValue={props.progress ?? 0}
          finishValue={Number(props.finnish ?? 0)}
          color="#6DA06F"
        />
      </div>
    </div>
  );
};

export default GoalProgressPreview;
