import { EBannerType } from '@fitmate-coach/fitmate-types';
import { useAppSelector } from 'hooks';
import { Banner, BannerText, Icon } from './styles';
import { Timestamp } from 'firebase/firestore';
import { Handshake } from '@mui/icons-material';
import { CalendarIcon } from '@mui/x-date-pickers';

const WARNING_COLOR = '#FEF3E4';
const ERROR_COLOR = '#FFEBF1';

const ChatBanner = () => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { currentWeekPlan } = useAppSelector((state) => state.weekProgram);
  const { showBanner, createdAt } = customer || {};
  const timeSinceAppDownloaded =
    createdAt instanceof Timestamp
      ? Math.floor((Date.now() - createdAt.toDate().getTime()) / 3600000)
      : 0;
  const now = new Date();
  const EOWOverdue = currentWeekPlan?.endDate;
  const EOWOverdueDate = EOWOverdue ? EOWOverdue?.toDate() : now;
  const daysSinceEOW = Math.floor(
    (now.setHours(0, 0, 0, 0) - EOWOverdueDate.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24),
  );
  let bannerColor = '#EAEAFB';
  if (!showBanner) return null;
  switch (showBanner) {
    case EBannerType.ONBOARDING:
      bannerColor = timeSinceAppDownloaded > 24 ? ERROR_COLOR : WARNING_COLOR;
      return (
        <Banner color={bannerColor}>
          <Icon as={Handshake} />
          <BannerText>
            Complete Onboarding ({timeSinceAppDownloaded} hrs since app downloaded)
          </BannerText>
        </Banner>
      );
    case EBannerType.ACTIVE_EOW:
      bannerColor = daysSinceEOW >= 1 ? ERROR_COLOR : WARNING_COLOR;
      return (
        <Banner color={bannerColor}>
          <Icon as={CalendarIcon} />
          <BannerText>
            Complete End of week review{' '}
            {daysSinceEOW >= 1
              ? `(${daysSinceEOW} days overdue)`
              : daysSinceEOW < 1 && daysSinceEOW >= 0
              ? '(due today)'
              : ''}
          </BannerText>
        </Banner>
      );
    case EBannerType.DAY_OF_EOW:
      bannerColor = daysSinceEOW >= 1 ? ERROR_COLOR : WARNING_COLOR;
      return (
        <Banner color={bannerColor}>
          <Icon as={CalendarIcon} />
          <BannerText>
            Start End of week review{' '}
            {daysSinceEOW >= 1
              ? `(${daysSinceEOW} days overdue)`
              : daysSinceEOW < 1 && daysSinceEOW >= 0
              ? '(due today)'
              : ''}
          </BannerText>
        </Banner>
      );
    case EBannerType.DAY_BEFORE_EOW:
    default:
      return null;
  }
};

export default ChatBanner;
