import { db } from 'utils/firebase/firebaseInit';
import { isNotEmpty } from 'utils/tools/isEmpty';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { getUserRef } from 'api/firestores';
import { EFeatureFlagsType, EPaymentListType } from 'types';
import { endOfDay } from 'date-fns';
import { ClientUser, ENutritionCaloriesTarget } from '@fitmate-coach/fitmate-types';
import { IPartnerData } from '@fitmate-coach/fitmate-types';
import { deleteField, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { DocumentReference } from '@firebase/firestore';

export const useUserProfile = (userId: string) => {
  const [userData] = useDocumentData<ClientUser>(
    getUserRef(userId) as DocumentReference<ClientUser>,
  );

  const editUserProfile = async (
    firstName: string,
    lastName: string,
    gender: string,
    dateOfBirth: number,
    familyStatus: string,
    location: string,
    dietaryPreferences: string,
    occupation: string,
    height: number,
    weight: number,
    otherMemorableInfo: string,
    healthGoals: string,
    isPaid: boolean,
    paymentStatus: string,
    paymentEmail: string,
    referralUrl: string,
    isReferralLinkEnabled: boolean,
    currencyReferral: string,
    referralLinkWasShow?: boolean,
    pregnancy?: boolean | undefined,
    stressLevel?: number | undefined,
    physicalActivity?: number | undefined,
    proteinsBreakfast?: number | undefined,
    proteinsLunches?: number | undefined,
    simpleCarbs?: number | undefined,
    foodCravings?: number | undefined,
    diabetes?: number | undefined,
    glp1drugs?: boolean | undefined,
    partnerData?: IPartnerData | undefined,
    partnerReferralUid?: string,
    featureFlags?: string[] | unknown[] | undefined,
    cancellationDate?: any | undefined,
    isCallCompleted?: boolean,
    cancellationPauseRequest?: string,
    cancellationPauseOutcome?: string,
    coachSentRequest?: boolean | undefined,
  ) => {
    console.log('coachSentRequest', coachSentRequest);
    const docRef = doc(db, 'users', userId);
    await updateDoc(docRef, {
      firstName: firstName,
      lastName: lastName,
      gender: gender ?? deleteField(),
      dateOfBirth: dateOfBirth ?? deleteField(),
      familyStatus: familyStatus ?? deleteField(),
      location: location ?? deleteField(),
      dietaryPreferences: dietaryPreferences ?? deleteField(),
      occupation: occupation ?? deleteField(),
      height: height ?? deleteField(),
      weight: weight ?? deleteField(),
      otherMemorableInfo: otherMemorableInfo ?? deleteField(),
      partnerReferralUid: partnerReferralUid ?? deleteField(),
      nutritionTargetsType: !userData?.nutritionTargetsType
        ? !featureFlags?.includes(EFeatureFlagsType.nutrition_baseline)
          ? ENutritionCaloriesTarget.refined_calorie_target
          : ENutritionCaloriesTarget.baseline_calorie_target
        : userData?.nutritionTargetsType,
      healthGoals: healthGoals ?? deleteField(),
      billingData: {
        ...userData?.billingData,
        ...(cancellationDate && { cancellationDate: endOfDay(cancellationDate) }),
        ...(userData?.partner ? { subscriptionStatus: paymentStatus } : {}),
        isPaid: isPaid,
        status:
          paymentStatus === EPaymentListType.ACTIVE_REF ? EPaymentListType.ACTIVE : paymentStatus,
        ...(paymentEmail && { email: paymentEmail }),
        isReferralLinkEnabled: isReferralLinkEnabled,
        referralLink: referralUrl
          ? {
              url: referralUrl,
              currency: currencyReferral,
              wasShown: referralLinkWasShow,
            }
          : null,
      },
      pregnancy: isNotEmpty(pregnancy) ? pregnancy : deleteField(),
      ...(isNotEmpty(stressLevel) || isNotEmpty(physicalActivity)
        ? {
            lifestyle: {
              ...(isNotEmpty(stressLevel) && { stressLevel: stressLevel }),
              ...(isNotEmpty(physicalActivity) && { physicalActivity: physicalActivity }),
            },
          }
        : { lifestyle: deleteField() }),
      ...(isNotEmpty(proteinsBreakfast) ||
      isNotEmpty(proteinsLunches) ||
      isNotEmpty(simpleCarbs) ||
      isNotEmpty(foodCravings)
        ? {
            dietaryHabits: {
              ...(isNotEmpty(proteinsBreakfast) && { proteinsBreakfast: proteinsBreakfast }),
              ...(isNotEmpty(proteinsLunches) && { proteinsLunches: proteinsLunches }),
              ...(isNotEmpty(simpleCarbs) && { simpleCarbs: simpleCarbs }),
              ...(isNotEmpty(foodCravings) && { foodCravings: foodCravings }),
            },
          }
        : { dietaryHabits: deleteField() }),
      diabetes: diabetes,
      glp1drugs: glp1drugs,
      ...(partnerData && { partnerData: partnerData }),
      featureFlags: featureFlags,
      introCallCompleted: isCallCompleted,
      cancellationPauseRequest: cancellationPauseRequest ?? deleteField(),
      cancellationPauseOutcome: cancellationPauseOutcome ?? deleteField(),
      ...(coachSentRequest === true && {
        referralRequest: {
          coachSentRequest: coachSentRequest,
          coachRequestDate: new Date(),
        },
      }),
      ...(coachSentRequest === false && {
        referralRequest: {
          coachSentRequest: coachSentRequest,
        },
      }),
    });
    if (!cancellationDate) {
      await updateDoc(docRef, {
        'billingData.cancellationDate': deleteField(),
      });
    }
  };

  // TODO: The upper one should be deprecated imo
  function editUserProfileAsObject(user: ClientUser) {
    const docRef = doc(db, 'users', userId);
    return updateDoc(docRef, { ...user });
  }

  function setUserCoPilotFlow(flowId: string) {
    const docRef = doc(db, 'users', userId);
    return updateDoc(docRef, {
      copilotFlow: flowId,
    });
  }

  return {
    userData,
    editUserProfile,
    editUserProfileAsObject,
    setUserCoPilotFlow,
  };
};
