import { EClientListEnums, EGeneralListEnums, ITask } from 'types';

export const maximumTaskTime = 600; // time in seconds (10 min)
export const inactivityWarningTime = 300; // time in seconds (5 min)
export const inactivityErrorTime = 600; // time in seconds (10 min)

export const generalList: ITask[] = [
  { id: { develop: 91936091, stage: 91985479, main: 91986255 }, name: EGeneralListEnums.ON_A_CALL },
  {
    id: { develop: 91936090, stage: 91986018, main: 91986257 },
    name: EGeneralListEnums.EDIT_CHECK_USER_INFO,
  },
  { id: { develop: 91936077, stage: 91986019, main: 91986258 }, name: EGeneralListEnums.NO_APP },
  {
    id: { develop: 91936076, stage: 91986020, main: 91986259 },
    name: EGeneralListEnums.CREATE_TODO_LIST,
  },
  {
    id: { develop: 91936075, stage: 91986021, main: 91986260 },
    name: EGeneralListEnums.CHECK_HANDOVER,
  },
  {
    id: { develop: 91936073, stage: 91986023, main: 91986261 },
    name: EGeneralListEnums.WRITE_HANDOVER,
  },
  {
    id: { develop: 91936071, stage: 91986024, main: 91986262 },
    name: EGeneralListEnums.CHECK_EMAILS,
  },
  { id: { develop: 91936070, stage: 91986025, main: 91986270 }, name: EGeneralListEnums.SLACK },
  {
    id: { develop: 91936068, stage: 91986026, main: 91986272 },
    name: EGeneralListEnums.CHECK_SALES_MSG,
  },
  {
    id: { develop: 91936067, stage: 91986044, main: 91986273 },
    name: EGeneralListEnums.CHECK_AIRTABLE,
  },
  {
    id: { develop: 91936065, stage: 91986045, main: 91986274 },
    name: EGeneralListEnums.CHECK_PAYMENT,
  },
  {
    id: { develop: 91936063, stage: 91986046, main: 91986275 },
    name: EGeneralListEnums.CHECK_EDIT,
  },
  { id: { develop: 91936060, stage: 91986047, main: 91986276 }, name: EGeneralListEnums.BRAKE },
  { id: { develop: 91936059, stage: 91986050, main: 91986282 }, name: EGeneralListEnums.OTHER },
];

export const clientList: ITask[] = [
  {
    id: { develop: 91977320, stage: 91986051, main: 91986284 },
    name: EClientListEnums.MANUAL_ONBOARDING,
  },
  {
    id: { develop: 91977321, stage: 91986052, main: 91986286 },
    name: EClientListEnums.ONBOARDING_MESSAGES,
  },
  {
    id: { develop: 91977322, stage: 91986053, main: 91986287 },
    name: EClientListEnums.UPDATE_PROGRAM,
  },
  { id: { develop: 91977324, stage: 91986054, main: 91986288 }, name: EClientListEnums.PROGRAM },
  { id: { develop: 91977326, stage: 91986055, main: 91986290 }, name: EClientListEnums.REMINDERS },
  { id: { develop: 91977327, stage: 91986056, main: 91986291 }, name: EClientListEnums.PROMPTS },
  { id: { develop: 91977328, stage: 91986057, main: 91986294 }, name: EClientListEnums.FEEDBACK },
  { id: { develop: 91977329, stage: 91986058, main: 91986300 }, name: EClientListEnums.ANSWERING },
  { id: { develop: 91977331, stage: 91986059, main: 91986301 }, name: EClientListEnums.SETBACKS },
  {
    id: { develop: 91977333, stage: 91986060, main: 91986302 },
    name: EClientListEnums.END_OF_WEEK_SURVEY,
  },
  {
    id: { develop: 91977334, stage: 91986061, main: 91986303 },
    name: EClientListEnums.MONTH_OFFER,
  },
  {
    id: { develop: 91977338, stage: 91986062, main: 91986304 },
    name: EClientListEnums.EDITING_CHECK_INFO,
  },
  {
    id: { develop: 91977338, stage: 91986063, main: 91986307 },
    name: EClientListEnums.CLIENT_CANCELLATION,
  },
  { id: { develop: 91977339, stage: 91986064, main: 91986308 }, name: EClientListEnums.OTHER },
  {
    id: { develop: 91977333, stage: 91986060, main: 95876670 },
    name: EClientListEnums.END_OF_WEEK_REVIEW,
  },
];
