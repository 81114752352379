import React from 'react';
import {
  BodyText,
  CircularProgressWrapper,
  DateText,
  FileImageBlock,
  FileImageIcon,
  FileImagePreview,
  FileImageText,
  ImgWrapper,
  IsReadIcon,
  MessageBlock,
  MessageBottomInfoBlock,
  MessageBottomLeftInfoBlock,
  MessageBottomRightInfoBlock,
  MessageSuperBlock,
  ProgressControls,
  StyledImg,
  UnReadIcon,
  UserNameText,
} from './style';
import { IActionTracking, IChatMessage } from 'types';
import { formatTime } from 'utils/format/datetime';
import { Done as DoneIcon, DoneAll as DoneAllIcon } from '@mui/icons-material';
import CircularProgressWithLabel from 'common/core/CircularProgressWithLabel';
import previewImage from 'assets/images/file_image.png';
import iconImage from 'assets/icons/file_image.svg';
import Button from 'common/core/Button';
import { initFeedback } from 'store/customers/progressFeedbackSlice';
import { useAppDispatch } from 'hooks';
import { addQuotedMessage } from 'store/chat/quotedMessageSlice';
import { fetchProgramActionById } from 'store/program/programActionsSlice';
import { fetchCustomerById } from 'store/customers/customerInfosSlice';

interface IChatMessagesListItemActionProgram {
  actionTracking: IActionTracking;
  userIdFrom: string;
  userNameFrom: string;
  currentUserId: string;
  createdAtSeconds: number;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
  isViewed: boolean;
  messageBy: string;
  message: IChatMessage;
}

const ChatMessagesListItemActionProgram = ({
  actionTracking,
  userIdFrom,
  currentUserId,
  userNameFrom,
  createdAtSeconds,
  toggleMenu,
  anchorEl,
  isViewed,
  messageBy,
  message,
}: IChatMessagesListItemActionProgram) => {
  const dispatch = useAppDispatch();

  const handleLaunchProgressFeedback = (e: any) => {
    e.preventDefault();
    const {
      id: messageId,
      body,
      files,
      userId,
      actionTracking: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        actionId,
      },
    } = message;

    dispatch(fetchProgramActionById({ userId, actionId }));
    dispatch(fetchCustomerById(userId));
    dispatch(initFeedback({ message, actionId: actionTracking.actionId }));
    dispatch(
      addQuotedMessage({
        id: messageId,
        body: body ? body : '',
        files,
        userId: currentUserId,
        userIdFrom: message.virtualUserIdFrom || userIdFrom,
        userNameFrom: message.virtualUserNameFrom || userNameFrom,
        ...(actionTracking && { actionTracking: actionTracking }),
      }),
    );
  };

  return (
    <MessageSuperBlock>
      <MessageBlock
        isCurrentUser={userIdFrom === currentUserId}
        anchorEl={anchorEl}
        onClick={toggleMenu}
      >
        <ProgressControls>
          <CircularProgressWrapper>
            <CircularProgressWithLabel
              progressValue={actionTracking.currentDay}
              finishValue={Number(actionTracking.daysPerWeek)}
              color="#6DA06F"
            />
          </CircularProgressWrapper>
          <BodyText as="div">{actionTracking.goal}</BodyText>
        </ProgressControls>
        {actionTracking.details && (
          <BodyText
            sx={{
              marginTop: '10px',
            }}
          >
            {actionTracking.details}
          </BodyText>
        )}
        {actionTracking.files && actionTracking.files?.length > 0 && (
          <ImgWrapper>
            {actionTracking.files.map((img) => {
              return img.thumbnailUrl ? (
                <FileImageBlock key={img.name}>
                  <StyledImg key={img.name} src={img.thumbnailUrl} alt={img.name} />
                </FileImageBlock>
              ) : (
                <FileImageBlock key={img.name}>
                  <FileImagePreview src={previewImage} alt="" />
                  <FileImageIcon src={iconImage} alt="" />
                  <FileImageText>{img.name}</FileImageText>
                </FileImageBlock>
              );
            })}
          </ImgWrapper>
        )}
        <MessageBottomInfoBlock>
          <MessageBottomLeftInfoBlock>
            <UserNameText isCurrentUser={userIdFrom === currentUserId}>{messageBy}</UserNameText>
          </MessageBottomLeftInfoBlock>
          <MessageBottomRightInfoBlock>
            <DateText isCurrentUser={userIdFrom === currentUserId}>
              {formatTime(createdAtSeconds * 1000)}
            </DateText>
            {userIdFrom !== currentUserId && (
              <>{isViewed ? <IsReadIcon as={DoneAllIcon} /> : <UnReadIcon as={DoneIcon} />}</>
            )}
          </MessageBottomRightInfoBlock>
        </MessageBottomInfoBlock>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleLaunchProgressFeedback(e)}
          >
            Give feedback on progress
          </Button>
        </div>
      </MessageBlock>
    </MessageSuperBlock>
  );
};

export default ChatMessagesListItemActionProgram;
