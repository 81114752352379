import { useState } from 'react';
import { Edit, LinearScaleRounded } from '@mui/icons-material';
import { SelectChangeEvent, Typography } from '@mui/material';
import {
  AnswerTitle,
  EditIcon,
  Icon,
  Item,
  LocalWrapper,
  Question,
  QuestionText,
  Row,
  SliderWrapper,
  StyledSelect,
  TypeTitle,
} from '../styles';
import { ISliderSurvey } from 'types';

const SliderComponent = ({
  indexStage,
  data,
  isEditable,
  suerveyId,
  handleEditSurvey,
}: ISliderSurvey) => {
  const { id, question, type, answer, title } = data;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [value, setValue] = useState<string[]>(answer?.answers || ['']);

  const handleInput = (event: SelectChangeEvent<unknown>) => {
    setValue([event.target.value as string]);

    if (handleEditSurvey) {
      handleEditSurvey(suerveyId || '', indexStage || 0, id, [event.target.value as string]);
    }
  };

  return (
    <Question>
      <Row>
        <LocalWrapper>
          <Icon as={LinearScaleRounded} />
          <QuestionText>
            {title} - {question}
          </QuestionText>
        </LocalWrapper>
        {isEditable && <EditIcon as={Edit} onClick={() => setEditMode(true)} />}
      </Row>
      <Row>
        <LocalWrapper>
          <TypeTitle>Type</TypeTitle>
          <Typography>{type}</Typography>
        </LocalWrapper>
      </Row>
      <Row>
        <LocalWrapper>
          <AnswerTitle>Answers</AnswerTitle>
          <SliderWrapper>
            <StyledSelect
              // variant="standard"
              defaultValue={value}
              onChange={handleInput}
              native
              disabled={!editMode}
            >
              {[...Array(data.slider.max)].map((item, index) => (
                <option key={`survey-select-option${index}`} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </StyledSelect>
            <Item as={Typography}>&nbsp;to&nbsp;</Item>
            <Item as={Typography}>{data.slider.max}</Item>
          </SliderWrapper>
        </LocalWrapper>
      </Row>
    </Question>
  );
};

export default SliderComponent;
