import { styled } from '@mui/system';
import { AccordionSummary, Typography } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#F9FAFC !important',
  padding: 0,
  '&:hover': {
    backgroundColor: '#F2F4F7 !important',
  },
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.Mui-expanded': {
    marginLeft: 0,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    height: 20,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
}));

export const MenuIcon = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const SubMenu = styled('div')(({ theme }) => ({
  minWidth: 190,
}));

export const CategoryName = styled(Typography)<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  fontWeight: 600,
  fontSize: 16,
  color: 'rgba(15, 15, 33, 0.6)',
  ...(isSelected && {
    color: 'black',
  }),
}));

export const Arrow = styled(ArrowRight)(({ theme }) => ({
  fontSize: '1.2rem',
}));
