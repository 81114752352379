import { useDocumentData } from 'react-firebase-hooks/firestore';
import { getDocs, query, where } from '@firebase/firestore';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { getTeamRef, getUserCollectionRef, getNewMessageUpdateRef } from 'api/firestores';
import { getClientUserStatus } from 'utils/data/user';
import { Danger, Live, Warn } from './styles';
import { TFireBaseUser } from '@fitmate-coach/fitmate-types';

const ChatThreadsFilterTeamLabel = (p: { id: string; active: boolean }) => {
  const [data] = useDocumentData(getTeamRef(p.id));
  const [refresh] = useDocumentData(getNewMessageUpdateRef());
  const [users, setUsers] = useState<TFireBaseUser[]>([]);

  useEffect(() => {
    let isMounted = true;

    getDocs(
      query(
        getUserCollectionRef(),
        where('coachAssignment.team', '==', p.id),
        where('billingData.status', '!=', 'cancelled'),
      ),
    ).then((querySnapshot) => {
      if (isMounted) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setUsers(querySnapshot.docs.map((docSnap) => ({ id: docSnap.id, ...docSnap.data() })));
      }
    });

    return () => {
      isMounted = false;
    };
  }, [refresh?.at.toMillis()]);

  const statuses = users.map((x) => getClientUserStatus(x));
  const danger = statuses?.reduce((a, b) => (b.status === 'danger' ? a + 1 : a), 0);
  const warn = statuses?.reduce((a, b) => (b.status === 'warn' ? a + 1 : a), 0);
  const live = users.filter((u) => u.copilotFlow && u.lastMessage?.answerRequired).length;

  return (
    <div style={{ display: 'flex', gap: '.3rem', alignItems: 'center' }}>
      <Typography
        color={p.active ? 'primary' : 'initial'}
        style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(15, 15, 33, 0.6)' }}
      >
        {data?.mainCoach?.firstName}
      </Typography>
      <div className="flex flex-row">
        {live > 0 && (
          <div className="flex flex-row">
            <Icon icon="mdi:wireless" height={22} />
            <Live>{live}</Live>
          </div>
        )}
        {danger ? <Danger>{danger}</Danger> : null}
        {warn ? <Warn>{warn}</Warn> : null}
      </div>
    </div>
  );
};

export default ChatThreadsFilterTeamLabel;
