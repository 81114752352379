import { useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconTypo, HeaderBlock, LeftBlock, RightBlock, TitleText } from './styles';
import { IChatAllClientsHeader } from 'types';

const ChatAllClientsHeader = ({ handleOpenAllClients, currentPage }: IChatAllClientsHeader) => {
  const refDiv = useRef<any>();

  useEffect(() => {
    refDiv?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentPage]);

  return (
    <HeaderBlock ref={refDiv}>
      <LeftBlock>
        <TitleText>All clients</TitleText>
      </LeftBlock>
      {handleOpenAllClients && (
        <RightBlock>
          <CloseIconTypo onClick={handleOpenAllClients}>
            <CloseIcon />
          </CloseIconTypo>
        </RightBlock>
      )}
    </HeaderBlock>
  );
};

export default ChatAllClientsHeader;
