import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '30px 20px 10px 30px',
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  paddingRight: 10,
}));

export const HeaderIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.4)',
}));
