import { Container, Grid, MenuItem, Select, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchProgramActionById, listProgramActions } from 'store/program/programActionsSlice';
import { fetchCustomerById } from 'store/customers/customerInfosSlice';
import { initFeedback, resetFeedback } from 'store/customers/progressFeedbackSlice';
import { initFaq } from 'store/customers/progressFaqSlice';
import { IProgramAction } from 'types';

const MessageFeedbackForm = () => {
  const dispatch = useAppDispatch();
  const { message } = useAppSelector((state) => state.progressFeedback);
  const { programActions } = useAppSelector((state) => state.programActions);

  const [feedbackType, setFeedbackType] = React.useState('');
  const [customerMessage, setCustomerMessage] = React.useState(message?.body ?? '');

  useEffect(() => {
    if (message?.userId) {
      dispatch(listProgramActions(message.userId));
    }
  }, [message]);

  const handleSelectFeedbackType = (e: any) => {
    setFeedbackType(e.target.value);
    if (e.target.value === 'faq') {
      dispatch(initFaq({ message: { ...message, body: customerMessage } }));
      dispatch(resetFeedback());
    }
  };

  const handleSelectGoal = (e: any) => {
    dispatch(fetchProgramActionById({ userId: message.userId, actionId: e.target.value }));
    dispatch(fetchCustomerById(message.userId));
    dispatch(initFeedback({ message, actionId: e.target.value }));
  };

  return (
    <Container>
      <Grid item xs={12}>
        <Typography>User message:</Typography>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          value={customerMessage}
          onChange={(e) => setCustomerMessage(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>What type of feedback do you need ?</Typography>
        <Select
          variant="outlined"
          fullWidth
          value={feedbackType}
          onChange={handleSelectFeedbackType}
        >
          <MenuItem value="faq">FAQ</MenuItem>
          <MenuItem value="fop">Feedback on progress</MenuItem>
        </Select>
      </Grid>
      {feedbackType === 'fop' && (
        <Grid item xs={12}>
          <Typography>Please select the goal area this is about:</Typography>
          <Select fullWidth variant="outlined" onChange={handleSelectGoal}>
            {programActions?.map((action: IProgramAction) => (
              <MenuItem key={action.id} value={action.id}>
                {action.goal}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
    </Container>
  );
};

export default MessageFeedbackForm;
