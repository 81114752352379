// TODO Need to rewrite to regexp and add format maybe with country cods
export const phoneValidation = (valueNew: string) => {
  if (valueNew && valueNew.length > 0) {
    const phoneSymbols = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+']);
    return valueNew
      .split('')
      .filter((char) => phoneSymbols.has(char))
      .join('');
  } else {
    return '';
  }
};
