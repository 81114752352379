import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addDoc, collection, doc, FieldValue, updateDoc } from 'firebase/firestore';
import { auth, db } from 'utils/firebase/firebaseInit';
import { ClientUser, ENutritionCaloriesTarget } from '@fitmate-coach/fitmate-types';
import { deleteField, getDoc } from '@firebase/firestore';
import { matchCustomerToStripeCustomer } from 'api/user';

export interface ICustomerState {
  loading: boolean;
  customer: ClientUser | null | undefined;
  isReadOnly: boolean;
  stripeLoading: boolean;
}

const initialState: ICustomerState = {
  isReadOnly: false,
  customer: null,
  loading: true,
  stripeLoading: false,
};

const getUserRef = (id: string) => doc(db, 'users', id);

export const fetchCustomerById = createAsyncThunk(
  'customerInfos/fetchCustomerById',
  async (id: string | undefined) => {
    if (!id) return;
    const customerRef = doc(db, 'users', id);
    const docSnap = await getDoc(customerRef);
    if (!docSnap.exists()) {
      return;
    }
    return { id, ...docSnap.data() };
  },
);

export const matchCustomerToStripe = createAsyncThunk(
  'customerInfos/mathCustomerToStripeCustomer',
  async (email: string | undefined) => {
    if (!email) return;
    const token = await auth.currentUser?.getIdToken(true);
    const response = await matchCustomerToStripeCustomer(email, token);
    return response;
  },
);

export const launchMigration = createAsyncThunk(
  'customerInfos/launchMigration',
  async (id: string, APIThunk) => {
    const { dispatch } = APIThunk;
    await Promise.all([
      updateDoc(getUserRef(id), {
        migration: 'started',
      }),
      addDoc(collection(db, 'queue'), {
        type: 'migration',
        userId: id,
        createdAt: new Date(),
      }),
    ]);
    dispatch(fetchCustomerById(id));
  },
);

export const assignActivitiesToCustomer = createAsyncThunk(
  'customerInfos/assignActivities',
  async (data: ClientUser, APIThunk) => {
    if (!data.id) return;
    const { dispatch } = APIThunk;
    try {
      const { activities, id } = data;
      const docRef = doc(db, 'users', id);

      await updateDoc(docRef, {
        activities,
      });

      dispatch(fetchCustomerById(id));
    } catch (e) {
      console.log(e);
    }
  },
);

type assignNutritionTargetsToCustomerType = {
  id?: string;
  nutritionTargets: Partial<ClientUser['nutritionTargets']>;
};

export const assignNutritionTargetsToCustomer = createAsyncThunk(
  'customerInfos/assignNutritionTargets',
  async (data: assignNutritionTargetsToCustomerType, APIThunk) => {
    if (!data.id) return;
    const { dispatch } = APIThunk;
    try {
      const { nutritionTargets, id } = data;
      const docRef = doc(db, 'users', id);

      await updateDoc(docRef, {
        nutritionTargets,
      });

      dispatch(fetchCustomerById(id));
    } catch (e) {
      console.log(e);
    }
  },
);
// Assign nutritionTargetType + reset nutritionTargets
export const assignNutritionTargetsTypeToCustomer = createAsyncThunk(
  'customerInfos/assignNutritionTargetsType',
  async (data: ClientUser, APIThunk) => {
    if (!data.id) return;
    const { dispatch } = APIThunk;
    try {
      const { nutritionTargetsType, id } = data;
      const docRef = doc(db, 'users', id);

      let nutritionTargetsUpdated: Partial<ClientUser['nutritionTargets']> | FieldValue = {};
      if (nutritionTargetsType === ENutritionCaloriesTarget.baseline_calorie_target) {
        nutritionTargetsUpdated = deleteField();
      } else {
        nutritionTargetsUpdated = {
          ...data.nutritionTargets,
          caloriesTarget: 0,
        };
      }
      await updateDoc(docRef, {
        nutritionTargetsType,
        nutritionTargets: nutritionTargetsUpdated,
      });

      dispatch(fetchCustomerById(id));
    } catch (e) {
      console.log(e);
    }
  },
);

export const customerInfosSlice = createSlice({
  name: 'customerInfos',
  initialState,
  reducers: {
    setReadOnly: (state, action) => {
      state.isReadOnly = !!action.payload;
    },
    resetCurrentCustomerId: (state) => {
      state.customer = null;
      state.loading = true;
    },
    saveCustomer: (state, action) => {
      state.customer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomerById.fulfilled, (state, action) => {
        state.customer = action.payload as ClientUser;
        state.loading = false;
      })
      .addCase(matchCustomerToStripe.pending, (state) => {
        state.stripeLoading = true;
      })
      .addCase(matchCustomerToStripe.fulfilled, (state, action) => {
        state.stripeLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setReadOnly, resetCurrentCustomerId } = customerInfosSlice.actions;

export default customerInfosSlice.reducer;
