import {
  IChatMessage,
  IChatUser,
  EMessageType,
  IThreadsFilterValues,
  IUserThreadData,
  IUserThreadDataComplete,
  IProgramAction,
  IWeekProgram,
  TFirePlan,
} from 'types';
import { EStatus } from 'types';

import { calculateAge, getTimezoneTime } from 'utils/format/datetime';
import {
  differenceInHours,
  sub,
  add,
  startOfDay,
  startOfHour,
  endOfDay,
  isBefore,
  isAfter,
  differenceInWeeks,
} from 'date-fns';
import { TFireBaseUser } from '@fitmate-coach/fitmate-types';
import { Timestamp } from 'firebase/firestore';

/**
 * Make priority in thread list
 *
 * Priority
 * 1 Users with saved and not viewed statuses.
 * 2 Users with saved status.
 * 3 Users with Unread status (this includes the unread status, which is set manually, and if we haven't read the last message from the user).
 * 4 Users who have the last message, it is read, the status is not unread, and it is not saved
 * 5 Users who have no last message.
 *
 * Note!
 * - Each group must not contain other groups.
 * - Each group is sorted by the date of the last message. Except for the group with no last message
 * @param {IUserThreadDataComplete[]} list - List to be sorted.
 * @return {IUserThreadDataComplete[]} A list sorted by rules
 */

const orderFunction = (list: IUserThreadDataComplete[]): IUserThreadDataComplete[] => {
  const compare = (a: any, b: any) => b.lastMessage.createdAt - a.lastMessage.createdAt;
  const isSaved = (user: IUserThreadDataComplete) => user.isSaved;

  const isUnread = (user: IUserThreadDataComplete) =>
    (user.isMessagesUnRead || checkUnreadMessage(user.lastMessage)) && !isWithoutMessage(user);
  const isReadMessage = (user: IUserThreadDataComplete) =>
    !isSaved(user) && !isUnread(user) && !isWithoutMessage(user);
  const isWithoutMessage = (user: IUserThreadDataComplete) => user.lastMessage?.body === '';

  const listSavedAndUnread = list
    .filter((user: IUserThreadDataComplete) => isSaved(user) && isUnread(user))
    .sort((a: IUserThreadDataComplete, b: IUserThreadDataComplete) => compare(a, b));

  const listSaved = list
    .filter(
      (user: IUserThreadDataComplete) => isSaved(user) && !isUnread(user) && !isReadMessage(user),
    )
    .sort((a: IUserThreadDataComplete, b: IUserThreadDataComplete) => compare(a, b));

  const listUnread = list
    .filter(
      (user: IUserThreadDataComplete) => isUnread(user) && !isSaved(user) && !isReadMessage(user),
    )
    .sort((a: IUserThreadDataComplete, b: IUserThreadDataComplete) => compare(a, b));

  const listRead = list
    .filter((user: IUserThreadDataComplete) => isReadMessage(user))
    .sort((a: IUserThreadDataComplete, b: IUserThreadDataComplete) => compare(a, b));

  const listWithoutMessage = list.filter(
    (user: IUserThreadDataComplete) => isWithoutMessage(user) && !isSaved(user),
  );

  return [...listSavedAndUnread, ...listSaved, ...listUnread, ...listRead, ...listWithoutMessage];
};

export const prepareFromAssignment = (list: IUserThreadData[]): IUserThreadDataComplete[] => {
  const userList: any[] = Object.entries(list).map((user) => {
    const [userId, userData] = user;
    return {
      ...userData,
      userId,
      avatarUrl: '',
      lastMessage: setLastMessage(userData),
      isSaved: userData.isSaved ?? false,
      isMessagesUnRead: userData.isMessagesUnRead ?? false,
      messageLastFromUser: userData.lastMessage?.userId === userData.lastMessage?.userIdFrom,
      partner: userData.partner ?? '',
      diabetes: userData.diabetes ?? 0,
      glp1drugs: userData.glp1drugs ?? false,
    };
  });
  return orderFunction(deleteCancelledUsers(userList));
};

export const prepareForAssigmentSave = (user: TFireBaseUser): IUserThreadData => {
  const copyUser: any = { ...user };
  delete copyUser.userId;
  delete copyUser.messageLastFromUser;
  return copyUser;
};

// users from list New and from allClients list
export const prepareFromFullUser = (
  usersNewList: IChatUser[],
  savedUsers?: IUserThreadData[],
): IUserThreadDataComplete[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const userList: IUserThreadDataComplete[] = usersNewList.map((user) => {
    return {
      userId: user.userId,
      uid: user.uid,
      email: user.email,
      billingData: user.billingData ?? null,
      firstName: user.firstName ?? '',
      isMain: false,
      isSaved:
        user.userId && savedUsers ? !!savedUsers[user.userId as keyof typeof savedUsers] : false,
      isMessagesUnRead: user.isMessagesUnRead ?? false,
      lastMessage: user.lastMessage ? setLastMessage(user) : { body: '' },
      lastName: user.lastName ?? '',
      lastSeenAt: user.lastSeenAt ? user.lastSeenAt : { seconds: 0, nanoseconds: 0 },
      avatarUrl: user.avatarUrl ?? '',
      messageLastFromUser: user.lastMessage?.userId === user.lastMessage?.userIdFrom,
      timezone: user.timezone ?? '',
      coachAssignment: user.coachAssignment,
      partner: user.partner ?? '',
      diabetes: user.diabetes ?? 0,
      glp1drugs: user.glp1drugs ?? false,
      appVersion: user.appVersion ?? '',
    };
  });
  return orderFunction(userList);
};

const setLastMessage = (user: IUserThreadData | IChatUser) => ({
  ...user.lastMessage,
  body: user.lastMessage ? setEMessageType(user.lastMessage) : '',
});

const setEMessageType = (message: IChatMessage) => {
  if (message?.surveyData) {
    if (message?.surveyData.status === EStatus.ASSIGNED) return EMessageType.SURVEY_SENT;
    if (message?.surveyData.status === EStatus.CANCELED) return EMessageType.SURVEY_DECLINED;
    if (message?.surveyData.status === EStatus.COMPLETED) return EMessageType.SURVEY_COMPLETED;
    return EMessageType.SURVEY;
  } else {
    if (message.isDeleted) {
      return EMessageType.DELETED;
    } else {
      if (message?.files?.length) {
        return EMessageType.FILE;
      } else {
        if (message?.references) {
          return EMessageType.RESOURCE;
        }
      }
    }
    if (message?.actionTracking) {
      return EMessageType.ACTION_TRACKING;
    }
  }
  if (message.body) {
    return message.body || '';
  }
};

export const deleteCancelledUsers = (
  users: IUserThreadDataComplete[],
): IUserThreadDataComplete[] => {
  return users.filter((user) => user.billingData?.status !== 'cancelled');
};

export const filterList = (
  usersList: IUserThreadDataComplete[],
  filter: string,
  searchValue: string,
): IUserThreadDataComplete[] => {
  const filterUsersBySearch = (
    list: IUserThreadDataComplete[],
    searchParam: string,
  ): IUserThreadDataComplete[] => {
    return list.filter((user: IUserThreadDataComplete) => {
      const fullNameLower = user.firstName?.toLowerCase() + ' ' + user.lastName?.toLowerCase();
      return fullNameLower.lastIndexOf(searchParam.toLowerCase()) !== -1;
    });
  };

  const isWithoutMessage = (message?: IChatMessage) => message?.body === '';

  if (filter === IThreadsFilterValues.READ) {
    return filterUsersBySearch(usersList, searchValue).filter(
      ({ isMessagesUnRead, lastMessage }) => !isMessagesUnRead && !checkUnreadMessage(lastMessage),
    );
  } else if (filter === IThreadsFilterValues.UNREAD) {
    return filterUsersBySearch(usersList, searchValue).filter(
      ({ isMessagesUnRead, lastMessage }) => {
        return (
          !isWithoutMessage(lastMessage) && (!!isMessagesUnRead || checkUnreadMessage(lastMessage))
        );
      },
    );
  } else {
    return filterUsersBySearch(usersList, searchValue);
  }
};

export const shouldShowMessageInChat = (message: IChatMessage) =>
  !message.isDeleted &&
  (!message.surveyData ||
    message.surveyData?.status === EStatus.COMPLETED ||
    message.surveyData?.status === EStatus.CANCELED ||
    message.surveyData?.status === EStatus.ASSIGNED);

export const checkUnreadMessage = (message?: IChatMessage): boolean => {
  const isUnreadSurveyMessage =
    message?.isViewedByCoach === false &&
    message?.surveyData?.status &&
    (message.surveyData.status === EStatus.CANCELED ||
      message.surveyData.status === EStatus.COMPLETED);

  const isUnreadMessage = !message?.isViewed && message?.userId === message?.userIdFrom;

  return isUnreadSurveyMessage || isUnreadMessage;
};

export const getWeekNumberProgram = (weeks: any, today: any) => {
  const weekNumber = 'not applicable';

  if (!weeks) {
    return weekNumber;
  }

  return weeks[weeks.length - 1].week;
};

export const getGlp1AndDiabetes = (user: TFireBaseUser) => {
  const { diabetes, glp1drugs } = user;
  const diabetesStatuses = (diabetes: any) => {
    switch (diabetes) {
      case 1:
        return 'Pre-diabetes';
      case 2:
        return 'Diabetes Type-1';
      case 3:
        return 'Diabetes Type-2';
      case undefined:
      default:
        return 'No diabetes';
    }
  };
  return `GLP1: ${
    glp1drugs ? 'GLP1 drugs' : 'No GLP1 drugs'
  }\nMy diabetes status: ${diabetesStatuses(diabetes)}`;
};

export const getUserAge = (user: TFireBaseUser) => {
  const { dateOfBirth } = user;
  if (!dateOfBirth) return 'missing information';
  const age = calculateAge(dateOfBirth);
  return `${age} years old`;
};

export const getUserCalendarWeek = (user: TFireBaseUser) => {
  const { createdAt } = user;
  if (!createdAt) return 'missing information';
  const today = new Date();
  return differenceInWeeks(today, (createdAt as Timestamp).toDate());
};

export const getStartWeight = (profiles: any) => {
  if (!profiles.length) return 'missing information';
  return `${profiles[profiles.length - 1].weight} lbs`;
};

export const getCurrentWeight = (profiles: any) => {
  if (!profiles.length) return 'missing information';
  return `${profiles[0].weight} lbs`;
};

type ActivityPlanKey = 'nutrition' | 'full' | 'low';

export const getActivityPlan = (user: TFireBaseUser) => {
  const { partnerData } = user;
  if (!partnerData) return 'not applicable';

  const activityPlan = {
    nutrition: 'Nutrition only',
    full: 'Full program',
    low: 'Low activity only',
  };

  const clearance = partnerData.medicalClearance as ActivityPlanKey;
  const plan = activityPlan[clearance] || 'none';

  return `${partnerData.medicalClearance ? plan : 'missing information'}.
  Additional note on physical activity (in case client is referred by medical partner):
  ${partnerData?.medicalClearanceDescription ?? 'Nothing specified'}`;
};

export const getLastWeekNewGoals = (
  weeks: { id: string & IWeekProgram }[],
  programActions: IProgramAction[],
) => {
  if (!weeks || !programActions) return 'not applicable';
  const currentWeekProgram = weeks[weeks.length - 1];
  const currentGoals = programActions.filter(
    (action: IProgramAction) => action.planId === currentWeekProgram.id && action.published,
  );
  const newGoals = currentGoals.filter(
    (action: IProgramAction) => action.published?.status === 'new' && !action.published?.isDeleted,
  );
  let content = '\n| Goal | Progress | Strategy |\n';
  content += '| --- | --- | --- |\n';
  newGoals.forEach((goal: IProgramAction) => {
    content += `|${goal.published?.goal} | (progress:${goal.lastTracking?.currentDay ?? 0}/${
      goal.published?.daysPerWeek
    }) | ${goal.published?.strategy} |\n`;
  });

  return content;
};

export const getLastWeekExistingGoals = (
  weeks: { id: string & IWeekProgram }[],
  programActions: IProgramAction[],
) => {
  if (!weeks || !programActions) return 'not applicable';
  const currentWeekProgram = weeks[weeks.length - 1];
  const currentGoals = programActions.filter(
    (action: IProgramAction) => action.planId === currentWeekProgram.id && action.published,
  );
  const newGoals = currentGoals.filter(
    (action: IProgramAction) =>
      action.published?.status === 'existing' && !action.published?.isDeleted,
  );
  let content = '\n| Goal | Progress | Strategy |\n';
  content += '| --- | --- | --- |\n';
  newGoals.forEach((goal: IProgramAction) => {
    content += `|${goal.published?.goal} | (progress:${goal.lastTracking?.currentDay ?? 0}/${
      goal.published?.daysPerWeek
    }) | ${goal.published?.strategy} |\n`;
  });

  return content;
};

export const getLastWeekObstacles = (currentWeek: TFirePlan) => {
  if (!currentWeek) return 'not applicable';
  if (!currentWeek.published) return 'not applicable';

  const obstacles = currentWeek.published.plan.filter(
    (focusArea: any) => focusArea.category === 'obstacle',
  );
  if (!obstacles.length) {
    return 'nothing set yet';
  }
  let content = '';
  obstacles.forEach((obstacle: any) => {
    content += `${obstacle.title},\n `;
  });
  return content;
};

export const getClientUserStatus = (
  user: Pick<TFireBaseUser, 'id' | 'timezone' | 'lastMessage'>,
) => {
  const timezone = user?.timezone;
  const userTime = getTimezoneTime(new Date(), timezone);
  const userDayStart = add(startOfHour(startOfDay(userTime)), { hours: 7 }); // 07:00
  const useDayEnd = sub(startOfHour(endOfDay(userTime)), { hours: 1 }); // 22:00
  const userIsNight = isBefore(userTime, userDayStart) || isAfter(userTime, useDayEnd);
  const isNotDisturb = timezone && userIsNight;

  const answerRequired = user.lastMessage?.answerRequired
    ? user.lastMessage?.answerRequired.toDate()
    : null;

  const hoursSinceAnswerRequired = answerRequired
    ? differenceInHours(new Date(), answerRequired)
    : undefined;

  const status = (() => {
    if (isNotDisturb) return 'night';
    if (typeof hoursSinceAnswerRequired === 'number') {
      if (hoursSinceAnswerRequired >= 6) return 'danger';
      if (hoursSinceAnswerRequired >= 3) return 'warn';
      return 'active';
    }
    return 'inactive';
  })();

  return { status, hoursSinceAnswerRequired } as const;
};
