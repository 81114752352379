import { styled, Theme } from '@mui/system';
import { Chip, Typography } from '@mui/material';
import MenuItem from '../../../../../common/core/MenuItem';

export const Container = styled('div')({
  position: 'relative',
});
export const ContainerWatched = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(15, 15, 33, 0.20)',
  zIndex: 1,
});
export const BadgeWatched = styled(Chip)<{ addExtraMargin: boolean }>(({ addExtraMargin }) => ({
  backgroundColor: '#000',
  color: '#fff',
  height: 17,
  fontSize: 10,
  marginLeft: 12,
  ...(addExtraMargin && {
    marginLeft: 22,
  }),
}));

export const BadgeUnviewed = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: 'rgba(15, 15, 33, 0.20)',
  zIndex: 1,
}));

export const ContentBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 94,
  width: 340,
}));

export const LeftBlock = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  margin: 10,
}));

export const CentralBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: 78,
}));

export const NameBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: 6,
  maxWidth: 140,
}));

export const MessageBlock = styled('div')(({ theme }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: 12,
}));

export const RightBlock = styled('div')(({ theme }) => ({
  width: 40,
}));

export const UserNameText = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  fontWeight: 600,
  lineHeight: '22px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const UserNameTextChosen = styled('div')(({ theme }) => ({
  color: '#fff !important',
}));

export const StyledImgIcon = styled('img')(({ theme }) => ({
  width: 18,
  height: 18,
  color: '#24C09F',
  marginLeft: 4,
  margin: 0,
  padding: 0,
}));

export const PaidIconChosen = styled('div')(({ theme }) => ({
  color: '#fff !important',
}));

export const CancelledIcon = styled('div')(({ theme }) => ({
  width: 18,
  height: 18,
  color: 'rgba(15, 15, 33, 0.6)',
  marginLeft: 4,
  margin: 0,
  padding: 0,
}));

export const CancelledIconChosen = styled('div')(({ theme }) => ({
  color: '#fff !important',
}));

export const SavedIcon = styled('div')(({ theme }) => ({
  width: 16,
  height: 16,
  color: theme.palette.primary.main,
  marginLeft: 4,
  margin: 0,
  padding: 0,
}));

export const SavedIconChosen = styled('div')(({ theme }) => ({
  color: '#fff !important',
}));

export const MessageText = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  color: '#666666',
  width: 190,
  lineHeight: '17px',
  wordWrap: 'break-word',
}));

export const MessageTextChosen = styled('div')(({ theme }) => ({
  color: '#fff !important',
}));

export const DateText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  fontSize: 12,
  fontWeight: 350,
  color: 'rgba(15, 15, 33, 0.3)',
  top: 12,
  right: 32,
}));

export const DateTextChosen = styled('div')(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.5)',
}));

export const UnviewedNumberBlock = styled('div')(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#9FA1F2',
}));

export const UnviewedNumberBlockChosen = styled('div')(({ theme }) => ({
  color: '#9FA1F2 !important',
  backgroundColor: '#fff !important',
}));

export const BottomBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  height: 1,
}));

export const BottomBlockLine = styled('div')(({ theme }) => ({
  width: '80%',
  height: 1,
  backgroundColor: '#E4E7ED',
}));

export const BageCircleBlock = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  right: 17,
  color: '#9FA1F2',
}));

export const BageCircleBlockChosen = styled('div')(({ theme }) => ({
  color: '#fff !important',
}));

export const MenuBlock = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 6,
}));

export const MenuButton = styled('div')(({ theme }) => ({
  padding: 8,
}));

export const StyledMenuIcon = styled('div')(({ theme }) => ({
  fontSize: 22,
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
}));

export const SubMenu = styled(MenuItem)(({ theme }) => ({
  minWidth: 180,
}));

export const SubMenuIcon = styled('div')(({ theme }) => ({
  fontSize: 22,
}));
