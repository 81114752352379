import { IAction, IActionCategoryNew, IActionCategoryNewType } from 'types';
import { IHabitTypeFull } from '@fitmate-coach/fitmate-types';

export const uniqueHabitTypes = (arr: IActionCategoryNew[]): string[] => {
  let habitArr: string[] = [];
  arr.forEach((category) => {
    habitArr = [...habitArr, ...category.habitTypes];
  });

  return habitArr.filter((value, index, array) => array.indexOf(value) === index);
};

export const buildHabitType = (list: IActionCategoryNew[], type: string): IHabitTypeFull[] => {
  const arr: IHabitTypeFull[] = [];
  list.forEach((item) =>
    item.habitTypes.forEach((habit) => {
      if (habit === type) {
        arr.push({ name: item.name, id: item.id, sortingIndex: item.sortingIndex });
      }
    }),
  );
  return arr.sort((a, b) => a.sortingIndex - b.sortingIndex);
};

export const buildReversStructure = (list: IActionCategoryNew[]) =>
  uniqueHabitTypes(list)
    .map((item: string) => {
      return {
        enum: item,
        label: ActionCategoryNewEnums[item].name,
        habitTypes: buildHabitType(list, item),
        sortingIndex: ActionCategoryNewEnums[item].sortingIndex,
      };
    })
    .sort((a, b) => a.sortingIndex - b.sortingIndex);

export const isAllActionNew = (list: IAction[]): boolean =>
  list.every((action) => !!action.sortingIndex && !!action.categoryId && !!action.habitType);

const ActionCategoryNewEnums: IActionCategoryNewType = {
  core: { name: 'Core habits', sortingIndex: 0 },
  additional: { name: 'Additional habits', sortingIndex: 1 },
  obstacle: { name: 'Obstacles', sortingIndex: 2 },
};
