import { styled } from '@mui/system';
import { FormControlLabel } from '@mui/material';

export const StyledTagItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 65,
  '&:hover': {
    backgroundColor: '#F2F4F7 !important',
  },
}));

export const TagName = styled(FormControlLabel)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  color: 'rgba(15, 15, 33, 0.5)',
}));

export const Arrow = styled('div')(({ theme }) => ({
  fontSize: '1.2rem',
}));

export const Hidden = styled('div')(({ theme }) => ({
  visibility: 'hidden',
}));
