export const cleanNumber = (dirtyNumber: number): number => {
  return Number(
    dirtyNumber
      .toString()
      .replace('-', '')
      .replace('+', '')
      .replace(',', '')
      .replace('.', '')
      .replace('e', ''),
  );
};
