import { useCallback } from 'react';
import { db } from 'utils/firebase/firebaseInit';
import { useAppSelector } from '../index';
import { doc, updateDoc } from 'firebase/firestore';

export const useChatMessageEdit = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  return useCallback(
    async (messageId: string, message: string) => {
      await updateDoc(doc(db, 'messages', messageId), {
        body: message,
      });
    },
    [coachData],
  );
};
