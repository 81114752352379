import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import { IInput } from 'types';
import { styled } from '@mui/system';
import { FormHelperText } from '@mui/material';

const RootTextField = styled(TextField)({
  backgroundColor: '#fff',
});

const Error = styled(FormHelperText)({
  color: 'red',
  fontSize: '14px!important',
  margin: '0!important',
  padding: 0,
});

const Input = forwardRef<any, IInput>(
  (
    {
      error,
      id,
      name,
      label,
      placeholder,
      value,
      onChange,
      onClick,
      onKeyDown,
      required,
      autoComplete,
      fullWidth,
      type,
      multiline,
      rows,
      maxRows,
      autoFocus,
      helperText,
      formHelperTextProps,
      inputProps = {},
      muiLowerCaseInputProps = {},
      className = '',
      margin = 'normal',
      disabled,
    },
    ref,
  ) => {
    return (
      <RootTextField
        className={className}
        variant="outlined"
        margin={margin}
        size="medium"
        required={required}
        fullWidth={fullWidth}
        error={error}
        value={value}
        label={label}
        placeholder={placeholder}
        name={name}
        id={id}
        type={type}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        multiline={multiline}
        minRows={rows}
        maxRows={maxRows}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        helperText={helperText}
        disabled={disabled}
        FormHelperTextProps={{ component: Error }}
        // FormHelperTextProps={formHelperTextProps ?? { component: Error }}
        inputProps={muiLowerCaseInputProps}
        InputProps={{
          ...inputProps,
          classes: { input: className },
        }}
        ref={ref}
      />
    );
  },
);

export default Input;
