import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mui/material';
import { Create, Delete, MoreVert } from '@mui/icons-material';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Modal from 'common/core/Modal';
import { useAdminSurveys } from 'hooks/admin/useAdminSurveys';
import { DotsIcon, StyledMenuItem, SurveyItem, Title } from './styles';
import { ESurveyType, ISurveysItem } from 'types';

const SurveysItem = ({ surveysItem }: ISurveysItem) => {
  const navigate = useNavigate();
  const { deleteSurveyTemplate, activateOnboarding } = useAdminSurveys();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [onboardingModalInfoId, setOnboardingModalInfoId] = useState<string>('');
  const [onboardingModalInfoMessage, setOnboardingModalInfoMessage] = useState<string>('');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id: string) => {
    deleteSurveyTemplate(id).then(() => {
      setIsModalOpen(false);
      handleClose();
    });
  };

  const handleModal = (e: React.MouseEvent<HTMLElement>, value: boolean) => {
    e.stopPropagation();
    setIsModalOpen(value);
  };

  const editHandler = (e: React.MouseEvent<HTMLElement>, surveyId: string) => {
    e.stopPropagation();
    navigate(`/surveys/edit/${surveyId}`);
  };

  const activeSurveyHandler = (
    e: React.MouseEvent<HTMLElement>,
    onboardingId: string,
    onboardingType?: ESurveyType,
  ) => {
    toggleHandoutEditMenu(e);
    e.stopPropagation();
    setOnboardingModalInfoMessage(
      !onboardingType || onboardingType === ESurveyType.ONBOARDING
        ? 'Are you sure you want to make this survey live for users?'
        : 'Are you sure you want to turn this survey off for users?',
    );
    setOnboardingModalInfoId(onboardingId);
  };

  const onboardingModalInfoClose = () => {
    setOnboardingModalInfoId('');
  };

  const onboardingModalInfoSubmit = () => {
    activateOnboarding(onboardingModalInfoId);
    onboardingModalInfoClose();
  };

  const toggleHandoutEditMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  return (
    <>
      <Modal
        widthMax={320}
        title="Are you sure you want to delete this template? The template will be deleted."
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleSubmit={() => handleDelete(surveysItem.id)}
        textBtnClose="No"
        textBtnSubmit="Yes"
        // containerClassName={classes.modalWrapper}
      />
      <Modal
        widthMax={320}
        title={onboardingModalInfoMessage}
        open={!!onboardingModalInfoId}
        handleClose={onboardingModalInfoClose}
        handleSubmit={onboardingModalInfoSubmit}
        textBtnClose="No"
        textBtnSubmit="Yes"
        // containerClassName={classes.modalWrapper}
      />
      <SurveyItem
        isActive={surveysItem.onboardingType === ESurveyType.ONBOARDING_ACTIVE}
        key={surveysItem.id}
        onClick={(e) => {
          editHandler(e, surveysItem.id);
        }}
      >
        <Title>{surveysItem.title}</Title>
        <DotsIcon onClick={toggleHandoutEditMenu} size="large">
          <MoreVert />
        </DotsIcon>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={toggleHandoutEditMenu}
          PaperProps={{
            style: {
              width: '175px',
            },
          }}
        >
          <StyledMenuItem
            onClick={(e) => {
              editHandler(e, surveysItem.id);
            }}
          >
            <span>Edit</span>
            <Create />
          </StyledMenuItem>
          {surveysItem.onboardingType && (
            <StyledMenuItem
              onClick={(e) => {
                activeSurveyHandler(e, surveysItem.id, surveysItem.onboardingType);
              }}
            >
              <span>
                {surveysItem?.onboardingType === ESurveyType.ONBOARDING
                  ? 'Make survey live'
                  : 'Turn survey off'}
              </span>
              <StarRoundedIcon />
            </StyledMenuItem>
          )}

          <StyledMenuItem
            sx={{
              color: '#FF6D7D',
            }}
            onClick={(e) => handleModal(e, true)}
          >
            <span>Delete</span>
            <Delete />
          </StyledMenuItem>
          <StyledMenuItem>
            <span>{surveysItem?.id}</span>
          </StyledMenuItem>
        </Menu>
      </SurveyItem>
    </>
  );
};

export default SurveysItem;
