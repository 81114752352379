import React, { useState } from 'react';
import { v4 } from 'uuid';
import Button from 'common/core/Button';
import { MenuWrapper } from './styles';
import { ETypeMeal } from '@fitmate-coach/fitmate-nutrition-table';
import { EResourceBlockTypes, INutritionTable } from 'types';

const NutritionTable = ({ handleBlocksChange, handleAddNutritionTable }: INutritionTable) => {
  const [selectedMeals, setSelectedMeals] = useState<Record<string, boolean>>({});

  function handleSave() {
    handleAddNutritionTable();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleBlocksChange((prev) => {
      return [
        ...prev,
        {
          id: v4(),
          type: EResourceBlockTypes.NUTRITION_TABLE,
          focusArea: Object.keys(selectedMeals).map((meal) => meal),
        },
      ];
    });
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMeals({
      ...selectedMeals,
      [ETypeMeal[event.target.name as keyof typeof ETypeMeal]]: event.target.checked,
    });
  };

  return (
    <MenuWrapper>
      <h2 className="font-bold">Choose which section to highlight</h2>
      {Object.keys(ETypeMeal).map((meal) => (
        <div key={meal}>
          <label>
            <input
              type="checkbox"
              name={meal}
              checked={selectedMeals[ETypeMeal[meal as keyof typeof ETypeMeal]] || false}
              onChange={handleCheckboxChange}
            />
            {ETypeMeal[meal as keyof typeof ETypeMeal]}
          </label>
        </div>
      ))}
      <Button onClick={handleSave} sx={{ marginTop: 0 }}>
        Save
      </Button>
    </MenuWrapper>
  );
};

export default NutritionTable;
