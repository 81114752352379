import { styled } from '@mui/system';
import { TableCell, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
}));

export const HeaderBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: 4,
}));

export const HeaderNameBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '40%',
}));

export const HeaderValueBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '20%',
}));

export const ElementBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 8,
  paddingBottom: 8,
}));

export const ElementNameBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '40%',
}));

export const ElementValueBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '20%',
}));

export const LineBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 28,
  backgroundColor: '#F2F4F7',
  borderRadius: 8,
}));

export const TableHeaderText = styled(TableCell)(({ theme }) => ({
  color: '#0F0F2166',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '16px',
}));

export const HeaderText = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: 'rgba(15, 15, 33, 0.4)',
}));

export const StyledEditIcon = styled(IconButton)(({ theme }) => ({
  width: 12,
  height: 12,
  color: '#8F94A0',
  cursor: 'pointer',
  marginLeft: 6,
}));

export const ElementText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#000',
}));

export const LineText = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'normal',
  color: 'rgba(15, 15, 33, 0.4)',
}));
