import { useFormContext } from 'react-hook-form';
import { FormControl, InputLabel } from '@mui/material';
import SelectFormField from 'common/core/FormElements/SelectFormField';
import SelectOptionsWrapper from 'common/core/SelectOptionsWrapper';
import { DiabetesList, MedicalClearanceList, YesNoList } from 'utils/data/question';
import { EFeatureSelectedType, IPartnerDataInfo } from 'types';
import Wrapper from '../Wrapper';
import { SelectBlock, StyledSelect } from '../styles';

const PartnerDataInfo = ({ isGlp1, setIsGlp1, partnerData }: IPartnerDataInfo) => {
  const { control } = useFormContext();

  return (
    <Wrapper blockTitle="Medical informations">
      <SelectBlock>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">GLP1 drugs</InputLabel>
          <StyledSelect
            // variant="standard"
            native
            autoWidth
            value={isGlp1 ? EFeatureSelectedType.enabled : EFeatureSelectedType.disabled}
            onChange={({ target }) => {
              setIsGlp1(target.value === EFeatureSelectedType.enabled);
            }}
            label="GLP1 drugs"
          >
            <SelectOptionsWrapper items={YesNoList} />
          </StyledSelect>
        </FormControl>
      </SelectBlock>
      <SelectBlock>
        <SelectFormField
          name="diabetes"
          label="Diabetes"
          control={control}
          optionList={DiabetesList}
        />
      </SelectBlock>
      {partnerData && (
        <SelectBlock>
          <SelectFormField
            name="medicalClearance"
            control={control}
            label="Medical clearance"
            optionList={MedicalClearanceList}
          />
        </SelectBlock>
      )}
    </Wrapper>
  );
};

export default PartnerDataInfo;
