import React, { useState, useEffect } from 'react';
import { format } from 'date-fns-tz';
import { FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { energyLevelValues, selfFeelingValues } from 'config';
import Modal from 'common/core/Modal';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  addBodyProfile,
  closeAddBodyProfile,
  updateStartBodyProfile,
} from 'store/customers/bodyProfileSlice';
import { Container, DateField, InputBlock, StyledDatepicker, StyledSelect } from './styles';
import { IChatProgramProfileEditLatestModal } from 'types';
import { IBodyProfile } from '@fitmate-coach/fitmate-types';
import { StyledInput } from '../commonStyles';
import { toast } from 'sonner';

const ChatProgramProfileEditLatestModal = ({
  isOpenEditLatestModal,
}: IChatProgramProfileEditLatestModal) => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { profiles, editProfileId } = useAppSelector((state) => state.bodyProfile);
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [weight, setWeight] = useState<string>('');
  const [selfFeeling, setSelfFeeling] = useState<string | null>();
  const [energyLevel, setEnergyLevel] = useState<string | null>();
  const [weightError, setWeightError] = useState<string>('');

  useEffect(() => {
    if (editProfileId) {
      const profile = profiles.find((item: IBodyProfile) => item.id === editProfileId);
      const isoString = profile?.createdAt.toDate().toISOString();
      const dateToShow = isoString.split('T')[0];
      setDate(dateToShow);
      setWeight(profile?.weight ? profile?.weight.toString() : '');
      setSelfFeeling(profile?.selfFeeling ? profile?.selfFeeling.toString() : '1');
      setEnergyLevel(profile?.energyLevel ? profile?.energyLevel.toString() : '1');
      setWeightError('');
    } else {
      setDate(format(new Date(), 'yyyy-MM-dd'));
      setWeight('');
      setSelfFeeling(null);
      setEnergyLevel(null);
      setWeightError('');
    }
  }, [editProfileId, profiles]);

  const handleWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '0' || value === '' || Number(value)) {
      setWeight(value);
    }
  };

  const handleSelfFeeling = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    if (value === '0' || value === '' || Number(value)) {
      setSelfFeeling(value as string);
    }
  };

  const handleEnergyLevel = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    if (value === '0' || value === '' || Number(value)) {
      setEnergyLevel(value as string);
    }
  };

  const handleSubmit = () => {
    if (editProfileId) {
      // edit existing measurement
      dispatch(
        updateStartBodyProfile({
          id: editProfileId,
          date,
          userId: customer?.id,
          weight: Number(weight) ?? null,
          selfFeeling: Math.max(Number(selfFeeling), 1) ?? null,
          energyLevel: Math.max(Number(energyLevel), 1) ?? null,
        }),
      );
    } else {
      // new measurement
      dispatch(
        addBodyProfile({
          date,
          userId: customer?.id,
          weight: Number(weight) ?? null,
          selfFeeling: selfFeeling ? Math.max(Number(selfFeeling), 1) : null,
          bodyWaist: null,
          heartRate: null,
          energyLevel: energyLevel ? Math.max(Number(energyLevel), 1) : null,
        }),
      );
    }
    dispatch(closeAddBodyProfile());
    toast.success('Your latest measures have been updated successfully');
  };

  return (
    <Modal
      widthMax={320}
      title="Update measures"
      open={isOpenEditLatestModal}
      handleClose={() => dispatch(closeAddBodyProfile())}
      handleSubmit={handleSubmit}
      textBtnClose="Cancel"
      textBtnSubmit="Save"
    >
      <div>
        <Container>
          <InputBlock>
            <DateField variant="standard">
              <StyledDatepicker
                label="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </DateField>
          </InputBlock>
          <InputBlock>
            <FormControl variant="standard">
              <StyledInput
                error={!!weightError}
                onChange={handleWeight}
                value={weight}
                label="Weight (lb)"
                helperText={weightError}
                type="text"
                fullWidth
              />
            </FormControl>
          </InputBlock>
          <InputBlock>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Feel good about my body</InputLabel>
              <StyledSelect
                // variant="standard"
                native
                value={selfFeeling}
                onChange={handleSelfFeeling}
                label="Feel good about my body"
              >
                <option aria-label="None" value="" />
                {selfFeelingValues.map((item: any) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </StyledSelect>
            </FormControl>
          </InputBlock>
          <InputBlock>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">My energy levels</InputLabel>
              <StyledSelect
                // variant="standard"
                native
                value={energyLevel}
                onChange={handleEnergyLevel}
                label="My energy levels"
              >
                <option aria-label="None" value="" />
                {Object.keys(energyLevelValues).map((value: string, key: number) => (
                  <option value={key + 1} key={key}>
                    {energyLevelValues[key + 1]}
                  </option>
                ))}
              </StyledSelect>
            </FormControl>
          </InputBlock>
        </Container>
      </div>
    </Modal>
  );
};

export default ChatProgramProfileEditLatestModal;
