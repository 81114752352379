import React, { useState } from 'react';
import { ListItemIcon, ListItemText } from '@mui/material';
import {
  MenuButtonList,
  MenuButtonListText,
  MenuWrapper,
  StyledMenu,
  StyledMenuItem,
} from './styles';
import { IPopupMenu } from 'types';
import NutritionTableIcon from 'assets/icons/recources_article.svg';
import AttachmentIcon from 'assets/icons/Attachment.svg';
import TextIco from 'assets/icons/TextIco.svg';
import SubtitleIco from 'assets/icons/subtitleIco.svg';

const PopupMenu = ({ AddSubtitle, AddAttachment, AddText, AddNutritionTable }: IPopupMenu) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddSubtitle = () => {
    AddSubtitle();
    setAnchorEl(null);
  };
  const handleAddText = () => {
    AddText();
    setAnchorEl(null);
  };
  const handleAddAttachment = () => {
    AddAttachment();
    setAnchorEl(null);
  };

  const handleAddNutritionTable = () => {
    AddNutritionTable();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuWrapper>
      <MenuButtonList
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="text"
        onClick={handleClick}
      >
        <MenuButtonListText>+ Add new block</MenuButtonListText>
      </MenuButtonList>
      <StyledMenu
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleAddSubtitle}>
          <ListItemText primary="Subtitle" />
          <ListItemIcon>
            <img src={SubtitleIco} alt="Subtitle" />
          </ListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleAddText}>
          <ListItemText primary="Text" />
          <ListItemIcon>
            <img src={TextIco} alt="Text" />
          </ListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleAddAttachment}>
          <ListItemText primary="Attachment" />
          <ListItemIcon>
            <img src={AttachmentIcon} alt="Attachment" />
          </ListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleAddNutritionTable}>
          <ListItemText primary="Nutrition table" />
          <ListItemIcon>
            <img src={NutritionTableIcon} alt="Nutrition table" />
          </ListItemIcon>
        </StyledMenuItem>
      </StyledMenu>
    </MenuWrapper>
  );
};

export default PopupMenu;
