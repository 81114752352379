export const matchCustomerToStripeCustomer = async (
  email: string | undefined,
  token: string | undefined,
) => {
  if (!email) return;
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });

  return fetch(`${process.env.REACT_APP_BACKEND_API_ROOT}/matchCustomerToStripeCustomer`, {
    method: 'POST',
    body: JSON.stringify({
      data: { email },
    }),
    headers,
  });
};
