import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Tabs, Typography } from '@mui/material';
import { CurrentTime } from 'common/core/Time';
import { formatDateLastSeen } from 'utils/format/datetime';
import { getUserStatus } from 'utils/data/payment';
import { getTeamRef } from 'api/firestores';
import {
  ClientInfoBlock,
  ClientInfoBottomBlock,
  ClientInfoTopBlock,
  ClientNameText,
  Container,
  LastSeenDateText,
  StatusBlock,
  StyledButton,
  TagBox,
  TagChip,
  TopBlock,
  TopLeftBlock,
  TopRightBlock,
  PaymentStatus,
  BottomBlock,
  TabsBlock,
  StyledTab,
} from './styles';
import { EChatPageType, EPaymentStatusValue } from 'types';
import { IPartnerData } from '@fitmate-coach/fitmate-types';
import { useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import ChatFAQLoader from '../../Coach/ChatContainer/ChatFAQ/ChatFAQLoader';

const partnerChipName = (partner: string) => {
  const list: any = {
    '4IX9dX8l5lS6Zz8JUNVgzl8L4Wx2': 'BGS',
    IvV2qehoKxQcu4YUoJv53xTriDe2: 'Empower',
    UoBgD7vC3cRFVd51aJWpRAQqOk12: 'Fitmate-test',
    wuL2AGcKo4OJm3HWvfc4hUXU20l2: 'Ola Big Island',
    EftFXV2XySUqEi3dNelOh59aVhl2: 'Chicago skin clinic',
    WHsRzwIVLMYw81nsRxaZhE2bOA73: 'Reveal Wellness',
  };

  return list[partner] || 'n/a';
};

const DiabetesChip = (user: any) => {
  let label;

  switch (user.diabetes) {
    case 1:
      label = 'Pre-diabetes';
      break;
    case 2:
      label = 'Type 1 diabetes';
      break;
    case 3:
      label = 'Type 2 diabetes';
      break;
  }

  if (user.diabetes === 0 || !user.diabetes) {
    return null;
  }

  return <TagChip label={label} />;
};

const MedicalClearanceChip = (partnerData: any) => {
  const { medicalClearance } = partnerData.partnerData as IPartnerData;
  let label;
  if (medicalClearance) {
    switch (medicalClearance) {
      case 'low':
        label = 'Low';
        break;
      case 'nutrition':
        label = 'Nutrition only';
        break;
      default:
        return null;
    }
  } else {
    return null;
  }

  return <TagChip label={`${label}`} />;
};

interface ClientNavigationProps {
  pageType: string;
  handlePageType: (value: string) => void;
}

const ClientNavigation = ({ pageType, handlePageType }: ClientNavigationProps) => {
  const navigate = useNavigate();
  const { customer } = useAppSelector((state) => state.customerInfos);

  const userStatus = getUserStatus(
    customer?.billingData?.status,
    customer?.billingData?.isPaid,
    customer?.billingData?.isReferralLinkEnabled,
  );

  const handleChange = (_event: any, newValue: string) => {
    handlePageType(newValue);
  };

  const getUserClasByStatus = () => {
    if (
      userStatus?.value === EPaymentStatusValue.PAID ||
      userStatus?.value === EPaymentStatusValue.TRIAL
    )
      return 'paid';
    if (userStatus?.value === EPaymentStatusValue.UNPAID) return 'unpaid';
    if (userStatus?.value === EPaymentStatusValue.CANCELLED) return 'cancelled';
  };

  const handleBackDashboard = () => {
    navigate('/partner-referral-dashboard', { replace: true });
  };

  if (!customer) {
    return <ChatFAQLoader />;
  }

  return (
    <Container>
      <TopBlock>
        <TopLeftBlock>
          <ClientInfoBlock>
            <ClientInfoTopBlock>
              <ClientNameText>
                {customer?.firstName} {customer?.lastName}
              </ClientNameText>
              {customer?.coachAssignment?.team && (
                <MainTeamCoach teamId={customer?.coachAssignment?.team} />
              )}
              <TagBox>
                {customer.partner && <TagChip label={partnerChipName(customer.partner)} />}
                {customer.partnerData && (
                  <MedicalClearanceChip partnerData={customer.partnerData} />
                )}
                <DiabetesChip diabetes={customer.diabetes} />
                {customer.glp1drugs && <TagChip label="GLP-1 meds" />}
              </TagBox>
              <StyledButton onClick={handleBackDashboard} variant="outlined" size="small">
                Back to dashboard
              </StyledButton>
            </ClientInfoTopBlock>

            <ClientInfoBottomBlock>
              {customer && (
                <>
                  <LastSeenDateText>
                    Last seen {formatDateLastSeen(customer)} - <b>User local time: </b>
                  </LastSeenDateText>
                  <LastSeenDateText>
                    <CurrentTime timezone={customer?.timezone} format="HH:mm (E, LLLL dd)" />
                  </LastSeenDateText>
                </>
              )}
            </ClientInfoBottomBlock>
          </ClientInfoBlock>
        </TopLeftBlock>
        <TopRightBlock>
          {userStatus && (
            <StatusBlock>
              <PaymentStatus paymentStatus={getUserClasByStatus()}>
                {userStatus.label}
              </PaymentStatus>
            </StatusBlock>
          )}
        </TopRightBlock>
      </TopBlock>
      <BottomBlock>
        <TabsBlock>
          <Tabs
            value={pageType}
            onChange={handleChange}
            aria-label="Switch conversation and program"
          >
            <StyledTab label="Program" value={EChatPageType.PROGRAM} />
            <StyledTab label="Client info" value={EChatPageType.USER_PROFILE} />
            <StyledTab label="Survey" value={EChatPageType.SURVEY} />
          </Tabs>
        </TabsBlock>
      </BottomBlock>
    </Container>
  );
};

const MainTeamCoach = (p: { teamId: string }) => {
  const [data] = useDocumentDataOnce(getTeamRef(p.teamId));
  if (!data?.mainCoach.firstName) return null;
  return (
    <Typography
      style={{
        color: 'rgba(15, 15, 33, 0.4)',
        fontWeight: 600,
        paddingLeft: '.3rem',
        marginTop: 5,
      }}
    >
      ({data?.mainCoach.firstName}'s team)
    </Typography>
  );
};

export default ClientNavigation;
