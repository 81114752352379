import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const MessageContainer = styled('div')(({ theme }) => ({
  marginBottom: 12,
}));

export const Date = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '18px',
  color: 'rgba(15, 15, 33, 0.4)',
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '22px',
  marginBottom: 4,
}));
