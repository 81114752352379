import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 40,
  paddingRight: 40,
  paddingTop: 10,
  paddingBottom: 20,
}));

export const ColumnContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
}));

export const ColumnContentBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: 16,
}));

export const ColumnLineBlock = styled('div')(({ theme }) => ({
  width: 1,
  height: '100%',
  backgroundColor: '#FFF',
  marginLeft: 20,
  marginRight: 20,
}));

export const TypeNameBlock = styled('div')(({ theme }) => ({
  marginBottom: 15,
}));

export const EmptyBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: 200,
}));

export const TypeNameText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: '#0F0F21',
  opacity: 0.6,
}));

export const SubTypeNameBlock = styled('div')(({ theme }) => ({
  marginTop: 10,
  marginBottom: 15,
  paddingLeft: 10,
}));

export const SubTypeNameText = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 'normal',
  fontStyle: 'italic',
  color: '#0F0F21',
  opacity: 0.5,
}));

export const EmptyIcon = styled('div')(({ theme }) => ({}));

export const EmptyText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  fontStyle: 'italic',
  color: 'rgba(15, 15, 33, 0.4)',
}));
