const deadTimeWorker = () => {
  let intervalShiftStarted: NodeJS.Timeout | null | unknown = null;
  let deadTime = 0;
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = ({ data: isShiftStarted }) => {
    if (isShiftStarted) {
      clearInterval(intervalShiftStarted as NodeJS.Timeout);
      deadTime = 0;
      intervalShiftStarted = setInterval(() => {
        deadTime += 1;
        // eslint-disable-next-line no-restricted-globals
        self.postMessage({ deadTime });
      }, 1000);
    }

    if (!isShiftStarted) {
      clearInterval(intervalShiftStarted as NodeJS.Timeout);
      deadTime = 0;
    }
  };
};

let codeDeadTimer = deadTimeWorker.toString();
codeDeadTimer = codeDeadTimer.substring(
  codeDeadTimer.indexOf('{') + 1,
  codeDeadTimer.lastIndexOf('}'),
);

const blobDead = new Blob([codeDeadTimer], { type: 'application/javascript' });
const dead_worker_script = URL.createObjectURL(blobDead);

export default dead_worker_script;
