import { styled } from '@mui/system';

export const FilterWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 17,
  paddingLeft: 20,
  paddingTop: 20,
  maxHeight: 'calc(100vh - 128px)',
  overflowY: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
