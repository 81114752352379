// UNUSED
import ModalDialog from '../ModalDialog';

const ConfirmModal = (props: {
  size?: 'md' | 'lg';
  open?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  description?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
}) => {
  return (
    <ModalDialog open={!!props.open} onClose={() => props.onClose()} size={props.size ?? 'md'}>
      <h5 className="text-dark font-semibold pb-2 text-xl">{props.title}</h5>
      <p className="py-3 text-sm">{props.description}</p>
      <div className="grid grid-cols-2 gap-3 font-semibold pt-4">
        <button
          className="border border-dark/20 text-dark/60 rounded-lg py-3 px-7 text-sm hover:opacity-90"
          onClick={props.onCancel}
        >
          {props.cancelButtonText ? props.cancelButtonText : 'Back'}
        </button>
        <button
          className="bg-[#8587E5] text-white rounded-lg py-3 px-7 text-sm transition-all hover:opacity-90"
          onClick={props.onConfirm}
        >
          {props.confirmButtonText ? props.confirmButtonText : 'Confirm'}
        </button>
      </div>
    </ModalDialog>
  );
};

export default ConfirmModal;
