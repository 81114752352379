/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import Button from 'common/core/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clearSelectedHandout, saveSelectedHandout } from 'store/program/handoutSlice';
import { createFocusArea, hideAddModal, updateFocusArea } from 'store/program/focusAreaSlice';

const AddFocusAreaModal = () => {
  const dispatch = useAppDispatch();
  const { showAddModal, selectedFocusArea } = useAppSelector((state) => state.focusArea);
  const { handouts, selectedHandout } = useAppSelector((state) => state.handout);

  const [focusAreaName, setFocusAreaName] = useState('');

  useEffect(() => {
    if (selectedFocusArea) {
      setFocusAreaName(selectedFocusArea.name);
      const handout = handouts?.find((handout) => handout.id === selectedFocusArea.handoutId);
      dispatch(saveSelectedHandout(handout));
    }
  }, [selectedFocusArea]);

  const handleClose = () => {
    setFocusAreaName('');
    dispatch(clearSelectedHandout());
    dispatch(hideAddModal());
  };

  const handleAddFocusArea = () => {
    // @ts-ignore
    dispatch(createFocusArea({ name: focusAreaName }));
    handleClose();
  };

  const handleUpdateFocusArea = () => {
    dispatch(
      // @ts-ignore
      updateFocusArea({
        id: selectedFocusArea.id,
        name: focusAreaName,
        handoutId: selectedHandout.id,
      }),
    );
    handleClose();
  };

  return (
    <Dialog open={showAddModal} onClose={handleClose} aria-labelledby="form-dialog-title">
      {!selectedFocusArea && <DialogTitle id="form-dialog-title">New focus area</DialogTitle>}
      {selectedFocusArea && <DialogTitle id="form-dialog-title">Edit focus area</DialogTitle>}
      <DialogContent>
        <DialogContentText>
          Please choose a name and the handout for this new focus area.
        </DialogContentText>
        <TextField
          autoComplete="off"
          autoFocus
          variant="outlined"
          id="focusAreaName"
          label="Focus area name"
          type="text"
          fullWidth
          value={focusAreaName}
          onChange={(e) => setFocusAreaName(e.target.value)}
          sx={{
            marginTop: 2,
          }}
        />
        {handouts && (
          <Autocomplete
            value={selectedHandout}
            options={handouts}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <TextField {...params} label="Handout" variant="outlined" />}
            onChange={(event, newValue) => {
              dispatch(saveSelectedHandout(newValue));
            }}
            sx={{
              marginTop: 2,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {!selectedFocusArea && (
          <Button
            onClick={handleAddFocusArea}
            color="primary"
            disabled={!focusAreaName || !selectedHandout}
          >
            Add
          </Button>
        )}
        {selectedFocusArea && (
          <Button
            onClick={handleUpdateFocusArea}
            color="primary"
            disabled={!focusAreaName || !selectedHandout}
          >
            Update
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddFocusAreaModal;
