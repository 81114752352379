import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IAdminUser, IClients } from 'types';
import { CoachesBlock, HeaderBlock, SearchBlock, TitleBlock, TitleText } from './styles';
import ClientsList from '../ClientsList';

const Clients = ({ usersList }: IClients) => {
  const [search, setSearch] = React.useState<string>('');
  const [filteredUsersList, setFilteredUsersList] = React.useState<IAdminUser[]>([]);

  useEffect(() => {
    setFilteredUsersList(usersList);
  }, [usersList]);

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
    if (event.target?.value === '') {
      setFilteredUsersList(usersList);
      return;
    }
    const filteredList = usersList.filter((user) => {
      return (
        user.firstName.includes(event.target.value) ||
        user.lastName.includes(event.target.value) ||
        user.email.includes(event.target.value)
      );
    });
    setFilteredUsersList(filteredList);
  };

  return (
    <Container maxWidth="lg">
      <>
        <div>
          <HeaderBlock>
            <TitleBlock>
              <TitleText>Clients</TitleText>
            </TitleBlock>
            <div></div>
          </HeaderBlock>
        </div>
        <SearchBlock>
          <TextField
            onChange={handleSearch}
            fullWidth
            id="outlined-basic"
            label="Search client"
            variant="outlined"
            value={search}
          />
        </SearchBlock>
        <CoachesBlock>
          <ClientsList usersList={filteredUsersList} />
        </CoachesBlock>
      </>
    </Container>
  );
};

export default Clients;
