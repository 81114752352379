import { StyledButton } from './styles';
import { ButtonProps } from '@mui/material/Button/Button';

const Button = ({
  variant = 'contained',
  onClick,
  fullWidth,
  disabled,
  children,
  ...props
}: ButtonProps) => {
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      <StyledButton
        onClick={onClick}
        variant={variant ?? 'outlined'}
        size="medium"
        color="primary"
        fullWidth={fullWidth}
        disabled={disabled}
        {...props}
      >
        {children}
      </StyledButton>
    </>
  );
};

export default Button;
