import { styled } from '@mui/system';
import { Menu, MenuItem, Typography } from '@mui/material';

export const ListHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '35px 10px 10px',
});

export const RightSideWrapper = styled('div')({
  display: 'flex',
});

export const ClientsSort = styled('div')({
  display: 'flex',
  paddingRight: 27,
  cursor: 'pointer',
});

export const ClientsFilter = styled('div')({
  display: 'flex',
  cursor: 'pointer',
});

export const MenuSort = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 239,
    padding: '16px 0 16px 16px',
    background: '#F9FAFC',
    '& .MuiList-root': {
      padding: 0,
      '& .MuiListItem-root': {
        paddingLeft: 0,
        borderBottom: '1px solid #E4E7ED',
        '&:last-child': {
          borderBottom: 'none',
        },
      },
    },
  },
}));

export const MenuFilter = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 239,
    padding: '16px 0 16px 16px',
    '& .MuiList-root': {
      padding: 0,
      '& .MuiListItem-root': {
        paddingLeft: 0,
        '& .MuiCheckbox-root': {
          padding: '0px 7px 0px 0px',
          color: 'rgba(15, 15, 33, 0.4)',
        },
      },
    },
  },
}));

export const ItemHeader = styled(MenuItem)({
  '&:hover': {
    backgroundColor: 'white',
  },
});

export const StyledMenuItem = styled(MenuItem)<{ isChecked: boolean }>(({ isChecked }) => ({
  height: 30,
  color: '#000000',

  ...(isChecked && {
    color: '#6B6ADE !important',
    '& .Mui-checked': {
      color: '#6B6ADE !important',
    },
  }),
}));

export const Icons = styled('div')({
  marginRight: 8,
  color: 'rgba(15, 15, 33, 0.4)',
});

export const FiltersCountWrapper = styled('div')({
  display: 'flex',
});

export const Count = styled('div')({
  textAlign: 'center',
  margin: '0px 5px',
  width: 26,
  height: 24,
  borderRadius: 9,
  fontSize: '12px',
  backgroundColor: '#EAEAFB',
});

export const Reset = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.primary.main,
}));

export const FilterOptionText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '21px',
});
