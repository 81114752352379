import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Button from '../../../../../common/core/Button';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '30vh',
  borderLeft: '1px solid #E4E7ED',
}));

export const TitleBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
}));

export const ContentBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'start',
  padding: '10px 40px',
  flexGrow: 1,
  alignContent: 'flex-start',
  gap: 16,
  overflowY: 'scroll',
}));

export const ButtonBlock = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '12px 0 12px 0',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px -1px 0px #E4E7ED',
  justifyContent: 'flex-end',
  display: 'flex',
  gap: 8,
  '& .MuiButton-label': {
    fontSize: 12,
    fontWeight: 600,
  },
  '& .MuiButton-root': {
    boxShadow: 'none',
    borderRadius: 4,
    padding: '12px 28px',
    height: '34px !important',
    minWidth: 'revert',
    margin: '0 2px',
  },
  '& .Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: '#C5C5FE',
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  padding: '16px 0 0 16px',
}));

export const CancelSubmit = styled(Button)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'transparent',
  color: 'rgba(15, 15, 33, 0.6)',
  '&:hover': {
    border: '1px solid #8587E5',
    backgroundColor: 'rgba(133, 135, 229, 0.04)',
  },
}));

export const ButtonSubmit = styled(Button)(({ theme }) => ({
  padding: '12px 43px',
  marginRight: '20px !important',
  color: 'white',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: 'rgb(93, 94, 160)',
  },
}));
