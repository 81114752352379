import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { PersonAdd } from '@mui/icons-material';
import MenuItem from '../../../../../common/core/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 60,
});

export const BottomLineBlock = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#E4E7ED',
  marginTop: 5,
  marginBottom: 5,
});

export const NameBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '31%',
});

export const NameTopBlock = styled('div')({
  paddingBottom: 1,
});

export const NameBottomBlock = styled('div')({});

export const GenderBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '16%',
});

export const LocationBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '16%',
});

export const TimezoneBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '16%',
});

export const DateBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '16%',
});

export const ActionsBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '5%',
});

export const Text1 = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '24px',
  color: '#0F0F21',
});

export const Text2 = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  color: '#0F0F21',
});

export const Text3 = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
  opacity: 0.4,
  color: '#0F0F21',
});

export const StyledMenuIcon = styled(MoreVertIcon)({
  fontSize: 20,
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.4)',
});

export const StyledAssignIcon = styled(PersonAdd)({
  fontSize: 20,
  cursor: 'pointer',
  color: '#FF6D7D',
});

export const SubMenu = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row',
  width: 175,
  fontSize: 14,
  fontWeight: 500,
  color: '#000000',
});
