import React from 'react';
import { Select, SelectChangeEvent, Typography } from '@mui/material';
import Input from 'common/core/Input';
import { InputWrapper, LinerScale, Wrapper } from './styles';
import { ISliderBlock } from 'types';

const SliderBlock = ({ id, slider, sliderHandler }: ISliderBlock) => {
  const { max, minDescription, maxDescription } = slider;

  const handleValueQuestionFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value as string;

    if (sliderHandler) {
      sliderHandler(id, max, value, maxDescription);
    }
  };

  const handleValueQuestionTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value as string;

    if (sliderHandler) {
      sliderHandler(id, max, minDescription, value);
    }
  };

  const handleLinerScale = (e: SelectChangeEvent<unknown>) => {
    const value: number = e.target.value as number;
    if (sliderHandler) {
      sliderHandler(id, value, slider.minDescription, slider.maxDescription);
    }
  };

  return (
    <>
      <LinerScale>
        <Typography>1</Typography>
        <Typography>to</Typography>
        <Select
          // variant="standard"
          defaultValue={slider?.max}
          onChange={handleLinerScale}
          native
        >
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
        </Select>
      </LinerScale>
      <Wrapper>
        <InputWrapper>
          <Input
            name="from"
            type="text"
            value={minDescription}
            label="1 - Name this label (optional)"
            onChange={(e) => handleValueQuestionFrom(e)}
            fullWidth
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="to"
            type="text"
            value={maxDescription}
            label={`${max} - Name this label (optional)`}
            onChange={(e) => handleValueQuestionTo(e)}
            fullWidth
          />
        </InputWrapper>
      </Wrapper>
    </>
  );
};

export default SliderBlock;
