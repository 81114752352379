import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RadioGroup, FormControl } from '@mui/material';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { useAdminResourceCategories } from 'hooks/admin/useAdminResourceCategories';
import { useAdminResource } from 'hooks/admin/useAdminResource';
import { useFilesUpload } from 'hooks/useFilesUpload';
import { FilterWrapper } from './styles';
import { IResource, EResourceTypes, IFilteredContentExtension } from 'types';
import Categories from '../ResourceContentCategories';
import Resources from '../Resources';
import HoverResource from '../ResourceContentHoverResource';

const FilteredContent = ({
  selectedCategory,
  selectedTags,
  handleCategorySelected,
}: IFilteredContentExtension) => {
  const [resourceId, setResourceId] = useState<string>('');
  const [resources, setResources] = useState<IResource[]>([]);
  const [isOpenResourceRemoveModal, setIsOpenResourceRemoveModal] = useState<boolean>(false);
  const { categoriesList } = useAdminResourceCategories();
  const { resourcesList, removeResource } = useAdminResource();
  const { removeFile } = useFilesUpload();

  const navigate = useNavigate();

  useEffect(() => {
    setResources(resourcesList);
  }, [resourcesList]);

  const toggleResourceRemoveModal = () => {
    setIsOpenResourceRemoveModal((prev) => !prev);
  };

  const handleResourceRemove = (resourceId: string) => {
    setResourceId(resourceId);
    toggleResourceRemoveModal();
  };

  const handleResourceEdit = (resourceId: string, resourceType: string) => {
    switch (resourceType) {
      case EResourceTypes.WORKOUT:
        navigate(`/resources/workout/edit/${resourceId}`);
        break;
      case EResourceTypes.ARTICLE:
        navigate(`/resources/article/edit/${resourceId}`);
        break;
      case EResourceTypes.RECIPE:
        navigate(`/resources/recipe/edit/${resourceId}`);
        break;
      default:
        return;
    }
  };

  const handleResourceRemoved = async () => {
    if (resourceId) {
      const currentResource = resourcesList.filter((resource) => resource.id === resourceId);
      if (currentResource[0].type === 'recipe' && currentResource[0].htmlBodyUrl) {
        await removeFile(currentResource[0].htmlBodyUrl);
      }
      await removeResource(resourceId).then(() => {
        setResources((prev) => prev.filter((resource) => resource.id !== resourceId));
        toggleResourceRemoveModal();
      });
    }
  };

  return (
    <>
      <FormControl variant="standard" component="div">
        <RadioGroup aria-label="resources" name="resources">
          <FilterWrapper>
            {!selectedCategory
              ? categoriesList.map((category) => {
                  return (
                    <Categories
                      category={category}
                      handleCategorySelected={handleCategorySelected}
                      key={category.id}
                    />
                  );
                })
              : selectedCategory?.children.length
              ? selectedCategory?.children.map((category) => {
                  return (
                    <Categories
                      category={category}
                      handleCategorySelected={handleCategorySelected}
                      key={category.id}
                    />
                  );
                })
              : resources
                  .filter((resource) => resource.categoryId === selectedCategory.id)
                  .filter((resource) => {
                    if (selectedTags.length > 0) {
                      return selectedTags.every((tag) => resource.tags.includes(tag));
                    } else {
                      return true;
                    }
                  })
                  .map((resource) => {
                    return (
                      <HoverResource
                        handleResourceEdit={handleResourceEdit}
                        handleResourceRemove={handleResourceRemove}
                        resource={resource}
                        key={resource.id}
                      >
                        <Resources
                          resource={resource}
                          handleResourceEdit={handleResourceEdit}
                          key={resource.id}
                        />
                      </HoverResource>
                    );
                  })}
          </FilterWrapper>
        </RadioGroup>
      </FormControl>
      <ModalConfirm
        isOpenConfirmModal={isOpenResourceRemoveModal}
        toggleOpenConfirmModal={toggleResourceRemoveModal}
        handleSumbit={handleResourceRemoved}
        title="Do you want to delete this resource?"
        note="You will not be able to undo this."
      />
    </>
  );
};

export default FilteredContent;
