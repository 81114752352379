import React, { useState, useEffect } from 'react';
import Input from '../Input';
import { IMaskedInput } from 'types';

const MaskedInput = ({ inputProps = {}, label, name, onChange, resourceData }: IMaskedInput) => {
  const MAX_MINUTES_VALUE = 59;

  const [card, setCard] = useState<string>('');

  useEffect(() => {
    if (resourceData?.parameters?.get(name)) {
      setCard(resourceData?.parameters?.get(name));
    }
  }, [resourceData]);

  const valid = (value: string, min: number, max: number) => {
    const values = parseInt(value, 10);
    return !isNaN(values) && values >= min && values <= max;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const cardValue = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,2})/);
    value = !cardValue![2]
      ? cardValue![1]
      : `${cardValue![1]}:${
          valid(cardValue![2], 0, MAX_MINUTES_VALUE) ? cardValue![2] : MAX_MINUTES_VALUE
        }`;
    setCard(value);
    onChange(event.target.name, value);
  };

  return (
    <Input
      label={label}
      name={name}
      onChange={handleChange}
      value={card}
      inputProps={{
        ...inputProps,
      }}
    />
  );
};

export default MaskedInput;
