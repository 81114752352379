import { styled } from '@mui/system';
import { Chip, Tab, Typography } from '@mui/material';
import Button from '../../../common/core/Button';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 123,
  backgroundColor: '#fff',
  margin: 0,
  padding: 0,
  borderLeft: '1px solid #E4E7ED',
});

export const StyledButton = styled(Button)({
  marginLeft: 10,
  fontWeight: 'bold',
});

export const TopBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '60%',
});

export const BottomBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '40%',
});

export const TopLeftBlock = styled('div')({
  marginRight: '15px',
});

export const TopRightBlock = styled('div')({
  display: 'flex',
});

export const ClientInfoBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginLeft: 32,
});

export const ClientInfoTopBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const ClientInfoBottomBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'row wrap',
  gap: '.5rem',
  width: '100%',
  marginTop: '.3rem',
});

export const TabsBlock = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  paddingLeft: 10,
  height: '100%',
});

export const BottomIconsBlock = styled('div')({
  marginRight: 17,
  marginLeft: 5,
});

export const BottomIconsPlanner = styled('div')({
  marginLeft: 5,
});

export const ClientNameText = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  color: '#0F0F21',
  whiteSpace: 'nowrap',
  marginTop: 5,
});

export const LastSeenDateText = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
});

export const StyledTab = styled(Tab)({
  '&.MuiTab-root': {
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    color: 'rgba(15, 15, 33, 0.4)',
  },
  '&.Mui-selected': {
    fontSize: 18,
    fontWeight: 600,
    textTransform: 'none',
    color: '#8587E5',
  },
});

export const TabChosen = styled('div')({
  fontSize: 18,
  fontWeight: 600,
  textTransform: 'none',
  color: '#8587E5',
});

export const TabIndicator = styled('div')({
  height: 4,
  backgroundColor: '#8587E5',
  borderRadius: '3px 3px 0px 0px',
});

export const StatusBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: 20,
  whiteSpace: 'nowrap',
});

export const PaidText = styled('div')({
  fontSize: 14,
  fontWeight: 600,
});

export const PaidStatus = styled(PaidText)({
  color: '#24C09F',
});

export const UnpaidStatus = styled(PaidText)({
  color: '#FBB867',
});

export const CancelledStatus = styled(PaidText)({
  color: '#FF405C',
});

export const TagBox = styled('div')({
  display: 'flex',
  alignItems: 'space-between',
});

export const TagChip = styled(Chip)({
  backgroundColor: '#000',
  color: '#fff',
  height: '25px',
  margin: '0 5px',
});

export const PaymentStatus = styled(Typography)<{ paymentStatus?: string }>(
  ({ paymentStatus }) => ({
    fontSize: 14,
    fontWeight: 600,
    ...(paymentStatus === 'paid'
      ? { color: '#24C09F' }
      : paymentStatus === 'unpaid'
      ? { color: '#FBB867' }
      : { color: '#FF405C' }),
  }),
);
