import { styled } from '@mui/system';
import MenuItemOriginal from '@mui/material/MenuItem';

export const StyledRoot = styled(MenuItemOriginal)({
  display: 'flex',
  justifyContent: 'space-between',
  height: 48,
  fontSize: 14,
  fontWeight: 500,
  color: '#0F0F21',
  borderBottom: '1px solid #E4E7ED',
  paddingLeft: 8,
  paddingRight: 8,
});
