import { styled } from '@mui/system';
import { FormControl, Typography } from '@mui/material';

export const CategoriesWrapper = styled(FormControl)({
  display: 'revert',
});

export const HeaderBlock = styled('div')({
  display: 'flex',
  width: '100%',
  paddingBottom: 20,
});

export const TitleBlock = styled('div')({
  display: 'flex',
  flexGrow: 1,
});

export const MainBlock = styled('div')({
  maxHeight: 'calc(100vh - 128px)',
  overflowY: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const AddActionBlock = styled('div')({
  display: 'flex',
  flexGrow: 0,
  width: 200,
});

export const TitleText = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  padding: '16px 0 0 16px',
});

export const AppSideBar = styled('div')({
  minWidth: 340,
  backgroundColor: '#F9FAFC',
  borderRadius: 12,
  height: '100%',
  overflowY: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
