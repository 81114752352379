import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@mui/material';
import { useAdminResourceCategories } from 'hooks/admin/useAdminResourceCategories';
import { useAdminResource } from 'hooks/admin/useAdminResource';
import { FilterWrapper, FormWrapper } from './styles';
import { IResource, EMessageReferenceType, IFilteredContent } from 'types';
import Categories from '../ChatResourcesCategories';
import Resources from '../ChatResources';

const FilteredContent = ({
  selectedCategory,
  selectedTags,
  handleCategorySelected,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeReferences = () => {},
}: IFilteredContent) => {
  const [resources, setResources] = useState<IResource[]>([]);
  const [checkedId, setCheckedId] = useState<string>('');
  const { categoriesList } = useAdminResourceCategories();
  const { resourcesList } = useAdminResource();

  useEffect(() => {
    setResources(resourcesList);
  }, [resourcesList]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedId(event.target.value);
    handleChangeReferences({
      type: EMessageReferenceType.RESOURCE,
      referenceId: event.target.value,
    });
  };

  return (
    <>
      <FormWrapper variant="standard">
        <RadioGroup aria-label="resources" name="resources" onChange={changeHandler}>
          <FilterWrapper isSelected={!(!selectedCategory || selectedCategory?.children.length)}>
            {!selectedCategory
              ? categoriesList.map((category) => {
                  return (
                    <Categories
                      category={category}
                      handleCategorySelected={handleCategorySelected}
                      key={category.id}
                    />
                  );
                })
              : selectedCategory?.children.length
              ? selectedCategory?.children.map((category) => {
                  return (
                    <Categories
                      category={category}
                      handleCategorySelected={handleCategorySelected}
                      key={category.id}
                    />
                  );
                })
              : resources
                  .filter((resource) => resource.categoryId === selectedCategory.id)
                  .filter((resource) => {
                    if (selectedTags.length > 0) {
                      return selectedTags.every((tag) => resource.tags.includes(tag));
                    } else {
                      return true;
                    }
                  })
                  .map((resource) => {
                    return (
                      <Resources resource={resource} checkedId={checkedId} key={resource.id} />
                    );
                  })}
          </FilterWrapper>
        </RadioGroup>
      </FormWrapper>
    </>
  );
};

export default FilteredContent;
