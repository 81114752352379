import React, { useState, useEffect } from 'react';
import { Select, InputLabel, InputAdornment, SelectChangeEvent } from '@mui/material';
import Input from 'common/core/Input';
import MaskedInput from 'common/core/MaskedInput';
import {
  AddDesc,
  AddLink,
  AddTitle,
  AppWrapper,
  DefaultOptionValue,
  DifficultySelect,
  Form,
  FormContainer,
  InputGroup,
  InputGroupWrapper,
} from './styles';
import TimeIco from 'assets/icons/access_time.svg';
import { IResourceWorkoutContent } from 'types';

const ResourceWorkoutContent = ({
  handleResourceDataChange,
  resourceData,
  handleUrlValidate,
}: IResourceWorkoutContent) => {
  const [error, setError] = useState<boolean>(false);
  const [linkValue, setValueLink] = useState<string>('');
  const [parameters, setParameters] = useState<Map<string, any>>(new Map());

  const linkRegExp = new RegExp(
    '^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$',
  );

  useEffect(() => {
    handleResourceDataChange((prev: any) => ({ ...prev, parameters: parameters }));
  }, []);

  useEffect(() => {
    setValueLink(resourceData?.url || '');
    setParameters(resourceData?.parameters || new Map());
    if (resourceData?.url?.match(linkRegExp)) {
      setError(true);
      handleUrlValidate(true);
    } else {
      setError(false);
    }
  }, [resourceData]);

  useEffect(() => {
    if (linkValue.match(linkRegExp)) {
      setError(true);
      handleUrlValidate(true);
    } else {
      setError(false);
    }
  }, [linkValue]);

  const handleParametersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParameters((prev) => new Map(prev.set(event.target.name, event.target.value)));
    handleResourceDataChange((prev: any) => ({ ...prev, parameters: parameters }));
  };

  const handleMaskedInputChange = (name: string, value: string) => {
    setParameters((prev) => new Map(prev.set(name, value)));
    handleResourceDataChange((prev: any) => ({ ...prev, parameters: parameters }));
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleResourceDataChange((prev: any) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setParameters(
      (prev) => new Map(prev.set(event.target.name as string, event.target.value as string)),
    );
    handleResourceDataChange((prev: any) => ({ ...prev, parameters: parameters }));
  };

  const handleChangeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange(event);
    setValueLink((event.target as HTMLInputElement).value);
    if (event.target.value.match(linkRegExp)) {
      setError(true);
      handleUrlValidate(true);
    } else {
      setError(false);
    }
  };

  return (
    <FormContainer>
      <AppWrapper>
        <Form>
          <DifficultySelect variant="standard">
            <AddLink
              label="Add link to your video"
              error={linkValue === '' ? false : !error}
              onChange={handleChangeLink}
              value={linkValue}
              name="url"
            />
            <AddTitle
              name="title"
              placeholder="Add title"
              onChange={handleFormChange}
              value={resourceData?.title || ''}
            />
            <InputGroupWrapper>
              <InputGroup>
                <DifficultySelect variant="outlined">
                  <InputLabel
                    style={{ backgroundColor: '#F9FAFC', padding: '0 2px', left: '-2px' }}
                    shrink={resourceData?.difficulty}
                    htmlFor="outlined-subcategory"
                  >
                    Difficulty
                  </InputLabel>
                  <Select
                    // variant="standard"
                    native
                    onChange={handleSelectChange}
                    label="Difficulty"
                    value={resourceData?.parameters?.get('difficulty') || ''}
                    inputProps={{
                      name: 'difficulty',
                      id: 'outlined-subcategory',
                    }}
                  >
                    <DefaultOptionValue aria-label="None" />
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="All levels">All levels</option>
                  </Select>
                </DifficultySelect>
                <Input
                  label="Worked muscle group"
                  name="worked_muscle_group"
                  onChange={handleParametersChange}
                  value={resourceData?.parameters?.get('worked_muscle_group') || ''}
                />
                <MaskedInput
                  label="View time"
                  name="view_time"
                  resourceData={resourceData}
                  onChange={handleMaskedInputChange}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={TimeIco} alt="clock" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Input
                  label="Equipment"
                  name="equipment"
                  onChange={handleParametersChange}
                  value={resourceData?.parameters?.get('equipment') || ''}
                />
              </InputGroup>
            </InputGroupWrapper>
            <AddDesc
              // variant="standard"
              multiline
              name="description"
              onChange={handleFormChange}
              placeholder="Add description"
              value={resourceData?.description || ''}
            />
          </DifficultySelect>
        </Form>
      </AppWrapper>
    </FormContainer>
  );
};

export default ResourceWorkoutContent;
