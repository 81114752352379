import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { energyLevelValues } from 'config';
import { IBodyProfile } from '@fitmate-coach/fitmate-types';
import { StyledEditIcon, TableHeaderText } from './styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import { editBodyProfile } from 'store/customers/bodyProfileSlice';
import { Timestamp } from 'firebase/firestore';

const ChatProgramProfileView = () => {
  const dispatch = useAppDispatch();
  const { isReadOnly } = useAppSelector((state) => state.customerInfos);
  const { profiles } = useAppSelector((state) => state.bodyProfile);

  const handleEditProfile = (id: string | undefined) => {
    dispatch(editBodyProfile({ id }));
  };

  const renderDate = (profile: IBodyProfile) => {
    const date = new Date(
      profile.createdAt instanceof Timestamp
        ? profile.createdAt.toDate().toISOString()
        : new Date(),
    );
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth is 0-based
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderText>Date</TableHeaderText>
            <TableHeaderText>Weight (lbs)</TableHeaderText>
            <TableHeaderText>Feel good about body (1-5)</TableHeaderText>
            <TableHeaderText>Energy level</TableHeaderText>
            <TableHeaderText>Fat: body waist</TableHeaderText>
            <TableHeaderText>Resting heart rate (bpm)</TableHeaderText>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles &&
            profiles.length > 0 &&
            profiles.map((profile: IBodyProfile) => (
              <TableRow key={profile.id}>
                <TableCell>{renderDate(profile)}</TableCell>
                <TableCell>{profile.weight ?? '-'}</TableCell>
                <TableCell>{profile.selfFeeling ?? '-'}</TableCell>
                <TableCell>
                  {profile.energyLevel ? energyLevelValues[profile.energyLevel] : '-'}
                </TableCell>
                <TableCell>{profile.bodyWaist ? profile.bodyWaist : '-'}</TableCell>
                <TableCell>{profile.heartRate ? profile.heartRate : '-'}</TableCell>
                <TableCell>
                  <StyledEditIcon
                    disabled={isReadOnly}
                    onClick={() => handleEditProfile(profile.id)}
                    size="large"
                  >
                    <EditIcon />
                  </StyledEditIcon>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChatProgramProfileView;
