import { useAppSelector } from '../index';
import { trackEvent } from 'utils/tools/track';

export const useTrackEvent = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  const createLog = async (
    userId: string,
    type: string,
    beforeValue?: any,
    afterValue?: any,
  ): Promise<any> => {
    if (!coachData?.id) return;
    await trackEvent(userId, coachData.id, type, beforeValue, afterValue);
  };

  return {
    createLog,
  };
};
