import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from 'common/core/Menu';
import { Delete, StyledDeleteIcon, StyledMenuItem, SubMenu } from 'styles/dotsMenu';
import { IDotsMenu } from 'types';

const DotsMenu = ({
  anchorEl,
  toggleHandler,
  deleteClickHandler,
  editClickHandler,
  editTitle,
  handoutId,
}: IDotsMenu) => {
  return (
    <Menu anchorEl={anchorEl} toggleMenu={toggleHandler} vertical="bottom">
      <SubMenu>
        <StyledMenuItem>ID: {handoutId}</StyledMenuItem>
      </SubMenu>
      <SubMenu onClick={editClickHandler}>
        <StyledMenuItem>{editTitle}</StyledMenuItem>
        <EditIcon />
      </SubMenu>
      <SubMenu onClick={deleteClickHandler}>
        <Delete>Delete</Delete>
        <StyledDeleteIcon as={DeleteIcon} />
      </SubMenu>
    </Menu>
  );
};

export default DotsMenu;
