import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getDoc,
  getDocs,
  doc,
  updateDoc,
  collection,
  query,
  orderBy,
  addDoc,
  Timestamp,
  deleteField,
} from 'firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import { addProgramActions } from './programActionsSlice';
import { IUpdateWeekProgram, IWeekProgram, TFirePlanId, TFocusArea } from 'types';
import { onSnapshot } from '@firebase/firestore';

const initialState: IWeekProgram = {
  weeks: [],
  currentPlanStatus: undefined,
  loading: true,
  isNutrition: false,
  currentWeekPlan: undefined,
};

export const planDocRef = (userId: string, planId: string) =>
  doc(db, 'users', userId, 'plans', planId);

export const plansCollectionRef = (userId: string) => collection(db, 'users', userId, 'plans');

export const fetchWeekProgram = createAsyncThunk(
  'weekProgram/fetchWeekProgram',
  async (userId: string) => {
    try {
      const q = query(plansCollectionRef(userId), orderBy('week', 'asc'));
      const querySnapshot = await getDocs(q);

      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const fetchCurrentProgramWeek = createAsyncThunk(
  'weekProgram/fetchCurrentProgramWeek',
  async (args: { userId: string; currentPlanId: string }, APIThunk) => {
    try {
      const dispatch = APIThunk.dispatch;
      const docRef = planDocRef(args.userId, args.currentPlanId);
      return onSnapshot(docRef, (snapshot) => {
        const data = { id: snapshot.id, ...snapshot.data() };
        dispatch(documentUpdated(data)); // dispatch an action to update the state
      });
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const updateWeekProgram = createAsyncThunk(
  'weekProgram/updateWeekProgram',
  async (args: IUpdateWeekProgram, APIThunk) => {
    try {
      const { existingPlan, weeksInput, activePlanId, userId } = args;
      const { dispatch } = APIThunk;
      for (const week in weeksInput) {
        if (existingPlan[week]) {
          updateDoc(planDocRef(userId, existingPlan[week].id), {
            week: weeksInput[week].week,
            startDate: weeksInput[week].startDate,
            endDate: weeksInput[week].endDate,
          });
        } else {
          const q = query(plansCollectionRef(userId), orderBy('week', 'asc'));
          const querySnapshot = await getDocs(q);
          const plansRef = querySnapshot.docs.map((document) => {
            return planDocRef(userId, document.id).id;
          });

          // activePlan => displayedPlan not used for the moment, maybe in the future activePlan ? plansRef.indexOf(activePlan)
          const selectedPlan = plansRef.length - 1;

          if (weeksInput[selectedPlan].published) {
            const newWeek = await addDoc(plansCollectionRef(userId), {
              draft: weeksInput[selectedPlan].published,
              week: weeksInput[week].week,
              startDate: weeksInput[week].startDate,
              endDate: weeksInput[week].endDate,
            });

            if (activePlanId) {
              const args = {
                userId,
                activePlanId,
                planToUpdateId: newWeek.id,
              };
              dispatch(addProgramActions(args));
            }
          }
        }
      }
      dispatch(fetchWeekProgram(userId));
    } catch (e: any) {
      console.log(e);
    }
  },
);

interface IPublishUpdatePlan {
  weekPlan: TFirePlanId;
  userId: string;
}

export const publishUpdatePlan = createAsyncThunk(
  'weekProgram/publishUpdatePlan',
  async (args: IPublishUpdatePlan, APIThunk) => {
    const dispatch = APIThunk.dispatch;
    const { weekPlan, userId } = args;
    await updateDoc(planDocRef(userId, weekPlan.id), {
      updatedAt: new Date(),
      draft: deleteField(),
      lastPublishedDate: Timestamp.now(),
    });
    if (weekPlan.draft) {
      await updateDoc(planDocRef(userId, weekPlan.id), {
        published: { ...weekPlan.draft },
      });
    }
    dispatch(fetchWeekProgram(userId));
  },
);

interface IUpdateCardNotes {
  focusArea: TFocusArea;
  currentWeekPlan: TFirePlanId;
  userId: string;
  notes: string;
}

export const updateCardNotes = createAsyncThunk(
  'weekProgram/updateNotes',
  async (args: IUpdateCardNotes) => {
    try {
      const { currentWeekPlan, focusArea, notes, userId } = args;
      const focusAreas = currentWeekPlan?.draft?.plan ?? currentWeekPlan?.published?.plan;
      const draftPlan: TFocusArea[] = [];

      if (focusAreas) {
        focusAreas.forEach((focusAreaMap: TFocusArea) => {
          draftPlan.push(focusAreaMap.id === focusArea.id ? { ...focusArea, notes } : focusAreaMap);
        });

        await updateDoc(planDocRef(userId, currentWeekPlan.id), {
          draft: { plan: draftPlan },
          updatedAt: Timestamp.now(),
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  },
);

interface IDisableCard {
  focusArea: TFocusArea;
  userId: string;
  currentWeekPlan: TFirePlanId;
}

export const disableCard = createAsyncThunk(
  'weekProgram/disableCard',
  async (args: IDisableCard) => {
    try {
      const { focusArea, userId, currentWeekPlan } = args;
      const focusAreas = currentWeekPlan?.draft?.plan ?? currentWeekPlan?.published?.plan;
      const draftPlan: TFocusArea[] = [];
      if (focusAreas) {
        focusAreas.forEach((focusAreaMap: TFocusArea) => {
          draftPlan.push(
            focusAreaMap.id === focusArea.id
              ? { ...focusArea, disabled: !focusArea.disabled }
              : focusAreaMap,
          );
        });

        await updateDoc(planDocRef(userId, currentWeekPlan.id), {
          draft: { plan: draftPlan },
          updatedAt: Timestamp.now(),
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  },
);

interface IReOrderCard {
  selectedPlan: string;
  next: TFocusArea[];
  userId: string;
}

export const reOrderCard = createAsyncThunk(
  'weekProgram/reOrderCard',
  async (args: IReOrderCard) => {
    try {
      const { selectedPlan, next, userId } = args;
      if (selectedPlan && next && userId) {
        await updateDoc(planDocRef(userId, selectedPlan), {
          draft: { plan: next },
          updatedAt: Timestamp.now(),
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const fetchCategory = createAsyncThunk('weekProgram/fetchCategory', async (args: any) => {
  try {
    const { categoryId } = args;

    if (!categoryId) {
      return Promise.reject(new Error('no category id'));
    }

    const docRef = doc(db, 'actions_categories', categoryId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return Promise.reject(new Error('No such document!'));
    }

    return docSnap.data();
  } catch (e) {
    console.log('error in fetchCategory weekProgram', e);
    return Promise.reject(new Error('error in fetchCategory weekProgram'));
  }
});

export const weekProgramSlice = createSlice({
  name: 'weekProgram',
  initialState,
  reducers: {
    updateWeekProgram: (state, action: PayloadAction<any>) => {
      state.weeks = action.payload;
    },
    setCurrentPlanStatus: (state, action: PayloadAction<any>) => {
      state.currentPlanStatus = action.payload;
    },
    resetWeeks: (state) => {
      state.weeks = null;
      state.currentWeekPlan = undefined;
    },
    documentUpdated: (state, action: PayloadAction<any>) => {
      state.currentWeekPlan = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeekProgram.pending, (state) => {
        state.weeks = null;
        state.loading = true;
      })
      .addCase(fetchWeekProgram.fulfilled, (state, action: PayloadAction<any>) => {
        state.weeks = action.payload;
        state.loading = false;
      })
      .addCase(fetchCategory.rejected, (state) => {
        state.weeks = [];
        state.loading = false;
      })
      .addCase(fetchCategory.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload) {
          const { name } = action.payload;
          state.isNutrition = name === 'Nutrition';
          state.category = action.payload;
        }
      });
  },
});

export const { setCurrentPlanStatus, resetWeeks, documentUpdated } = weekProgramSlice.actions;

export default weekProgramSlice.reducer;
