import { styled } from '@mui/system';
import { Autocomplete, Paper } from '@mui/material';

export const AutocompleteSelectAction = styled(Autocomplete)(({ theme }) => ({
  width: '310px',
  '& .MuiOutlinedInput-input': {
    padding: '1.5px 4px !important',
  },
  '& .MuiInputLabel-formControl': {
    top: '-8px',
  },
}));

export const CustomStyleAutocompleteAdmin = styled(Paper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    borderRadius: '8px',
    background: '#F9FAFC',
  },
  '& .MuiAutocomplete-option': {
    padding: '5px 8px',
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '150%',
    color: '#0F0F21',
    background: '#F9FAFC',
  },
  '& .MuiAutocomplete-option[data-focus="true"]': {
    background: '#F2F4F7',
  },
}));
