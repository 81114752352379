/* eslint-disable @typescript-eslint/no-unused-vars */
import { DragIndicatorRounded } from '@mui/icons-material';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import Question from '../Question';
import { inputData } from 'utils/data/surveys';
import { IQuestionsSurvey, ISurveyQuestion } from 'types';
import {
  AddBlockWrapper,
  AddQuestion,
  DragableWrapper,
  DragIcon,
  ListQuestionsCoachWrapper,
} from './styles';

const Questions = ({
  selectedSurveyActions,
  questionsList,
  listQuestionsCoach,
  setListQuestionsCoach,
  inputHandler,
  questionHandler,
  optionHandler,
  addOptionHandler,
  deleteOptionHandler,
  sliderHandler,
  handleAddBlock,
  handleDelete,
  copyClickHandler,
}: IQuestionsSurvey) => {
  const handleOnDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newBlocks = Array.from(listQuestionsCoach);
    const [removed] = newBlocks.splice(result.source.index, 1);
    newBlocks.splice(result.destination.index, 0, removed);
    setListQuestionsCoach(newBlocks);
  };

  return (
    <>
      {selectedSurveyActions
        .filter((filtered: any) => filtered)
        .map((stage: any) => inputData(stage.questions))
        .map((questions: ISurveyQuestion[], index: number) =>
          questions.map((question: ISurveyQuestion) => (
            <Question
              key={question.id}
              index={index}
              id={question.id}
              question={question}
              inputHandler={inputHandler}
              questionHandler={questionHandler}
              nextQuestionList={questions}
              optionHandler={optionHandler}
              addOptionHandler={addOptionHandler}
              deleteOptionHandler={deleteOptionHandler}
              sliderHandler={sliderHandler}
              handleDelete={handleDelete}
              copyClickHandler={copyClickHandler}
              isDisabled={true}
            />
          )),
        )}
      {questionsList.map((question: ISurveyQuestion, index: number) => {
        return (
          <Question
            key={question.id}
            index={index}
            id={question.id}
            question={question}
            inputHandler={inputHandler}
            questionHandler={questionHandler}
            nextQuestionList={questionsList}
            optionHandler={optionHandler}
            addOptionHandler={addOptionHandler}
            deleteOptionHandler={deleteOptionHandler}
            sliderHandler={sliderHandler}
            handleDelete={handleDelete}
            copyClickHandler={copyClickHandler}
            isDisabled={true}
          />
        );
      })}
      <AddBlockWrapper>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
              return (
                <ListQuestionsCoachWrapper
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {listQuestionsCoach.map((question: ISurveyQuestion, index: number) => {
                    return (
                      <Draggable key={index} draggableId={index.toString()} index={index}>
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot,
                        ) => {
                          return (
                            <DragableWrapper
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <DragIcon as={DragIndicatorRounded} />
                              <Question
                                key={question.id}
                                index={index}
                                id={question.id}
                                question={question}
                                inputHandler={inputHandler}
                                questionHandler={questionHandler}
                                nextQuestionList={listQuestionsCoach}
                                optionHandler={optionHandler}
                                addOptionHandler={addOptionHandler}
                                deleteOptionHandler={deleteOptionHandler}
                                sliderHandler={sliderHandler}
                                handleDelete={handleDelete}
                                copyClickHandler={copyClickHandler}
                                isDisabled={false}
                              />
                            </DragableWrapper>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {droppableProvided.placeholder}
                </ListQuestionsCoachWrapper>
              );
            }}
          </Droppable>
        </DragDropContext>
        <AddQuestion variant="outlined" onClick={handleAddBlock}>
          Add question
        </AddQuestion>
      </AddBlockWrapper>
    </>
  );
};

export default Questions;
