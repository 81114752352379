import { styled } from '@mui/system';
import { Select } from '@mui/material';

export const SelectBlock = styled('div')({
  marginBottom: 15,
});

export const StyledSelect = styled(Select)({
  minWidth: 320,
  margin: 0,
});
