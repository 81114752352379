import { useState, useEffect, useCallback } from 'react';
import { v4 } from 'uuid';
import { db } from 'utils/firebase/firebaseInit';
import { IHandout, IResourceBlock } from 'types';
import {
  DocumentData,
  query,
  collection,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  Timestamp,
} from 'firebase/firestore';
import { getDoc } from '@firebase/firestore';

export const useAdminHandouts = () => {
  const [handoutsList, setHandoutsList] = useState<IHandout[]>([]);

  const convertFromDocumentData = (data: DocumentData | undefined, id: string): IHandout => {
    const handout: IHandout = {
      id: id,
      title: data?.title,
      picture: data?.picture,
      blocks: data?.blocks,
      categoryId: data?.categoryId,
    };
    if (handout.blocks) {
      handout.blocks.forEach((block) => (block.id = block.id ?? v4()));
    }
    return handout;
  };

  useEffect(() => {
    const q = query(collection(db, 'handouts'), orderBy('createdAt', 'desc'));
    const refHandouts = getDocs(q);
    refHandouts.then((snapshot) => {
      const getAll = (): IHandout[] => {
        const result: IHandout[] = [];
        snapshot.docs.forEach((rawHandout) => {
          result.push(convertFromDocumentData(rawHandout.data(), rawHandout.id));
        });
        return result;
      };
      setHandoutsList(getAll());
    });
  }, []);

  const addNewHandout = useCallback(
    async (title: string, categoryId: string, blocks: IResourceBlock[], picture: string) => {
      let add: Partial<IHandout> = {
        title,
        picture,
        blocks,
        createdAt: Timestamp.now(),
      };
      if (categoryId) {
        add = {
          ...add,
          categoryId,
        };
      }
      const docRef = await addDoc(collection(db, 'handouts'), add);

      return docRef.id;
    },
    [],
  );

  const editHandout = useCallback(
    async (
      title: string,
      categoryId: string,
      blocks: IResourceBlock[],
      picture: string,
      handoutId = '',
    ) => {
      await updateDoc(doc(db, 'handouts', handoutId), {
        title: title,
        picture: picture,
        categoryId: categoryId,
        blocks: blocks,
      });
    },
    [],
  );

  const removeHandout = useCallback((id: string) => {
    return deleteDoc(doc(db, 'handouts', id));
  }, []);

  const getHandoutById = useCallback(async (id: string) => {
    const docRef = doc(db, 'handouts', id);
    const docSnap = await getDoc(docRef);
    return convertFromDocumentData(docSnap.data(), id);
  }, []);

  return {
    handoutsList,
    addNewHandout,
    editHandout,
    getHandoutById,
    removeHandout,
  };
};
