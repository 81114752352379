import { styled } from '@mui/system';

export const AppWrapperContent = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'start',
  margin: '10px 0 0 40px',
  paddingRight: 40,
  maxHeight: 'calc(100vh - 128px)',
  overflowY: 'scroll',
  gap: 16,
});

export const AppWrapper = styled('div')({
  flexBasis: '100%',
});
