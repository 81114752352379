import { db, functions, auth, storage } from './firebaseInit';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { connectAuthEmulator } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectStorageEmulator } from 'firebase/storage';

const sendEmail = httpsCallable(functions, 'sendEmail');
const handleSurveyStatusChanges = httpsCallable(functions, 'handleSurveyStatusChanges');
const markMessagesViewed = httpsCallable(functions, 'markMessagesViewed');

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_FIREBASE_EMULATOR_USE === 'true'
) {
  connectAuthEmulator(auth, String(process.env.REACT_APP_FIREBASE_EMULATOR_AUTH_URL));
  connectFirestoreEmulator(
    db,
    String(process.env.REACT_APP_FIREBASE_EMULATOR_DB_HOST),
    Number(process.env.REACT_APP_FIREBASE_EMULATOR_DB_PORT),
  );
  connectStorageEmulator(
    storage,
    String(process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_HOST),
    Number(process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_PORT),
  );
  connectFunctionsEmulator(
    functions,
    String(process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_HOST),
    Number(process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_PORT),
  );
}

export { sendEmail, handleSurveyStatusChanges, markMessagesViewed };
