import { useState, useEffect } from 'react';
import { db } from 'utils/firebase/firebaseInit';
import { v4 } from 'uuid';
import { convertToSeconds } from 'utils/format/convert';
import { IResourceBlock, EResourceBlockTypes, EResourceTypes, IResource } from 'types';
import { useFilesUpload } from '../useFilesUpload';
import {
  addDoc,
  collection,
  getDocs,
  Timestamp,
  doc,
  updateDoc,
  deleteDoc,
  DocumentData,
  getDoc,
} from 'firebase/firestore';

export const useAdminResource = () => {
  const [resourcesList, setResourcesList] = useState<IResource[]>([]);
  const { removeFile } = useFilesUpload();

  const convertFromDocumentData = (data: DocumentData | undefined, id: string): IResource => {
    const resource: IResource = {
      id: id,
      title: data?.title,
      htmlBodyUrl: data?.htmlBodyUrl,
      type: data?.type,
      picture: data?.picture,
      categoryId: data?.categoryId,
      tags: data?.tags,
      blocks: data?.blocks,
      thumbnail: data?.thumbnail,
      parameters: data?.parameters ? new Map(Object.entries(data?.parameters)) : new Map(),
    };

    if (resource.blocks) {
      resource.blocks.forEach((block) => {
        block.id = block.id ?? v4();
      });
    }

    return resource;
  };

  const newParametersConvert = (parameters: Map<string, any>) => {
    const newParameters = Object.fromEntries(parameters);
    return {
      ...newParameters,
      prep_time: convertToSeconds(newParameters?.prep_time),
      cook_time: convertToSeconds(newParameters?.cook_time),
    };
  };

  useEffect(() => {
    const refResources = getDocs(collection(db, 'resources'));
    refResources.then((snapshot) => {
      const findChildren = (): IResource[] => {
        const result: IResource[] = [];
        snapshot.docs.forEach((rawResource) => {
          result.push(convertFromDocumentData(rawResource.data(), rawResource.id));
        });
        return result;
      };
      setResourcesList(findChildren());
    });
  }, []);

  const addNewResourceWorkout = async (
    parameters: Map<string, any>,
    description: string,
    url: string,
    tags: string[] = [],
    categoryId: string,
    title: string,
  ) => {
    const blocks: IResourceBlock[] = [];
    blocks.push({
      type: EResourceBlockTypes.TEXT,
      body: description,
      url: '',
    });
    blocks.push({
      type: EResourceBlockTypes.VIDEO,
      body: '',
      url: url,
    });
    let parametres = Object.fromEntries(parameters);
    parametres = {
      ...parametres,
      view_time: convertToSeconds(parametres?.view_time),
    };
    const docRef = await addDoc(collection(db, 'resources'), {
      parameters: parametres,
      title: title,
      tags: tags,
      categoryId: categoryId,
      type: EResourceTypes.WORKOUT,
      blocks: blocks,
      createdAt: Timestamp.now(),
    });

    return docRef.id;
  };

  const addNewResourceRecipe = async (
    title: string,
    categoryId: string,
    tags: string[] = [],
    htmlBodyUrl: string,
    thumbnail: string,
    parameters: Map<string, any>,
  ) => {
    const docRef = await addDoc(collection(db, 'resources'), {
      title,
      tags,
      thumbnail,
      parameters: newParametersConvert(parameters),
      categoryId,
      type: EResourceTypes.RECIPE,
      htmlBodyUrl,
      createdAt: Timestamp.now(),
      blocks: [], // delete
    });

    return docRef.id;
  };

  const editResourceRecipe = async (
    title: string,
    categoryId: string,
    tags: string[] = [],
    htmlBodyUrlDelete: string,
    htmlBodyUrl: string,
    thumbnail: string,
    parameters: Map<string, any>,
    resourceId = '',
  ) => {
    const docRef = doc(db, 'resources', resourceId);
    await updateDoc(docRef, {
      title,
      tags,
      thumbnail,
      parameters: newParametersConvert(parameters),
      categoryId,
      htmlBodyUrl,
    });

    if (htmlBodyUrlDelete.length > 0) {
      await removeFile(htmlBodyUrlDelete);
    }
  };

  const addNewResourceArticle = async (
    title: string,
    categoryId: string,
    tags: string[] = [],
    blocks: IResourceBlock[],
    thumbnail: string,
    picture: string,
  ) => {
    const docRef = await addDoc(collection(db, 'resources'), {
      title: title,
      tags: tags,
      thumbnail: thumbnail,
      picture: picture,
      categoryId: categoryId,
      type: EResourceTypes.ARTICLE,
      blocks: blocks,
      createdAt: Timestamp.now(),
    });

    return docRef.id;
  };

  const editResourceWorkout = async (
    parameters: Map<string, any>,
    description: string,
    url: string,
    tags: string[] = [],
    categoryId: string,
    title: string,
    resourceId = '',
  ) => {
    const blocks: IResourceBlock[] = [];
    blocks.push({
      type: EResourceBlockTypes.TEXT,
      body: description,
      url: '',
    });
    blocks.push({
      type: EResourceBlockTypes.VIDEO,
      body: '',
      url: url,
    });
    let parametres = Object.fromEntries(parameters);
    parametres = {
      ...parametres,
      view_time: convertToSeconds(parametres?.view_time),
    };
    const docRef = doc(db, 'resources', resourceId);
    await updateDoc(docRef, {
      parameters: parametres,
      tags: tags,
      categoryId: categoryId,
      blocks: blocks,
      title: title,
    });
  };

  const editResourceArticle = async (
    title: string,
    categoryId: string,
    tags: string[] = [],
    blocks: IResourceBlock[],
    thumbnail: string,
    picture: string,
    resourceId = '',
  ) => {
    const docRef = doc(db, 'resources', resourceId);
    await updateDoc(docRef, {
      title: title,
      tags: tags,
      thumbnail: thumbnail,
      picture: picture,
      categoryId: categoryId,
      blocks: blocks,
    });
  };

  const removeResource = (id: string) => {
    return deleteDoc(doc(db, 'resources', id));
  };

  const removeResourceOldBlock = async (id: string) => {
    const docRef = doc(db, 'resources', id);
    await updateDoc(docRef, {
      blocks: [],
    });
  };

  const getResourceById = async (id: string) => {
    const docRef = doc(db, 'resources', id);
    const docSnap = await getDoc(docRef);
    const rawResource = docSnap.data();
    return convertFromDocumentData(rawResource, id);
  };

  return {
    resourcesList,
    getResourceById,
    removeResource,
    editResourceWorkout,
    editResourceRecipe,
    editResourceArticle,
    addNewResourceWorkout,
    addNewResourceRecipe,
    addNewResourceArticle,
    removeResourceOldBlock,
  };
};
