import { useContext } from 'react';
import { SubText } from './style';
import { EChatMessageAnalysisType, IChatMessage } from 'types';
import Button from 'common/core/Button';
import { useAppDispatch } from 'hooks';
import { Context } from 'context/Context';
import { initFaq } from 'store/customers/progressFaqSlice';
import { handleCopyToClipboard } from 'utils/tools/copyToClipboard';
import { setEditMode } from 'store/customers/aboutClientSlice';
import { setDrawerOpen } from 'store/coPilot/coPilotSlice';
import { useUserProfile } from 'hooks/profile/useUserProfile';
import { cleanMessageAnalysis } from 'utils/data/message';
import { toast } from 'sonner';
import { classifyImportantInfo } from '../../../../../api/llm';
import { useTrackEvent } from 'hooks/tracking/useTrackEvent';

interface IChatMessagesListItemTypeAnalysis {
  message: IChatMessage;
}

const ChatMessagesListItemTypeAnalysis = ({ message }: IChatMessagesListItemTypeAnalysis) => {
  const dispatch = useAppDispatch();
  const { setUserCoPilotFlow } = useUserProfile(message.userId);
  const setbackFlowId = process.env.REACT_APP_COPILOT_FLOW_SETBACK;
  const cancelFlowId = process.env.REACT_APP_COPILOT_FLOW_CANCEL;

  const setIsClientInfoOpen = useContext(Context).setIsClientInfoOpen;
  const cleanType = cleanMessageAnalysis(message);

  const { createLog } = useTrackEvent();

  const buttonText = (type: EChatMessageAnalysisType): string => {
    switch (type) {
      case EChatMessageAnalysisType.SETBACK:
        return 'Address this setback';
      case EChatMessageAnalysisType.QUESTION:
        return 'Answer this question';
      case EChatMessageAnalysisType.CANCEL:
        return 'Process this cancellation';
      case EChatMessageAnalysisType.IMPORTANT_INFO:
        return 'Add important info about the client';
      default:
        return '';
    }
  };

  const subText = (type: EChatMessageAnalysisType): string => {
    switch (type) {
      case EChatMessageAnalysisType.SETBACK:
        return 'If the client expressed a setback towards achieving one of their weekly goals, click the button to trigger a conversation to address the setback. If the messages is not a setback use the free text to respond.';
      case EChatMessageAnalysisType.QUESTION:
        return 'Click the above for questions about the programme ("what is a good snack etc"). If this a question about the Fitmate service (price, app functionality etc), then use the free text.';
      case EChatMessageAnalysisType.IMPORTANT_INFO:
        return 'Only add new info about the client that is relevant to personalise the programme in the future eg. dietary preferences and medical conditions etc (click the button to see the relevant categories). Don’t add minor day-to-day info.';
      case EChatMessageAnalysisType.CANCEL:
        return 'If the client is cancelling their subscription, click the button to process the cancellation. If the message is not a cancellation, use the free text to respond';
      default:
        return '';
    }
  };

  const handleTypeClick = (e: any, type: EChatMessageAnalysisType) => {
    e.stopPropagation();
    e.preventDefault();
    // open correct pannel for the message type
    switch (type) {
      case EChatMessageAnalysisType.SETBACK:
        // open co-pilot with setback flow selected
        createLog(message.userId, 'setback-clicked', message.id);
        handleCopyToClipboard(message.body);
        setUserCoPilotFlow(setbackFlowId ?? '');
        dispatch(setDrawerOpen(true));
        return;
      case EChatMessageAnalysisType.QUESTION:
        createLog(message.userId, 'faq-clicked', message.id);
        dispatch(initFaq({ message }));
        return;
      case EChatMessageAnalysisType.CANCEL:
        createLog(message.userId, 'cancel-clicked', message.id);
        // open copilot with cancel flow.
        handleCopyToClipboard(message.body);
        setUserCoPilotFlow(cancelFlowId ?? '');
        dispatch(setDrawerOpen(true));
        return;
      case EChatMessageAnalysisType.IMPORTANT_INFO:
        createLog(message.userId, 'info-clicked', message.id);
        toast.promise(classifyImportantInfo(message.body), {
          loading: 'checking important info categorisation...',
          success: (data: any) => {
            toast(`This is for ${data.categoryName}`, {
              description: data.information,
              duration: Infinity,
              action: {
                label: 'Add to client info',
                onClick: () => {
                  handleCopyToClipboard(data.information);
                  dispatch(setEditMode({ isEdit: true }));
                  setIsClientInfoOpen(true);
                },
              },
            });
            return data.information;
          },
          error: 'An error occurred, please try again.',
        });
        return;
      default:
        return '';
    }
  };

  return (
    <div>
      {Array.isArray(cleanType) ? (
        cleanType.map((type) => (
          <div key={type}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={(e: any) => handleTypeClick(e, type)}
            >
              {buttonText(type)}
            </Button>
            <SubText>{subText(type)}</SubText>
          </div>
        ))
      ) : (
        <div>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={(e: any) => handleTypeClick(e, cleanType)}
          >
            {buttonText(cleanType)}
          </Button>
          <SubText>{subText(cleanType)}</SubText>
        </div>
      )}
    </div>
  );
};

export default ChatMessagesListItemTypeAnalysis;
