import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Input from '../../../common/core/Input';
import { Select } from '@mui/material';
import Button from '../../../common/core/Button';

export const Container = styled('div')({});

export const TitleBlock = styled('div')({
  marginBottom: 5,
});

export const ElementBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const ElementLeftBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  marginRight: 5,
});

export const ElementRightBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  marginLeft: 5,
});

export const NoteBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  paddingTop: 15,
  paddingBottom: 12,
});

export const ButtonBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: 20,
});

export const TitleText = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  color: '#0F0F21',
});

export const NoteText = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
});

export const StyledInput = styled(Input)({
  borderRadius: 8,
  fontSize: 14,
  fontWeight: 400,
});

export const InputTime = styled('input')({
  cursor: 'pointer',
  borderRadius: 8,
  fontSize: 14,
  fontWeight: 400,
});

export const StyledSelect = styled(Select)({
  width: 315,
  borderRadius: 8,
  fontSize: 14,
  fontWeight: 400,
});

export const InputAdornmentIcon = styled('span')({
  fontSize: 22,
  color: 'rgba(15, 15, 33, 0.4)',
});

export const ButtonCancel = styled(Button)({
  fontSize: 14,
  fontWeight: 600,
  color: 'rgba(15, 15, 33, 0.6)',
  width: '48%',
  height: 44,
  borderRadius: 8,
});

export const ButtonSubmit = styled(Button)({
  fontSize: 14,
  fontWeight: 600,
  width: '48%',
  height: 44,
  borderRadius: 8,
});
