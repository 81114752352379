const ChatProgramPlanHelp = () => {
  return (
    <>
      <h4>How this works</h4>
      <h5>Purpose</h5>
      <p>
        This section is where you manage your client's program and define the specific goals for the
        selected program week.
      </p>
      <h5>How it’s generated</h5>
      <p>
        When you create a new program week, it will clone the previous week's plan. After discussing
        new specific goals with your client during the end-of-week review, you can customize this
        plan for the new week and then publish it, enabling tracking of both new and existing goals.
      </p>
      <p>
        Please note that only the plan for the latest program week is editable, but you can review
        previous weeks.
      </p>
      <h5>How to use</h5>
      <p>
        IMPORTANT: Changes made here won't be visible to the client until you click 'Publish plan'
        or 'Update plan' (if the plan was already published).
      </p>
      <h5>Adding a Specific Goal to a Focus Area:</h5>
      <ul>
        <li>Click on the three dots icon within the focus area.</li>
        <li>Select 'Add specific goal' and enter the information into the goal modal.</li>
        <li>Click Save.</li>
      </ul>
      <h5>Disabling a Focus Area:</h5>
      <ul>
        <li>Click on the three dots icon within the focus area.</li>
        <li>Select 'Disable focus area'.</li>
      </ul>
      <h5>Moving a Focus Areas and it’s Specific Goals: </h5>
      <ul>
        <li>
          You can drag the card containing the focus area to a different position on the board.
        </li>
        <li>
          For example, if a focus area has become a habit, move it to the 'Existing healthy habits'
          section.
        </li>
        <li>
          If the client wants to prioritize a lower-ranked area, drag it up the priority order.
        </li>
      </ul>
      <h5>Editing Coach Notes for the Focus Area:</h5>
      <ul>
        <li>Click on the three dots icon and select 'Edit coach notes'.</li>
        <li>Make your changes and press Save.</li>
        <li>
          Tip: You can use coach notes to capture information about areas to work on within the
          focus area for future specific goals.
        </li>
      </ul>
    </>
  );
};

export default ChatProgramPlanHelp;
