import { styled } from '@mui/system';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 20,
  paddingRight: 20,
  backgroundColor: '#fff',
  width: 'calc(100% - 20px)',
});

export const InputBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  minHeight: 60,
  maxHeight: 600,
  overflowY: 'auto',
  wordWrap: 'break-word',
});

export const ControlsBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  width: '100%',
  paddingTop: 5,
});

export const ControlsLeftBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexGrow: 1,
  paddingLeft: 35,
});

export const ActionIcon = styled('img')({
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
  paddingRight: 16,
  paddingBottom: 4,
});
