export function isCategoryNotEmpty(category: string, actions: any, status: string) {
  let filteredList;
  if (status === 'all') {
    filteredList = actions.filter((action: any) => action.habitType === category);
  } else {
    filteredList = actions.filter(
      (action: any) => action.habitType === category && action.status === status,
    );
  }

  return filteredList.length !== 0;
}
