import { useState } from 'react';
import { Container } from '@mui/material';
import { useAdminActions } from 'hooks/admin/useAdminActions';
import { useAdminHandouts } from 'hooks/admin/useAdminHandouts';
import { HeaderBlock, TitleBlock, TitleText } from './styles';
import Button from 'common/core/Button';
import ActionsList from '../ActionsList';
import ActionsModal from '../ActionsModal';
import { IAction } from 'types';

const Actions = () => {
  const { handoutsList } = useAdminHandouts();
  const { categoriesList, subcategoriesList, handoutsListSymbols, categoryListNew } =
    useAdminActions();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [activeAction, setActiveAction] = useState<IAction | null>(null);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleChooseAction = (action: IAction) => {
    setActiveAction(action);
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setActiveAction(null);
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: 10 }}>
      <HeaderBlock>
        <TitleBlock>
          <TitleText>Actions</TitleText>
        </TitleBlock>
        <div>
          <div>
            <Button onClick={handleOpenModal}>Create new action</Button>
          </div>
        </div>
      </HeaderBlock>
      <ActionsList
        categoriesList={categoriesList}
        subcategoriesList={subcategoriesList}
        handleChooseAction={handleChooseAction}
        categoryListNew={categoryListNew}
      />
      {isOpenModal && (
        <ActionsModal
          activeAction={activeAction}
          categoriesList={categoriesList}
          subcategoriesList={subcategoriesList}
          handoutsListSymbols={handoutsListSymbols}
          handoutsList={handoutsList}
          isOpenModal={isOpenModal}
          handleCloseModal={handleCloseModal}
          categoryListNew={categoryListNew}
        />
      )}
    </Container>
  );
};

export default Actions;
