import FilterListIcon from '@mui/icons-material/FilterList';
import { RadioGroup } from '@mui/material';
import Menu from 'common/core/Menu';
import { clientSearchFilterReadStatus } from 'store/client-search/clientSearchSlice';
import { threadsFilter } from 'config';
import React, { useState } from 'react';
import {
  FilterIcon,
  FilterText,
  FilterWrapper,
  FontFilter,
  ItemRadio,
  MenuFilter,
  MenuHeader,
  ResetText,
  RightBlock,
  StyledMenuItem,
  ThreadsFilterOptionElement,
  ThreadsFilterOptionText,
} from '../ChatThreadsFilter/styles';
import { useAppDispatch, useAppSelector } from 'hooks';

const ChatThreadsReadFilter = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((x) => {
    const value = x.clientSearch.filters?.ismessagesunread;
    if (!value) return 'all';
    if (value === 'true') return 'unread';
    return 'read';
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const toggleMenu = (e: React.MouseEvent<any>) =>
    anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);

  return (
    <RightBlock onClick={toggleMenu}>
      <FilterWrapper>
        <FilterIcon as={FilterListIcon} />
        <FontFilter>Filter</FontFilter>
      </FilterWrapper>
      <Menu toggleMenu={toggleMenu} anchorEl={anchorEl} style={{ marginTop: 30, width: 255 }}>
        <MenuFilter>
          <MenuHeader disableRipple={true}>
            <FilterText>Filter by</FilterText>
            <ResetText
              onClick={() => dispatch(clientSearchFilterReadStatus(threadsFilter[2].value))}
            >
              Reset filters
            </ResetText>
          </MenuHeader>
          <StyledMenuItem disableRipple={true}>
            <RadioGroup
              value={filter}
              onChange={(e) => dispatch(clientSearchFilterReadStatus(e.target.value as any))}
              name="radio-group-filter"
            >
              {threadsFilter.map((filterItem) => (
                <ThreadsFilterOptionElement
                  key={filterItem.value}
                  value={filterItem.value}
                  control={<ItemRadio color="primary" size="small" />}
                  label={
                    <ThreadsFilterOptionText isOpen={filterItem.value === filter}>
                      {filterItem.label}
                    </ThreadsFilterOptionText>
                  }
                />
              ))}
            </RadioGroup>
          </StyledMenuItem>
        </MenuFilter>
      </Menu>
    </RightBlock>
  );
};

export default ChatThreadsReadFilter;
