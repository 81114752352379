import { useAppSelector } from '../index';
import { useContext } from 'react';
import { Context } from 'context/Context';

export const useDrawerOffset = () => {
  const { openedDrawer: faqOpen } = useAppSelector((state) => state.progressFaq);
  const { openedDrawer: feedbackOpen } = useAppSelector((state) => state.progressFeedback);
  const { openedDrawer: coPilotOpen } = useAppSelector((state) => state.coPilot);
  const myPlanOpen = useContext(Context).openedMyPlanDrawer;
  //   const clientInfoOpen = useContext(Context).isClientInfoOpen;
  const mealDrawerOpen = useContext(Context).openedMealDrawer;

  const getDrawerOffset = (drawerName: string): number => {
    // get all opened drawers, and return the offset for the drawer

    // order of drawers:
    // co-pilot -> myPlan  -> faq -> feedback -> mealDrawer -> clientInfo
    // co-pilot never has offset

    // drawer sizes:
    // co-pilot: 340
    // myPlan: 340
    // clientInfo: 340
    // faq: 400 ?? why is this different from the others
    // feedback: 400
    // mealDrawer: 400
    switch (drawerName) {
      case 'myPlan':
        return 0 + (coPilotOpen ? 340 : 0);
      case 'faq':
        return 0 + (myPlanOpen ? 340 : 0) + (coPilotOpen ? 340 : 0);
      case 'feedback':
        return 0 + (myPlanOpen ? 340 : 0) + (coPilotOpen ? 340 : 0) + (faqOpen ? 400 : 0);
      case 'mealDrawer':
        return (
          0 +
          (myPlanOpen ? 340 : 0) +
          (coPilotOpen ? 340 : 0) +
          (faqOpen ? 400 : 0) +
          (feedbackOpen ? 400 : 0)
        );
      case 'clientInfo':
        return (
          0 +
          (myPlanOpen ? 340 : 0) +
          (coPilotOpen ? 340 : 0) +
          (faqOpen ? 400 : 0) +
          (feedbackOpen ? 400 : 0) +
          (mealDrawerOpen ? 400 : 0)
        );
      default:
        return 0;
    }
  };

  return getDrawerOffset;
};
