import { styled } from '@mui/system';

export const CategoryCoachCard = styled('figure')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: 12,
  margin: 0,
  maxHeight: 164,
  minHeight: 164,
  minWidth: 230,
  background: '#7EDDC7',
  cursor: 'pointer',
}));

export const CategoryCoachImg = styled('img')(({ theme }) => ({
  width: 230,
  borderRadius: 12,
  objectFit: 'cover',
}));

export const CategoryCaption = styled('figcaption')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  textAlign: 'center',
  paddingTop: 45,
  paddingBottom: 10,
  background: 'linear-gradient(180deg, rgba(90, 80, 139, 0) 0%, rgba(0, 0, 0, 0.82) 100%)',
  opacity: 0.8,
  borderRadius: '0 0 12px 12px',
  '& .MuiTypography-root': {
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: 16,
  },
}));
