import { useController } from 'react-hook-form';
import { InputBlock, StyledInput } from './styles';
import { IInputFormField } from 'types';

const InputFormField = ({
  name,
  control,
  label,
  value,
  defaultValue,
  rules,
  handleChange,
  inputProps,
  multiline,
  disabled,
  fullWidth,
  type,
  mb = 15,
}: IInputFormField) => {
  const { field, formState } = useController({ control, name, rules, defaultValue });
  const error = formState?.errors?.[name]?.message?.toString() ?? '';

  return (
    <InputBlock isFullWidth={fullWidth} mb={mb}>
      <StyledInput
        name={name}
        label={label}
        value={value ?? field.value}
        onChange={(event) => {
          handleChange ? handleChange(event) : field.onChange(event);
        }}
        inputProps={inputProps}
        multiline={!!multiline?.rows}
        rows={multiline?.rows}
        maxRows={multiline?.maxRows}
        disabled={disabled}
        error={!!error}
        helperText={error}
        fullWidth={fullWidth}
        type={type}
      />
    </InputBlock>
  );
};

export default InputFormField;
