import { IChatMessage } from 'types';
import {
  StyledEngagementIcon,
  StyledManualEowIcon,
  SurveyHorizontalBlock,
  SurveyHorizontalChip,
} from '../styles';

const EngagementChip = ({ message }: { message: IChatMessage }) => {
  const { body } = message;
  return (
    <SurveyHorizontalBlock>
      <SurveyHorizontalChip icon={<StyledEngagementIcon />} label={body} />
    </SurveyHorizontalBlock>
  );
};

export const EoWChip = ({ message }: { message: IChatMessage }) => {
  const { body } = message;
  return (
    <SurveyHorizontalBlock>
      <SurveyHorizontalChip icon={<StyledManualEowIcon />} label={body} />
    </SurveyHorizontalBlock>
  );
};

export default EngagementChip;
