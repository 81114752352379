import { styled, Theme } from '@mui/system';
import { Chip } from '@mui/material';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import EngaementIcon from '@mui/icons-material/ReportProblem';
import TodayIcon from '@mui/icons-material/Today';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '100%',
  height: '100%',
  alignSelf: 'flex-end',
  paddingTop: 20,
  paddingBottom: 20,
}));

export const CombinedWithDateBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const DateHorizontalBlock = styled('div')({
  display: 'flex',
  alignSelf: 'center',
  marginBottom: 10,
});

export const SurveyHorizontalBlock = styled('div')({
  display: 'flex',
  alignSelf: 'center',
  marginBottom: 10,
});

export const DateHorizontalChip = styled(Chip)({
  fontSize: 12,
  fontWeight: 600,
  color: '#0F0F21',
  backgroundColor: '#F2F4F7',
  borderRadius: 26,
  padding: '3px 10px',
});

export const SurveyHorizontalChip = styled(Chip)({
  fontSize: 12,
  fontWeight: 600,
  color: 'rgba(15, 15, 33, 0.6)',
  backgroundColor: '#F2F4F7',
  borderRadius: 26,
  padding: '3px 10px',
});

export const SurveyChipIcon = styled('div')({
  fontSize: 16,
});

export const StyledAccessibilityNewIcon = styled(AccessibilityNewIcon)(({ theme }) => ({
  color: `${theme.palette.secondary.main}!important`,
  fontSize: 16,
}));

export const SurveyCompletedIcon = styled(SurveyChipIcon)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
}));

export const SurveyDeclinedIcon = styled(SurveyChipIcon)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.error.main,
}));

export const SurveySendIcon = styled(SurveyChipIcon)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
}));

export const StyledEngagementIcon = styled(EngaementIcon)(({ theme }: { theme: Theme }) => ({
  color: `${theme.palette.error.main}!important`,
  fontSize: 16,
}));

export const StyledManualEowIcon = styled(TodayIcon)(({ theme }: { theme: Theme }) => ({
  color: `${theme.palette.error.main}!important`,
  fontSize: 16,
}));
