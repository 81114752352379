import { styled, width } from '@mui/system';
import { Drawer } from '@mui/material';

export const StyledDrawer = styled(Drawer)<{ isOpen: boolean; rightOffset: number; width: number }>(
  ({ isOpen, rightOffset, width }) => ({
    flexShrink: 0,
    ...(isOpen && {
      width: width,
      flexShrink: 0,
    }),
    '& .MuiDrawer-paper': {
      marginRight: rightOffset,
      width: width,
    },
  }),
);

export const DrawerClosed = styled('div')({});

export const DrawerOpened = styled('div')<{ width: number }>(({ width }) => ({
  width: width,
  flexShrink: 0,
}));

export const DrawerPaper = styled('div')<{ width: number }>(({ width }) => ({
  width: width,
}));

export const IframeCloseIcon = styled('div')({
  position: 'absolute',
  top: 10,
  right: 5,
  zIndex: 999,
  cursor: 'pointer',
});

export const Iframe = styled('iframe')({
  width: '340px', // iframe doesn't support width: 100%
  height: '100%',
  border: 'none',
  outline: 'none',
});
