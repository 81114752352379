import { IDocument, IAdminUser } from 'types';

export const sortByFirstName = (a: any, b: any) => {
  const x = a.firstName.toLowerCase().trim();
  const y = b.firstName.toLowerCase().trim();
  return x < y ? -1 : x > y ? 1 : 0;
};

export const sortByFirstAssignedAndName = (a: IAdminUser, b: IAdminUser) => {
  if (a.coachAssignment?.hasCoachesAssigned === b.coachAssignment?.hasCoachesAssigned) {
    return a.firstName.toLowerCase().trim().localeCompare(b.firstName.toLowerCase().trim());
  } else {
    return a.coachAssignment?.hasCoachesAssigned ? 1 : -1;
  }
};

export const sortAnswerByDate = (a: IDocument, b: IDocument) => {
  return (
    new Date(b.meta?.created_at ?? '').getTime() / 1000 -
    new Date(a.meta?.created_at ?? '').getTime() / 1000
  );
};
