import {
  customIconButtonTitle,
  degreesButtonTitle,
  fractionsButtonTitle,
  oneLineButtonTitle,
  twoLineButtonTitle,
} from './const';
import { recipeTemplate } from '../templates/cutomTemplate';

export const initPlugin =
  'preview importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media table charmap nonbreaking insertdatetime advlist lists wordcount help charmap quickbars emoticons template';
export const initMenuBar = 'edit view insert format tools table help';
export const initToolbar = `undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview | insertfile image media link | ltr rtl | template ${oneLineButtonTitle} ${twoLineButtonTitle} | ${degreesButtonTitle} ${fractionsButtonTitle} | ${customIconButtonTitle}`;
export const initTemplateCDateFormat = '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]';
export const initTemplateMDateFormat = '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]';
export const initQuickBarsSelectionToolbar =
  'bold italic | quicklink h2 h3 blockquote quickimage quicktable';
export const initNonEditableClass = 'mceNonEditable';
export const initToolbarMode = 'sliding';
export const initContextMenu = 'link image table';
export const initFontSizeFormats = '8pt 10pt 12pt 13pt 14pt 15pt 18pt 20pt 24pt 36pt';
export const initContentStyle = `@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');
          body {font-family: 'Manrope'; font-size: 15pt };`;
export const initFontFamilyFormats: string =
  'Arial=arial,helvetica,sans-serif; ' +
  'Arial Black=arial black,avant garde; ' +
  'Book Antiqua=book antiqua,palatino; ' +
  'Courier New=courier new,courier; ' +
  'Georgia=georgia,palatino; ' +
  'Helvetica=helvetica; ' +
  'Impact=impact,chicago; ' +
  'Manrope=manrope; ' +
  'Symbol=symbol; ' +
  'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
  'Terminal=terminal,monaco; ' +
  'Times New Roman=times new roman,times; ' +
  'Trebuchet MS=trebuchet ms,geneva; ' +
  'Verdana=verdana,geneva;';

export const initTinyData: any = {
  plugins: initPlugin,
  menubar: initMenuBar,
  toolbar: initToolbar,
  toolbar_sticky: true,
  image_advtab: true, //Enabled adventure tab when user add image
  importcss_append: true, //Enabled Formats menu in Format
  template_cdate_format: initTemplateCDateFormat,
  template_mdate_format: initTemplateMDateFormat,
  height: 600,
  image_caption: true,
  quickbars_selection_toolbar: initQuickBarsSelectionToolbar,
  noneditable_class: initNonEditableClass,
  toolbar_mode: initToolbarMode, //Fixes the appearing toolbar
  contextmenu: initContextMenu,
  font_size_formats: initFontSizeFormats, //Defines font size list
  content_style: initContentStyle,
  font_family_formats: initFontFamilyFormats, //Defines fonts to be displayed in the list font
  formats: {
    //Setting custom formats item in Format menu
    customFormat: {
      block: 'div',
      styles: { marginLeft: '16px', marginRight: '16px' },
    },
  },
  style_formats: [{ title: 'Margin 16px', format: 'customFormat' }], //Create new custom Formats
  style_formats_merge: true, //If false custom format replace all default format
  branding: false, //Remove Tiny logo from editor
  //Templates list
  templates: [
    {
      title: 'Recipe template',
      description: 'Template that will allow you to create a recipe with this template',
      content: recipeTemplate,
    },
  ],
};
