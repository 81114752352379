import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Button } from '@mui/material';
import {
  BtnLabel,
  CategoryWrapper,
  TagsWrapper,
  TitleBlock,
  TitleText,
  WarningBlock,
  WarningTitle,
} from './styles';
import { IResourceTags } from 'types';
import WarningIcon from 'assets/icons/warning.svg';
import AddIcon from 'assets/icons/plus.svg';

const ResourceTags = ({
  tags,
  toggleTagAddModal,
  handleResourceDataChange,
  activeCategory,
  currentTags = [],
}: IResourceTags) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    setSelectedTags([]);
  }, [activeCategory]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let copy = [...selectedTags, event.target.value];
    if (selectedTags.includes(event.target.value)) {
      copy = copy.filter((tag) => tag !== event.target.value);
    }
    setSelectedTags(copy);
    handleResourceDataChange((prev: any) => {
      return { ...prev, tags: [...copy] };
    });
  };

  return (
    <CategoryWrapper>
      <TitleBlock>
        <TitleText>Choose tags</TitleText>
        <Button onClick={toggleTagAddModal} disabled={activeCategory?.tags === undefined}>
          <BtnLabel>New tag</BtnLabel>
          <img src={AddIcon} alt="plus" />
        </Button>
      </TitleBlock>
      {tags && tags?.length > 0 ? (
        <TagsWrapper>
          {tags.map((tag) => {
            return (
              <FormControlLabel
                key={tag}
                control={
                  <Checkbox
                    checked={currentTags.includes(tag)}
                    onChange={handleCheckboxChange}
                    key={`${tag}-key-checkbox`}
                    name={tag}
                    value={tag}
                    color="primary"
                  />
                }
                label={tag}
              />
            );
          })}
        </TagsWrapper>
      ) : (
        <WarningBlock>
          <img src={WarningIcon} alt="Warning" />
          <WarningTitle>Please choose category and subcategory to get a tag list</WarningTitle>
        </WarningBlock>
      )}
    </CategoryWrapper>
  );
};

export default ResourceTags;
