import { styled } from '@mui/system';
import Button from '../../../../../../common/core/Button';

export const AddBlockWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const AddQuestion = styled(Button)({
  margin: '0 auto',
  boxShadow: '0px -1px 0px #E4E7ED',
  borderRadius: 8,
  padding: '12px 43px',
  height: '44px !important',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'transparent',
  color: 'rgba(15, 15, 33, 0.6)',
  fontSize: 14,
  fontWeight: 600,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    boxShadow: 'none',
  },
});

export const ListQuestionsCoachWrapper = styled('ul')({
  paddingLeft: '0px',
  '& li': {
    listStyle: 'none',
  },
});

export const DragableWrapper = styled('li')({
  position: 'relative',
});

export const DragIcon = styled('span')({
  top: '0px',
  left: '-32px',
  position: 'absolute',
  margin: '4px 7px',
  color: 'rgba(15, 15, 33, 0.2);',
});
