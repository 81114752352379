import { useState } from 'react';
import { Timestamp } from '@firebase/firestore';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { add, isWeekend, nextMonday } from 'date-fns';
import { cn } from 'mcn';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateWeekProgram } from 'store/program/weekProgramSlice';
import { Button, Card, Tooltip } from '../ChatProgramComponents';
import ChatProgramWeeksForm from './ChatProgramWeeksForm';
import ChatProgramWeekPlan from './ChatProgramWeekPlan';
import ChatProgramWeekHelp from './ChatProgramWeekHelp';
import { TProgramWeeksData } from 'types';
import { toast } from 'sonner';

export default function ChatProgramWeek() {
  const [editing, setEditing] = useState(false);
  const dispatch = useAppDispatch();
  const { currentWeekPlan, weeks, loading } = useAppSelector((state) => state.weekProgram);
  const { customer, isReadOnly } = useAppSelector((state) => state.customerInfos);
  const form = useForm<TProgramWeeksData>({ defaultValues: { weeks } });

  const addWeek = () => {
    const programLastWeek = weeks[weeks.length - 1];
    const lastWeekPublishedPlan = programLastWeek.published?.plan;
    const programLastWeekDate = programLastWeek.endDate.toDate();
    let newWeekEndDate = add(programLastWeekDate, { days: 7 }); // 7 days from now

    if (isWeekend(newWeekEndDate)) newWeekEndDate = nextMonday(newWeekEndDate); // No end date on weekend

    if (lastWeekPublishedPlan) {
      return form.setValue('weeks', [
        ...weeks,
        {
          week: programLastWeek.week + 1, // Incremented week number
          draft: {
            plan: lastWeekPublishedPlan,
            updatedAt: Timestamp.fromDate(programLastWeekDate),
          }, // Same plan as the last week's draft
          startDate: Timestamp.fromDate(programLastWeekDate), // Current date as the start date
          endDate: Timestamp.fromDate(newWeekEndDate), // Calculated end date
        },
      ]);
    } else {
      toast.error("You can't add a new week without a published plan.");
    }
  };

  const onUpdate = async () => {
    if (customer?.id && currentWeekPlan) {
      const args = {
        existingPlan: weeks,
        weeksInput: form.getValues().weeks,
        activePlanId: currentWeekPlan.id,
        userId: customer.id,
      };
      dispatch(updateWeekProgram(args));
      setEditing(false);
    }
  };

  if (loading) {
    return <Card>loading...</Card>;
  }

  return (
    <Card>
      <div className="p-5 flex justify-between w-full items-center">
        <h4 className="font-bold text-xl flex gap-1.5">
          Program week
          <Tooltip
            offset={0.5}
            anchor={<span className="icon-[mdi--info-outline] w-6 h-6 text-dark/40 mt-0.5" />}
            children={<ChatProgramWeekHelp />}
          />
        </h4>
      </div>
      <form className="px-5 pb-5" onSubmit={form.handleSubmit(onUpdate)}>
        <div className={cn([!editing, 'hidden', 'py-3 flex flex-col gap-5'])}>
          <FormProvider {...form}>
            <Controller
              control={form.control}
              name="weeks"
              render={(weeksInput) => (
                <>
                  {weeksInput?.field?.value?.map((programWeek, i) => (
                    <ChatProgramWeeksForm key={`ProgramWeek${i}`} index={i} />
                  ))}
                </>
              )}
            />
          </FormProvider>
        </div>
        <ul className={cn([editing, 'hidden'])}>
          {weeks?.map((week: any) => (
            <ChatProgramWeekPlan key={week.id} {...week} index={week.week} />
          ))}
        </ul>
        <div className="flex justify-between">
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                addWeek();
              }}
              type="button"
              size="lg"
              className={cn([!editing, 'hidden'])}
              variant="outlined"
            >
              Add program week
            </Button>
          </div>
          <div className={cn('flex gap-3', [editing, 'grid-cols-2', 'grid-cols-1'])}>
            <Button
              type="button"
              variant="outlined"
              size="lg"
              disabled={isReadOnly}
              className={cn([!editing, 'hidden'])}
              onClick={(e) => {
                e.preventDefault();
                setEditing(false);
                form.setValue('weeks', weeks);
              }}
            >
              Cancel
            </Button>
            {!editing ? (
              <Button
                size="lg"
                type="button"
                disabled={isReadOnly}
                onClick={(e) => {
                  e.preventDefault();
                  setEditing(true);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button type="submit" size="lg">
                Update
              </Button>
            )}
          </div>
        </div>
      </form>
    </Card>
  );
}
