import { useState, useEffect } from 'react';
import {
  Break,
  Container,
  OptionBlock,
  OptionCheckbox,
  OptionText,
  TextValidationBlock,
  TextValidationBlockInput,
} from './styles';
import { ETypeTextFieldFormat, ISurveyOption } from 'types';

const Options = ({ block, textFieldFormat }: ISurveyOption) => {
  const [isTextValidation, setIsTextValidation] = useState<boolean>(false);

  useEffect(() => {
    if (
      block?.textFieldFormat?.type === ETypeTextFieldFormat.STRING &&
      block?.textFieldFormat?.min
    ) {
      setIsTextValidation(true);
    }
  }, [block]);

  return (
    <>
      <Container>
        <OptionBlock>
          <OptionCheckbox checked={isTextValidation} color="primary" size="small" disabled />
          <OptionText isTextValidation={isTextValidation}>Validation</OptionText>
        </OptionBlock>
        <Break />
        {isTextValidation && (
          <TextValidationBlock>
            <TextValidationBlockInput
              value={textFieldFormat?.min}
              name="textFieldFormat"
              type="number"
              disabled
              label="Minimum characters"
              placeholder="Minimum characters"
            />
          </TextValidationBlock>
        )}
      </Container>
    </>
  );
};

export default Options;
