import { styled } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import Button from '../../../../common/core/Button';

export const ControlBlock = styled(Grid)({
  width: '100%',
  backgroundColor: '#FFF',
  borderBottom: '1px solid #E4E7ED',
});

export const AllClientsButton = styled(Button)<{ isOpenAllClients: boolean }>(
  ({ isOpenAllClients }) => ({
    margin: '15px 0px 0',
    borderRadius: 0,
    backgroundColor: '#F9FAFC!important',
    color: 'rgba(15, 15, 33, 0.4)!important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#F9FAFC',
      boxShadow: 'none',
    },

    ...(isOpenAllClients && {
      backgroundColor: '#9FA1F2',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#9FA1F2',
        color: '#FFFFFF',
        boxShadow: 'none',
      },
    }),
  }),
);

export const Icon = styled('div')({
  fontSize: 20,
  verticalAlign: 'top',
  padding: 0,
  marginRight: 5,
});

export const FontBold = styled(Typography)({
  fontSize: 16,
  fontWeight: 700,
});
