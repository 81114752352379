import {
  CloseIconBlock,
  Container,
  ContentBlock,
  LineBlock,
  LineDiv,
  MessageBlock,
  MessageText,
  NameBlock,
  NameText,
  ReplyIcon,
  ReplyIconBlock,
  Wrapper,
} from './styles';
import { IChatEditMessage } from 'types';
import { useAppSelector } from 'hooks';
import { StyledCloseIcon } from '../ChatEditFileMessage/styles';

const ChatEditMessage = ({ messageEdited, handleMessageEdited }: IChatEditMessage) => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  return (
    <Wrapper>
      <Container>
        <ReplyIconBlock>
          <ReplyIcon />
        </ReplyIconBlock>
        <LineBlock>
          <LineDiv />
        </LineBlock>
        <ContentBlock>
          <NameBlock>
            <NameText>
              {messageEdited?.message.userNameFrom ||
                coachData?.firstName + ' ' + coachData?.lastName}
            </NameText>
          </NameBlock>
          <MessageBlock>
            <MessageText>{messageEdited?.message.body}</MessageText>
          </MessageBlock>
        </ContentBlock>
        <CloseIconBlock>
          <StyledCloseIcon onClick={() => handleMessageEdited(null)} />
        </CloseIconBlock>
      </Container>
    </Wrapper>
  );
};

export default ChatEditMessage;
