import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, IconButton } from '@mui/material';
import DotsMenu from '../HandoutsDotsMenu';
import { HandoutItem, ThreeDots } from './styles';
import { IHandoutsList } from 'types';

const HandoutsList = ({ handout, deleteHandler }: IHandoutsList) => {
  const [anchorEditMenuEl, setAnchorEditMenuEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const toggleHandoutEditMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (anchorEditMenuEl) {
      setAnchorEditMenuEl(null);
    } else {
      setAnchorEditMenuEl(e.currentTarget);
    }
  };

  const hideMenus = () => {
    setAnchorEditMenuEl(null);
  };

  const editHandler = (e: React.MouseEvent<HTMLElement>, handoutID: string) => {
    e.stopPropagation();
    navigate(`/handouts/edit/${handoutID}`);
    hideMenus();
  };

  const handleDelete = (e: React.MouseEvent<HTMLElement>, handoutID: string) => {
    e.stopPropagation();
    deleteHandler(handoutID);
    hideMenus();
  };

  return (
    <HandoutItem
      onClick={(e) => {
        editHandler(e, handout.id);
      }}
    >
      <Typography>{handout.title}</Typography>
      <IconButton style={{ padding: 0 }} onClick={toggleHandoutEditMenu} size="large">
        <ThreeDots />
      </IconButton>
      <DotsMenu
        handoutId={handout.id}
        anchorEl={anchorEditMenuEl}
        editTitle="Edit"
        toggleHandler={toggleHandoutEditMenu}
        deleteClickHandler={(e) => {
          handleDelete(e, handout.id);
        }}
        editClickHandler={(e) => {
          editHandler(e, handout.id);
        }}
      />
    </HandoutItem>
  );
};

export default HandoutsList;
