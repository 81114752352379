import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { onSnapshot } from 'firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import { doc } from '@firebase/firestore';

interface versionState {
  version: any;
  loading: boolean;
  isVersionOutdated: boolean;
}

const initialState: versionState = {
  version: null,
  loading: false,
  isVersionOutdated: false,
};

export const fetchLatestVersion = createAsyncThunk(
  'version/fetchLatestVersion',
  async (_args, APIThunk) => {
    try {
      const dispatch = APIThunk.dispatch;
      return onSnapshot(
        doc(db, 'version/latest'),
        (snapshot) => {
          const data = { id: snapshot.id, ...snapshot.data() };
          dispatch(documentUpdated(data));
        },
        (error) => {
          console.log(error);
          dispatch(snapshotFailed());
        },
      );
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    documentUpdated: (state, action: PayloadAction<any>) => {
      state.version = action.payload.timestamp;
    },
    snapshotFailed: (state) => {
      state.version = null;
      state.loading = false;
    },
    setVersionOutdated: (state, action: PayloadAction<boolean>) => {
      state.isVersionOutdated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestVersion.pending, (state) => {
        state.version = null;
        state.loading = true;
      })
      .addCase(fetchLatestVersion.fulfilled, (state, action: PayloadAction<any>) => {
        state.version = action.payload;
        state.loading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { documentUpdated, snapshotFailed, setVersionOutdated } = versionSlice.actions;

export default versionSlice.reducer;
