import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ITeamAllocation } from 'types';
import Button from 'common/core/Button';
import { ITeamData } from '@fitmate-coach/fitmate-types';
import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import { updateAssignmentRateForTeams } from 'store/coaches/coachesSlice';
import IconButton from '@mui/material/IconButton';

const TeamAllocationForm = ({ team }: { team: ITeamData & { id: string } }) => {
  const dispatch = useAppDispatch();
  const [field, setField] = React.useState<number>(
    team.assignmentRate ? parseFloat(team.assignmentRate) * 100 : 0,
  );
  const setRate = parseFloat(team.assignmentRate ?? '') * 100;

  const handleChangeAssignmentRate = (value: string) => {
    setField(Number(value));
  };

  const handleSave = () => {
    dispatch(updateAssignmentRateForTeams({ [team.id]: field }));
  };

  return (
    <div className="w-full flex flex-col border-b my-2 p-2">
      <div className="flex-1">
        <p>{team.mainCoach?.firstName}</p>
      </div>
      <div className="flex-1">
        <OutlinedInput
          placeholder="Value between 0 and 100"
          type="text"
          value={field}
          autoComplete="off"
          onChange={(e) => handleChangeAssignmentRate(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={field === setRate}
                aria-label="toggle password visibility"
                onClick={handleSave}
                edge="end"
              >
                {field !== setRate ? <SaveIcon /> : <CheckIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    </div>
  );
};

const DialogAllocation = ({ open, toggleDialog }: ITeamAllocation) => {
  const teams = useAppSelector((state) => state.coaches.teams);
  const videoCoaches = teams.filter(
    (team) => team.book10MinCall !== '' && team.book10MinCall !== undefined,
  );
  const nonVideoCoaches = teams.filter((team) => team.book10MinCall === '' || !team.book10MinCall);
  const handleClose = () => toggleDialog();
  return (
    <Dialog maxWidth="xl" open={open} onClose={handleClose}>
      <DialogTitle>Allocation rules</DialogTitle>
      <DialogContent className="p-5">
        <DialogContentText className="font-bold mt-3">Rules for Video coaches:</DialogContentText>
        <div className="w-full">
          {videoCoaches.map((coach) => (
            <TeamAllocationForm key={coach.id} team={coach} />
          ))}
        </div>
        <DialogContentText className="font-bold mt-3">
          Rules for non video coaches:
        </DialogContentText>
        <div className="w-full">
          {nonVideoCoaches.map((coach: ITeamData & { id: string }) => (
            <TeamAllocationForm key={coach.id} team={coach} />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAllocation;
