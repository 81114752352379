import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 30px 0px 30px',
  marginRight: 8,
  wordWrap: 'break-word',
  overflowY: 'scroll',
  scrollbarWidth: 'auto',
  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    outline: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#c1c1c1',
    width: 6,
    borderRadius: 10,
  },
});

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: 12,
  marginTop: 12,
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '22px',
  marginBottom: 4,
}));
