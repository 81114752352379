import { useEffect, useState } from 'react';
import { EditorState, RichUtils } from 'draft-js';
import { Editor, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EmojiModal from 'common/core/EmojiModal';
import {
  getResetEditorState,
  convertFromStateToString,
  convertFromStringToState,
  insertCharacter,
} from 'utils/tools/reactDraftWysiwyg';
import { InputBlock, StyledEmojiIcon, Wrapper } from './styles';
import { IInputEditor } from 'types';
import FormatBoldIcon from 'assets/icons/format_bold.svg';
import FormatItalicIcon from 'assets/icons/format_italic.svg';
import FormatStrikethroughIcon from 'assets/icons/format_strikethrough.svg';
import { useAppDispatch } from '../../../../../hooks';
import { analyzeExcludedCharactersMessage } from '../../../../../store/chat/messageAnalyzeSlice';

const defaultOptions = {
  options: ['inline'],
  inline: {
    options: ['bold', 'italic', 'strikethrough'],
    bold: {
      icon: FormatBoldIcon,
    },
    italic: {
      icon: FormatItalicIcon,
    },
    strikethrough: {
      icon: FormatStrikethroughIcon,
    },
  },
};

const InputEditor = ({
  message,
  handleMessage,
  editorState,
  setEditorState,
  isInputMessageExpanded,
  currentUserId,
  parentHeight,
}: IInputEditor) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>('');
  const [isOpenAddEmojiModal, setIsOpenAddEmojiModal] = useState<boolean>(false);

  const clearState = () => {
    setEditorState(getResetEditorState(editorState));
    setValue('');
  };

  useEffect(() => {
    if (message !== value) {
      setEditorState(convertFromStringToState(message));
    }
    if (message.length === 0) {
      clearState();
    }
  }, [message]);

  useEffect(() => {
    clearState();
  }, [currentUserId]);

  useEffect(() => {
    handleMessage(convertFromStateToString(editorState));
    setValue(convertFromStateToString(editorState));
  }, [editorState]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleReturn = (e: SyntheticKeyboardEvent, editorState: EditorState): boolean => {
    return false;
  };

  const toggleOpenAddEmojiModal = () => {
    setIsOpenAddEmojiModal((prev) => !prev);
  };

  const handleAddEmoji = (emoji: string) => {
    onChangeEditor(insertCharacter(emoji, editorState), true);
    const timer = setTimeout(() => setIsOpenAddEmojiModal(false), 0);
    return () => clearTimeout(timer);
  };

  // do not set directly in Editor in toolbarCustomButtons props, as it causes errors in terminal
  const EmojiIcon = () => {
    return <StyledEmojiIcon onClick={toggleOpenAddEmojiModal} />;
  };

  const onChangeEditor = (editorState: EditorState, forcedFocus = false) => {
    if (forcedFocus) {
      const timer = setTimeout(() => setEditorState(EditorState.moveFocusToEnd(editorState)), 1);
      return () => clearTimeout(timer);
    } else {
      setEditorState(editorState);
    }
  };

  return (
    <Wrapper>
      <InputBlock parentHeight={parentHeight} isInputMessageExpanded={isInputMessageExpanded}>
        <Editor
          handlePastedText={(text) => {
            dispatch(analyzeExcludedCharactersMessage(text));
            return false;
          }}
          toolbar={defaultOptions}
          editorState={editorState}
          onEditorStateChange={(editorState) => onChangeEditor(editorState)}
          placeholder="Write a message..."
          handleReturn={(e, editorState) => handleReturn(e, editorState)}
          toolbarCustomButtons={[<EmojiIcon key="emoji-icon" />]}
          toolbarOnFocus={false}
          spellCheck={true}
          handleKeyCommand={(command, editorState) => {
            const newState = RichUtils.handleKeyCommand(editorState, command);
            if (newState) {
              onChangeEditor(newState);
              return 'handled';
            }

            return 'not-handled';
          }}
        />
      </InputBlock>
      <EmojiModal
        isOpen={isOpenAddEmojiModal}
        toggleOpen={toggleOpenAddEmojiModal}
        handleSubmit={handleAddEmoji}
      />
    </Wrapper>
  );
};

export default InputEditor;
