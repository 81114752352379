import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
  borderRadius: 12,
}));

export const BottomBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 12,
  paddingBottom: 12,
}));

export const Button = styled('div')(({ theme }) => ({
  fontWeight: 600,
}));
