import { useNavigate } from 'react-router-dom';
import Button from 'common/core/Button';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-1/5 items-center">
        <h1 className="font-bold my-5">404 - Page not found</h1>
        <Button className="my-5" onClick={() => navigate('/')}>
          back to dashboard
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
