import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const HeaderBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const LeftBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
});

export const RightBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

export const TitleText = styled(Typography)({
  fontSize: 15,
  fontWeight: 600,
  color: '#0F0F21',
});

export const CloseIconTypo = styled(Typography)({
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
});
