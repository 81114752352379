import { styled } from '@mui/system';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 146px)',
  padding: '17px 20px',
  paddingTop: 0,
  marginTop: 17,
  overflowY: 'auto',
});

export const ListBlock = styled('div')({
  flexGrow: 1,
  position: 'relative',
});
