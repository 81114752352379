import { useFormContext } from 'react-hook-form';
import Wrapper from '../Wrapper';
import { InputBlock, StyledInput } from '../styles';

const EmailInfo = () => {
  const { getValues } = useFormContext();

  return (
    <Wrapper blockTitle="Account emails">
      <InputBlock>
        <StyledInput
          value={getValues('email')}
          label="Personal email"
          type="text"
          disabled
          fullWidth
          inputProps={{
            'data-testid': 'personalEmail',
          }}
        />
      </InputBlock>
    </Wrapper>
  );
};

export default EmailInfo;
