import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderTop: '1px solid #E4E7ED',
  padding: '15px 10px',
  position: 'relative',
  '&:hover': {
    backgroundColor: '#F9FAFC',
    '& button': {
      visibility: 'visible',
    },
  },
}));

export const NameBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'end',
  width: '100%',
  marginBottom: 1,
});

export const UserNameText = styled(Typography)({
  fontSize: 15,
  fontWeight: 600,
  lineHeight: '22px',
});

export const DateText = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
  paddingLeft: 10,
});

export const EmailBlock = styled('div')({
  marginBottom: 1,
});

export const EmailText = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
});

export const StatusBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: 1,
});

export const PaidText = styled(Typography)<{ paidColor?: string }>(({ theme, paidColor }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: paidColor ?? 'rgba(15, 15, 33, 0.6)',
}));

export const PaidTextError = styled(PaidText)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const SavedText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.6)',
});

export const Icon = styled('div')({
  fontSize: 8,
  padding: '0 5px',
  color: 'rgba(15, 15, 33, 0.4)',
});
