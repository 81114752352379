import { CSSProperties } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

export interface IMenu {
  anchorEl: null | HTMLElement;
  toggleMenu: (e: React.MouseEvent<any>) => void;
  vertical?: string;
  horizontal?: string;
  children: React.ReactNode;
  style?: CSSProperties;
}

export interface IMenuItem {
  className?: string;
  onClick?: (event: React.MouseEvent<any>) => void;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export interface IMenuEditButtons {
  deleteClickHandler: () => void;
  editClickHandler: () => void;
}

export interface IThreeDotsMenu {
  anchorEl: null | HTMLElement;
  toggleHandler: (e: React.MouseEvent<HTMLElement>) => void;
  renameClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
  deleteClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
  editTitle: string;
}

export enum EAdminMenuItem {
  COACHES = 'coaches',
  ACTIONS = 'actions',
  CLIENTS = 'clients',
  RESOURCES = 'resources',
  HANDOUTS = 'handouts',
  SURVEYS = 'surveys',
}

export enum ECoachProfileMenuItem {
  PROFILE = 'profile',
  PASSWORD = 'password',
  TOGGL = 'toggl',
}

export interface IPopupMenu {
  AddNutritionTable: () => void;
  AddSubtitle: () => void;
  AddAttachment: () => void;
  AddText: () => void;
}

export interface IAddMenu {
  categoryName: string;
  anchorEl: null | HTMLElement;
  toggleHandler: (e: React.MouseEvent<HTMLElement>) => void;
  addCategoryClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
  addTagClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
}

export interface IMenuButtons {
  categoryName: string;
  addCategoryClickHandler: () => void;
  addTagClickHandler: () => void;
  renameClickHandler: () => void;
  deleteClickHandler: () => void;
}

export interface IMenuDragButtons {
  deleteClickHandler: () => void;
  draggableProvided: DraggableProvided;
}

export interface IBottomButtons {
  onHandleClose: () => void;
  onHandleSubmit: () => void;
}
