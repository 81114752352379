import { styled } from '@mui/system';
import Button from 'common/core/Button';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
});

export const NoteBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
  marginBottom: 24,
});

export const ButtonBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: 24,
});

export const TitleText = styled('div')({
  fontSize: 18,
  fontWeight: 900,
  lineHeight: '32px',
});

export const TimePicker = styled('div')({
  '& .MuiInputBase-root': {
    border: '1px solid red',
  },
});

export const NoteText = styled('div')({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(17, 17, 27, 0.6)',
});

export const ButtonCancel = styled(Button)({
  width: 133,
  height: 45,
  fontSize: 14,
  fontWeight: 700,
  borderRadius: 8,
});

export const ButtonSubmit = styled(Button)({
  width: 133,
  height: 45,
  fontSize: 14,
  fontWeight: 700,
  borderRadius: 8,
});
