import { Typography, CircularProgress, CircularProgressProps } from '@mui/material';
import { StyledBox, Wrapper } from './styles';

const CircularProgressWithPercents = (props: CircularProgressProps & { value: number }) => {
  return (
    <Wrapper sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <StyledBox>
        <Typography variant="caption" component="div">{`${Math.round(props.value)}%`}</Typography>
      </StyledBox>
    </Wrapper>
  );
};

export default CircularProgressWithPercents;
