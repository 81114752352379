import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import MenuItem from '../../../../../common/core/MenuItem';

export const BottomLineBlock = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#E4E7ED',
  marginTop: 5,
  marginBottom: 5,
});

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 60,
});

export const NameBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '28%',
});

export const NameTopBlock = styled('div')({});

export const NameBottomBlock = styled('div')({});

export const EmailBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '25%',
});

export const PhoneBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '22%',
});

export const ShiftBlock = styled('div')({
  width: '20%',
});

export const ActionsBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '5%',
});

export const Text1 = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '24px',
  color: '#0F0F21',
});

export const Text2 = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '22px',
  color: '#0F0F21',
});

export const Text2WithStatus = styled(Text2)<{ status: 'registered' | 'pending' | 'deactivated' }>(
  ({ status }) => ({
    color: status === 'pending' ? '#FAA94D' : status === 'registered' ? '#AEEBDD' : '#FF6D7D',
  }),
);

export const StyledMenuIcon = styled(MoreVert)({
  fontSize: 20,
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.4)',
});

export const SubMenu = styled(MenuItem)({
  minWidth: 190,
});
