import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderTop: '1px solid #E4E7ED',
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  height: '100%',
}));

export const ReplyIconBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  paddingLeft: 27,
  paddingRight: 15,
}));

export const LineBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 2,
  height: '100%',
}));

export const LineDiv = styled('div')(({ theme }) => ({
  width: 2,
  height: 36,
  backgroundColor: '#8587E5',
}));

export const ContentBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  height: 52,
  paddingLeft: 12,
}));

export const NameBlock = styled('div')(({ theme }) => ({
  paddingBottom: 4,
}));

export const MessageBlock = styled('div')<{ hasActionTracking: boolean }>(
  ({ theme, hasActionTracking }) => ({
    width: 500,
    display: 'box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...(hasActionTracking && {
      lineClamp: 1,
      width: '100%',
      overflow: 'unset',
    }),
  }),
);

export const ActionTrackingGoal = styled('div')(({ theme }) => ({
  display: 'box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineClamp: 1,
  fontWeight: 600,
  fontSize: 14,
  color: theme.palette.primary.main,
}));

export const ActionTrackingDetails = styled('div')(({ theme }) => ({
  lineClamp: 1,
  fontSize: 14,
  color: '#000000',
}));

export const MessageText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: '#000000',
  lineHeight: '16px',
}));

export const CloseIconBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  paddingRight: 30,
}));

export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
}));
