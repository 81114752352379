import { convertFromRaw, convertToRaw, EditorState, Modifier, RichUtils } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

const removeSelectedBlocksStyle = (editorState: EditorState) => {
  const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
  if (newContentState) {
    return EditorState.push(editorState, newContentState, 'change-block-type');
  }
  return editorState;
};

// https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
export const getResetEditorState = (editorState: EditorState) => {
  const blocks = editorState.getCurrentContent().getBlockMap().toList();
  const updatedSelection = editorState.getSelection().merge({
    anchorKey: blocks.first().get('key'),
    anchorOffset: 0,
    focusKey: blocks.last().get('key'),
    focusOffset: blocks.last().getLength(),
  });
  const newContentState = Modifier.removeRange(
    editorState.getCurrentContent(),
    updatedSelection,
    'forward',
  );

  const newState = EditorState.push(editorState, newContentState, 'remove-range');
  return removeSelectedBlocksStyle(newState);
};

export const convertFromStateToString = (editorState: EditorState) => {
  return draftToMarkdown(convertToRaw(editorState.getCurrentContent())) ?? '';
};

export const convertFromStringToState = (message: string) => {
  const rawData = markdownToDraft(message);
  const contentState = convertFromRaw(rawData);
  const newState = EditorState.createWithContent(contentState);
  return EditorState.moveFocusToEnd(newState);
};

export const insertCharacter = (characterToInsert: string, editorState: EditorState) => {
  const oldContent = editorState.getCurrentContent();
  const oldSelection = editorState.getSelection();
  const newContent = Modifier.replaceText(oldContent, oldSelection, characterToInsert);
  const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

  return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
};
