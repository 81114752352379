import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RESOURCE_RECIPE_HTML_DIR } from 'config';
import TinyMce from 'common/core/TinyMce';
import blockToString from 'common/core/BlockToString';
import { useFilesUpload } from 'hooks/useFilesUpload';
import { useAdminResource } from 'hooks/admin/useAdminResource';
import { convertToTime } from 'utils/format/convert';
import { onCopyToClipboard } from 'utils/tools/copyToClipboard';
import {
  AppContent,
  AppWrapper,
  ButtonGroup,
  CancelBtn,
  ContentWrapper,
  CreateBtn,
  DeleteBtn,
} from './styles';
import { IResourceBlock, IResourceRecipe } from 'types';
import NewResourcesSidebar from '../ResourceSidebar';
import RecipeContent from '../ResourceRecipeArticleBlocks';
import { toast } from 'sonner';

/*
 *
 * Now we add html editor, and support two formats of editing.
 * when all recipes will be rewritten to new format. delete support for blocks format
 *
 */

const ResourceRecipe = ({ isArticle }: IResourceRecipe) => {
  const [resourceData, setResourceData] = useState<any>({ blocks: [] });
  const [title, setTitle] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [blocks, setBlocks] = useState<IResourceBlock[]>([]);
  const [htmlBody, setHtmlBody] = useState<string>('');
  const [htmlBodyUrl, setHtmlBodyUrl] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<string>('');
  const [parameters, setParameters] = useState<Map<string, any>>(new Map());
  const [blocksForCopy, setBlocksForCopy] = useState<IResourceBlock[]>([]);

  const [picture, setPicture] = useState<string>('');
  const [validator, setValidator] = useState<boolean>(false);
  const [isLastCategoryHasChild, setIsLastCategoryHasChild] = useState<boolean>(false);

  const {
    addNewResourceRecipe,
    addNewResourceArticle,
    getResourceById,
    editResourceRecipe,
    editResourceArticle,
    removeResourceOldBlock,
  } = useAdminResource();
  const { uploadFiles, filesList } = useFilesUpload();
  const navigate = useNavigate();

  const { recipeId, articleId } = useParams<{ recipeId?: string; articleId?: string }>();

  useEffect(() => {
    if (filesList[0]) {
      if (recipeId) {
        editResourceRecipe(
          title,
          categoryId,
          tags,
          htmlBodyUrl, // old html url, need to delete
          filesList[0]?.url,
          thumbnail,
          parameters,
          recipeId,
        ).then(() => {
          toast.success('Recipe is updated');
          navigate('/resources');
        });
      } else {
        addNewResourceRecipe(
          title,
          categoryId,
          tags,
          filesList[0]?.url,
          thumbnail,
          parameters,
        ).then(() => {
          toast.success('Recipe is added');
          navigate('/resources');
        });
      }
    }
  }, [filesList]);

  const articleValidation = (): boolean =>
    !!resourceData?.categoryId &&
    !isLastCategoryHasChild &&
    !!resourceData?.title?.length &&
    !!resourceData?.thumbnail?.length &&
    !!resourceData?.picture?.length;

  const recipeValidator = (): boolean =>
    !!resourceData?.categoryId &&
    !isLastCategoryHasChild &&
    !!resourceData?.title?.length &&
    !!resourceData?.thumbnail?.length &&
    htmlBody.length > 0 &&
    resourceData.parameters &&
    !!resourceData.parameters.get('prep_time') &&
    !!resourceData.parameters.get('cook_time') &&
    !!resourceData.parameters.get('calories');

  useEffect(() => {
    setTitle(resourceData?.title);
    setCategoryId(resourceData?.categoryId);
    setTags(resourceData?.tags);
    setThumbnail(resourceData?.thumbnail);
    setPicture(resourceData?.picture);
    setParameters(resourceData?.parameters);
    setBlocks(resourceData?.blocks);
    setHtmlBodyUrl(resourceData?.htmlBodyUrl ?? '');
    setValidator(isArticle ? articleValidation() : recipeValidator());
  }, [resourceData, htmlBody, isLastCategoryHasChild]);

  useEffect(() => {
    if (recipeId) {
      getResourceById(recipeId).then((resource) => {
        resource.parameters.set('prep_time', convertToTime(resource.parameters.get('prep_time')));
        resource.parameters.set('cook_time', convertToTime(resource.parameters.get('cook_time')));
        setResourceData(resource);
        if (resource?.blocks) {
          setBlocksForCopy(resource.blocks);
        }
      });
    }
    if (articleId) {
      getResourceById(articleId).then((resource) => {
        setResourceData(resource);
      });
    }
  }, []);

  const handleRecipeSubmit = async () => {
    const file = new File([htmlBody], `${title}.html`, { type: 'text/html' });
    await uploadFiles([file], RESOURCE_RECIPE_HTML_DIR);
  };

  const handleArticleSubmit = () => {
    addNewResourceArticle(title, categoryId, tags, blocks, thumbnail, picture).then(() => {
      toast.success('Article is added');
      navigate('/resources');
    });
  };

  const handleArticleUpdate = () => {
    editResourceArticle(title, categoryId, tags, blocks, thumbnail, picture, articleId).then(() => {
      toast.success('Article is updated');
      navigate('/resources');
    });
  };

  const handleResourceDataChange = (callback: React.SetStateAction<any>) => {
    setResourceData(callback);
  };

  const handleDeleteOldBlockFormat = () => {
    removeResourceOldBlock(resourceData.id).then(() => {
      toast.success('Old blocks were successfully removed');
      navigate(0);
    });
  };

  const handleCopyOldBlocks = () => {
    let stringCopy = '';
    blocksForCopy.forEach((block) => {
      stringCopy = stringCopy + blockToString(block);
    });

    onCopyToClipboard(stringCopy);
  };

  return (
    <div>
      <AppWrapper>
        <AppContent>
          <NewResourcesSidebar
            resourceData={resourceData}
            handleResourceDataChange={handleResourceDataChange}
            handleSetIsLastCategoryHasChild={setIsLastCategoryHasChild}
            thumbnail
          />
          <ContentWrapper>
            <RecipeContent
              block={blocks}
              resourceData={resourceData}
              handleResourceDataChange={handleResourceDataChange}
              isArticle={isArticle}
            />
            {!isArticle && <TinyMce htmlBodyUrl={htmlBodyUrl} setHtmlBody={setHtmlBody} />}
          </ContentWrapper>
        </AppContent>
      </AppWrapper>
      <ButtonGroup>
        {!isArticle && blocks.length > 0 && (
          <>
            <CancelBtn variant="outlined" onClick={handleCopyOldBlocks}>
              Copy old format
            </CancelBtn>
            <DeleteBtn onClick={handleDeleteOldBlockFormat}>Delete old format</DeleteBtn>
          </>
        )}
        <CancelBtn variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </CancelBtn>
        {recipeId || articleId ? (
          <CreateBtn
            onClick={isArticle ? handleArticleUpdate : handleRecipeSubmit}
            disabled={!validator}
          >
            Update
          </CreateBtn>
        ) : (
          <CreateBtn
            onClick={isArticle ? handleArticleSubmit : handleRecipeSubmit}
            disabled={!validator}
          >
            Create
          </CreateBtn>
        )}
      </ButtonGroup>
    </div>
  );
};

export default ResourceRecipe;
