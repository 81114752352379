import { styled } from '@mui/system';
import Button from '../../../../../../common/core/Button';

export const AppWrapper = styled('div')(({ theme }) => ({
  flexBasis: '100%',
  position: 'relative',
}));

export const ButtonGroup = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  height: 36,
  width: '100%',
  padding: '12px 0 12px 0',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px -1px 0px #E4E7ED',
  justifyContent: 'flex-end',
  display: 'flex',
  gap: 8,
  '& .MuiButton-label': {
    fontSize: 12,
    fontWeight: 600,
  },
  '& .MuiButton-root': {
    boxShadow: 'none',
    borderRadius: 4,
    padding: '12px 28px',
    height: '34px !important',
    minWidth: 'revert',
    margin: '0 2px',
  },
  '& .Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: '#C5C5FE',
  },
}));

export const CancelBtn = styled(Button)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'transparent',
  color: 'rgba(15, 15, 33, 0.6)',
  '&:hover': {
    border: '1px solid #8587E5',
    backgroundColor: 'rgba(133, 135, 229, 0.04)',
  },
}));

export const CreateBtn = styled(Button)(({ theme }) => ({
  padding: '12px 43px',
  marginRight: '20px !important',
  color: 'white',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: 'rgb(93, 94, 160)',
  },
}));
