import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import MenuItem from '../../../../../../common/core/MenuItem';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 10,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 10,
}));

export const LeftBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '50%',
}));

export const RightBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '50%',
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: '#000',
}));

export const FilteredText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: 'rgba(15, 15, 33, 0.4)',
  marginRight: 12,
}));

export const SelectBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: 24,
  backgroundColor: '#F2F4F7',
  border: '1px solid #E4E7ED',
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: 8,
  cursor: 'pointer',
}));

export const SelectText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: 'rgba(15, 15, 33, 0.6)',
  marginRight: 12,
}));

export const SelectIcon = styled('div')(({ theme }) => ({
  color: 'rgba(15, 15, 33, 0.6)',
}));

export const SubMenu = styled(MenuItem)(({ theme }) => ({
  minWidth: 180,
  paddingLeft: 12,
  paddingRight: 12,
}));
