/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import { cn } from 'mcn';
import { TColumnComponent } from 'types';
import { Tooltip } from '../../ChatProgramComponents';
import Help from '../ChatProgramPlanColumnsHelps';

const Column: TColumnComponent<HTMLDivElement> = forwardRef(
  ({ column, children, ...props }, ref) => {
    const label = {
      priority: 'Routines to improve',
      obstacle: 'Obstacles',
      existing: 'Existing healthy habits',
    }[column];

    return (
      <div ref={ref} {...props} className={cn('flex flex-col py-4')}>
        <h5 className="font-semibold text-sm text-dark/60 mb-4 flex gap-1.5 items-start">
          {label}
          <Tooltip
            offset={0.3}
            anchor={<span className="icon-[mdi--info-outline] w-6 h-6 text-dark/40" />}
            children={<Help column={column} />}
          />
        </h5>
        {children}
      </div>
    );
  },
);

export default Column;
