import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledIcon } from '../ResourcesCategory/CommonStyles';
import ThreeDotsMenu from '../ResourcesCategoryThreeDotsMenu';
import AddMenu from '../ResourcesCategoryAddMenu';
import { IMenuButtons } from 'types';

const MenuButtons = ({
  categoryName,
  addCategoryClickHandler,
  addTagClickHandler,
  renameClickHandler,
  deleteClickHandler,
}: IMenuButtons) => {
  const [anchorEditMenuEl, setAnchorEditMenuEl] = useState<null | HTMLElement>(null);
  const [anchorAddMenuEl, setAnchorAddMenuEl] = useState<null | HTMLElement>(null);

  const toggleCategoryEditMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (anchorEditMenuEl) {
      setAnchorEditMenuEl(null);
    } else {
      setAnchorEditMenuEl(e.currentTarget);
    }
  };

  const toggleAddMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (anchorAddMenuEl) {
      setAnchorAddMenuEl(null);
    } else {
      setAnchorAddMenuEl(e.currentTarget);
    }
  };

  const hideMenus = () => {
    setAnchorAddMenuEl(null);
    setAnchorEditMenuEl(null);
  };

  const chooseItem = (e: React.MouseEvent<HTMLElement>, handler: () => void) => {
    e.stopPropagation();
    hideMenus();
    handler();
  };

  return (
    <>
      <IconButton onClick={toggleCategoryEditMenu} size="large">
        <MoreVertIcon />
      </IconButton>

      <IconButton onClick={toggleAddMenu} size="large">
        <StyledIcon as={AddIcon} />
      </IconButton>

      <ThreeDotsMenu
        anchorEl={anchorEditMenuEl}
        editTitle="Edit"
        toggleHandler={toggleCategoryEditMenu}
        renameClickHandler={(e) => chooseItem(e, renameClickHandler)}
        deleteClickHandler={(e) => chooseItem(e, deleteClickHandler)}
      />
      <AddMenu
        categoryName={categoryName}
        anchorEl={anchorAddMenuEl}
        toggleHandler={toggleAddMenu}
        addCategoryClickHandler={(e) => chooseItem(e, addCategoryClickHandler)}
        addTagClickHandler={(e) => chooseItem(e, addTagClickHandler)}
      />
    </>
  );
};

export default MenuButtons;
