export enum EFeatureFlagsType {
  inAppReview = 'in_app_review',
  newProgramTab = 'new_program_tab',
  nutrition_baseline = 'nutrition_baseline',
}

export enum EFeatureSelectedType {
  disabled = 'disabled',
  enabled = 'enabled',
}
