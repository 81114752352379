import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import ChatProgramProfile from './ChatProgramProfile';
import ChatProgramWeek from './ChatProgramWeek';
import ChatProgramPlan from './ChatProgramPlan';
import ChatFAQLoader from '../ChatFAQ/ChatFAQLoader';
import ChatProgramNutrition from './ChatProgramNutrition';
import ChatProgramActions from './ChatProgramActions';
import { fetchWeekProgram } from 'store/program/weekProgramSlice';

export default function ChatProgram() {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const [isMigrated, setIsMigrated] = useState<boolean>(false);

  useEffect(() => {
    if (!customer?.id) return;
    dispatch(fetchWeekProgram(customer.id));
  }, [customer]);

  useEffect(() => {
    if (customer?.featureFlags) {
      if (!customer.featureFlags?.length) return;
      const flag = customer.featureFlags.find((flag: string) => flag === 'new_program_tab');
      setIsMigrated(!!flag);
    }
  }, [customer]);

  if (!customer) return <ChatFAQLoader />;

  return (
    <div className="space-y-6 p-8">
      <ChatProgramWeek />
      <ChatProgramPlan />
      <ChatProgramNutrition customer={customer} />
      {!isMigrated && <ChatProgramActions />}
      <ChatProgramProfile />
    </div>
  );
}
