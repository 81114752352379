import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@mui/material';
import { useAdminResourceCategories } from 'hooks/admin/useAdminResourceCategories';
import { HandoutsCategories } from '../HandoutsCategories';
import {
  AppSideBar,
  CategoriesWrapper,
  HeaderBlock,
  MainBlock,
  TitleBlock,
  TitleText,
} from './styles';
import { IHandoutsAddSidebar } from 'types';

const HandoutsAddSidebar = ({ handleResourceDataChange, resourceData }: IHandoutsAddSidebar) => {
  const [activeCategoryID, setActiveCategoryID] = useState<string>('');
  const { categoriesList } = useAdminResourceCategories();

  useEffect(() => {
    handleResourceDataChange((prev: any) => {
      if (activeCategoryID) {
        return { ...prev, categoryId: activeCategoryID };
      }
    });
  }, [activeCategoryID]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveCategoryID(event.target.value);
  };

  return (
    <AppSideBar>
      <HeaderBlock>
        <TitleBlock>
          <TitleText>Linked resources</TitleText>
        </TitleBlock>
      </HeaderBlock>
      <MainBlock>
        <CategoriesWrapper variant="standard">
          <RadioGroup aria-label="categories" name="categories" onChange={handleChange}>
            {categoriesList.map((category, index) => (
              <HandoutsCategories
                key={`categorie${index}`}
                resourceData={resourceData}
                category={category}
              />
            ))}
          </RadioGroup>
        </CategoriesWrapper>
      </MainBlock>
    </AppSideBar>
  );
};

export default HandoutsAddSidebar;
