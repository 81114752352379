import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { IMealFeedbackInterface } from 'types';

const initialState: IMealFeedbackInterface = {
  loading: false,
};

export const getMealFeedback = createAsyncThunk(
  'mealFeedback/getMealFeedback',
  async (args: any) => {
    const { water, firstName, mealType, mealSize, myMeal } = args;
    const res = await axios({
      url: process.env.REACT_APP_FOM_BASE_API + 'feedback-meal',
      method: 'post',
      data: {
        water,
        firstName,
        mealType,
        mealSize,
        myMeal,
      },
    });

    return res.data;
  },
);

export const mealFeedbackSlice = createSlice({
  name: 'mealFeedback',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMealFeedback.pending, (state) => {
      state.loading = true;
      state.feedback = undefined;
    });
    builder.addCase(getMealFeedback.fulfilled, (state, action) => {
      state.loading = false;
      state.feedback = action.payload.result.completion.message.content;
    });
  },
});

export default mealFeedbackSlice.reducer;
