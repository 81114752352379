import { EResourceBlockTypes, ITextEditor } from 'types';
import MarkdownEditor from '../MarkdownEditor';

const TextEditor = ({ handleBlocksChange, block, index }: ITextEditor) => {
  const handleEditorChange = (text = '') => {
    handleBlocksChange((prev) => {
      const copy = [...prev];
      copy[index] = {
        id: block.id,
        body: text,
        url: '',
        type: EResourceBlockTypes.TEXT,
      };
      return copy;
    });
  };
  return <MarkdownEditor valueInitial={block.body} handleValue={handleEditorChange} />;
};

export default TextEditor;
