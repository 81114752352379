import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { ActionIcon, ControlsBlock, ControlsLeftBlock, InputBlock, Wrapper } from './styles';
import { EEditorAction, IMarkdownEditor } from 'types';
import FormatBoldIcon from 'assets/icons/format_bold.svg';
import FormatItalicIcon from 'assets/icons/format_italic.svg';
import FormatStrikethroughIcon from 'assets/icons/format_strikethrough.svg';
import './Draft.css';

const MarkdownEditor = ({ valueInitial, handleValue }: IMarkdownEditor) => {
  const [value, setValue] = useState<string>('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const editor = useRef<any>(null);

  useEffect(() => {
    if (valueInitial !== value) {
      const rawData = markdownToDraft(valueInitial);
      const contentState = convertFromRaw(rawData);
      const newState = EditorState.createWithContent(contentState);
      setEditorState(newState);
      setValue(valueInitial);
    }
  }, []);

  const handleClickWrapper = () => {
    editor.current.focus();
  };

  const updateValue = (e: EditorState) => {
    const rawContentState = convertToRaw(e.getCurrentContent());
    const markdownString = draftToMarkdown(rawContentState);
    setValue(markdownString ?? '');
    if (valueInitial !== markdownString) {
      handleValue(markdownString ?? '');
    }
  };

  const updateInitData = debounce(updateValue, 500);

  const handleChangeState = (e: EditorState) => {
    setEditorState(e);
    updateInitData(e);
  };

  const handleMenuClick = (e: React.MouseEvent, command: string) => {
    e.stopPropagation();
    const newState = RichUtils.toggleInlineStyle(editorState, command);
    setEditorState(newState);
    updateValue(newState);
  };

  return (
    <>
      <Wrapper onClick={handleClickWrapper}>
        <InputBlock>
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={handleChangeState}
            placeholder="Write a message..."
          />
        </InputBlock>
        <ControlsBlock>
          <ControlsLeftBlock>
            <ActionIcon
              src={FormatBoldIcon}
              onClick={(e: React.MouseEvent) => handleMenuClick(e, EEditorAction.BOLD)}
              alt=""
            />
            <ActionIcon
              src={FormatItalicIcon}
              onClick={(e: React.MouseEvent) => handleMenuClick(e, EEditorAction.ITALIC)}
              alt=""
            />
            <ActionIcon
              src={FormatStrikethroughIcon}
              onClick={(e: React.MouseEvent) => handleMenuClick(e, EEditorAction.STRIKETHROUGH)}
              alt=""
            />
          </ControlsLeftBlock>
        </ControlsBlock>
      </Wrapper>
    </>
  );
};

export default MarkdownEditor;
