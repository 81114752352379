import { useCallback, useEffect } from 'react';

import { useCopilotIframe } from './useIframe';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setDrawerOpen } from 'store/coPilot/coPilotSlice';
import { Iframe, StyledDrawer } from 'styles/drawers';
import { fetchCustomerById } from 'store/customers/customerInfosSlice';

const copilot_url = process.env.REACT_APP_COPILOT_URL;

export const DrawerCopilot = (p: { clientId?: string }) => {
  const dispatch = useAppDispatch();
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { openedDrawer } = useAppSelector((state) => state.coPilot);
  const iframe = useCopilotIframe();
  const open = openedDrawer && !!p.clientId;

  // Authenticate iframe
  const reAuth = useCallback(() => {
    axios({
      url: process.env.REACT_APP_FOM_BASE_API + 'session',
      method: 'POST',
      data: {
        idToken: coachData?.uid,
      },
    })
      .then((res) => {
        try {
          iframe.send('auth', res.data.customToken);
        } catch (e) {
          console.log('failed to send token to copilot', e);
        }
      })
      .catch((e) => console.log('auth error', e));
  }, [iframe.send]);

  // Handle communication
  useEffect(() =>
    iframe.onMessage((ev) => {
      if (ev.type === 'close') dispatch(setDrawerOpen(false));
      if (ev.type === 'copy-text') navigator.clipboard.writeText(ev.payload).then();
      if (ev.type === 're-auth') reAuth();
      if (ev.type === 'flow-selected') {
        // waits for the customer to be updated before fetching
        setTimeout(() => dispatch(fetchCustomerById(customer?.id)), 1000);
        setTimeout(() => dispatch(fetchCustomerById(customer?.id)), 5000);
        setTimeout(() => dispatch(fetchCustomerById(customer?.id)), 10000);
      }
    }),
  );

  if (!open) return null;

  return (
    <StyledDrawer
      isOpen={open}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{
        width: 340,
      }}
      width={340}
      rightOffset={0}
    >
      <div style={{ width: '100%', height: '100%', display: 'grid' }}>
        <Iframe
          title="copilot"
          ref={iframe.ref}
          src={`${copilot_url}/${p.clientId}/${coachData?.uid}`}
          allow="Clipboard"
        />
      </div>
    </StyledDrawer>
  );
};
