import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { actionsProgramOptionsList } from 'config';
import Menu from 'common/core/Menu';
import {
  Container,
  FilteredText,
  LeftBlock,
  RightBlock,
  SelectBlock,
  SelectIcon,
  SelectText,
  SubMenu,
  TitleText,
} from './styles';
import { IActionsProgramOptionsListItem, IChatProgramActionsHeader } from 'types';

// DEPRECATED OLD VERSION
const ChatProgramActionsHeader = ({
  actionsProgramOption,
  handleActionsProgramOption,
}: IChatProgramActionsHeader) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleChangeFilter = (value: string) => {
    handleActionsProgramOption(value);
    setAnchorEl(null);
  };

  return (
    <>
      <Container>
        <LeftBlock>
          <TitleText>Actions to achieve goals</TitleText>
        </LeftBlock>
        <RightBlock>
          <FilteredText>Filtered by: </FilteredText>
          <SelectBlock onClick={toggleMenu}>
            <SelectText>
              {
                actionsProgramOptionsList.find(
                  (item: { value: string; label: string }) => item.value === actionsProgramOption,
                )?.label
              }
            </SelectText>
            <SelectIcon as={ArrowDropDownIcon} />
          </SelectBlock>
        </RightBlock>
      </Container>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        {actionsProgramOptionsList.map((item: IActionsProgramOptionsListItem, index: number) => (
          <SubMenu onClick={() => handleChangeFilter(item.value)} key={`${index}-${item.value}`}>
            {item.label}
            <img src={item.icon} alt="" />
          </SubMenu>
        ))}
      </Menu>
    </>
  );
};

export default ChatProgramActionsHeader;
