import { styled } from '@mui/system';
import { FormControl } from '@mui/material';

export const SubCategoriesSelect = styled(FormControl)({
  marginTop: 20,
  '& .MuiFormLabel-root': {
    fontSize: 14,
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.85)',
  },
});

// TODO: Need to be removed I guess..
export const DefaultOptionValue = styled('option')({
  display: 'none',
});
