import { useRef } from 'react';

import Button from 'common/core/Button';
import { Timestamp } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from 'hooks';
import { chatLoadOlder } from 'store/chat';
import { formatDateToList } from 'utils/format/datetime';
import { CombinedWithDateBlock, DateHorizontalBlock, DateHorizontalChip, Wrapper } from './styles';
import { IChatMessage, IChatMessagesList } from 'types';
import ChatMessagesListItem from './ChatMessagesListItem';
import SurveyChip from './ChatMessageSurveyChip';
import BodyProfileChip from './ChatMessageBodyProfileChip';
import ChatFAQLoader from '../ChatFAQ/ChatFAQLoader';
import EngagementChip, { EoWChip } from './ChatMessageEngagementChip';

const ChatMessagesList = ({
  currentUserMessagesList,
  currentUserId,
  handleMessageEdited,
  handleMessageFileEdited,
  messageIdTagsCurrent,
  handleMessageIdTagsCurrent,
}: IChatMessagesList) => {
  const loading = useAppSelector((state) => !!state.chat.data?.[currentUserId]?.loading);
  const hasMoreMessages = useAppSelector(
    (state) => state.chat.data[currentUserId]?.docs.length < state.chat.data[currentUserId]?.count,
  );
  const dispatch = useAppDispatch();
  const dateRef = useRef<string>('');

  if (loading) {
    return <ChatFAQLoader />;
  }

  return (
    <Wrapper>
      {hasMoreMessages && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() => dispatch(chatLoadOlder(currentUserId))}
          >
            Show older messages
          </Button>
        </div>
      )}
      {currentUserMessagesList.map((message: IChatMessage, index: number) => {
        const {
          id,
          isSurveyDeclined,
          isSurveyCompleted,
          isSurveyAssigned,
          bodyProfileData,
          isEngagementMessage,
          isEowAutomationDelayed,
        } = message;
        const createdAt = message.createdAt || Timestamp.now();
        let isDateChanged = false;
        const dataFormatted = formatDateToList(createdAt?.seconds);

        if (dateRef.current !== dataFormatted || !index) {
          dateRef.current = dataFormatted;
          isDateChanged = true;
        }

        return (
          <CombinedWithDateBlock key={id + '_d'}>
            {isDateChanged && (
              <DateHorizontalBlock>
                <DateHorizontalChip label={dataFormatted} />
              </DateHorizontalBlock>
            )}
            {isEowAutomationDelayed && <EoWChip message={message} />}
            {isEngagementMessage && <EngagementChip message={message} />}
            {bodyProfileData && <BodyProfileChip message={message} />}
            {(isSurveyAssigned || isSurveyDeclined || isSurveyCompleted) && (
              <SurveyChip message={message} />
            )}
            {!isSurveyDeclined &&
              !isSurveyCompleted &&
              !isSurveyAssigned &&
              !bodyProfileData &&
              !isEngagementMessage &&
              !isEowAutomationDelayed && (
                <ChatMessagesListItem
                  key={id}
                  message={message}
                  currentUserId={currentUserId}
                  handleMessageEdited={handleMessageEdited}
                  handleMessageFileEdited={handleMessageFileEdited}
                  messageIdTagsCurrent={messageIdTagsCurrent}
                  handleMessageIdTagsCurrent={handleMessageIdTagsCurrent}
                />
              )}
          </CombinedWithDateBlock>
        );
      })}
    </Wrapper>
  );
};

export default ChatMessagesList;
