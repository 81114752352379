export const dairyImg = `<img
        src='https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2FDairy%20free%20icon.svg?alt=media&token=a6653b84-c650-4eda-a832-76808cdd284f'
        alt='dairy'
      />`;

export const glutenImg = `<img
        src='https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2Fgluten%20free%20icon.svg?alt=media&token=ee8cf9ad-fca2-4539-8fdf-ffd82a44fb4a'
        alt='gluten'
      />`;

export const nutImg = `<img
        src='https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2Fnut%20free%20icon.svg?alt=media&token=b189b501-6b15-47a8-97f0-fe7b9215f3bf'
        alt='nut'
      />`;

export const veganImg = `<img
        src='https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2Fvegan%20icon.svg?alt=media&token=c007201d-f5da-451e-86f6-00f2231d91b2'
        alt='vegan'
      />`;

export const vegetarianImg = `<img
        src='https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2F%20vegetarian%20icon.svg?alt=media&token=633a8a58-c421-455b-8522-19da3950fac1'
        alt='vegetable'
      />`;
