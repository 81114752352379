import { forwardRef } from 'react';
import { StyledRoot } from './styles';
import { IMenu } from 'types';

const Menu = forwardRef<HTMLDivElement, IMenu>(
  ({ anchorEl, toggleMenu, vertical = 'top', horizontal = 'right', children, style }, ref) => {
    return (
      <StyledRoot
        ref={ref}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={toggleMenu}
        anchorOrigin={{
          vertical: vertical === 'top' ? 'top' : 'bottom',
          horizontal: horizontal === 'right' ? 'right' : 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        // MenuListProps={{ className: classes.root }}
        PaperProps={{ style }}
      >
        {children}
      </StyledRoot>
    );
  },
);

export default Menu;
