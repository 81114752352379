import { styled } from '@mui/system';

export const Container = styled('div')({
  marginLeft: 32,
  marginRight: 32,
  display: 'flex',
  flexDirection: 'column',
});

export const TitleText = styled('div')<{ isTitleHasShortMargin?: boolean }>(
  ({ theme, isTitleHasShortMargin }) => ({
    marginTop: isTitleHasShortMargin ? 20 : 30,
    marginBottom: 25,
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.common.black,
  }),
);

export const FormBlock = styled('div')<{ isOpenedMealDrawer: boolean }>(
  ({ theme, isOpenedMealDrawer }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up(1050)]: {
      columnGap: 20,
      gridTemplateColumns: 'repeat(2, 320px)',
    },
    [theme.breakpoints.up(1400)]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    ...(isOpenedMealDrawer && ResponsiveFormBlock),
  }),
);

export const ResponsiveFormBlock = styled('div')({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: 'repeat(auto-fit, 320px)',
});
