import { styled } from '@mui/system';

export const TopLineBlock = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#E4E7ED',
  marginBottom: 5,
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100vh - 226px)',
});

export const BottomLineBlock = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#E4E7ED',
  marginTop: 5,
  marginBottom: 5,
});

export const TableBlock = styled('div')({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
});

export const PaginationBlock = styled('div')({
  display: 'flex',
  flexGrow: 0,
  justifyContent: 'center',
  width: '100%',
  height: 80,
  marginTop: 10,
});
