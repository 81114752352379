import { Routes, Route, Navigate } from 'react-router-dom';
import { EUserRoles } from 'types';

import LoginContainer from 'components/Authentication/LoginContainer';
import ForgotPassword from 'components/Authentication/ForgotPasswordContainer';
import InvitationContainer from 'components/Authentication/InvitationContainer';
import ChatTimer from './components/Coach/ChatTimer';
import ChatContainer from 'components/Coach/ChatContainer';
import ProfileContainer from 'components/Coach/ProfileContainer';
import OnboardinSurveyContainer from './components/Coach/OnboardinSurveyContainer';
import TeamsContainer from 'components/Admin/TeamsContainer';
import CoachesContainer from 'components/Admin/CoachesContainer';
import ActionsContainer from 'components/Admin/ActionsContainer';
import ClientsContainer from 'components/Admin/ClientsContainer';
import ResourcesContainer from 'components/Admin/ResourcesContainer';
import WorkoutContainer from 'components/Admin/ResourcesContainer/ResourcesAdd/ResourceWorkout';
import RecipeContainer from 'components/Admin/ResourcesContainer/ResourcesAdd/ResourceRecipeArticle';
import HandoutsContainer from 'components/Admin/HandoutsContainer';
import NewHandoutsContainer from 'components/Admin/HandoutsContainer/HandoutsAdd';
import SurveysContainer from 'components/Admin/SurveysContainer';
import NewSurveysContainer from 'components/Admin/SurveysContainer/SurveysAdd';
import FocusAreasContainer from './components/Admin/FocusAreasContainer';
import PartnerReferralsContainer from './components/Admin/PartnerReferralsContainer';
import ChatContentContainer from './components/Coach/ChatContainer/ChatContentContainer';
import NotFound from './components/NotFound';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './utils/firebase/firebaseInit';
import { useAppSelector } from './hooks';
import PartnerReferralDashboard from './components/PartnerReferral';
import ClientReadOnly from './components/PartnerReferral/ClientReadOnly';
import { Box } from '@mui/material';
import VersionBanner from 'components/Coach/VersionBanner';
import StatsContainer from './components/Stats';

const Router = () => {
  const [user, loading] = useAuthState(auth as any);
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const { isVersionOutdated } = useAppSelector((state) => state.version);

  return (
    <>
      {user && coachData?.role === EUserRoles.ADMIN && (
        <Routes>
          <Route path="/stats" element={<StatsContainer />} />
          <Route path="/teams" element={<TeamsContainer />} />
          <Route path="/coaches" element={<CoachesContainer />} />
          <Route path="/actions" element={<ActionsContainer />} />
          <Route path="/clients" element={<ClientsContainer />} />
          <Route path="/resources" element={<ResourcesContainer />} />
          <Route path="/resources/workout" element={<WorkoutContainer />} />
          <Route path="/resources/workout/edit/:workoutId" element={<WorkoutContainer />} />
          <Route path="/resources/recipe" element={<RecipeContainer />} />
          <Route path="/resources/recipe/edit/:recipeId" element={<RecipeContainer />} />
          <Route path="/resources/article" element={<RecipeContainer isArticle />} />
          <Route
            path="/resources/article/edit/:articleId"
            element={<RecipeContainer isArticle />}
          />

          <Route path="/focus-areas/" element={<FocusAreasContainer />} />

          <Route path="/handouts" element={<HandoutsContainer />} />
          <Route path="/handouts/new" element={<NewHandoutsContainer />} />
          <Route path="/handouts/edit/:handoutId" element={<NewHandoutsContainer />} />

          <Route path="/surveys" element={<SurveysContainer />} />
          <Route path="/surveys/addTemplate" element={<NewSurveysContainer />} />
          <Route path="/surveys/addTemplateAction" element={<NewSurveysContainer />} />
          <Route path="/surveys/addOnboarding" element={<NewSurveysContainer />} />
          <Route path="/surveys/edit/:surveyId" element={<NewSurveysContainer />} />
          <Route path="/partnerReferrals" element={<PartnerReferralsContainer />} />
          <Route path="*" element={<CoachesContainer />} />
        </Routes>
      )}
      {user && coachData?.role === EUserRoles.COACH && (
        <>
          {!!coachData.toggleApiToken && <ChatTimer toggleApiToken={coachData.toggleApiToken} />}
          {isVersionOutdated && <VersionBanner />}
          <Box paddingTop={coachData.toggleApiToken ? 5.5 : 0}>
            <Routes>
              <Route path="/stats" element={<StatsContainer />} />
              <Route path="/" element={<ChatContainer />} />
              <Route path="/coach-dashboard" element={<ChatContainer />} />
              <Route path="/coach-dashboard/client/:id" element={<ChatContentContainer />} />
              <Route path="/coach-dashboard/myprofile" element={<ProfileContainer />} />
              <Route
                path="/coach-dashboard/client/:id/onboarding-survey/:surveyId"
                element={<OnboardinSurveyContainer />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </>
      )}

      {user && [EUserRoles.PARTNER_REFERRAL].includes(coachData?.role) && (
        <>
          <Routes>
            <Route path="/" element={<Navigate to="/partner-referral-dashboard" />} />
            <Route path="/partner-referral-dashboard" element={<PartnerReferralDashboard />} />
            <Route path="/partner-referral-dashboard/client/:id" element={<ClientReadOnly />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      )}
      {!user && !loading && (
        <Routes>
          <Route path="/" element={<LoginContainer />} />
          <Route path="/login" element={<LoginContainer />} />
          <Route path="/invitation/:id" element={<InvitationContainer />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="*" element={<LoginContainer />} />
        </Routes>
      )}
    </>
  );
};

export default Router;
