import { ISelectOptionsWrapper } from 'types';

const SelectOptionsWrapper = ({ items, hasEmpty }: ISelectOptionsWrapper) => {
  return (
    <>
      {hasEmpty && <option aria-label="None" value="" key={0}></option>}

      {items.map((item) => (
        <option value={item.value} key={item.key ?? item.value}>
          {item.title}
        </option>
      ))}
    </>
  );
};

export default SelectOptionsWrapper;
