import { ReactChild, ReactFragment, ReactPortal, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button from 'common/core/Button';
import { fetchTeams, setDeleteTeam, setEditTeam } from 'store/coaches/coachesSlice';
import { Mic, MicOff, Videocam, VideocamOff } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

const TeamTable = () => {
  const dispatch = useAppDispatch();
  const teams = useAppSelector((state) => state.coaches.teams);
  const loading = useAppSelector((state) => state.coaches.loading);

  useEffect(() => {
    if (loading) {
      dispatch(fetchTeams());
    }
  }, [loading]);

  if (loading) {
    return <CircularProgress />;
  }

  const handleEditTeam = (id: string) => {
    dispatch(setEditTeam(id));
  };

  const handleDeleteTeam = (id: string) => {
    dispatch(setDeleteTeam(id));
  };

  return (
    <TableContainer className="mb-10 w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Main coach</TableCell>
            <TableCell>Supportive coach(es)</TableCell>
            <TableCell>Presentation text</TableCell>
            <TableCell className="text-center">Features</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teams &&
            teams.map((team) => (
              <TableRow key={team.id} className="h-full">
                <TableCell>
                  <Typography>
                    {team.mainCoach.firstName} {team.mainCoach.lastName}
                  </Typography>
                  <Typography variant="caption">{team.id}</Typography>
                </TableCell>
                <TableCell>
                  {team.supportiveCoaches.map(
                    (coach: {
                      firstName:
                        | boolean
                        | ReactChild
                        | ReactFragment
                        | ReactPortal
                        | null
                        | undefined;
                      lastName:
                        | boolean
                        | ReactChild
                        | ReactFragment
                        | ReactPortal
                        | null
                        | undefined;
                      id: string;
                    }) => (
                      <Typography key={coach.id}>
                        {coach.firstName} {coach.lastName}
                      </Typography>
                    ),
                  )}
                </TableCell>
                <TableCell className="max-w-[200px]">{team.presentation || '-'}</TableCell>
                <TableCell className="flex min-h-[160px] items-center justify-center">
                  {team.book10MinCall ? (
                    <Tooltip title="Coach offers video calls">
                      <Videocam color="primary" />
                    </Tooltip>
                  ) : (
                    <VideocamOff className="text-red-400" />
                  )}
                  {team.elevenLabsVoice ? (
                    <Tooltip title="Coach offers voice notes">
                      <Mic color="primary" />
                    </Tooltip>
                  ) : (
                    <MicOff className="text-red-400" />
                  )}
                </TableCell>
                <TableCell width="100">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleEditTeam(team.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    onClick={() => handleDeleteTeam(team.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}

          {teams && teams.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>No team found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamTable;
