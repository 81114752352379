import React, { useState, useEffect } from 'react';
import Input from 'common/core/Input';
import Modal from 'common/core/Modal';
import { ITagAddModal } from 'types';
import { Box } from '@mui/material';

const TagAddModal = ({
  isOpenAddModal,
  toggleOpenAddModal,
  handleCreateTag,
  tags = [],
}: ITagAddModal) => {
  const [tag, setTag] = useState<string>('');
  const [tagError, setTagError] = useState<string>('');

  useEffect(() => {
    if (isOpenAddModal) {
      setTag('');
      setTagError('');
    }
  }, [isOpenAddModal]);

  const checkTagExists = (): boolean => {
    return tags.some((value) => value.toLowerCase() === tag.toLowerCase());
  };

  const handleSubmit = () => {
    const newTag = tag.trim();
    const tagExists: boolean = checkTagExists();
    setTagError(!newTag ? 'Please fill the tag' : tagExists ? 'This tag already exist' : '');

    if (newTag && !tagExists) {
      handleCreateTag(tag);
    }
  };

  return (
    <Modal
      widthMax={340}
      title="Create new tag"
      open={isOpenAddModal}
      handleClose={toggleOpenAddModal}
      handleSubmit={handleSubmit}
      textBtnClose="Cancel"
      textBtnSubmit="Save"
    >
      <div>
        <div>
          <Box component="div" sx={{ paddingBottom: 2 }}>
            <Input
              error={tagError ? true : false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTag(e.target.value)}
              value={tag}
              id="tag"
              label="Name of tag"
              helperText={tagError}
              type="text"
              fullWidth
            />
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default TagAddModal;
