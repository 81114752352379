import {
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
  DroppableProvidedProps,
} from 'react-beautiful-dnd';

import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Timestamp } from '@firebase/firestore';

export type TColumnComponent<H extends HTMLElement = HTMLElement> = ForwardRefExoticComponent<
  { children: React.ReactNode; column: string; disabled?: boolean } & DroppableProvidedProps &
    RefAttributes<H>
>;

export type TCardComponent<
  T extends { id: string },
  H extends HTMLElement = HTMLElement,
> = ForwardRefExoticComponent<
  {
    item: T;
    disabled?: boolean;
    handle: DraggableProvidedDragHandleProps | null | undefined;
  } & DraggableProvidedDraggableProps &
    RefAttributes<H>
>;

export interface IHoverAdditionalBlock {
  deleteClickHandler: () => void;
  children: React.ReactNode;
  index: number;
}

export interface ISummaryHover {
  categoryName: string;
  addCategoryClickHandler: () => void;
  addTagClickHandler: () => void;
  renameClickHandler: () => void;
  deleteClickHandler: () => void;
  isSelected: boolean;
  level?: number;
}

export interface IChildren {
  children: React.ReactNode;
}

export interface IPagination {
  count: number;
  page: number;
  onChange: (event: React.ChangeEvent<any>, pageNew: number) => void;
}

export enum EIconType {
  payment = 'payment',
  email = 'email',
  apple = 'apple',
  identifier = 'identifier',
  appVersion = 'appVersion',
  featureFlag = 'featureFlag',
  webLink = 'webLink',
}

export interface IUserDataList {
  title: string;
  value?: string | number | null | undefined;
  icon?: EIconType;
  webLink?: string;
  showButton?: boolean;
}
