import {
  DocumentReference,
  CollectionReference,
  Firestore,
  doc,
  collection,
  query,
  where,
  getDocs,
  QueryConstraint,
  QuerySnapshot,
} from '@firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import {
  ClientUser,
  CoachUser,
  PartnerReferralUser,
  EUserRoles,
  TFireBaseUser,
} from '@fitmate-coach/fitmate-types';

export const getUserRef = (id: string) => doc(db, 'users', id) as DocumentReference<TFireBaseUser>;

export const getUserCollectionRef = () =>
  collection(db, 'users') as CollectionReference<TFireBaseUser>;

type AnyUser = ClientUser | CoachUser | PartnerReferralUser;
// TODO: Fix types
export async function getByRole(
  role: EUserRoles,
  extraQueries: QueryConstraint[] = [],
): Promise<QuerySnapshot<AnyUser>> {
  const q = query(collection(db, 'users'), where('role', '==', role), ...extraQueries);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return getDocs(q);
}
