import { styled } from '@mui/system';
import MenuItem from '../../../../../common/core/MenuItem';
import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

export const MessageLeftContainer = {
  alignSelf: 'flex-start',
};

export const MessageRightContainer = {
  alignSelf: 'flex-end',
};

export const MessageContainer = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
  ...(isCurrentUser ? MessageLeftContainer : MessageRightContainer),
}));

export const MessageSuperBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const MessageSuggestionPointerBlock = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const MessageBorderedLeftBlock = {
  border: '2px solid #7B7CE0',
};

export const MessageBorderedRightBlock = {
  border: '2px solid #52CDB1',
};

export const MessageLeftBlock = {
  backgroundColor: 'rgba(197, 197, 254, 0.16)',
  marginLeft: 24,
  borderRadius: '12px 12px 12px 0px',
};

export const MessageRightBlock = {
  backgroundColor: '#D6F5EE',
  marginRight: 24,
  borderRadius: '12px 12px 0px 12px',
};

export const MessageLeftBlockChosen = {
  backgroundColor: 'rgba(197,197,254,0.35) !important',
};

export const MessageRightBlockChosen = {
  backgroundColor: '#AEEBDD !important',
};

export const MessageBlock = styled('div')<{
  isCurrentUser: boolean;
  isCurrentMessage: boolean;
  anchorEl: any;
}>(({ isCurrentUser, isCurrentMessage, anchorEl }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 419,
  minWidth: 254,
  padding: '12px 12px 12px 12px',
  ...(isCurrentMessage
    ? isCurrentUser
      ? MessageBorderedLeftBlock
      : MessageBorderedRightBlock
    : ''),
  ...(isCurrentUser ? MessageLeftBlock : MessageRightBlock),
  ...(anchorEl ? (isCurrentUser ? MessageLeftBlockChosen : MessageRightBlockChosen) : ''),
}));

export const AvatarLeftBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: 14,
}));

export const QuoteBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 6,
}));

export const QuoteLineBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 2,
  minHeight: '100%',
}));

export const QuoteLine = styled('div')(({ theme }) => ({
  width: 2,
  height: '100%',
  backgroundColor: '#18997C',
  borderRadius: 4,
}));

export const QuoteMessageBlock = styled('div')(({ theme }) => ({
  width: '100%',
  marginLeft: 10,
  paddingTop: 4,
  paddingBottom: 4,
}));

export const QuoteBodyText = styled('div')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  color: '#0F0F21',
  maxWidth: '430px !important',
  wordWrap: 'break-word',
}));

export const QuoteNameBlock = styled('div')(({ theme }) => ({
  paddingBottom: 5,
}));

export const QuoteName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: '18px',
  color: '#18997C',
  maxWidth: '430px !important',
  wordWrap: 'break-word',
}));

export const BodyText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  color: '#0F0F21',
  maxWidth: '450px !important',
  wordWrap: 'break-word',
}));

export const BodyTextEmojiBig = styled('div')(({ theme }) => ({
  fontSize: '60px !important',
  lineHeight: '60px !important',
}));

export const MessageBottomInfoBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: 6,
}));

export const MessageBottomLeftInfoBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexGrow: 1,
}));

export const MessageBottomRightInfoBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexGrow: 0,
}));

export const DateTextLeft = {
  color: 'rgba(15, 15, 33, 0.4) !important',
};

export const DateTextRight = {
  color: '#96BCB4 !important',
};

export const UserNameText = styled(Typography)<{ isCurrentUser: boolean }>(
  ({ theme, isCurrentUser }) => ({
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    width: 163,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...(isCurrentUser ? DateTextLeft : DateTextRight),
  }),
);

export const DateText = styled(Typography)<{ isCurrentUser: boolean }>(
  ({ theme, isCurrentUser }) => ({
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    ...(isCurrentUser ? DateTextLeft : DateTextRight),
  }),
);

export const FileContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const FileContainerLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const FileContainerRight = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
}));

export const FileBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 204,
  marginTop: 6,
}));

export const FileBlockLeft = styled('div')(({ theme }) => ({
  marginLeft: 24,
}));

export const FileBlockRight = styled('div')(({ theme }) => ({
  marginRight: 24,
  backgroundColor: '#D6F5EE',
  borderRadius: '2px 12px 0px 12px',
}));

export const FileBlockChosen = styled('div')(({ theme }) => ({
  backgroundColor: '#F2F4F7 !important',
}));

export const FileInfoBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: 6,
}));

export const FileInfoBlockLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '50%',
  paddingLeft: 9,
}));

export const FileInfoBlockRight = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '50%',
  paddingRight: 9,
}));

export const FileImageBlock = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

export const FileImage = styled('img')(({ theme }) => ({
  marginTop: 8,
  marginBottom: 8,
  width: '100%',
  height: 'auto',
  maxWidth: 180,
  maxHeight: 360,
}));

export const FileImagePreview = styled('img')(({ theme }) => ({
  marginTop: 8,
  marginBottom: 8,
  width: '100%',
  height: 'auto',
  maxWidth: 180,
  maxHeight: 121,
}));

export const FileImageIcon = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 45,
  left: 0,
  right: 0,
  margin: 'auto',
}));

export const FileImageText = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 30,
  margin: 'auto',
  fontSize: 12,
  fontWeight: 500,
  color: '#FFFFFF',
  maxWidth: 120,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const FileImagePreviewReply = styled('img')(({ theme }) => ({
  marginTop: 20,
  width: '100%',
  height: 'auto',
  maxWidth: 230,
}));

export const ActionTrackingGoal = styled('div')(({ theme }) => ({
  color: '#18997C',
  display: 'box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineClamp: 1,
}));

export const ActionTrackingDetails = styled('div')(({ theme }) => ({
  display: 'box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineClamp: 1,
}));

export const UserNameFileText = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
  width: 100,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const SharedMessage = styled('div')(({ theme }) => ({
  marginRight: 28,
}));

export const ReadIcon = styled('div')(({ theme }) => ({
  fontSize: 12,
  padding: 0,
  margin: 0,
  marginLeft: 2,
}));

export const UnReadIcon = styled(ReadIcon)(({ theme }) => ({
  color: '#96BCB4',
}));

export const IsReadIcon = styled(ReadIcon)(({ theme }) => ({
  color: '#47B29A',
}));

export const SubMenu = styled(MenuItem)(({ theme }) => ({
  minWidth: 150,
}));

export const SubMenuDelete = styled(SubMenu)(({ theme }) => ({
  color: '#FF6D7D !important',
}));

export const IconSuggestion = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  color: theme.palette.secondary.main,
  paddingLeft: 6,
  cursor: 'pointer',
}));

export const IconSuggestionUsed = styled('div')(({ theme }) => ({
  color: 'rgba(15, 15, 33, 0.2) !important',
}));

export const StyledReactMarkDown = styled(ReactMarkdown)(({ theme }) => ({
  '& p': {
    margin: 0,
  },
}));
