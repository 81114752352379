const timeInactiveWorker = () => {
  let intervalInactivity: NodeJS.Timeout | null | unknown = null;
  let timeInactivity = 0;
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = ({ data: { isStartInactivityTimer } }) => {
    if (isStartInactivityTimer) {
      clearInterval(intervalInactivity as NodeJS.Timeout);
      intervalInactivity = setInterval(() => {
        timeInactivity += 1;
        // eslint-disable-next-line no-restricted-globals
        self.postMessage({ timeInactivity });
      }, 1000);
    }

    if (!isStartInactivityTimer) {
      clearInterval(intervalInactivity as NodeJS.Timeout);
      timeInactivity = 0;
    }
  };
};

let codeTimeInactive = timeInactiveWorker.toString();
codeTimeInactive = codeTimeInactive.substring(
  codeTimeInactive.indexOf('{') + 1,
  codeTimeInactive.lastIndexOf('}'),
);

const blobInactive = new Blob([codeTimeInactive], { type: 'application/javascript' });
const inactive_worker_script = URL.createObjectURL(blobInactive);

export default inactive_worker_script;
