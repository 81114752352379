import { InputLabel } from '@mui/material';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import NextQuestion from 'common/core/NextQuestion';
import { fromEnumToArr, setTypeOnboarding } from 'utils/data/surveys';
import {
  ESurveysQuestionTypes,
  ESurveyType,
  EExtendsSurveysQuestionTypes,
  ESurveyTypeFieldLabel,
  ESurveyTypeField,
  IQuestionsBlock,
} from 'types';
import {
  ActionIcons,
  ColumnBottom,
  ColumnCentral,
  ColumnRight,
  DragIcon,
  Item,
  ItemWrapper,
  Row,
  RowWrapper,
  StyledDelete,
  StyledFileCopy,
  StyledFormControlSelect,
  StyledInput,
  StyledSelect,
  Title,
  TypeBlock,
} from './styles';
import CheckBoxBlock from '../BlocksTypes/CheckBoxBlock';
import RadioBlock from '../BlocksTypes/RadioBlock';
import ScaleBlock from '../BlocksTypes/SliderBlock';
import UnitBlock from '../BlocksTypes/UnitBlock';

const QuestionsBlock = ({
  block,
  blocks,
  index,
  deleteClickHandler,
  inputHandler,
  questionHandler,
  optionHandler,
  sliderHandler,
  copyClickHandler,
  addOptionHandler,
  deleteOptionHandler,
  showDeleteButton,
  typeOfSurvey,
  targetFieldHandler,
}: IQuestionsBlock) => {
  const {
    id,
    title,
    category,
    question,
    helpText,
    answerVariants,
    slider,
    type,
    tags,
    defaultNextQuestionId,
    unit,
    isRequired,
  } = block;

  const deleteHandler = (answerId: string) => {
    if (deleteOptionHandler) {
      deleteOptionHandler(id, answerId);
    }
  };

  const typeHandler = (id: string, key: string, value: string) => {
    questionHandler(id, key, value);
    if (setTypeOnboarding(typeOfSurvey)) {
      targetFieldHandler(id, '');
    }
  };

  return (
    <Draggable key={index} draggableId={index.toString()} index={index}>
      {(draggableProvided: DraggableProvided) => {
        return (
          <ItemWrapper
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <DragIcon />
            <Item>
              <RowWrapper>
                <Row>
                  <div>
                    <Title>Title</Title>
                  </div>
                  <ColumnCentral>
                    <StyledInput
                      value={title.value}
                      name="title"
                      type="text"
                      onChange={(e) => inputHandler(id, 'title', e.target.value, false)}
                      error={title.error}
                      helperText={title.error ? 'Please, fill the field' : ''}
                      label={title.error ? 'Error' : ''}
                      multiline
                      placeholder={!title.error ? 'Give a title to your question' : ''}
                    />
                  </ColumnCentral>
                  <ColumnRight>
                    <ActionIcons>
                      <StyledFileCopy onClick={() => copyClickHandler(index)} />
                      <StyledDelete
                        onClick={() => showDeleteButton && deleteClickHandler(id, index)}
                      />
                    </ActionIcons>
                  </ColumnRight>
                </Row>
                {(typeOfSurvey === ESurveyType.ONBOARDING ||
                  typeOfSurvey === ESurveyType.ONBOARDING_ACTIVE) && (
                  <Row>
                    <div>
                      <Title>Category</Title>
                    </div>
                    <ColumnCentral>
                      <StyledInput
                        placeholder={!category?.error ? 'Choose the category title' : ''}
                        onChange={(e) => inputHandler(id, 'category', e.target.value, false)}
                        value={category?.value}
                        name="category"
                        type="text"
                        multiline
                        error={category?.error}
                        label={category?.error ? 'Error' : ''}
                        helperText={category?.error ? 'Please, link category' : ''}
                      />
                    </ColumnCentral>
                    <ColumnRight />
                  </Row>
                )}
                <Row>
                  <div>
                    <Title>Question</Title>
                  </div>
                  <ColumnCentral>
                    <StyledInput
                      placeholder={!question.error ? 'Write your question' : ''}
                      onChange={(e) => inputHandler(id, 'question', e.target.value, false)}
                      value={question.value}
                      name="question"
                      type="text"
                      multiline
                      error={question.error}
                      label={question.error ? 'Error' : ''}
                      helperText={question.error ? 'Please, link question' : ''}
                    />
                  </ColumnCentral>
                  <ColumnRight />
                </Row>
                {(typeOfSurvey === ESurveyType.ONBOARDING ||
                  typeOfSurvey === ESurveyType.ONBOARDING_ACTIVE) && (
                  <>
                    <Row>
                      <div>
                        <Title>Question help text</Title>
                      </div>
                      <ColumnCentral>
                        <StyledInput
                          placeholder={!helpText?.error ? 'Add supporting text if relevant' : ''}
                          onChange={(e) => inputHandler(id, 'helpText', e.target.value, false)}
                          value={helpText?.value}
                          name="helpText"
                          type="text"
                          multiline
                          error={helpText?.error}
                          label={helpText?.error ? 'Error' : ''}
                          helperText={helpText?.error ? 'Please, link question' : ''}
                        />
                      </ColumnCentral>
                      <ColumnRight />
                    </Row>
                    <Row>
                      <div>
                        <Title>Required</Title>
                      </div>
                      <ColumnCentral>
                        <ColumnBottom>
                          <StyledFormControlSelect variant="outlined">
                            <StyledSelect
                              native
                              variant="outlined"
                              onChange={(e) =>
                                inputHandler(id, 'isRequired', e.target.value as string, false)
                              }
                              label="required question"
                              value={isRequired?.value}
                              inputProps={{
                                name: 'isRequired',
                                id: 'isRequired',
                              }}
                            >
                              <option value="no">No</option>
                              <option value="yes">Yes</option>
                            </StyledSelect>
                          </StyledFormControlSelect>
                        </ColumnBottom>
                      </ColumnCentral>
                    </Row>
                  </>
                )}
                <Row>
                  <div>
                    <Title>Type</Title>
                  </div>
                  <ColumnCentral>
                    <ColumnBottom>
                      <StyledFormControlSelect variant="outlined">
                        <InputLabel htmlFor="type">Type of question</InputLabel>
                        <StyledSelect
                          // variant="standard"
                          native
                          onChange={(e) => typeHandler(id, 'type', e.target.value as string)}
                          label="Type of question"
                          value={type}
                          inputProps={{
                            name: 'type',
                            id: 'type',
                          }}
                        >
                          <option value={ESurveysQuestionTypes.CHECKBOX}>Checkbox</option>
                          {(typeOfSurvey === ESurveyType.REGULAR ||
                            typeOfSurvey === ESurveyType.ACTION) && (
                            <option value={ESurveysQuestionTypes.SLIDER}>Linear Scale</option>
                          )}
                          <option value={ESurveysQuestionTypes.TEXT}>Text answer</option>
                          <option value={ESurveysQuestionTypes.TEXT_WITH_UNITS}>
                            Text with units
                          </option>
                          <option value={ESurveysQuestionTypes.RADIO}>Radiobutton</option>
                          {(typeOfSurvey === ESurveyType.ONBOARDING ||
                            typeOfSurvey === ESurveyType.ONBOARDING_ACTIVE) && (
                            <option value={EExtendsSurveysQuestionTypes.DATE}>Date</option>
                          )}
                          <option value={ESurveysQuestionTypes.MULTI_TEXT}>Multi text</option>
                          <option value={ESurveysQuestionTypes.MULTI_TEXT_WITH_UNITS}>
                            Multi text with units
                          </option>
                        </StyledSelect>
                      </StyledFormControlSelect>
                      <NextQuestion
                        id={id}
                        nextQuestionId={defaultNextQuestionId}
                        questionHandler={questionHandler}
                        blocks={blocks}
                        isRadio={false}
                      />
                    </ColumnBottom>
                  </ColumnCentral>
                  <ColumnRight />
                </Row>
                {setTypeOnboarding(typeOfSurvey) && (
                  <Row>
                    <div>
                      <Title>Target field</Title>
                    </div>
                    <ColumnCentral>
                      <ColumnBottom>
                        <StyledFormControlSelect variant="outlined">
                          <InputLabel htmlFor="targetField">Please select</InputLabel>
                          <StyledSelect
                            // variant="standard"
                            native
                            onChange={(e) => targetFieldHandler(id, e.target.value as string)}
                            label="Please select"
                            value={tags ? tags[0] : ''}
                            inputProps={{
                              name: 'targetField',
                              id: 'targetField',
                            }}
                          >
                            {fromEnumToArr(ESurveyTypeFieldLabel).map((item, index) => (
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              <option key={`${item}${index}`} value={ESurveyTypeField[item.key]}>
                                {item.value}
                              </option>
                            ))}
                          </StyledSelect>
                        </StyledFormControlSelect>
                        {/*<SurveyQuestionPageField page={page} targetFieldHandler={inputHandler} questionId={id} />*/}
                      </ColumnBottom>
                    </ColumnCentral>
                    <ColumnRight />
                  </Row>
                )}
              </RowWrapper>
              <TypeBlock>
                {ESurveysQuestionTypes.CHECKBOX === type && (
                  <CheckBoxBlock
                    id={id}
                    optionHandler={optionHandler}
                    deleteHandler={deleteHandler}
                    answerVariants={answerVariants}
                    addOptionHandler={addOptionHandler}
                  />
                )}
                {ESurveysQuestionTypes.RADIO === type && (
                  <RadioBlock
                    id={id}
                    optionHandler={optionHandler}
                    deleteHandler={deleteHandler}
                    answerVariants={answerVariants}
                    addOptionHandler={addOptionHandler}
                    questionHandler={questionHandler}
                    blocks={blocks}
                  />
                )}
                {ESurveysQuestionTypes.SLIDER === type && (
                  <ScaleBlock id={id} slider={slider} sliderHandler={sliderHandler} />
                )}
                {(ESurveysQuestionTypes.TEXT_WITH_UNITS === type ||
                  ESurveysQuestionTypes.MULTI_TEXT_WITH_UNITS === type) && (
                  <UnitBlock id={id} questionHandler={inputHandler} unit={unit} />
                )}
              </TypeBlock>
            </Item>
          </ItemWrapper>
        );
      }}
    </Draggable>
  );
};

export default QuestionsBlock;
