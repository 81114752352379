import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { ArrowDropUpRounded } from '@mui/icons-material';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { v4 } from 'uuid';
import Modal from 'common/core/Modal';
import { useChatSurveyUpdate } from 'hooks/chat/useChatSurveyUpdate';
import { allItemValid, inputData, textFieldValidation } from 'utils/data/surveys';
import {
  AccordionDetailsStyled,
  AccordionQuestion,
  AccordionSummaryStyled,
  ItemButton,
  ItemHeader,
  ItemTitle,
  ItemWrapper,
  ListWrapper,
  LoaderIcon,
  Title,
} from './styles';
import {
  EStatus,
  ISurvey,
  ISurveyQuestion,
  ESurveysQuestionTypes,
  ISurveyTemplate,
  ESurveyType,
  ETypeTextFieldFormat,
  IChatSurveysTemplates,
} from 'types';
import Questions from './Questions';
import QuestionsForActions from './QuestionsForActions';

const ChatSurveysTemplates = ({ surveysTemplateList, userId }: IChatSurveysTemplates) => {
  const { createSurvey } = useChatSurveyUpdate();
  const [isExpanded, setIsExpanded] = useState<string | false>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [surveyCreateLoader, setSurveyCreateLoader] = useState<{
    loader: boolean;
    title: string;
  }>({ loader: false, title: '' });
  const [selectedSurveyActions, setSelectedSurveyActions] = useState<any[]>([null]);
  const [listQuestionsCoach, setListQuestionsCoach] = useState<ISurveyQuestion[] | []>([]);

  useEffect(() => {
    setListQuestionsCoach([]);
    const timer = setTimeout(() => setSelectedSurveyActions([null]), isExpanded ? 200 : 900);
    return () => clearTimeout(timer);
  }, [isExpanded]);

  const handleChange = (panelId: string) => (event: any, isExpanded: boolean) => {
    setIsExpanded(isExpanded ? panelId : false);
  };

  const addBlock = () => {
    setListQuestionsCoach([
      ...listQuestionsCoach,
      {
        id: v4(),
        title: { value: '', error: false },
        category: { value: '', error: false },
        question: { value: '', error: false },
        type: ESurveysQuestionTypes.TEXT,
        answerVariants: [
          { answerId: v4(), answer: '', nextQuestionId: null, error: false },
          { answerId: v4(), answer: '', nextQuestionId: null, error: false },
        ],
        slider: {
          max: 5,
          minDescription: '',
          maxDescription: '',
        },
        textFieldFormat: {
          min: 0,
          type: ETypeTextFieldFormat.STRING,
        },
        defaultNextQuestionId: null,
        tags: null,
      },
    ]);
  };

  const sliderHandler = (
    id: string,
    max: number,
    minDescription: string,
    maxDescription: string,
  ) => {
    setListQuestionsCoach((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            slider: { max, minDescription, maxDescription },
          };
        }
        return item;
      }),
    );
  };

  const isBlockValid = (data: ISurveyQuestion): boolean => {
    const { id, type, title, question, answerVariants } = data;

    const isTitleError: boolean = textFieldValidation(title.value);
    const isQuestionError: boolean = textFieldValidation(question.value);
    const isVariantsError: any[] = [];

    if (type === ESurveysQuestionTypes.CHECKBOX || type === ESurveysQuestionTypes.RADIO) {
      answerVariants.forEach((variant) => {
        optionHandler(id, 'error', textFieldValidation(variant.answer), variant.answerId);
        isVariantsError.push(!textFieldValidation(variant.answer));
      });
    }

    const isAllAnswerValid: boolean = allItemValid(isVariantsError);

    inputHandler(id, 'title', title.value, isTitleError);
    inputHandler(id, 'question', question.value, isQuestionError);
    return !isTitleError && !isQuestionError && isAllAnswerValid;
  };

  const handleAddBlock = () => {
    if (listQuestionsCoach.length) {
      const last = listQuestionsCoach[listQuestionsCoach.length - 1];
      const isValid: boolean = isBlockValid(last);
      isValid && addBlock();
    } else {
      addBlock();
    }
  };

  // for type, answerVariants, slider, defaultNextQuestionId
  const questionHandler = (id: string, key: string, value: string | null) => {
    setListQuestionsCoach((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          if (value === ESurveysQuestionTypes.RADIO || value === ESurveysQuestionTypes.CHECKBOX) {
            item.answerVariants = [
              { answerId: v4(), answer: '', nextQuestionId: null, error: false },
              { answerId: v4(), answer: '', nextQuestionId: null, error: false },
            ];
          }
          if (value === ESurveysQuestionTypes.SLIDER) {
            item.slider = {
              max: 5,
              minDescription: '',
              maxDescription: '',
            };
          }
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      }),
    );
  };

  // for title and question
  const inputHandler = (id: string, key: string, value: string, error: boolean) => {
    setListQuestionsCoach((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [key]: { value: value, error: error },
          };
        }

        return item;
      }),
    );
  };

  const handleDelete = (key: number) => {
    setListQuestionsCoach((prev) => prev.filter((item, index) => key !== index));
  };

  const copyClickHandler = (copyIndex: number) => {
    const newBlocks = Array.from(listQuestionsCoach);
    const copyBlock = newBlocks[copyIndex];

    if (isBlockValid(copyBlock)) {
      newBlocks.splice(copyIndex + 1, 0, {
        ...copyBlock,
        id: v4(),
        ...((copyBlock.type === ESurveysQuestionTypes.RADIO ||
          copyBlock.type === ESurveysQuestionTypes.CHECKBOX) && {
          answerVariants: copyBlock.answerVariants.map((item) => ({
            ...item,
            answerId: v4(),
          })),
        }),
      });
      setListQuestionsCoach(newBlocks);
    }
  };

  const optionHandler = (
    id: string,
    key: string,
    value: string | boolean,
    answerId: string | null,
  ) => {
    setListQuestionsCoach((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          item.answerVariants.map((variant) => {
            if (variant.answerId === answerId) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              variant[key] = value;
            }
            return variant;
          });
        }
        return item;
      }),
    );
  };

  const addOptionHandler = (id: string) => {
    setListQuestionsCoach((prev) =>
      prev.map((item) => {
        if (item.id === id && item.answerVariants) {
          const isVariantsError: any[] = [];
          item.answerVariants.forEach((variant) => {
            optionHandler(id, 'error', textFieldValidation(variant.answer), variant.answerId);
            isVariantsError.push(!textFieldValidation(variant.answer));
          });

          if (allItemValid(isVariantsError)) {
            item.answerVariants.push({
              answerId: v4(),
              answer: '',
              nextQuestionId: null,
              error: false,
            });
          }
        }
        return item;
      }),
    );
  };

  const deleteOptionHandler = (id: string, answerId: string) => {
    setListQuestionsCoach((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          item?.answerVariants?.map((answer, index) => {
            if (answer.answerId === answerId) {
              item.answerVariants.splice(index, 1);
            }
            return item;
          });
        }
        return item;
      }),
    );
  };

  const handleSendNow = (
    e: React.MouseEvent<HTMLElement>,
    id: string,
    questions: ISurveyQuestion[],
    title: string,
    /*    startMessage: string,
    endMessage: string,*/
  ) => {
    e.stopPropagation();
    const stages: object[] = [];

    if (selectedSurveyActions) {
      selectedSurveyActions.forEach((item) => {
        item &&
          stages.push({ title: item.title, questions: item.questions, actionId: item.actionId });
      });
    }
    stages.push({ templateId: id, questions: questions });

    const surveys: ISurvey = {
      userId,
      title,
      /*      startMessage: startMessage || '',
      endMessage: endMessage || '',*/
      stages: stages,
      status: EStatus.ASSIGNED,
    };
    const localValidator: any[] = [];

    const checkAll = () => {
      if (listQuestionsCoach.length > 0) {
        listQuestionsCoach.forEach((block) => {
          localValidator.push(isBlockValid(block));
        });
        return allItemValid(localValidator);
      }
    };

    if (listQuestionsCoach.length > 0) {
      surveys.stages.push({
        questions: listQuestionsCoach.map((item, index) => ({
          ...item,
          title: item.title.value,
          question: item.question.value,
          defaultNextQuestionId: listQuestionsCoach[index + 1]?.id || null,
        })),
      });
    }

    const sendSurvey = () => {
      setSurveyCreateLoader({ loader: true, title: surveys.title });
      createSurvey(surveys).then(() => {
        setSurveyCreateLoader({ loader: false, title: '' });
      });
      setIsExpanded(false);
    };

    if (!listQuestionsCoach.length) {
      sendSurvey();
    } else {
      checkAll() ? sendSurvey() : setIsModalOpen(true);
    }
  };

  return (
    <>
      <Modal
        widthMax={320}
        title="Please, check your additional question(s)."
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleSubmit={() => setIsModalOpen(false)}
        textBtnSubmit="OK"
      ></Modal>
      <Title>Templates</Title>
      <ListWrapper>
        {surveysTemplateList.length > 0 &&
          surveysTemplateList
            .filter(
              (item: ISurveyTemplate) =>
                !item.actionId &&
                item.onboardingType !== ESurveyType.ONBOARDING &&
                item.onboardingType !== ESurveyType.ONBOARDING_ACTIVE, // TODO: Add a migration and use 'where' in get query
            )
            /*.map(({ id, questions, title, startMessage, endMessage }: SurveyTemplate) => (*/
            .map(({ id, questions, title }: ISurveyTemplate) => (
              <ItemWrapper
                key={id}
                expanded={isExpanded === id}
                onChange={handleChange(id)}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummaryStyled
                  expandIcon={<ArrowDropUpRounded sx={{ transform: 'rotate(180deg)' }} />}
                  aria-controls={title}
                  id={id}
                >
                  <ItemHeader>
                    <ItemTitle>{title}</ItemTitle>
                    <ItemButton
                      onClick={(e) =>
                        /*handleSendNow(e, id, questions, title, startMessage, endMessage)*/
                        handleSendNow(e, id, questions, title)
                      }
                      disabled={surveyCreateLoader.loader}
                    >
                      {surveyCreateLoader.title === title ? (
                        <LoaderIcon as={AutorenewRoundedIcon} />
                      ) : (
                        'Send now'
                      )}
                    </ItemButton>
                  </ItemHeader>
                </AccordionSummaryStyled>
                <AccordionDetailsStyled>
                  <AccordionQuestion>
                    <QuestionsForActions
                      selectedSurveyActions={selectedSurveyActions}
                      surveyListActions={surveysTemplateList.filter(
                        (item: ISurveyTemplate) => item.actionId,
                      )}
                      setSelectedSurveyActions={setSelectedSurveyActions}
                    />
                    <Questions
                      selectedSurveyActions={selectedSurveyActions}
                      questionsList={inputData(questions)}
                      listQuestionsCoach={listQuestionsCoach}
                      setListQuestionsCoach={setListQuestionsCoach}
                      handleAddBlock={handleAddBlock}
                      inputHandler={inputHandler}
                      questionHandler={questionHandler}
                      optionHandler={optionHandler}
                      addOptionHandler={addOptionHandler}
                      deleteOptionHandler={deleteOptionHandler}
                      sliderHandler={sliderHandler}
                      handleDelete={handleDelete}
                      copyClickHandler={copyClickHandler}
                    />
                  </AccordionQuestion>
                </AccordionDetailsStyled>
              </ItemWrapper>
            ))}
        {surveysTemplateList.length === 0 && (
          <Typography>Sorry, no templates. Contact the super admin to resolve the issue</Typography>
        )}
      </ListWrapper>
    </>
  );
};

export default ChatSurveysTemplates;
