import { cn } from 'mcn';

export const Button = ({
  children,
  className,
  variant,
  size,
  icon,
  loading,
  disabled,
  ...props
}: JSX.IntrinsicElements['button'] & {
  loading?: boolean;
  size?: 'lg' | 'sm';
  variant?: 'solid' | 'outlined';
  icon?: string | React.ReactNode;
}) => {
  const btn = cn('transition-all disabled:opacity-50 rounded-lg text-sm font-semibold');
  const sizec = { sm: cn('px-6 py-2'), lg: cn('px-7 py-3') }[size ?? 'sm'];
  const color = {
    solid: cn('bg-primary text-white hover:opacity-90'),
    outlined: cn('border border-dark/20 text-dark/60 hover:bg-dark/5'),
  }[variant ?? 'solid'];

  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={cn(className, btn, sizec, color, 'flex gap-2 items-center justify-center')}
    >
      {icon && typeof icon !== 'string' && !loading && (
        <span className="[&>*]:!w-4 [&>*]:!h-4 relative -top-0.5">{icon}</span>
      )}
      {typeof icon === 'string' && !loading && <span className={cn('w-4 h-4', icon)} />}
      {loading && <span className="icon-[line-md--loading-twotone-loop] w-5 h-5" />}
      <span>{children}</span>
    </button>
  );
};
