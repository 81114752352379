import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from 'common/core/Button';
import { useAppSelector } from 'hooks';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { RightCta } from './styles';
import TeamsTable from './TeamsTable';
import AddTeamDrawer from './AddTeamDrawer';
import EditTeamDrawer from './EditTeamDrawer';
import DialogDeleteTeam from './DialogDeleteTeam';
import DialogAllocation from './DialogAllocation';

const TeamsContainer = () => {
  const { editedTeam, teamToDelete } = useAppSelector((state) => state.coaches);

  const [allocOpen, setAllocOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (editedTeam) {
      setEditOpen(true);
    } else {
      setEditOpen(false);
    }
  }, [editedTeam]);

  useEffect(() => {
    if (teamToDelete) {
      setDeleteOpen(true);
    } else {
      setDeleteOpen(false);
    }
  }, [teamToDelete]);

  return (
    <div className="w-full p-10">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h4">Teams</Typography>
        </Grid>
        <RightCta item xs={4}>
          <Button variant="outlined" onClick={() => setAllocOpen(!allocOpen)}>
            <AltRouteIcon />
            Change allocation rules
          </Button>
          <Button variant="contained" color="primary" onClick={() => setOpen(!open)}>
            Add team
          </Button>
        </RightCta>
        <Grid item xs={12} className="w-full">
          <TeamsTable />
        </Grid>
      </Grid>

      <DialogAllocation open={allocOpen} toggleDialog={() => setAllocOpen(!allocOpen)} />
      <AddTeamDrawer open={open} setOpen={setOpen} />
      <EditTeamDrawer open={editOpen} setOpen={setEditOpen} />
      <DialogDeleteTeam open={deleteOpen} />
    </div>
  );
};

export default TeamsContainer;
