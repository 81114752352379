/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OpenAI from 'openai';
import { PromptTemplate } from '@langchain/core/prompts';
import { IProgramActionsInterface } from 'types';
import { getGdocDocById } from 'api/gdrive';
import { fetchCustomerById } from '../customers/customerInfosSlice';
import { db } from 'utils/firebase/firebaseInit';
import { doc } from '@firebase/firestore';
import { deleteField, updateDoc } from 'firebase/firestore';
import { toast } from 'sonner';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_KEY, // defaults to process.env['OPENAI_API_KEY']
  dangerouslyAllowBrowser: true,
});

const initialState: IProgramActionsInterface = {
  loading: false,
  messages: [],
  feedbackLaunched: false,
};

export const getCompletion = createAsyncThunk(
  'automationAi/getCompletion',
  async (args: any, APIThunk: any) => {
    try {
      const { dispatch } = APIThunk;
      const { feedbackType } = args;
      dispatch(resetError());
      dispatch(loading());

      const gdoc = await getGdocDocById(feedbackType);
      const templatePrompt = gdoc.content;
      const variables = Object.keys(args).filter((k) => k !== 'feedbackType');
      // const { temperature, model } = querySnapshot.docs[0].data();
      const prompt = new PromptTemplate({
        template: templatePrompt,
        inputVariables: variables,
      });

      const formattedPrompt = await prompt.format(args);
      console.log(formattedPrompt);

      const chatCompletion = await openai.chat.completions.create({
        messages: [{ role: 'system', content: formattedPrompt }],
        model: 'gpt-4',
        temperature: 0,
      });

      dispatch(
        saveMessages([
          { role: 'system', content: formattedPrompt },
          chatCompletion.choices[0].message,
        ]),
      );
      dispatch(saveTemperature(0));
      dispatch(saveModel('gpt-4'));

      return chatCompletion.choices[0].message;
    } catch (e: any) {
      console.log('error while processing ai', e.toString());
      console.log(e);
      setError(e.toString());
    }
  },
);

export const getExtraCompletion = createAsyncThunk(
  'automationAi/getExtraCompletion',
  async (args: any, APIThunk: any) => {
    const { messages, currentModel, currentTemperature } = APIThunk.getState().automationAi;
    const { dispatch } = APIThunk;
    const newMessages = [...messages, { role: 'user', content: args.prompt }];

    try {
      const chatCompletion = await openai.chat.completions.create({
        messages: newMessages,
        model: currentModel,
        temperature: currentTemperature,
      });

      dispatch(saveMessages([...newMessages, chatCompletion.choices[0].message]));

      return chatCompletion.choices[0].message;
    } catch (e) {
      console.log(e);
    }
  },
);

interface ConvertImportantInfo {
  userId: string;
  importantInfo: string;
}

export const convertImportantInfoToImportantInfos = createAsyncThunk(
  'automationAi/convertImportantInfoToImportantInfos',
  async (args: ConvertImportantInfo, APIThunk: any) => {
    const { dispatch } = APIThunk;
    const gdoc = await getGdocDocById('1Ee0VRbLu0O-KzkaIXYTZFATRL9it6toW-eHL-yYeIlc');
    const templatePrompt = gdoc.content;
    const prompt = new PromptTemplate({
      template: templatePrompt,
      inputVariables: ['importantInfo'],
    });
    const formattedPrompt = await prompt.format({ importantInfo: args.importantInfo });
    console.log('important info prompt formatted', formattedPrompt);

    const chatCompletion = await openai.chat.completions.create({
      messages: [{ role: 'system', content: formattedPrompt }],
      model: 'gpt-4',
      temperature: 0,
    });
    try {
      if (chatCompletion.choices[0].message.content) {
        const formattedData = JSON.parse(chatCompletion.choices[0].message.content);
        const {
          mainChallenges,
          medicalConditions,
          dietaryRestrictions,
          physicalActivityRestrictions,
          nutritionPreferences,
          physicalActivityPreferences,
          otherLifeCircumstances,
          otherImportantInfos,
        } = formattedData;
        const userRef = doc(db, 'users', args.userId);
        await updateDoc(userRef, {
          importantInfos: {
            ...(mainChallenges.length > 0 && { mainChallenges: mainChallenges.join('\n') }),
            ...(medicalConditions.length > 0 && {
              medicalConditions: medicalConditions.join('\n'),
            }),
            ...(dietaryRestrictions.length > 0 && {
              dietaryRestrictions: dietaryRestrictions.join('\n'),
            }),
            ...(physicalActivityRestrictions.length > 0 && {
              physicalActivityRestrictions: physicalActivityRestrictions.join('\n'),
            }),
            ...(nutritionPreferences.length > 0 && {
              nutritionPreferences: nutritionPreferences.join('\n'),
            }),
            ...(physicalActivityPreferences.length > 0 && {
              physicalActivityPreferences: physicalActivityPreferences.join('\n'),
            }),
            ...(otherLifeCircumstances.length > 0 && {
              otherLifeCircumstances: otherLifeCircumstances.join('\n'),
            }),
            ...(otherImportantInfos.length > 0 && {
              otherImportantInfos: otherImportantInfos.join('\n'),
            }),
          },
          importantInfo: deleteField(),
        });
        dispatch(fetchCustomerById(args.userId));
        return;
      } else {
        console.log('error while converting important info');
        return;
      }
    } catch (e: any) {
      console.log('in catch for conversion', e);
      toast.error('Cannot convert important info', { description: e.toString() });
    }
  },
);

export const aiSlice = createSlice({
  name: 'automationAi',
  initialState,
  reducers: {
    saveMessages: (state, action) => {
      state.messages = action.payload;
    },
    resetMessages: (state) => {
      state.messages = [];
      state.feedbackLaunched = false;
      state.loading = false;
    },
    loading: (state) => {
      state.feedbackLaunched = true;
      state.loading = true;
    },
    setError: (state, action) => {
      state.errorMsg = action.payload;
    },
    resetError: (state) => {
      state.errorMsg = undefined;
    },
    saveModel: (state, action) => {
      state.currentModel = action.payload;
    },
    saveTemperature: (state, action) => {
      state.currentTemperature = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompletion.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload) return;
        // @ts-ignore
        state.messages = [...state.messages, action.payload];
      })
      .addCase(getCompletion.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCompletion.pending, (state) => {
        state.loading = true;
        state.errorMsg = undefined;
      })
      .addCase(getExtraCompletion.pending, (state) => {
        state.loading = true;
        state.errorMsg = undefined;
      })
      .addCase(getExtraCompletion.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload) return;
        // @ts-ignore
        state.messages = [...state.messages, action.payload];
      })
      .addCase(convertImportantInfoToImportantInfos.pending, (state) => {
        state.loading = true;
      })
      .addCase(convertImportantInfoToImportantInfos.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const {
  saveMessages,
  resetMessages,
  loading,
  setError,
  resetError,
  saveTemperature,
  saveModel,
} = aiSlice.actions;

export default aiSlice.reducer;
