import React, { useEffect, useState } from 'react';
import { sortByFirstName } from 'utils/format/sort';
import { numValuesOnPage } from 'config';
import { IPartnerReferralsList } from 'types';
import { BottomLineBlock, Container, PaginationBlock, TableBlock } from './styles';
import { PartnerReferralUser } from '@fitmate-coach/fitmate-types';
import Pagination from 'common/core/Pagination';
import PartnerReferralsListHeader from './PartnerReferralsListHeader';
import PartnerReferralsListItem from './PartnerReferralsListItem';
import PartnerReferralsEditModal from '../PartnerReferralsModal/PartnerReferralsListEditModal';

const PartnerReferralsList = ({
  partnerReferralsList,
  editPartnerReferral,
}: IPartnerReferralsList) => {
  const [userIdCurrent, setUserIdCurrent] = useState<string>('');
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    if (partnerReferralsList.length) {
      const pageCountNew = Math.ceil(partnerReferralsList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [partnerReferralsList]);

  const handleEditPartnerReferral = (
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
  ) => {
    editPartnerReferral(userId, firstName, lastName, email, phone);
    setIsOpenEditModal(false);
  };

  const toggleOpenEditModal = () => {
    setIsOpenEditModal(!isOpenEditModal);
  };

  const chooseEditPartnerReferral = (userId: string) => {
    setUserIdCurrent(userId);
    toggleOpenEditModal();
  };

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <PartnerReferralsListHeader />
      <Container>
        <TableBlock data-testid="table-block">
          <BottomLineBlock />
          {partnerReferralsList
            .sort(sortByFirstName)
            .filter(
              (_, index: number) =>
                index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
            )
            .map((item: PartnerReferralUser, i) => (
              <PartnerReferralsListItem
                key={i}
                item={item}
                chooseEditPartnerReferral={chooseEditPartnerReferral}
              />
            ))}
        </TableBlock>
        <PaginationBlock data-testid="paggination-block">
          {pageCount > 1 && (
            <Pagination count={pageCount} page={page} onChange={handleChangePage} />
          )}
        </PaginationBlock>
      </Container>
      <PartnerReferralsEditModal
        userIdCurrent={userIdCurrent}
        partnerReferralsList={partnerReferralsList}
        isOpenEditModal={isOpenEditModal}
        toggleOpenEditModal={toggleOpenEditModal}
        handleEditPartnerReferral={handleEditPartnerReferral}
      />
    </>
  );
};

export default PartnerReferralsList;
