import React, { useState, useEffect } from 'react';
import { FormControl } from '@mui/material';
import Modal from 'common/core/Modal';
import { IChatProgramProfileEditGoalModal } from 'types';
import { Container, InputBlock, StyledInput, Wrapper } from '../commonStyles';
import { useAppDispatch, useAppSelector } from 'hooks';
import { addBodyGoal, toggleEditGoal } from 'store/customers/bodyProfileSlice';
import { toast } from 'sonner';

const ChatProgramProfileEditGoalModal = ({
  bodyProfileGoal,
  isOpenEditGoalModal,
}: IChatProgramProfileEditGoalModal) => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const dispatch = useAppDispatch();
  const [weight, setWeight] = useState<string>('');
  const [weightError, setWeightError] = useState<string>('');

  useEffect(() => {
    if (isOpenEditGoalModal) {
      setWeight(bodyProfileGoal?.weight ? bodyProfileGoal?.weight.toString() : '');
      setWeightError('');
    }
  }, [isOpenEditGoalModal]);

  const handleWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '0' || value === '' || Number(value)) {
      setWeight(value);
    }
  };

  const handleSubmit = () => {
    if (customer?.id) {
      dispatch(
        addBodyGoal({
          userId: customer.id,
          weight: Number(weight) ?? null,
        }),
      );
      dispatch(toggleEditGoal());
      toast.success('Goal has been updated successfully');
    }
  };

  return (
    <Modal
      widthMax={320}
      title="Update goal weight"
      open={isOpenEditGoalModal}
      handleClose={() => dispatch(toggleEditGoal())}
      handleSubmit={handleSubmit}
      textBtnClose="Cancel"
      textBtnSubmit="Save"
    >
      <Wrapper>
        <Container>
          <InputBlock>
            <FormControl variant="standard">
              <StyledInput
                error={!!weightError}
                onChange={handleWeight}
                value={weight}
                label="Weight (lb)"
                helperText={weightError}
                type="text"
                fullWidth
              />
            </FormControl>
          </InputBlock>
        </Container>
      </Wrapper>
    </Modal>
  );
};

export default ChatProgramProfileEditGoalModal;
