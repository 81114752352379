import { useEffect, useState } from 'react';
import { sort, filtersAllClientsDefault, USERS_PER_PAGE } from 'config';
import { useGetUsersList } from 'api/elastic/useProfileEdit';
import { Container, ListBlock } from './styles';
import { TClientSearchUser, IFilter, ISort, IChatAllClients } from 'types';
import ChatAllClientsSearch from './ChatAllClientsSearch';
import ChatAllClientsHeader from './ChatAllClientsHeader';
import ChatAllClientsList from './ChatAllClientsList';
import ChatAllClientsListPagination from './ChatAllClientsListPagination';

const ChatAllClients = ({ handleOpenAllClients, isOpenAllClients }: IChatAllClients) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [usersPerPage, setUsersPerPage] = useState<number>(USERS_PER_PAGE);
  const [sortValue, setSortValue] = useState<ISort>(sort[0]);
  const [filtersValues, setFiltersValues] = useState<IFilter[]>(filtersAllClientsDefault);
  const [searchValue, setSearchValue] = useState<string>('');
  const { usersAllList, usersSize, totalPages } = useGetUsersList(
    usersPerPage,
    currentPage,
    sortValue,
    filtersValues,
    searchValue,
  );
  const [currentUsersPageList, setCurrentUsersPageList] = useState<TClientSearchUser[]>([]);

  useEffect(() => {
    setCurrentUsersPageList(usersAllList);
  }, [usersAllList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [usersPerPage, sortValue, searchValue, filtersValues]);

  return (
    <Container>
      <ChatAllClientsHeader handleOpenAllClients={handleOpenAllClients} currentPage={currentPage} />
      <ChatAllClientsSearch searchValue={searchValue} setSearchValue={setSearchValue} />
      <ListBlock>
        <ChatAllClientsList
          usersList={currentUsersPageList}
          usersSize={usersSize}
          sortValue={sortValue}
          setSortValue={setSortValue}
          filtersValues={filtersValues}
          setFiltersValues={setFiltersValues}
          isOpenAllClients={isOpenAllClients}
        />
        {!!currentUsersPageList.length && (
          <ChatAllClientsListPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            usersPerPage={usersPerPage}
            setUsersPerPage={setUsersPerPage}
            totalPages={totalPages}
          />
        )}
      </ListBlock>
    </Container>
  );
};

export default ChatAllClients;
