import { styled } from '@mui/system';
import Button from '../../../../../common/core/Button';
import { Select, Typography } from '@mui/material';
import Input from 'common/core/Input';
import InputError from 'common/core/InputError';
import DatePicker from 'common/core/DatePicker';

export const Container = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const MainBlock = styled('div')({
  height: 'calc(100% - 68px)',
  overflowY: 'auto',
});

export const BottomBlock = styled('div')({
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  height: 68,
  backgroundColor: '#F9FAFC',
  borderTop: '1px solid #E4E7ED',
  flexGrow: 0,
});

export const StyledButton = styled(Button)({
  height: 44,
  color: 'rgba(15, 15, 33, 0.6)',
  border: '1px solid rgba(15, 15, 33, 0.2)',
  borderRadius: 8,
  marginRight: 15,
  fontWeight: 600,
});

export const ButtonSubmit = styled(StyledButton)({
  marginRight: 32,
});

export const SelectBlock = styled('div')({
  marginBottom: 15,
});

export const StyledSelect = styled(Select)({
  minWidth: 320,
  margin: 0,
});

export const InputBlock = styled('div')({
  width: 320,
  marginBottom: 15,
});

export const FeatureFlagsBlock = styled('div')({
  marginBottom: 15,
  display: 'flex',
  gap: '1rem',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%',
});

export const StyledInput = styled(Input)({
  margin: 0,
});

export const StyledInputAdornment = styled(Typography)({
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
});

export const DatePickerBlock = styled('div')<{ invalid: boolean }>(({ invalid }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 320,
  marginBottom: 40,
  ...(invalid && {
    marginBottom: 36,
  }),
}));

export const DatePickerError = styled(InputError)({
  marginTop: 20,
  marginBottom: 10,
});

export const StyledDatePicker = styled(DatePicker)({
  margin: 0,
});
