import { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks';
import ChatUserProfileEdit from './ChatUserProfileEdit';
import ChatUserProfileView from './ChatUserProfileView';
import ChatFAQLoader from '../ChatFAQ/ChatFAQLoader';

const ChatUserProfile = () => {
  const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
  const { customer, isReadOnly } = useAppSelector((state) => state.customerInfos);

  useEffect(() => {
    setIsEditingMode(false);
  }, [customer?.id]);

  const toggleEditingMode = () => {
    setIsEditingMode(!isEditingMode);
  };

  if (!isEditingMode || isReadOnly) {
    return (
      <ChatUserProfileView userData={customer || null} toggleEditingMode={toggleEditingMode} />
    );
  }

  if (customer?.id && isEditingMode) {
    return (
      <ChatUserProfileEdit
        userId={customer.id}
        userData={customer || null}
        toggleEditingMode={toggleEditingMode}
      />
    );
  }

  return <ChatFAQLoader />;
};

export default ChatUserProfile;
