import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const HandoutItem = styled('div')<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  justifyContent: 'start',
  maxWidth: 340,
  width: '100%',
  border: '1px solid #E4E7ED',
  boxShadow: '0px 2px 6px rgba(0, 2, 23, 0.075)',
  borderRadius: 12,
  cursor: 'pointer',

  ...(isSelected && {
    borderColor: '#8587E5',
    cursor: 'pointer',
    color: '#8587E5',
  }),
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 16,
  lineHeight: '24px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const ActionText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#192926',
  opacity: 0.6,
  paddingTop: 5,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const ActionTitle = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  opacity: 0.8,
}));
