import { ETypeMeal } from '@fitmate-coach/fitmate-nutrition-table';

export interface IResources {
  resource: IResource;
  handleResourceEdit?: (resourceId: string, resourceType: string) => void;
  checkedId?: string;
}

export interface IResourcesExtention extends IResources {
  resource: IResource;
  handleResourceEdit?: (resourceId: string, resourceType: string) => void;
  checkedId?: string;
  isMessage?: boolean;
}

export interface IResourcesSideBar {
  handleCategorySelected: (category: IResourceCategory | null) => void;
  handleTagsSelected: (tags: string[]) => void;
  currentCategoryId: string;
}

export interface IResourcesContent {
  handleCategorySelected: (category: IResourceCategory | null) => void;
  selectedCategory: IResourceCategory | null;
  selectedTags: string[];
  currentUserId?: string;
  handleOpenResoucesBlock?: () => void;
  isSendingMessageAsMe?: boolean;
}

export interface ISummaryResourceCategoryHover {
  categoryName: string;
  isSelected: boolean;
  level?: number;
}

export interface IResourceCategory {
  id: string;
  name: string;
  children: IResourceCategory[];
  picture: string;
  tags: string[];
  order: number;
}

export interface IResourceHandleCategory {
  category: IResourceCategory;
  handleCategorySelected: (category: IResourceCategory | null) => void;
  handleTagsSelected: (tags: string[]) => void;
  currentCategoryId?: string;
  level: number;
}

export enum EResourceBlockTypes {
  VIDEO = 'video',
  PICTURE = 'picture',
  TEXT = 'text',
  SUBTITLE = 'subtitle',
  NUTRITION_TABLE = 'nutrition_table',
}

export enum EResourceTypes {
  ARTICLE = 'article',
  RECIPE = 'recipe',
  WORKOUT = 'workout',
}

export interface IResourceBlockNutritionTable extends IResourceBlock {
  readonly type: EResourceBlockTypes.NUTRITION_TABLE;
  focusArea: ETypeMeal[];
}

export interface IResourceBlock {
  id?: string;
  body: string;
  url: string;
  readonly type: EResourceBlockTypes;
}

export interface IResource {
  id: string;
  title: string;
  type: string;
  categoryId: string;
  picture: string;
  tags: string[];
  blocks: IResourceBlock[];
  htmlBodyUrl?: string;
  thumbnail: string;
  parameters: Map<string, any>;
}

export interface INewResourcesSidebar {
  handleResourceDataChange: (callback: React.SetStateAction<any>) => void;
  resourceData?: any;
  thumbnail?: boolean;
  handleSetIsLastCategoryHasChild?: (value: boolean) => void;
}

export interface IResourceCategories {
  categoriesList: IResourceCategory[];
  handleActiveCategoryChange: (
    callback: React.SetStateAction<IResourceCategory | undefined>,
  ) => void;
  handleCurrentCategoryIdChange: (categoryId: string) => void;
  handleResourceDataChange: (callback: React.SetStateAction<any>) => void;
  currentCategoryId: string;
}

export interface IResourceThumbnail {
  handleResourceDataChange: (callback: React.SetStateAction<any>) => void;
  resourceData: any;
}

export interface IResourcesCategory {
  category: IResourceCategory;
  handleCategorySelected: (category: IResourceCategory | null) => void;
  handleTagsSelected: (tags: string[]) => void;
  handleEditCategory: (
    sourceCategory: IResourceCategory,
    categoryEditedHandler: (name: string, url: string) => void,
  ) => void;
  handleAddCategory: (
    parentId: string,
    categoryAddedHandler: (newCategory: IResourceCategory) => void,
  ) => void;
  handleAddTag: (sourceCategory: IResourceCategory, tagAddedHandler: (tag: string) => void) => void;
  handleRemoveCategory: (
    sourceCategory: IResourceCategory,
    categoryRemovedHandler: (categoryId: string) => void,
  ) => void;
  handleEditTag: (
    sourceCategory: IResourceCategory,
    tagIndex: number,
    tagEditedHandler: (tagIndex: number, newTag: string) => void,
  ) => void;
  handleDeleteTag: (
    sourceCategory: IResourceCategory,
    tagIndex: number,
    tagRemovedHandler: (tagIndex: number) => void,
  ) => void;
  removeChild: (childId: string) => void;
  currentCategoryId?: string;
  level: number;
}

export interface IResourceTags {
  tags: string[];
  toggleTagAddModal: () => void;
  handleResourceDataChange: (callback: React.SetStateAction<any>) => void;
  activeCategory: IResourceCategory | undefined;
  currentTags: string[];
}

export interface IHoverResource {
  handleResourceRemove: (resourceId: string) => void;
  handleResourceEdit: (resourceId: string, resourceType: string) => void;
  resource: IResource;
  children: React.ReactNode;
}

export interface IShareCategories {
  currentUserId?: string;
  handleOpenCategoryBlock: () => void;
}

export interface IShareCategory {
  category: IResourceCategory;
  level?: number;
}

export interface IResourceContainer {
  currentUserId?: string;
  handleOpenResoucesBlock?: () => void;
  isSendingMessageAsMe?: boolean;
}
