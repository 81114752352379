import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 30,
  paddingBottom: 20,
});

export const ColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
});

export const ColumnContentBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: 16,
});

export const ColumnLineBlock = styled('div')({
  width: 1,
  height: '100%',
  backgroundColor: '#FFF',
  marginLeft: 20,
  marginRight: 20,
});

export const TypeNameBlock = styled('div')({
  marginBottom: 15,
});

export const TypeNameText = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  color: '#0F0F21',
  opacity: 0.6,
});

export const SubTypeNameBlock = styled('div')({
  marginTop: 10,
  marginBottom: 15,
  paddingLeft: 10,
});

export const SubTypeNameText = styled(Typography)({
  fontSize: 13,
  fontWeight: 'normal',
  fontStyle: 'italic',
  color: '#0F0F21',
  opacity: 0.5,
});

export const NewCreatedActions = styled(Typography)({
  marginTop: '35px',
  paddingTop: '35px',
  marginBottom: '35px',
  borderTop: '1px solid #E4E7ED',
});
