export const oneLineTemplate = `
<div>
  <table style="border-collapse: collapse; width: 99.9553%; border: 1px hidden rgb(255, 255, 255); height: 28px;" border="1">
    <colgroup><col style="width: 99.8658%;"></colgroup>
    <tbody>
    <tr style="height: 28px;">
     <td style="border-width: 1px; border-color: rgb(255, 255, 255); border-style: hidden; height: 28px;">
      <span style="font-size: 15px; color: rgb(72, 47, 32);">Lorem<strong> Ipsum </strong>is simply dummy text</span>
     </td>
    </tr>
    </tbody>
  </table>
  <hr>
</div><p/>`;

export const twoLineTemplate = `
<div>
  <table style="border-collapse: collapse; width: 99.9553%; height: 63px; border: 1px hidden rgb(255, 255, 255);" border="1">
    <colgroup><col style="width: 50.0671%;"></colgroup>
    <tbody>
      <tr style="height: 56px;">
        <td style="border-width: 0px; height: 56px; border-color: rgb(255, 255, 255); border-style: hidden; text-align: left;" colspan="2">
          <span style="color: rgb(72, 47, 32); font-size: 15px;">Lorem<strong> Ipsum </strong>is simply dummy text</span>
          <br>
          <span style="color: rgb(134, 106, 84); font-size: 13px;">Lorem Ipsum is simply dummy text</span>
        </td>
      </tr>
    </tbody>
  </table>
  <hr>
</div><p/>`;

export const recipeTemplate = `
<div>
  <table style="border-collapse: collapse; width: 100%; border: 1px hidden #FFFFFF;" border="1">
    <colgroup>
      <col style="width: 100%;">
    </colgroup>
    <tbody>
      <tr>
        <td style="border-color: rgb(255, 255, 255); border-style: hidden; border-width: 0px;">
          <img style="display: block; margin-left: auto; margin-right: auto;" src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2Ffood.png?alt=media&token=b8ba7e1d-53e5-4362-b180-0dea3e47595a" width="430" height="360" />
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="line-height: 1.1; margin-left: 16px; margin-right: 16px;">
  <span style="color: rgb(72, 47, 32);">
    <span style="font-size: 24px;">
      <br>
      <strong>XXXXXX XXXX XXXXXX XXXX</strong>
    </span>
  </span>
</div>
<div style="margin-left: 16px; margin-right: 16px;">
  <table style="border-collapse: collapse; width: 99.9533%; border: 1px hidden #FFFFFF;" border="1">
    <colgroup>
    <col style="width: 99.8597%;">
    </colgroup>
    <tbody>
    <tr>
    <td style="height: 10px; border-color: rgb(255, 255, 255); border-style: hidden; line-height: 1; border-width: 1px;">&nbsp;</td>
    </tr>
    </tbody>
  </table>
  <table style="border-collapse: collapse; width: 100%; height: 29.3958px; border: 1px hidden rgb(255, 255, 255);" border="1">
    <colgroup>
      <col style="width: 4.97312%;">
      <col style="width: 95.0269%;">
    </colgroup>
    <tbody>
      <tr style="height: 29.3958px;">
        <td style="height: 29.3958px; border-width: 1px; border-color: rgb(255, 255, 255);">
          <img style="display: block; margin-left: auto; margin-right: auto;" src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2FServings%20icon.svg?alt=media&token=ee68ae56-b372-4c05-9eab-89765b989810" width="24" height="24" />
        </td>
        <td style="height: 29.3958px; border-width: 1px; border-color: rgb(255, 255, 255);">
         <span style="font-size: 15px;">Serves<strong> X</strong> (xxx kcal / serving)</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="margin-left: 16px; margin-right: 16px;">
  <table style="border-collapse: collapse; width: 100.089%; height: 31.375px; border: 1px hidden #FFFFFF;" border="1">
    <colgroup>
      <col style="width: 4.91368%;">
      <col style="width: 10.5098%;">
      <col style="width: 2.59333%;">
      <col style="width: 82.0312%;">
    </colgroup>
    <tbody>
      <tr style="height: 31.375px;">
        <td style="height: 31.375px; border-width: 1px; border-color: rgb(255, 255, 255); border-style: hidden;">
          <img style="display: block; margin-left: auto; margin-right: auto;" src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2FPrep%20time%20icon.svg?alt=media&token=d77a951f-6251-4395-80e1-dfc762248f07" width="24" height="24" />
        </td>
        <td style="height: 31.375px; border-width: 1px; border-color: rgb(255, 255, 255); border-style: hidden;">
          <span style="font-size: 15px;">Prep: <strong>XXm</strong></span>
        </td>
        <td style="border-width: 1px; border-color: rgb(255, 255, 255); height: 31.375px; border-style: hidden;">
          <span style="font-size: 15px;">
            <img style="display: block; margin-left: auto; margin-right: auto;" src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2FDivider%20dot%20icon.svg?alt=media&token=6d6f7010-ae45-434e-a317-c99f9ee9c8ae" width="6" height="7" />
          </span>
        </td>
        <td style="border-width: 1px; border-color: rgb(255, 255, 255); height: 31.375px; border-style: hidden;">
         <span style="font-size: 15px;">Cook: <strong>XXm</strong></span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="line-height: 1; margin-left: 16px; margin-right: 16px;">&nbsp;</div>
<div style="margin-left: 16px; margin-right: 16px;">
  <table style="width: 100%; height: 47.375px; border: 1px hidden rgb(255, 255, 255);" border="1">
    <colgroup>
      <col style="width: 7.24044%;">
      <col style="width: 92.7596%;">
    </colgroup>
    <tbody>
      <tr style="height: 47.375px;">
        <td style="height: 47.375px; border-color: rgb(255, 255, 255);">
        <img style="display: block; margin-left: auto; margin-right: auto;" src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2FIngredients%20icon.svg?alt=media&token=f655a8f6-e8eb-4d59-929c-cbb95d23bec5" width="40" height="41" />
        </td>
        <td style="height: 47.375px; border-color: rgb(255, 255, 255); border-style: hidden;">
        <span style="color: rgb(72, 47, 32); font-size: 20px;">
          <strong>Ingredients</strong>
        </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="margin-left: 16px; margin-right: 16px;">&nbsp;</div>
<div style="margin-left: 16px; margin-right: 16px;">&nbsp;<img src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2Fnut%20free%20icon.svg?alt=media&token=b189b501-6b15-47a8-97f0-fe7b9215f3bf" width="77" height="21" /></div>
<div style="margin-left: 16px; margin-right: 16px;">&nbsp;</div>
<div style="margin-left: 16px; margin-right: 16px;">&nbsp;</div>
<div style="margin-left: 16px; margin-right: 16px;">
  <table style="border-collapse: collapse; width: 100%; height: 51px; border: 1px hidden rgb(255, 255, 255);" border="1">
    <colgroup>
      <col style="width: 3.69379%;">
      <col style="width: 96.3062%;">
    </colgroup>
    <tbody>
      <tr style="height: 51px;">
        <td style="border-width: 1px; height: 51px; border-color: rgb(255, 255, 255);">
          <span style="color: rgb(72, 47, 32);">
            <img src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2FInstructions%20icon.svg?alt=media&token=cf850906-b9e8-49fa-94f1-a9e658c46a4c" width="40" height="41" />
          </span>
        </td>
        <td style="border-width: 1px; height: 51px; border-color: rgb(255, 255, 255);">
          <span style="color: rgb(72, 47, 32);">
            <strong>
              <span style="font-size: 20px;">Instructions</span>
            </strong>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="margin-left: 16px; margin-right: 16px;">
  <table style="border-collapse: collapse; width: 99.9553%; border: 1px hidden #FFFFFF;" border="1">
    <colgroup>
      <col style="width: 99.8658%;">
    </colgroup>
    <tbody>
      <tr>
        <td style="border-width: 1px; border-color: rgb(255, 255, 255); border-style: hidden;">
          <ol>
            <li style="margin-bottom: 24px; font-size: 15px; line-height: 1.3; color: rgb(72, 47, 32);"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
            <li style="margin-bottom: 24px; font-size: 15px; line-height: 1.3; color: rgb(72, 47, 32);"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
            <li style="margin-bottom: 24px; font-size: 15px; line-height: 1.3; color: rgb(72, 47, 32);"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
            <li style="margin-bottom: 24px; font-size: 15px; line-height: 1.3; color: rgb(72, 47, 32);"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
            <li style="margin-bottom: 24px; font-size: 15px; line-height: 1.3; color: rgb(72, 47, 32);"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
          </ol>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="margin-left: 16px; margin-right: 16px;">&nbsp;</div>
<div style="margin-left: 16px; margin-right: 16px;">
  <table style="border-collapse: collapse; width: 100%; height: 55px; border: 1px hidden rgba(255, 255, 255, 0);" border="1">
    <colgroup>
      <col style="width: 7.42801%;">
      <col style="width: 92.4997%;">
    </colgroup>
    <tbody>
      <tr style="height: 55px;">
        <td style="border-width: 1px; height: 55px; border-color: rgba(255, 255, 255, 0);">
          <span style="color: rgb(72, 47, 32);">
            <img src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2FHow%20to%20meal%20prep%20icon.svg?alt=media&token=2ffc82af-16b5-4d2f-a173-c8a17878cb0e" width="40" height="41" />
          </span>
        </td>
        <td style="border-width: 1px; height: 55px; border-color: rgba(255, 255, 255, 0);">
          <span style="color: rgb(72, 47, 32); font-size: 20px;">
           <strong>How to meal prep</strong>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="margin-left: 16px; margin-right: 16px;">
  <table style="border-collapse: collapse; width: 99.9553%; border: 1px hidden #FFFFFF;" border="1">
    <colgroup><col style="width: 99.8658%;"></colgroup>
    <tbody>
      <tr>
        <td style="border-width: 1px; border-color: rgb(255, 255, 255); border-style: hidden;">
          <ul>
            <li style="margin-bottom: 24px; line-height: 1.3; color: rgb(72, 47, 32); font-size: 15px;"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
            <li style="margin-bottom: 24px; line-height: 1.3; color: rgb(72, 47, 32); font-size: 15px;"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div style="background: rgb(245, 242, 239);">
  <table style="border-collapse: collapse; width: 100%; height: 55px; border: 1px hidden rgba(255, 255, 255, 0);" border="1">
    <colgroup>
      <col style="width: 7.93011%;">
      <col style="width: 90.1882%;">
      <col style="width: 1.74731%;">
    </colgroup>
    <tbody>
      <tr>
        <td style="border-width: 1px; border-color: rgb(245, 242, 239);">&nbsp;</td>
        <th style="border-width: 1px; border-color: rgb(245, 242, 239); text-align: left;" scope="row"><span style="color: rgb(72, 47, 32); font-size: 15px;"><strong>&nbsp;</strong></span></th>
        <td>&nbsp;</td>
      </tr>
      <tr style="height: 47.375px;">
        <td style="border-width: 1px; height: 47.375px; border-color: rgb(245, 242, 239);">
          <div style="margin-left: 16px; margin-right: 1px;">
            <img src="https://firebasestorage.googleapis.com/v0/b/fitmate-a14a4.appspot.com/o/fitmate_recipe_images_and_svg%2FFitmate%20facts%20icon.svg?alt=media&token=18e77fa5-cc41-4511-b988-335260d765ad" width="40" height="41" />
          </div>
        </td>
        <th style="border-width: 1px; height: 47.375px; border-color: rgb(245, 242, 239); text-align: left;" scope="row">
          <span style="color: rgb(72, 47, 32); font-size: 15pt;">
            <strong>Fitmate facts</strong>
          </span>
        </th>
        <td style="height: 47.375px;">&nbsp;</td>
      </tr>
    </tbody>
  </table>
  <div style="margin-left: 16px;">
    <ul>
      <li style="margin-bottom: 24px; line-height: 1.3; color: rgb(72, 47, 32); font-size: 15px;"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
      <li style="margin-bottom: 24px; line-height: 1.3; color: rgb(72, 47, 32); font-size: 15px;"><span style="font-size: 15px; color: rgb(72, 47, 32);">XXXX</span></li>
    </ul>
  </div>
</div>
`;
