import React, { useState } from 'react';
import { ArrowRight } from '@mui/icons-material';
import {
  ActionTitleIconBlock,
  CategoryName,
  StyledAccordionSummary,
  StyledArrow,
} from '../ResourcesCategory/CommonStyles';
import { ISummaryHover } from 'types';
import MenuButtons from '../ResourcesCategoryMenuButtons';

const SummaryHover = ({
  categoryName,
  addCategoryClickHandler,
  addTagClickHandler,
  renameClickHandler,
  deleteClickHandler,
  isSelected = false,
  level = 0,
}: ISummaryHover) => {
  const [isButtonsVisible, setButtonsVisible] = useState<boolean>(false);

  const chooseItem = (handler: () => void) => {
    setButtonsVisible(false);
    handler();
  };

  const toggleVisibilityOn = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setButtonsVisible(true);
  };

  const toggleVisibilityOff = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setButtonsVisible(false);
  };

  return (
    <StyledAccordionSummary
      onMouseEnter={toggleVisibilityOn}
      onMouseLeave={toggleVisibilityOff}
      expandIcon={<StyledArrow as={ArrowRight} />}
      style={{ paddingLeft: 16 * level }}
    >
      <CategoryName isSelected={isSelected}>{categoryName}</CategoryName>
      {isButtonsVisible && (
        <ActionTitleIconBlock>
          <MenuButtons
            categoryName={categoryName}
            addCategoryClickHandler={() => chooseItem(addCategoryClickHandler)}
            addTagClickHandler={() => chooseItem(addTagClickHandler)}
            renameClickHandler={() => chooseItem(renameClickHandler)}
            deleteClickHandler={() => chooseItem(deleteClickHandler)}
          />
        </ActionTitleIconBlock>
      )}
    </StyledAccordionSummary>
  );
};

export default SummaryHover;
