import cn from 'mcn';
import { useEffect, useRef } from 'react';

export const Menu = ({
  children,
  className,
  open,
  onClose,
  horizontal = 'left',
  ...props
}: JSX.IntrinsicElements['ul'] & {
  open: boolean;
  onClose?: () => void;
  horizontal?: 'left' | 'right';
}) => {
  const style =
    'bg-white rounded-lg border border-separate [box-shadow:_0px_16px_30px_-10px_rgba(0,_0,_0,_0.15)]';
  const position = cn('absolute top-0', {
    'left-0': horizontal === 'left',
    'right-0': horizontal === 'right',
  });
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!open) return;
    const handler = () => {
      if (onClose) onClose();
    };
    window.document.body.addEventListener('click', handler);
    return () => window.document.body.removeEventListener('click', handler);
  }, [open]);

  return (
    <ul
      {...props}
      className={cn('w-[16.6rem] z-10', style, position, className, [open, 'block', 'hidden'])}
      ref={ref}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </ul>
  );
};

export const MenuItem = ({ children, className, onClick }: JSX.IntrinsicElements['li']) => {
  return (
    <li
      className={cn(className, 'px-5 py-3 text-dark text-sm text-semibold', [
        !!onClick,
        'hover:bg-dark/5 cursor-pointer',
      ])}
      role={onClick ? 'button' : 'listitem'}
      onClick={onClick}
    >
      {children}
    </li>
  );
};
