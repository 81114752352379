import { useEffect, useState } from 'react';
import ChatProgramSmartGoalCard from '../ChatProgramSmartGoalCard';
import { IProgramAction, TFocusArea } from 'types';
import { useAppSelector } from 'hooks';

export default function ChatProgramSmartGoalList(props: { area: TFocusArea; disabled?: boolean }) {
  const { programActions } = useAppSelector((state) => state.programActions);
  const { currentWeekPlan } = useAppSelector((state) => state.weekProgram);
  const [actions, setActions] = useState<IProgramAction[]>();

  useEffect(() => {
    if (programActions) {
      setActions(
        programActions.filter((action: IProgramAction) => action.planId === currentWeekPlan.id),
      );
    }
  }, [programActions, currentWeekPlan]);

  return (
    <>
      {actions?.map((action) => {
        if (action.planAreaId !== props.area.id) return null;
        if (action.id)
          return (
            <ChatProgramSmartGoalCard
              key={action.id}
              area={props.area}
              disabled={props.disabled}
              action={action}
            />
          );
      })}
    </>
  );
}
