import { useEffect, useState } from 'react';
import { Checkbox, MenuItem, Typography } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import { sort } from 'config';
import {
  ClientsFilter,
  ClientsSort,
  Count,
  FilterOptionText,
  FiltersCountWrapper,
  Icons,
  ItemHeader,
  ListHeader,
  MenuFilter,
  MenuSort,
  Reset,
  RightSideWrapper,
  StyledMenuItem,
} from './styles';
import { IChatAllClientsListHeaderManager, IFilter, ISort } from 'types';

const ChatAllClientsListHeader = ({
  usersSize,
  sortValue,
  setSortValue,
  filtersValues,
  setFiltersValues,
  isOpenAllClients,
}: IChatAllClientsListHeaderManager) => {
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);
  const openFilter = Boolean(filterAnchorEl);
  const openSort = Boolean(sortAnchorEl);

  useEffect(() => {
    setActiveFiltersCount(filtersValues.filter((item) => item.checked).length);
  }, [filtersValues]);

  useEffect(() => {
    resetFilters();
  }, [isOpenAllClients]);

  const handleChangeSort = (item: ISort) => {
    setSortValue(item);
    setSortAnchorEl(null);
  };

  const handleChangeFilter = (indexNumber: number) => {
    setFiltersValues((prev: IFilter[]) =>
      prev.map((item, index) => {
        if (indexNumber === index) {
          item.checked = !item.checked;
        }
        return item;
      }),
    );
  };
  // TODO: rewrite on set default value
  const resetFilters = () => {
    setFiltersValues((prev: IFilter[]) =>
      prev.map((item) => {
        item.checked = false;
        return item;
      }),
    );
  };

  return (
    <ListHeader>
      <Typography>{usersSize} clients</Typography>
      <RightSideWrapper>
        <ClientsSort onClick={(e) => setSortAnchorEl(e.currentTarget)}>
          <Icons as={SwapVertIcon} />
          <Typography>Sort: {sortValue.text}</Typography>
        </ClientsSort>
        <MenuSort
          open={openSort}
          onClose={() => setSortAnchorEl(null)}
          anchorEl={sortAnchorEl}
          style={{ marginTop: 50, marginLeft: 15 }}
        >
          {sort.map((item: ISort) => (
            <MenuItem key={item.text} onClick={() => handleChangeSort(item)}>
              {item.text}
            </MenuItem>
          ))}
        </MenuSort>
        <ClientsFilter onClick={(e) => setFilterAnchorEl(e.currentTarget)}>
          <Icons as={FilterListIcon} />
          <Typography>Filter</Typography>
        </ClientsFilter>
        <MenuFilter
          open={openFilter}
          onClose={() => setFilterAnchorEl(null)}
          anchorEl={filterAnchorEl}
          style={{ marginTop: 50, marginLeft: -25 }}
        >
          <ItemHeader disableRipple={true}>
            <Typography>User type</Typography>
          </ItemHeader>
          {filtersValues.map((item: IFilter, index) => (
            <StyledMenuItem
              key={item.text}
              isChecked={item.checked}
              onClick={() => handleChangeFilter(index)}
            >
              <Checkbox color="primary" size="small" checked={item.checked} />
              <FilterOptionText>{item.text}</FilterOptionText>
            </StyledMenuItem>
          ))}
        </MenuFilter>
        {activeFiltersCount > 0 && (
          <FiltersCountWrapper>
            <Count>
              <Typography>{activeFiltersCount}</Typography>
            </Count>
            <Reset onClick={() => resetFilters()}>Reset</Reset>
          </FiltersCountWrapper>
        )}
      </RightSideWrapper>
    </ListHeader>
  );
};

export default ChatAllClientsListHeader;
