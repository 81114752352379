import { styled } from '@mui/system';
import Input from '../../../../../../../common/core/Input';

export const LinerScale = styled('div')({
  marginTop: '19px',
  '& p': {
    display: 'inline',
    marginRight: '26px',
  },
  '& .MuiInput-underline:before': {
    border: 'none',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    paddingRight: '30px',
  },
});

export const InputStyled = styled(Input)({
  height: 'auto',
  borderRadius: '8px',
  '& .MuiInputBase-input': {
    height: '27px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});
