import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../utils/firebase/firebaseInit';
import { doc, updateDoc, deleteField } from 'firebase/firestore';
import { getDoc, onSnapshot } from '@firebase/firestore';

export interface CustomerState {
  canWatch: boolean;
  coaches?: string[];
  shiftStarted: boolean;
}

const initialState: CustomerState = {
  canWatch: false,
  shiftStarted: false,
};

const getWatchUserRef = () => doc(db, 'watcher', 'coaches');

export const getShiftStatusByCoachId = createAsyncThunk(
  'watcher/getShiftStatusByCoachId',
  async (arg: { coachId: string }) => {
    const docSnap = await getDoc(doc(db, 'users', arg.coachId));
    const data = docSnap.data();
    return data?.shiftStarted ?? false;
  },
);

export const updateShiftStatusByCoachId = createAsyncThunk(
  'watcher/updateShiftStatusByCoachId',
  async (arg: { coachId: string; shiftStarted: boolean }) => {
    await updateDoc(doc(db, 'users', arg.coachId), {
      shiftStarted: arg.shiftStarted,
    });
    return arg.shiftStarted;
  },
);

export const getCoachWatchingCustomer = createAsyncThunk(
  'watcher/verifyCoachWatchingCustomer',
  async (arg, APIThunk) => {
    const { dispatch } = APIThunk;
    const unsub = onSnapshot(getWatchUserRef(), (doc) => {
      const coaches = doc.data();
      dispatch(saveCoaches(coaches));
    });

    return () => unsub();
  },
);

export const setCoachWatchingCustomer = createAsyncThunk(
  'watcher/setCoachWatchingCustomer',
  async (arg: { userId: string; coachId: string }, APIThunk) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { shiftStarted } = APIThunk.getState().watcher;
    try {
      if (shiftStarted && arg.coachId) {
        await updateDoc(getWatchUserRef(), {
          [arg.coachId]: arg.userId,
        });
      }
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const releaseCoachWatchingCustomer = createAsyncThunk(
  'watcher/releaseCoachWatchingCustomer',
  async (arg: { coachId: string }) => {
    await updateDoc(getWatchUserRef(), {
      [arg.coachId]: deleteField(),
    });
  },
);

export const watcherSlice = createSlice({
  name: 'watcher',
  initialState,
  reducers: {
    saveCoaches: (state, action) => {
      state.coaches = action.payload;
    },
    disableWatch: (state) => {
      state.canWatch = false;
    },
    enableWatch: (state) => {
      state.canWatch = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShiftStatusByCoachId.fulfilled, (state, action) => {
      state.shiftStarted = action.payload;
    });
    builder.addCase(updateShiftStatusByCoachId.fulfilled, (state, action) => {
      state.shiftStarted = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { saveCoaches, disableWatch, enableWatch } = watcherSlice.actions;

export default watcherSlice.reducer;
