import { useCallback, useEffect, useRef, useState } from 'react';

export const useCopilotIframe = () => {
  const [frame, setFrame] = useState<Window>();
  const ref = useRef<HTMLIFrameElement>(null);

  const send = useCallback(
    (type: string, payload: any) => frame?.postMessage({ type, payload }, '*'),
    [frame],
  );

  // Wait for iframe content to load
  useEffect(() => {
    if (!ref.current) return;
    iframeLoaded(ref.current).then(setFrame);
  }, []);

  const onMessage = (cb: (ev: { type: string; payload: any }) => void) => {
    const handler = (event: MessageEvent) => {
      try {
        const ev = event.data;

        if (typeof ev?.type !== 'string') return;
        cb(ev);
      } catch (error) {
        console.log(error);
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  };

  return { ref, frame, onMessage, send };
};

const wait = (n: number) => new Promise<void>((resolve) => setTimeout(() => resolve(), n));
const iframeLoaded = (iframe: HTMLIFrameElement, acc = 0): Promise<Window> => {
  if (acc > 300) return Promise.reject('Failed to load iframe in 30 seconds');
  try {
    const doc = iframe?.contentWindow?.document;
    if (doc?.readyState === 'complete')
      return wait(1000).then(() => iframe.contentWindow as Window);
    return wait(100).then(() => iframeLoaded(iframe, acc + 1));
  } catch (e) {
    return wait(100).then(() => iframeLoaded(iframe, acc + 1));
  }
};
