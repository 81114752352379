import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: 56,
  backgroundColor: '#F2F4F7',
});

export const LeftBlock = styled('div')({
  paddingLeft: 20,
});

export const RightBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingRight: 20,
});

export const TitleText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  color: '#000',
});

export const Switch = styled('div')({
  margin: 0,
});

export const EndText = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  color: 'rgba(15, 15, 33, 0.2)',
});

export const StartText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.secondary.main, // replace with your theme's secondary color
}));
