import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import InputFormField from 'common/core/FormElements/InputFormField';
import SelectFormField from 'common/core/FormElements/SelectFormField';
import { checkIfFeature, getTodayInShortFormat } from 'utils/format/datetime';
import { convertCmToInches, convertInchesToCm } from 'utils/format/measures';
import { YesNoList } from 'utils/data/question';
import { getSelectedList } from '../helper';
import { EFeatureSelectedType, IOnboardingInfo } from 'types';
import Wrapper from '../Wrapper';
import {
  EFoodCravingsType,
  EPhysicalActivityType,
  EProteinsBreakfastType,
  EProteinsLunchesType,
  ESimpleCarbsType,
  EStressLevelType,
} from '@fitmate-coach/fitmate-types';
import {
  DatePickerBlock,
  DatePickerError,
  StyledDatePicker,
  StyledInputAdornment,
} from '../styles';

const OnboardingInfo = ({ isInches, setIsInches }: IOnboardingInfo) => {
  const { control, getValues, setValue } = useFormContext();

  const stressList = getSelectedList(EStressLevelType);
  const physicalActivityList = getSelectedList(EPhysicalActivityType);
  const proteinsBreakfastList = getSelectedList(EProteinsBreakfastType);
  const proteinsLunchesList = getSelectedList(EProteinsLunchesType);
  const simpleCarbsList = getSelectedList(ESimpleCarbsType);
  const foodCravingsList = getSelectedList(EFoodCravingsType);

  const handleIsInches = () => {
    const oldTypeHeight = getValues('height');
    if (oldTypeHeight) {
      const newTypeWeight = isInches
        ? convertInchesToCm(oldTypeHeight)
        : convertCmToInches(oldTypeHeight);
      setValue('height', newTypeWeight);
    }
    setIsInches(!isInches);
  };

  const pregnancyStatus = useWatch({
    name: 'pregnancy',
    control: control,
    defaultValue: getValues('pregnancy'),
  });

  return (
    <Wrapper blockTitle="Onboarding info">
      <Controller
        name="dateOfBirth"
        control={control}
        rules={{
          validate: {
            isValidDate: (date) => !checkIfFeature(date) || 'Date of birth cannot be in the future',
          },
        }}
        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
          <DatePickerBlock invalid={invalid}>
            <StyledDatePicker
              error={invalid}
              label="Date of Birth"
              value={value}
              onChange={onChange}
              fullWidth
              inputProps={{ inputProps: { max: getTodayInShortFormat() } }}
            />
            <DatePickerError text={error?.message ?? ''} opacityColor />
          </DatePickerBlock>
        )}
      />
      <InputFormField
        name="height"
        label="Height"
        control={control}
        rules={{
          min: isInches
            ? {
                value: 50,
                message: 'Height must be greater than 50 inches',
              }
            : {
                value: 127,
                message: 'Height must be greater than 127 cm',
              },
        }}
        inputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledInputAdornment onClick={handleIsInches}>
                {isInches ? 'in' : 'cm'}
              </StyledInputAdornment>
            </InputAdornment>
          ),
        }}
      />
      <SelectFormField
        name="pregnancy"
        label="Pregnancy status"
        control={control}
        optionList={YesNoList}
        value={
          pregnancyStatus === false
            ? EFeatureSelectedType.disabled
            : pregnancyStatus
            ? EFeatureSelectedType.enabled
            : ''
        }
        handleChange={({ target }) =>
          setValue(
            'pregnancy',
            target.value === EFeatureSelectedType.disabled
              ? false
              : target.value === EFeatureSelectedType.enabled
              ? true
              : '',
          )
        }
      />
      <SelectFormField
        name="stressLevel"
        label="Stress levels"
        control={control}
        optionList={stressList}
        handleChange={({ target }) =>
          setValue('stressLevel', target.value ? Number(target.value) : '')
        }
      />
      <SelectFormField
        name="physicalActivity"
        label="Physical activity (hrs / week)"
        control={control}
        optionList={physicalActivityList}
        handleChange={({ target }) =>
          setValue('physicalActivity', target.value ? Number(target.value) : '')
        }
      />
      <SelectFormField
        name="proteinsBreakfast"
        label="Lean protein at breakfast"
        control={control}
        optionList={proteinsBreakfastList}
        handleChange={({ target }) =>
          setValue('proteinsBreakfast', target.value ? Number(target.value) : '')
        }
      />
      <SelectFormField
        name="proteinsLunches"
        label="Lean protein (lunch & dinner)"
        control={control}
        optionList={proteinsLunchesList}
        handleChange={({ target }) =>
          setValue('proteinsLunches', target.value ? Number(target.value) : '')
        }
      />
      <SelectFormField
        name="simpleCarbs"
        label="Simple carbs (lunch & dinner)"
        control={control}
        optionList={simpleCarbsList}
        handleChange={({ target }) =>
          setValue('simpleCarbs', target.value ? Number(target.value) : '')
        }
      />
      <SelectFormField
        name="foodCravings"
        label="Food cravings"
        control={control}
        optionList={foodCravingsList}
        handleChange={({ target }) =>
          setValue('foodCravings', target.value ? Number(target.value) : '')
        }
      />
    </Wrapper>
  );
};

export default OnboardingInfo;
