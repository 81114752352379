import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openedDrawer: false,
};

export const coPilotSlice = createSlice({
  name: 'coPilot',
  initialState,
  reducers: {
    setDrawerOpen: (state, action) => {
      state.openedDrawer = action.payload;
    },
  },
});

export const { setDrawerOpen } = coPilotSlice.actions;

export default coPilotSlice.reducer;
