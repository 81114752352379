import axios from 'axios';

const BITLY_API_TOKEN = process.env.REACT_APP_BITLY_API_TOKEN;

export const generateBitlyLink = async (url: string) => {
  const res = await axios({
    method: 'POST',
    url: 'https://api-ssl.bitly.com/v4/shorten',
    data: {
      group_guid: process.env.REACT_APP_BITLY_GROUPID,
      domain: 'bit.ly',
      long_url: url,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${BITLY_API_TOKEN}`,
    },
  });

  return res.data;
};
