import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  ButtonBlock,
  Container,
  ForgotBlock,
  ForgotText,
  InputBlock,
  LoginBlock,
  LoginText,
  Logo,
  LogoBlock,
  StyledButton,
  StyledInput,
  Wrapper,
} from './styles';
import logoImage from 'assets/icons/logo.svg';
import { auth } from 'utils/firebase/firebaseInit';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getCoachData } from 'store/coaches/coachAuthSlice';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'sonner';

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (coachData && coachData.role === 'coach') {
      navigate('/coach-dashboard');
    } else if (coachData && coachData.role === 'admin') {
      navigate('/teams');
    }
  }, [coachData]);

  const toggleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(e.target.value ? '' : 'Please fill email');
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(e.target.value ? '' : 'Please fill password');
  };

  const handleSubmit = async () => {
    setLoading(true);
    setEmailError(email ? '' : 'Please fill email');
    setPasswordError(password ? '' : 'Please fill password');

    if (email && password) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          if (userCredential.user?.uid) {
            dispatch(getCoachData({ uid: userCredential.user?.uid }));
            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error(error.code);
          setLoading(false);
        });
    }
  };

  return (
    <Wrapper>
      <Container>
        <LogoBlock>
          <Logo src={logoImage} alt="" />
        </LogoBlock>
        <LoginBlock>
          <LoginText>Log in</LoginText>
        </LoginBlock>
        <InputBlock>
          <StyledInput
            error={!!emailError}
            onChange={handleChangeEmail}
            value={email}
            label="Email"
            helperText={emailError}
            type="text"
            fullWidth
            inputProps={{
              'data-testid': 'input-email',
            }}
          />
        </InputBlock>
        <InputBlock>
          <StyledInput
            error={!!passwordError}
            onChange={handleChangePassword}
            value={password}
            label="Password"
            helperText={passwordError}
            type={isShowPassword ? 'text' : 'password'}
            fullWidth
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    edge="end"
                    size="large"
                  >
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              'data-testid': 'input-password',
            }}
          />
        </InputBlock>
        <ButtonBlock>
          <StyledButton
            disabled={!email || !password || loading}
            onClick={handleSubmit}
            fullWidth={true}
          >
            {loading && <CircularProgress />}
            {!loading && 'Login'}
          </StyledButton>
        </ButtonBlock>
        <ForgotBlock>
          <ForgotText onClick={() => navigate('/forgotpassword')}>Forgot password?</ForgotText>
        </ForgotBlock>
      </Container>
    </Wrapper>
  );
};

export default LoginForm;
