import { v4 } from 'uuid';

const Help = (props: { column: string }) => {
  const content = {
    priority: [
      'These are the priority areas for the program, in the order that the client will tackle them. Each program week, you and your client will decide on which ones to focus on and set your specific goals for the week.',
      'Note: when setting new specific goals for the week, aim to have a mix of nutrition and physical activity goals rather than all nutrition or all activity, unless the client has specifically requested this.',
    ],
    obstacle: [
      'These are obstacles the client faces. If they prevent the client from achieving their specific goals for the week, you should move the relevant obstacles into the Routines to improve section and set specific goals around them.',
      'For example, if sleep is preventing a user from hitting an existing specific goal of eating protein rich breakfasts, then you should move the sleep focus areas high up in the priority order within the Routines to improve section and set a specific goal around it for this week or next week.',
    ],
    existing: [
      'This shows focus areas that have become habits and the client doesn’t need to actively track them.',
      'If the focus area contains active specific goals, they need to have their status set to completed, to be allowed to sit in this section.',
      'If the client highlights that something is no longer a habit, you can move it to the Routines to improve section and set an appropriate specific goal for it.',
    ],
  }[props.column];
  return (
    <>
      <h4>How this works</h4>
      {content?.map((x) => (
        <p key={v4()}>{x}</p>
      ))}
    </>
  );
};

export default Help;
