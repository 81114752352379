export const Warning = ({ children, className, ...props }: JSX.IntrinsicElements['div']) => (
  <div
    {...props}
    className={'p-4 text-sm font-medium flex items-center gap-2 ' + (className ?? '')}
  >
    <WarningRoundedOutlined />
    <span>{children}</span>
  </div>
);

const WarningRoundedOutlined = (p: JSX.IntrinsicElements['svg']) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...p}
  >
    <path
      d="M12.0001 5.98969L19.5301 18.9997H4.47012L12.0001 5.98969ZM2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997ZM11.0001 10.9997V12.9997C11.0001 13.5497 11.4501 13.9997 12.0001 13.9997C12.5501 13.9997 13.0001 13.5497 13.0001 12.9997V10.9997C13.0001 10.4497 12.5501 9.99969 12.0001 9.99969C11.4501 9.99969 11.0001 10.4497 11.0001 10.9997ZM11.0001 15.9997H13.0001V17.9997H11.0001V15.9997Z"
      fill="#0F0F21"
    />
  </svg>
);
