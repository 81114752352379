import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { getSurveyData } from 'store/savvy/survey';
import TextField from '@mui/material/TextField';
import isArray from 'lodash/isArray';

const ChatSavvyData = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { survey, loading } = useAppSelector((state) => state.savvySurvey);
  const [search, setSearch] = useState('');
  const [filteredSurvey, setFilteredSurvey] = useState<any>(null);

  useEffect(() => {
    if (customer?.email) {
      dispatch(
        getSurveyData({
          email: customer.email,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (survey) {
      setFilteredSurvey(Object.keys(survey));
    }
  }, [survey]);

  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value.length > 0) {
      const regex = new RegExp(e.target.value, 'i');
      const filtered = Object.keys(survey).filter((key) => {
        if (key.match(regex)) {
          return survey[key];
        }
      });
      setFilteredSurvey(filtered);
    } else {
      setFilteredSurvey(survey);
    }
  };

  if (!customer || loading || !survey) return <CircularProgress />;

  return (
    <div className="h-full w-full flex flex-col">
      <TextField
        disabled={Object.keys(survey).length === 0}
        placeholder="Filter by field"
        value={search}
        fullWidth
        onChange={handleSearchField}
        className="p-2"
      />
      <TableContainer sx={{ maxHeight: '500px', overflow: 'auto' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Field</b>
              </TableCell>
              <TableCell>
                <b>Value</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(survey).length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No savvy data found</TableCell>
              </TableRow>
            )}
            {filteredSurvey &&
              isArray(filteredSurvey) &&
              filteredSurvey.map((key: string) => {
                switch (typeof survey[key]) {
                  case 'object':
                    return (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{String(survey[key])}</TableCell>
                      </TableRow>
                    );
                  case 'string':
                    return (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{survey[key]}</TableCell>
                      </TableRow>
                    );
                  default:
                    return null;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ChatSavvyData;
