import { styled } from '@mui/system';
import { Grid, Typography } from '@mui/material';

export const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 10,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 10,
}));

export const TitleBlock = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: '#000',
}));

export const SubtitleText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: '#9F9FA6',
}));

export const WeightText = styled(Typography)(({ theme }) => ({
  display: 'inline',
  fontSize: 14,
  fontWeight: 400,
  color: '#000',
}));
