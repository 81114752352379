import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Banner = styled('div')(() => ({
  width: '100%',
  height: '80px',
  backgroundColor: '#FFB6B6',
  opacity: 0.8,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  zIndex: 1000,
  cursor: 'pointer',
}));

export const BannerText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
}));

export const Icon = styled('span')({
  marginRight: '11px',
});
