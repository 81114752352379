/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { EditorWrapper } from './styles';
import { TINY_MCE_API_KEY } from 'config';
import { useFilesUpload } from 'hooks/useFilesUpload';
import { initTinyData } from './utils/initData';
import { oneLineTemplate, twoLineTemplate } from './templates/cutomTemplate';
import {
  degreesTypeList,
  fractionsTypeList,
  degreesButtonTitle,
  fractionsButtonTitle,
  oneLineButtonTitle,
  twoLineButtonTitle,
  IconList,
  customIconButtonTitle,
  customIconList,
} from './utils/const';
import { IListIcon, ITinyMce } from 'types';

const TinyMce = ({ htmlBodyUrl, setHtmlBody }: ITinyMce) => {
  const [value, setValue] = useState<string>('');
  const { uploadHtml } = useFilesUpload();

  useEffect(() => {
    if (htmlBodyUrl.length > 0) {
      uploadHtml(htmlBodyUrl).then((result) => {
        setValue(result);
      });
    }
  }, [htmlBodyUrl]);

  useEffect(() => {
    setHtmlBody(value);
  }, [value]);

  const handleHtmlBodyUrl = (newValue: string) => {
    setValue(newValue);
    setHtmlBody(newValue);
  };

  return (
    <EditorWrapper>
      <Editor
        apiKey={TINY_MCE_API_KEY}
        value={value}
        onEditorChange={(newValue, editor) => handleHtmlBodyUrl(newValue)}
        init={{
          ...initTinyData,
          setup: (editor) => {
            // Init custom icon
            IconList.forEach((value: IListIcon) =>
              editor.ui.registry.addIcon(value.name, value.svg),
            );

            // Table line template
            editor.ui.registry.addButton(oneLineButtonTitle, {
              icon: 'oneLineSvg',
              onAction: (_) => editor.insertContent(oneLineTemplate),
            });

            editor.ui.registry.addButton(twoLineButtonTitle, {
              icon: 'twoLineSvg',
              onAction: (_) => editor.insertContent(twoLineTemplate),
            });

            //Add degrees list
            editor.ui.registry.addSplitButton(degreesButtonTitle, {
              text: degreesTypeList[0].text,
              onAction: () => editor.insertContent(degreesTypeList[0].value),
              onItemAction: (api, value) => editor.insertContent(value),
              fetch: (callback) => callback(degreesTypeList as any),
            });

            //Add fractions list
            editor.ui.registry.addSplitButton(fractionsButtonTitle, {
              text: fractionsTypeList[0].text,
              onAction: () => editor.insertContent(fractionsTypeList[0].value),
              onItemAction: (api, value) => editor.insertContent(value),
              fetch: (callback) => {
                callback(fractionsTypeList as any);
              },
            });

            //Add custom icon list
            editor.ui.registry.addSplitButton(customIconButtonTitle, {
              text: '',
              icon: customIconList[0].icon,
              onAction: () => editor.insertContent(customIconList[0].value),
              onItemAction: (api, value) => editor.insertContent(value),
              fetch: (callback) => {
                callback(customIconList as any);
              },
            });
          },
        }}
      />
    </EditorWrapper>
  );
};

export default TinyMce;
