import { useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { EStatus, IChatSurveysHistory, ISurveyExistent } from 'types';
import ChatSurveysHistoryItem from '../ChatSurveysHistoryItem';

const ChatSurveysHistory = ({ surveysList, onboardingSurveys }: IChatSurveysHistory) => {
  const [surveyIdCurrent, setSurveyIdCurrent] = useState<string>('');

  const handleSurveyIdCurrent = (value: string) => {
    setSurveyIdCurrent(value);
  };

  return (
    <>
      <Card>
        <CardHeader title="History of surveys" />
        <CardContent>
          {surveysList
            .filter(
              (survey: ISurveyExistent) =>
                survey.status === EStatus.APPROVED || survey.status === EStatus.CANCELED,
            )
            .map((survey: ISurveyExistent) => (
              <ChatSurveysHistoryItem
                key={survey.id}
                survey={survey}
                surveyIdCurrent={surveyIdCurrent}
                handleSurveyIdCurrent={handleSurveyIdCurrent}
              />
            ))}
        </CardContent>
      </Card>
      <Card style={{ marginTop: 30 }}>
        <CardHeader title="History of onboarding survey" />
        <CardContent>
          {onboardingSurveys?.map((survey: ISurveyExistent) => (
            <ChatSurveysHistoryItem
              key={survey.id}
              survey={survey}
              surveyIdCurrent={surveyIdCurrent}
              handleSurveyIdCurrent={handleSurveyIdCurrent}
            />
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default ChatSurveysHistory;
