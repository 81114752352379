import { Theme } from '@mui/material';
import { Icon } from '@iconify/react';
import { CurrentTime } from 'common/core/Time';
import { Bottom, Container, Middle, Top } from './styles';

export const ChatClientCard = (props: {
  name: string;
  status: keyof Theme['status'];
  timezone?: string | undefined;
  hours?: string | number | undefined;
  night?: boolean;
  isLive: boolean;
  isEow?: boolean;
  isOnboarding?: boolean;
  haveUnreadMessages?: any;
  isEngagementMessage?: boolean;
}) => {
  const getIcon = () => {
    if (props.isEngagementMessage) {
      return 'mdi:alert';
    } else if (props.isEow) {
      return 'mdi:calendar-week';
    } else if (props.isOnboarding) {
      return 'mdi:handshake';
    } else {
      return 'mdi:wireless';
    }
  };

  return (
    <Container status={props.status}>
      <Top>
        {!props.haveUnreadMessages && (props.isLive || props.isEngagementMessage) ? (
          <div>
            <Icon icon={getIcon()} height={16} />
          </div>
        ) : (
          <div></div>
        )}
        <div>{props.hours}</div>
      </Top>
      <Middle>
        <div>
          {(props.isLive || props.isEngagementMessage) && props.haveUnreadMessages ? (
            <div>
              <Icon icon={getIcon()} height={27} />
            </div>
          ) : (
            <div>
              {props.name
                .split(' ')
                .slice(0, 2)
                .map((x) => x[0])}
            </div>
          )}
        </div>
      </Middle>
      <Bottom>
        <div>{props.timezone && <CurrentTime timezone={props.timezone} />}</div>
        {props.night && (
          <div>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.05 10C4.35 10 3.69383 9.86667 3.0815 9.6C2.46917 9.33333 1.93583 8.97283 1.4815 8.5185C1.02717 8.0645 0.666667 7.53117 0.4 6.9185C0.133333 6.30583 0 5.64967 0 4.95C0 3.73333 0.3875 2.66033 1.1625 1.731C1.9375 0.801667 2.925 0.224667 4.125 0C3.975 0.825 4.02083 1.63133 4.2625 2.419C4.50417 3.20667 4.92083 3.89617 5.5125 4.4875C6.10417 5.07917 6.79383 5.49583 7.5815 5.7375C8.36917 5.97917 9.17533 6.025 10 5.875C9.78333 7.075 9.20833 8.0625 8.275 8.8375C7.34167 9.6125 6.26667 10 5.05 10Z" />
            </svg>
          </div>
        )}
      </Bottom>
    </Container>
  );
};
