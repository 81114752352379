import { EStatus, ISurveyQuestionCoach, ESurveysQuestionTypes, IChatQuestionSurvey } from 'types';
import MultiTextComponent from './MultiTextComponent';
import Text from './TextComponent';
import Checkbox from './CheckboxComponent';
import Radio from './RadioComponent';
import Slider from './SliderComponent';

const Questions = ({
  indexStage,
  isEditable,
  questions,
  status,
  suerveyId,
  handleEditSurvey,
}: IChatQuestionSurvey) => {
  const isShowQuestion = (answer: any) => {
    return !(!answer && status === EStatus.COMPLETED);
  };

  return (
    <>
      {questions.map((question: ISurveyQuestionCoach) => (
        <div key={question.id}>
          {isShowQuestion(question.answer) &&
            [
              ESurveysQuestionTypes.DATE,
              ESurveysQuestionTypes.TEXT,
              ESurveysQuestionTypes.TEXT_WITH_UNITS,
            ].includes(question.type as any) && (
              <Text
                indexStage={indexStage}
                data={question}
                isEditable={isEditable}
                suerveyId={suerveyId}
                handleEditSurvey={handleEditSurvey}
              />
            )}
          {isShowQuestion(question.answer) && question.type === ESurveysQuestionTypes.CHECKBOX && (
            <Checkbox
              indexStage={indexStage}
              data={question}
              isEditable={isEditable}
              suerveyId={suerveyId}
              handleEditSurvey={handleEditSurvey}
            />
          )}
          {isShowQuestion(question.answer) && question.type === ESurveysQuestionTypes.RADIO && (
            <Radio
              indexStage={indexStage}
              data={question}
              isEditable={isEditable}
              suerveyId={suerveyId}
              handleEditSurvey={handleEditSurvey}
            />
          )}
          {isShowQuestion(question.answer) && question.type === ESurveysQuestionTypes.SLIDER && (
            <Slider
              indexStage={indexStage}
              data={question}
              isEditable={isEditable}
              suerveyId={suerveyId}
              handleEditSurvey={handleEditSurvey}
            />
          )}
          {isShowQuestion(question.answer) &&
            [
              ESurveysQuestionTypes.MULTI_TEXT,
              ESurveysQuestionTypes.MULTI_TEXT_WITH_UNITS,
            ].includes(question.type as any) && (
              <MultiTextComponent
                indexStage={indexStage}
                data={question}
                isEditable={isEditable}
                suerveyId={suerveyId}
                handleEditSurvey={handleEditSurvey}
              />
            )}
        </div>
      ))}
    </>
  );
};

export default Questions;
