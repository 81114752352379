import { Typography } from '@mui/material';
import { RadioButtonUncheckedRounded, CloseRounded, AddRounded } from '@mui/icons-material';
import NextQuestion from 'common/core/NextQuestion';
import { AddOption, Close, Icon, InputWrapper, StyledInput, Wrapper } from './styles';
import { IAnswerVariant, ISurveyRadioButton } from 'types';

const RadioButton = ({
  id,
  questionsList,
  optionHandler,
  addOptionHandler,
  deleteHandler,
  answerVariants,
  isDisabled,
}: ISurveyRadioButton) => {
  return (
    <>
      {answerVariants.map((variant: IAnswerVariant) => (
        <Wrapper key={variant.answerId}>
          <InputWrapper>
            <Icon as={RadioButtonUncheckedRounded} />
            <StyledInput
              value={variant.answer}
              name="question"
              type="text"
              onChange={(e) => {
                optionHandler(id, 'answer', e.target.value, variant.answerId);
                optionHandler(id, 'error', false, variant.answerId);
              }}
              error={variant.error}
              helperText={
                variant.error && variant.answer === '' ? 'This option can’t be empty' : ''
              }
              autoComplete="off"
              fullWidth
              disabled={isDisabled}
            />
            {!isDisabled && answerVariants.length > 2 && (
              <Close
                as={CloseRounded}
                onClick={() => {
                  deleteHandler(variant.answerId);
                }}
              />
            )}
          </InputWrapper>
          {variant.nextQuestionId && (
            <NextQuestion
              id={id}
              nextQuestionId={variant.nextQuestionId}
              blocks={questionsList}
              isRadio={true}
              isDisabled={true}
            />
          )}
        </Wrapper>
      ))}
      <AddOption
        isDisabled={isDisabled}
        onClick={() => {
          addOptionHandler(id);
        }}
      >
        <AddRounded /> <Typography>Add next option</Typography>
      </AddOption>
    </>
  );
};

export default RadioButton;
