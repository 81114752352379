import { useCallback } from 'react';
import { db, storage } from 'utils/firebase/firebaseInit';
import { useAppSelector } from '../index';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';

export const useChatFileDelete = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  const deleteFile = useCallback(
    async (url: string, messageId: string) => {
      const messageRef = doc(db, 'messages', messageId);
      const result = await getDoc(messageRef);
      const data = result.exists() ? result.data() : null;
      if (coachData?.id && data?.userIdFrom && data?.userId !== data?.userIdFrom) {
        const filesListNew = data.files.filter((file: any) => file.url !== url);
        if (!filesListNew?.length && !data?.references?.length && !data?.body) {
          await updateDoc(messageRef, {
            files: [],
            isDeleted: true,
          });
        } else {
          await updateDoc(messageRef, {
            files: filesListNew,
          });
        }
        if (!url.includes('amazonaws')) {
          const fileRef = ref(storage, url);
          await deleteObject(fileRef);
        }
      }
    },
    [coachData],
  );

  return { deleteFile };
};
