import { SnackbarProvider } from 'notistack';
import { IChildren } from 'types';
import ThemeResponsiveSnackbar from './ThemeResponsiveSnackbar';

const Alerts = ({ children }: IChildren) => {
  return (
    <SnackbarProvider
      Components={{
        default: ThemeResponsiveSnackbar,
        success: ThemeResponsiveSnackbar,
        warning: ThemeResponsiveSnackbar,
        info: ThemeResponsiveSnackbar,
        error: ThemeResponsiveSnackbar,
      }}
      maxSnack={3}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  );
};

export default Alerts;
