import { Container } from '@mui/material';
import { HeaderBlock, TitleBlock, TitleText } from './styles';
import { ICoaches } from 'types';
import CoachesAdd from '../CoachesAdd';
import CoachesList from '../CoachesList';

const Coaches = ({ coachesList, editCoach, editCoachActivity, createCoach }: ICoaches) => {
  return (
    <Container maxWidth="lg" style={{ marginTop: 10 }}>
      <HeaderBlock>
        <TitleBlock>
          <TitleText>Coaches</TitleText>
        </TitleBlock>
        <div>
          <CoachesAdd createCoach={createCoach} />
        </div>
      </HeaderBlock>
      <CoachesList
        coachesList={coachesList}
        editCoach={editCoach}
        editCoachActivity={editCoachActivity}
      />
    </Container>
  );
};

export default Coaches;
