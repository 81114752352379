import { styled } from '@mui/system';
import { Breadcrumbs, Typography } from '@mui/material';

export const LeftColumn = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '20px',
}));

export const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
}));

export const BreadcrumbSurvey = styled(Typography)(({ theme }) => ({
  color: '#8587E5',
  cursor: 'pointer',
}));
