import { COEFF_INCHES_TO_CM, COEFF_LBS_TO_KG } from 'config';

export const convertInchesToCm = (value: number): number => {
  return Number((value * COEFF_INCHES_TO_CM).toFixed(2));
};

export const convertCmToInches = (value: number): number => {
  return Number((value / COEFF_INCHES_TO_CM).toFixed(2));
};

export const convertLbToKg = (value: number): number => {
  return Number((value * COEFF_LBS_TO_KG).toFixed(2));
};

export const convertKgToLb = (value: number): number => {
  return Number((value / COEFF_LBS_TO_KG).toFixed(2));
};

export const convertInchesToCmAdvanced = (value: string): string => {
  if (value === '') return '';
  return (Number(value) * COEFF_INCHES_TO_CM).toFixed(2).toString();
};

export const convertCmToInchesAdvanced = (value: string): string => {
  if (value === '') return '';
  return (Number(value) / COEFF_INCHES_TO_CM).toFixed(2).toString();
};

export const convertLbToKgAdvanced = (value: string): string => {
  if (value === '') return '';
  return (Number(value) * COEFF_LBS_TO_KG).toFixed(2).toString();
};

export const convertKgToLbAdvanced = (value: string): string => {
  if (value === '') return '';
  return (Number(value) / COEFF_LBS_TO_KG).toFixed(2).toString();
};

export const convertInchesAdvanced = (inches: number): string => {
  const feetFromInches = Math.floor(inches / 12);
  const inchesRemainder = (inches % 12).toFixed(0);

  if (feetFromInches === 0) {
    return `${inchesRemainder}"`;
  }
  return `${feetFromInches}\\${inchesRemainder}"`;
};
