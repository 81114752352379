import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const BottomLineBlock = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#E4E7ED',
  marginTop: 5,
  marginBottom: 5,
});

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 60,
});

export const NameBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '28%',
});

export const EmailBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '25%',
});

export const PhoneBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '22%',
});

export const ShiftBlock = styled('div')({
  width: '20%',
});

export const ActionsBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '5%',
});

export const Text1 = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '24px',
  color: '#0F0F21',
});
