import { styled } from '@mui/system';
import { Autocomplete, Typography } from '@mui/material';
import Button from '../../../../../common/core/Button';

export const TitleBlock = styled('div')({
  marginBottom: 8,
});

export const DescriptionBlock = styled('div')({
  marginBottom: 16,
});

export const SelectTitleBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: 12,
});

export const SelectBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const CoachListTitleBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: 12,
});

export const CoachListBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: 4,
});

export const ButtonBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: 20,
});

export const TitleText = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  color: '#0F0F21',
});

export const DescriptionTitleText = styled(Typography)({
  fontSize: 14,
});

export const SelectTitleText = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
  color: '#000000',
});

export const StyledAutocomplete = styled(Autocomplete)({
  width: '100%',
  '& .MuiOutlinedInput-input': {
    padding: '1.5px 4px !important',
  },
  '& .MuiInputLabel-formControl': {
    top: '-8px',
  },
  '.MuiAutocomplete-popper': {
    backgroundColor: 'red',
    zIndex: 1300,
  },
});

export const AutocompleteNameText = styled('div')({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.87)',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 150,
});

export const AutocompleteShiftText = styled('div')({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
  marginLeft: 3,
});

export const CoachListTitleText = styled('div')({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
});

export const ButtonCancel = styled(Button)({
  fontSize: 14,
  fontWeight: 600,
  color: 'rgba(15, 15, 33, 0.6)',
  width: '47%',
  height: 44,
  borderRadius: 8,
});

export const ButtonSubmit = styled(Button)({
  fontSize: 14,
  fontWeight: 600,
  width: '47%',
  height: 44,
  borderRadius: 8,
});
