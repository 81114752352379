export const debouncePromise = <T extends (...args: any[]) => Promise<any>>(
  func: T,
  delay: number,
) => {
  let timerId: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>): Promise<ReturnType<T>> {
    clearTimeout(timerId);
    return new Promise<ReturnType<T>>((resolve) => {
      timerId = setTimeout(async () => {
        const result = await func.apply(this, args);
        resolve(result);
      }, delay);
    });
  };
};
