import { styled } from '@mui/system';

export const Icon = styled('div')(({ theme }) => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...theme.typography.body1,
  fontSize: '12px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  minWidth: '7px',
  padding: '0px 5px',
  height: '17px',
}));

export const Danger = styled(Icon)(({ theme }) => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  background: `rgb(${theme.status['danger']})`,
  color: 'white',
}));

export const Warn = styled(Icon)(({ theme }) => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  background: `rgb(${theme.status['warn']})`,
}));

export const Infos = styled(Icon)(({ theme }) => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  background: `rgb(${theme.status['active']})`,
}));
