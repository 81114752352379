import { IChatMessage, IFilesAttached } from 'types';
import { IActionTracking } from 'types';

export enum EMessageType {
  TEXT = 'text',
  FILE = '[file]',
  RESOURCE = '[resource]',
  SURVEY = '[survey]',
  SURVEY_SENT = '[survey sent]',
  SURVEY_DECLINED = '[survey declined]',
  SURVEY_COMPLETED = '[survey completed]',
  ACTION_TRACKING = '[action tracking]',
  DELETED = '[message deleted]',
}

export enum EMessageReferenceType {
  RESOURCE = 'resource',
  RESOURCE_CATEGORY = 'resource_category',
  HANDOUT = 'handout',
}

export interface IFocusAreaHandout {
  handoutId?: string;
}

export interface IMessageReference {
  type: EMessageReferenceType;
  referenceId: string;
}

export interface IMessageQuoted {
  id?: string;
  body?: string;
  files?: IFilesAttached[];
  isDeleted?: boolean;
  userId?: string;
  userIdFrom?: string;
  userNameFrom?: string;
  virtualUserIdFrom?: string;
  virtualUserNameFrom?: string;
  actionTracking: IActionTracking | undefined;
}

export interface IMessageEdited {
  message: IChatMessage;
}

export interface IMessageFileEdited {
  id: string;
  userNameFrom: string;
  file: IFilesAttached | null;
}

export enum EChatMessageAnalysisType {
  SETBACK = 'setback',
  QUESTION = 'question',
  IMPORTANT_INFO = 'important',
  CANCEL = 'cancel',
  DEFAULT = '',
}
