import { useState, useEffect, useCallback } from 'react';
import { useProgramHandouts } from 'hooks/program/useProgramHandouts';
import { db } from 'utils/firebase/firebaseInit';
import { IAction, IActionSubCategory, IHandout } from 'types';
import { collection, getDocs } from 'firebase/firestore';

export const useProgramActions = () => {
  const [subcategoriesList, setSubcategoriesList] = useState<IActionSubCategory[]>([]);
  const [actionsList, setActionsList] = useState<IAction[]>([]);
  const { handouts } = useProgramHandouts();

  const loadActionSubcategories = useCallback(
    async (handouts: any) => {
      if (handouts.length) {
        const querySnapshot = await getDocs(collection(db, 'actions_subcategories'));
        if (!querySnapshot.empty) {
          setSubcategoriesList(
            querySnapshot.docs.map((doc) => {
              const { category, name } = doc.data();
              return {
                id: doc.id,
                category,
                name,
              };
            }),
          );
        }
      }
    },
    [handouts],
  );

  const loadActions = useCallback(
    async (handouts: any) => {
      const querySnapshot = await getDocs(collection(db, 'actions'));
      if (!querySnapshot.empty) {
        setActionsList(
          querySnapshot.docs
            .filter((action) => action.data().isDeleted !== true)
            .map((doc) => {
              const {
                category,
                subcategoryId,
                title,
                description,
                isDeleted,
                handoutId,
                categoryId,
                habitType,
                sortingIndex,
              } = doc.data();
              const handoutTitle =
                handouts.find((handout: IHandout) => handout.id === handoutId)?.title ?? '';
              return {
                id: doc.id,
                category,
                subcategoryId,
                title,
                description,
                isDeleted: isDeleted ?? false,
                handoutId: handoutId ?? '',
                handoutTitle,
                categoryId,
                habitType,
                sortingIndex,
              };
            }),
        );
      }
    },
    [handouts],
  );

  useEffect(() => {
    loadActionSubcategories(handouts).then(() => {
      loadActions(handouts).then();
    });
  }, [handouts]);

  return {
    subcategoriesList,
    actionsList,
  };
};
