import { remapData } from './profileEditServices';

// Documentation
// https://www.elastic.co/guide/en/app-search/current/sort.html
let controller = new AbortController();

export const searchElasticUsers = (query: any) => {
  controller.abort();
  controller = new AbortController();
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_ELASTIC_APP_SEARCH_SEARCH_TOKEN}`,
  });
  const url = `https://${process.env.REACT_APP_ELASTIC_APP_SEARCH_URL}/api/as/v1/engines/${process.env.REACT_APP_ELASTIC_APP_SEARCH_USERS_INDEX}/search`;
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(query) as any,
    signal: controller.signal,
  })
    .then((search) => search.json())
    .then((search) => {
      return { facets: search.facets, results: remapData(search.results) };
    });
};

export const multiSearchElasticUsers = (query: any) => {
  controller = new AbortController();
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_ELASTIC_APP_SEARCH_SEARCH_TOKEN}`,
  });
  const url = `https://${process.env.REACT_APP_ELASTIC_APP_SEARCH_URL}/api/as/v1/engines/${process.env.REACT_APP_ELASTIC_APP_SEARCH_USERS_INDEX}/multi_search`;
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(query) as any,
    signal: controller.signal,
  })
    .then((search) => search.json())
    .then((search) => {
      let results: any = [];
      search.forEach((item: any) => {
        results = [...results, ...item.results];
      });

      return { facets: search[0].facets, results: remapData(results) };
    });
};
