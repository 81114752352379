import HandoutsHeader from './HandoutsHeader';
import HandoutsContent from './HandoutsContent';
import { AppWrapper } from './styles';

const HandoutsContainer = () => {
  return (
    <AppWrapper>
      <HandoutsHeader />
      <HandoutsContent />
    </AppWrapper>
  );
};

export default HandoutsContainer;
