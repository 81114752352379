import { db } from 'utils/firebase/firebaseInit';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { TFireBaseUser } from '@fitmate-coach/fitmate-types';

export const useGetUserByUid = (uid: string | undefined) => {
  const [user, setUser] = useState<TFireBaseUser | null>(null);
  useEffect(() => {
    if (!uid) {
      return;
    }

    const fetchUserByUid = async () => {
      const q = query(collection(db, 'users'), where('uid', '==', uid));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return;
      }
      setUser({ ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id });
    };

    fetchUserByUid();
  }, [uid]);

  if (!uid) {
    return null;
  }

  return user;
};
