import { styled } from '@mui/system';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { ArrowRight } from '@mui/icons-material';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#F9FAFC !important',
  '& .MuiCollapse-root .MuiAccordionSummary-root.Mui-expanded': {
    backgroundColor: 'transparent !important',
  },
  '& .MuiFormControlLabel-root': {
    margin: 0,
    paddingLeft: 30,
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#8587E5',
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 600,
      color: 'rgba(15, 15, 33, 0.6)',
    },
    '& .Mui-checked + .MuiTypography-body1': {
      color: '#8587E5',
    },
  },
  '& .MuiAccordionSummary-root ': {
    minHeight: 'revert',
    width: 200,
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '&.MuiPaper-root::before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionDetails-root': {
    border: 'none',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormControlLabel-root': {
      maxWidth: 200,
    },
  },
  '& .MuiAccordionSummary-root': {
    border: 'none',
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

export const RadioCategory = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  paddingLeft: '0 !important',
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#F9FAFC !important',
  padding: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.Mui-expanded': {
    marginLeft: 0,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    height: 20,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const SubCategoryList = styled('div')(({ theme }) => ({
  marginLeft: 0,
}));

export const ActionTitleIconBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

export const MenuIcon = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const SubMenu = styled('div')(({ theme }) => ({
  minWidth: 190,
}));

export const CategoryName = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontWeight: 600,
  fontSize: 16,
  color: 'rgba(15, 15, 33, 0.6)',
}));

export const CategoryNameBold = styled('div')(({ theme }) => ({
  display: 'flex',
  fontWeight: 600,
  fontSize: 16,
  color: 'black',
}));

export const Arrow = styled(ArrowRight)(({ theme }) => ({
  fontSize: '1.2rem',
}));
