import { useController } from 'react-hook-form';
import { FormControl, InputLabel } from '@mui/material';
import { SelectBlock, StyledSelect } from './styles';
import InputError from 'common/core/InputError';
import SelectOptionsWrapper from 'common/core/SelectOptionsWrapper';
import { ISelectFormField } from 'types';

const SelectFormField = ({
  name,
  control,
  label,
  optionList = [],
  value,
  defaultValue,
  useEmptyOption = true,
  rules,
  handleChange,
  inputProps,
  children,
  disabled = false,
}: ISelectFormField) => {
  const { field, formState } = useController({ control, name, rules, defaultValue });
  const error = formState?.errors?.[name]?.message?.toString() ?? '';

  // TODO: Double check the errors colors, especially for the label
  return (
    <SelectBlock>
      <FormControl variant="outlined">
        <InputLabel
          htmlFor="outlined-age-native-simple"
          sx={{
            color: error ? 'error.main' : 'inherit',
          }}
        >
          {label}
        </InputLabel>
        <StyledSelect
          // variant="standard"
          disabled={disabled}
          name={name}
          label={label}
          value={value ?? field.value}
          onChange={(event) => {
            handleChange ? handleChange(event) : field.onChange(event);
          }}
          inputProps={inputProps}
          error={!!error}
          sx={{
            '& .MuiSelect-iconOutlined': {
              color: error ? 'error.main' : 'inherit',
            },
          }}
          native
          autoWidth
        >
          {children ?? <SelectOptionsWrapper hasEmpty={useEmptyOption} items={optionList} />}
        </StyledSelect>
        <InputError text={error} opacityColor />
      </FormControl>
    </SelectBlock>
  );
};

export default SelectFormField;
