import { styled } from '@mui/system';
import MoodIcon from '@mui/icons-material/Mood';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 20,
  paddingRight: 20,
  backgroundColor: '#fff',
  borderRadius: 25,
}));

export const InputBlock = styled('div')<{ isInputMessageExpanded: boolean; parentHeight?: number }>(
  ({ isInputMessageExpanded, parentHeight }) => ({
    transition: 'all 0.15s ease-out',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    minHeight: 60,
    maxHeight: 100,
    overflowY: 'auto',

    // rewrite default editor styles
    '& .rdw-editor-wrapper': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
      paddingTop: '12px',
    },
    '& .rdw-editor-toolbar': {
      padding: '0',
      marginBottom: '0',
      border: 'none',
      '& .rdw-inline-wrapper': {
        marginLeft: '26px',
      },
      '& .rdw-option-wrapper': {
        border: 'none',
        opacity: '0.4',
      },
      '& .rdw-option-wrapper:hover': {
        boxShadow: 'none',
      },
      '& .rdw-option-active': {
        boxShadow: 'none',
        opacity: '1',
        '& img': {
          filter:
            'invert(52%) sepia(88%) saturate(724%) hue-rotate(206deg) brightness(100%) contrast(100%)',
        },
      },
    },
    '& .public-DraftStyleDefault-block': {
      margin: '0px',
      wordBreak: 'break-word',
    },
    '& .rdw-editor-main': {
      overflowX: 'hidden',
    },
    '& .DraftEditor-editorContainer': {
      maxWidth: '100%',
      minHeight: '52px',
    },
    '& .public-DraftEditorPlaceholder-root': {
      paddingTop: '0px',
    },
    ...(isInputMessageExpanded && {
      transition: 'all 0.15s ease-out',
      minHeight: 60,
      maxHeight: parentHeight && parentHeight <= 700 ? parentHeight - 15 : 700,
    }),
  }),
);

export const StyledEmojiIcon = styled(MoodIcon)(({ theme }) => ({
  position: 'absolute',
  bottom: 14,
  fontSize: 20,
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
}));
