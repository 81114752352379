import {
  ActionsBlock,
  Container,
  DateBlock,
  GenderBlock,
  LocationBlock,
  NameBlock,
  Text,
  TimezoneBlock,
} from './styles';

const ClientsListHeader = () => {
  return (
    <>
      <Container>
        <NameBlock>
          <Text>First name and Last name</Text>
        </NameBlock>
        <GenderBlock>
          <Text>Sex</Text>
        </GenderBlock>
        <LocationBlock>
          <Text>Location</Text>
        </LocationBlock>
        <TimezoneBlock>
          <Text>Timezone</Text>
        </TimezoneBlock>
        <DateBlock>
          <Text>Sign-up Date</Text>
        </DateBlock>
        <ActionsBlock />
      </Container>
    </>
  );
};

export default ClientsListHeader;
