import { Checkbox, Dialog, DialogContent } from '@mui/material';
import cn from 'mcn';
import { weekDaysOptions } from 'config';
import { EActionsProgramStatus, IProgramAction, TFocusArea } from 'types';
import ChatProgramSmartGoalForm from '../ChatProgramSmartGoalForm';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  addProgramAction,
  setProgramActionStatus,
  updateProgramAction,
} from 'store/program/programActionsSlice';
import { SmartGoalBadge } from '../ChatProgramSmartGoalBadge';
import { useEffect, useState } from 'react';
import LinearProgressWithLabel from '../../../../../../common/core/LinearProgress';

export default function ChatProgramSmartGoalCard(props: {
  action: IProgramAction;
  disabled?: boolean;
  area: TFocusArea;
}) {
  const [openGoalModal, setOpenGoalModal] = useState(false);
  const dispatch = useAppDispatch();
  const { customer, isReadOnly } = useAppSelector((state) => state.customerInfos);
  const { programActionStatus } = useAppSelector((state) => state.programActions);
  const userId = customer?.id;
  const { action } = props;

  useEffect(() => {
    if (action?.draft) {
      dispatch(setProgramActionStatus('draft'));
    } else if (action?.published) {
      dispatch(setProgramActionStatus(programActionStatus === 'draft' ? 'draft' : 'published'));
    }
  }, [action]);

  const updateAction = async (programActionInputs: any) => {
    if (programActionInputs && action?.id && action?.planId && userId) {
      dispatch(
        updateProgramAction({
          action: programActionInputs,
          actionId: action?.id,
          planId: action?.planId,
          userId,
        }),
      );
      dispatch(setProgramActionStatus('draft'));
    }
    setOpenGoalModal(false);
  };
  const addAction = async (programActionInputs: any) => {
    dispatch(addProgramAction(programActionInputs));
    setOpenGoalModal(false);
  };

  const handleCloseModal = () => {
    setOpenGoalModal(false);
  };

  function displayDayOfWeekWithStartIndex(
    firstDayWeekDaysOptionIndex: number,
    daysOfActions: number[] = [],
  ) {
    const index = weekDaysOptions.findIndex((day) => day.value === firstDayWeekDaysOptionIndex);
    const adjustedDays = [...weekDaysOptions.slice(index), ...weekDaysOptions.slice(0, index)];

    return adjustedDays.filter((day) => daysOfActions.includes(day.value));
  }

  const handleDisableAction = async () => {
    if (!props.disabled) {
      if (action.draft && action.planId && userId && action.id) {
        dispatch(
          updateProgramAction({
            actionId: action.id,
            action: {
              ...action,
              draft: {
                ...action.draft,
                isDeleted: !action.draft?.isDeleted,
              },
            },
            planId: action.planId,
            userId,
          }),
        );
      } else if (action.published && action.planId && userId && action.id) {
        dispatch(
          updateProgramAction({
            actionId: action.id,
            action: {
              ...action,
              draft: {
                ...action.published,
                isDeleted: !action.published?.isDeleted,
              },
            },
            planId: action.planId,
            userId,
          }),
        );
      }
    }
  };

  const actionData = action.draft ?? action.published;

  return (
    <div className="rounded-lg bg-white border border-[#CFCFD3] m-2 overflow-hidden [box-shadow:_0px_2px_6px_0px_rgba(0,2,23,0.08)]">
      <Dialog
        className="flex items-center justify-center p-8"
        onClose={() => handleCloseModal()}
        open={openGoalModal}
      >
        {openGoalModal && (
          <DialogContent>
            <ChatProgramSmartGoalForm
              onClose={handleCloseModal}
              action={action}
              plan={props.area}
              {...(props.disabled
                ? {}
                : {
                    onSave: async (programActionInputs) =>
                      action.id
                        ? updateAction(programActionInputs)
                        : addAction(programActionInputs),
                  })}
            />
          </DialogContent>
        )}
      </Dialog>
      <div className="px-2 grid [grid-template-columns:min-content_1fr_min-content] gap-2 py-3 rounded-lg">
        <div>
          <Checkbox
            className={cn('w-6 h-6', [
              !!props.disabled,
              '!text-[rgba(164,164,169,1)]',
              '!text-dark',
            ])}
            color="default"
            checked={action.draft ? !action.draft?.isDeleted : !action.published?.isDeleted}
            onChange={handleDisableAction}
          />
        </div>
        <div className="space-y-2">
          <h5 className="text-dark/80 font-semibold text-sm">{actionData?.goal}</h5>
          <p className="text-dark/60 text-sm">{actionData?.strategy}</p>
        </div>
        <div className="flex flex-col items-end space-y-2">
          {actionData?.status && !actionData?.isDeleted && (
            <SmartGoalBadge
              status={(actionData?.status as any) ?? (EActionsProgramStatus.PAST as any)}
            />
          )}

          {!actionData?.isDeleted && (
            <button
              disabled={isReadOnly}
              className="text-primary text-right border-b border-primary w-min text-[13px] font-semibold"
              onClick={() => setOpenGoalModal(true)}
            >
              Edit
            </button>
          )}
        </div>
      </div>
      <div className="bg-[rgba(249,250,252,1)] w-full flex flex-row py-2 px-2 text-dark font-semibold text-sm justify-between items-center">
        <span className="flex">Progress</span>
        <LinearProgressWithLabel
          progressValue={action?.lastTracking?.currentDay ?? 0}
          finishValue={actionData?.daysPerWeek ?? 0}
        />
      </div>
      {actionData?.firstDay && actionData.days && (
        <div className="bg-[rgba(249,250,252,1)] w-full flex flex-row py-2 px-2 text-dark font-semibold text-sm justify-between items-center">
          <span className="flex">When</span>
          <span>
            {displayDayOfWeekWithStartIndex(actionData.firstDay ?? 0, actionData.days)
              .map((s) => s.short)
              .join(', ')}
          </span>
        </div>
      )}
    </div>
  );
}
