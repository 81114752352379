import { styled } from '@mui/system';
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

export const Container = styled('div')<{ isErrored: boolean; isWarning: boolean }>(
  ({ theme, isErrored, isWarning }) => ({
    display: 'flex',
    position: 'fixed',
    top: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '44px',
    backgroundColor: 'rgba(15, 15, 33, 0.6)',
    color: theme.palette.common.white,
    zIndex: 1000,
    ...(isErrored && { backgroundColor: '#D90A3C!important' }),
    ...(isWarning && { backgroundColor: '#F5BD9A!important' }),
  }),
);

export const RadioWrapper = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

export const RadioActive = styled(RadioWrapper)(({ theme }) => ({
  color: theme.palette.primary.light,
}));

export const RadioLabel = styled(FormControlLabel)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    '& .MuiFormControlLabel-label': {
      fontWeight: 600,
      fontSize: '14px',
    },
    ...(isActive && RadioActive),
  }),
);

export const RadioStyle = styled(Radio)(({ theme }) => ({
  color: theme.palette.common.white,
  '&.Mui-checked': {
    color: theme.palette.primary.light,
  },
}));

export const TaskListWrapper = styled(FormControl)(({ theme }) => ({
  marginLeft: '20px',
  color: theme.palette.common.white,
  '& .MuiInput-underline:before': {
    display: 'none',
  },
  '& .MuiInput-underline:after': {
    display: 'none',
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  },
  '& .MuiSelect-selectMenu': {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: '14px',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    paddingRight: '30px',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.common.white,
  },
}));

export const TaskListItem = styled(MenuItem)({
  width: '100%',
  maxWidth: '553px',
  whiteSpace: 'break-spaces',
});

export const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  '& .MuiTypography-body1': {
    fontWeight: 600,
    fontSize: '14px',
  },
});

export const NameTaskWrapper = styled(Typography)({
  marginRight: '35px',
});

export const RightSide = styled('div')({
  position: 'relative',
});

export const StyledButton = styled(Button)({
  width: '50px',
  height: '30px',
  marginLeft: '82px',
  backgroundColor: `${'#EAEAFB'}!important`,
  borderRadius: '4px',
  fontWeight: 600,
  fontSize: '14px',
  color: 'rgba(15, 15, 33, 0.6)',
  textTransform: 'capitalize',
  '& .MuiButton-label': {
    height: '30px',
  },
  '&.MuiButton-text': {
    padding: '0px',
  },
  '&:hover': {
    borderRadius: '4px',
    color: 'rgba(15, 15, 33, 0.6)',
    backgroundColor: `${'#EAEAFB'}!important`,
  },
  // ...(isTimeStarted && StartButton),
  // ...(isInactivityWarning && InactivityWarningTimeButton)
});

export const StartButton = styled(Button)({
  backgroundColor: `${'#F5ED38'}!important`,
  '&:hover': {
    borderRadius: '4px',
    color: 'rgba(15, 15, 33, 0.6)',
    backgroundColor: `${'#F5ED38'}!important`,
  },
});

export const InactivityWarningTimeButton = styled(Button)(({ theme }) => ({
  color: '#D90A3C',
  background: `${theme.palette.common.white}!important`,
  '&:hover': {
    height: '30px',
    borderRadius: '4px',
    color: '#D90A3C',
    background: `${theme.palette.common.white}!important`,
  },
}));
