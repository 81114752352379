import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import { Drawer } from '@mui/material';

export const Root = styled('div')({
  flexGrow: 1,
});

export const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const Title = styled('div')({
  flexGrow: 1,
});

export const Logo = styled('img')({
  width: 75,
  cursor: 'pointer',
});

export const StyledDrawer = styled(Drawer)({
  width: 240,
  flexShrink: 0,
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const Wrapper = styled('div')({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});

export const Header = styled('div')({
  flexGrow: 0,
  flexShrink: 0,
  flexDirection: 'column',
});

export const Content = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
});
