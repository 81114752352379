import { Grid } from '@mui/material';
import { Container, SubtitleText, TitleBlock, TitleText, WeightText } from './styles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useAppDispatch, useAppSelector } from 'hooks';
import { toggleEditGoal, toggleEditStartBodyProfile } from 'store/customers/bodyProfileSlice';

const ChatProgramProfileHeader = () => {
  const dispatch = useAppDispatch();
  const { isReadOnly } = useAppSelector((state) => state.customerInfos);
  const { goals, profiles } = useAppSelector((state) => state.bodyProfile);

  const handleEditStartProfile = () => {
    dispatch(toggleEditStartBodyProfile());
  };

  const handleEditGoalProfile = () => {
    dispatch(toggleEditGoal());
  };

  return (
    <Container>
      <TitleBlock item xs={8}>
        <TitleText>Body profile</TitleText>
      </TitleBlock>
      <Grid item xs={2}>
        <SubtitleText as="div">
          Start weight (lbs):{' '}
          <WeightText>
            {(profiles && profiles.length > 0 && profiles[profiles.length - 1].weight) || '-'}
          </WeightText>
          <IconButton disabled={isReadOnly} onClick={handleEditStartProfile} size="large">
            <EditIcon />
          </IconButton>
        </SubtitleText>
      </Grid>
      <Grid item xs={2}>
        <SubtitleText as="div">
          Goal weight (lbs): <WeightText>{goals && goals.weight}</WeightText>
          <IconButton disabled={isReadOnly} onClick={handleEditGoalProfile} size="large">
            <EditIcon />
          </IconButton>
        </SubtitleText>
      </Grid>
    </Container>
  );
};

export default ChatProgramProfileHeader;
