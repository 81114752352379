import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@mui/material';
import { v4 } from 'uuid';
import Button from 'common/core/Button';
import Input from 'common/core/Input';
import { RESOURCE_FILES_DIR } from 'config';
import { useFilesUpload } from 'hooks/useFilesUpload';
import {
  Body,
  Container,
  MenuButtonList,
  MenuButtonListText,
  MenuWrapper,
  StyledMenu,
  TabItem,
  Tabs,
  UploadBlock,
} from './styles';
import { EResourceBlockTypes, IAttachment } from 'types';

const Attachment = ({ handleBlocksChange, handleAddAttachment }: IAttachment) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeTab, setActiveTab] = useState<string>('upload');
  const { uploadFiles, filesList, emptyFilesList } = useFilesUpload();
  const [additionalUrl, setAdditionalUrl] = useState<string>('');
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [linkValue, setValueLink] = useState<string>('');
  const fileRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);
  const linkRegExp = new RegExp(
    '^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$',
  );

  useEffect(() => {
    if (filesList !== null && filesList.length > 0) {
      setAdditionalUrl(filesList[0].url);
      handleAddAttachment();
      handleBlocksChange((prev) => {
        return [
          ...prev,
          {
            id: v4(),
            body: '',
            url: filesList[0].url,
            type: EResourceBlockTypes.PICTURE,
          },
        ];
      });
    }
  }, [filesList]);

  useEffect(() => {
    buttonRef.current?.click();
  }, [buttonRef]);

  const chooseFileInUploader = (e: any) => {
    emptyFilesList();
    setAnchorEl(null);
    uploadFiles([e.target.files[0]], RESOURCE_FILES_DIR);
    fileRef.current.value = '';
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickFileUploader = () => {
    fileRef.current.click();
  };

  const handleChangeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueLink((event.target as HTMLInputElement).value);
    if (event.target.value.match(linkRegExp)) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if ((event.target as HTMLInputElement).value.match(linkRegExp)) {
        setVideoUrl((event.target as HTMLInputElement).value);
        handleAddAttachment();
        handleBlocksChange((prev) => {
          return [
            ...prev,
            {
              body: '',
              url: (event.target as HTMLInputElement).value,
              type: EResourceBlockTypes.VIDEO,
            },
          ];
        });
      }
    }
  };
  return (
    <MenuWrapper>
      <UploadBlock style={additionalUrl || videoUrl ? { width: 'revert' } : { width: 520 }}>
        <div onClick={handleClick} ref={buttonRef} />
        <MenuButtonList
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="text"
          onClick={handleClick}
        >
          <MenuButtonListText>Upload file</MenuButtonListText>
        </MenuButtonList>
      </UploadBlock>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Container>
          <Tabs>
            <TabItem isActive={activeTab === 'upload'} onClick={() => setActiveTab('upload')}>
              <Typography>Upload</Typography>
            </TabItem>
            <TabItem isActive={activeTab === 'link'} onClick={() => setActiveTab('link')}>
              <Typography>Embed link</Typography>
            </TabItem>
          </Tabs>
          <Body>
            {activeTab === 'upload' ? (
              <>
                <Button onClick={handleClickFileUploader}>Choose file</Button>
                <input
                  type="file"
                  accept="image/*"
                  id="file"
                  ref={fileRef}
                  onChange={chooseFileInUploader}
                  style={{ display: 'none' }}
                />
              </>
            ) : (
              <Input
                onChange={handleChangeLink}
                onKeyDown={handleSend}
                error={linkValue === '' ? false : !error}
                value={linkValue}
                label="Add link"
              />
            )}
          </Body>
        </Container>
      </StyledMenu>
    </MenuWrapper>
  );
};

export default Attachment;
