import { styled } from '@mui/system';
import ImageUploader from '../../../../../common/core/ImageUploader';
import Input from '../../../../../common/core/Input';

export const FormContainer = styled('div')({
  width: '100%',
  overflowY: 'auto',
});

export const Form = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  maxWidth: 600,
});

export const AddTitle = styled(Input)({
  fontWeight: 600,
  fontSize: 24,
  color: 'black',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input::placeholder': {
    color: 'rgba(15, 15, 33, 0.2)',
  },
  '& .MuiOutlinedInput-input': {
    paddingRight: 0,
    paddingLeft: 0,
  },
});

export const Thumb = styled(ImageUploader)({
  display: 'flex',
  justifyContent: 'center',
  height: 332,
});

export const InputGroupWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  marginTop: 28,
});

export const InputGroup = styled('div')({
  display: 'flex',
  padding: '16px 24px',
  marginBottom: 28,
  backgroundColor: '#F9FAFC',
  borderRadius: 12,
  flexWrap: 'wrap',
  gap: 16,
});

export const Picture = styled('img')({
  width: '100%',
});
