import RefreshIcon from '@mui/icons-material/Refresh';
import loaderCircle from 'assets/icons/loader_circle.svg';
import {
  Container,
  EllipseWrapper,
  LoaderCircle,
  NoteBlock,
  NoteText,
  StyledRefreshIcon,
  TitleText,
} from './styles';

const ChatFAQLoader = () => {
  return (
    <Container>
      <div>
        <EllipseWrapper>
          <LoaderCircle src={loaderCircle} alt="" />
          <StyledRefreshIcon as={RefreshIcon} />
        </EllipseWrapper>
      </div>
      <div>
        <TitleText>Loading content</TitleText>
      </div>
      <NoteBlock>
        <NoteText align="center">Be patient, this may take some time</NoteText>
      </NoteBlock>
    </Container>
  );
};

export default ChatFAQLoader;
