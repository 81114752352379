import { styled } from '@mui/system';
import Button from '../../../../../../common/core/Button';
import { Menu, Typography } from '@mui/material';

export const MenuButtonList = styled(Button)({
  textTransform: 'revert',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
});

export const MenuButtonListText = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  color: 'rgba(15, 15, 33, 0.6)',
});

export const MenuWrapper = styled('div')({
  marginTop: 12,
});

export const Container = styled('div')({
  width: 380,
});

export const Tabs = styled('header')({
  display: 'flex',
  borderBottom: '1px solid #E4E7ED',
  '& .MuiTypography-root': {
    fontSize: 14,
  },
});

export const TabItem = styled('div')<{ isActive: boolean }>(({ isActive }) => ({
  padding: '7px 20px',
  cursor: 'pointer',
  ...(isActive && {
    borderBottom: '2px solid #8587E5',
    color: '#8587E5',
  }),
}));

export const Body = styled('main')({
  padding: 10,
  display: 'flex',
  justifyContent: 'center',
  '& .MuiFormControl-marginNormal': {
    marginTop: 0,
    marginBottom: 0,
    height: 'revert',
    width: '100%',
  },
  '& .MuiInputBase-input': {
    height: 'revert',
    fontSize: 14,
  },
});

export const UploadBlock = styled('div')({
  width: 520,
  padding: '6px 10px',
  '& .MuiButtonBase-root': {
    padding: 0,
    minWidth: 'revert',
  },
  '& .MuiButtonBase-root  p': {
    fontSize: 14,
    color: '#9FA1F2',
    fontWeight: 'revert',
  },
});

export const StyledMenu = styled(Menu)({
  minWidth: 175,
  borderRadius: 7,
  marginTop: -100,
  marginLeft: -15,
  boxShadow: '0px 7px 17px -6px rgba(23, 33, 48, 0.19)',
  border: '1px solid #E4E7ED',
  '& .MuiList-padding': {
    padding: '0 !important',
  },
});
