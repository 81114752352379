import { styled } from '@mui/system';

export const SubText = styled('div')(() => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '16px',
  width: '100%',
  color: 'rgba(15, 15, 33, 0.4) !important',
  overflow: 'auto',
  marginTop: 5,
}));
