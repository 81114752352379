import CloseIcon from '@mui/icons-material/Close';
import {
  Bold,
  Header,
  HeaderIcon,
  ListWrapper,
  Text,
  Title,
  TypographyWrapper,
  Wrapper,
} from './styles';
import { IHelperTooltipContent } from 'types';

const HelperTooltipContent = ({ handleClose }: IHelperTooltipContent) => {
  return (
    <Wrapper>
      <Header>
        <HeaderIcon as={CloseIcon} onClick={handleClose} />
      </Header>
      <Title>Handling similar messages</Title>

      <TypographyWrapper>
        <Text>Varying the language creates a more personalised experience. You can:</Text>
      </TypographyWrapper>

      <TypographyWrapper>
        <Bold>1. Send anyway </Bold>
        <Text> if the message: </Text>
      </TypographyWrapper>

      <TypographyWrapper>
        <ListWrapper>
          <li>
            <Text>is being sent in a very</Text>
            <Bold> different context</Bold>
          </li>
          <li>
            <Text>doesn’t sound robotic/unnatural to reuse the wording</Text>
          </li>
        </ListWrapper>
      </TypographyWrapper>

      <TypographyWrapper>
        <Bold>2. Edit the message </Bold>
        <Text>if none of the above apply (even if you’re copying from the guidelines).</Text>
      </TypographyWrapper>

      <TypographyWrapper>
        <Text>Remember to:</Text>
      </TypographyWrapper>

      <TypographyWrapper>
        <ListWrapper>
          <li>
            <Bold>Keep the meaning </Bold>
            <Text>of the sentence the same!</Text>
          </li>
          <li>
            <Text>Don't just change one word, </Text>
            <Bold>try to restart the sentence from scratch</Bold>
          </li>
          <li>
            <Text>Make sure the edited sentence makes complete sense — </Text>
            <Bold>use grammarly </Bold>
            <Text>to double check </Text>
          </li>
        </ListWrapper>
      </TypographyWrapper>
    </Wrapper>
  );
};

export default HelperTooltipContent;
