import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getDoc,
  query,
  updateDoc,
  where,
  getDocs,
  Timestamp,
  writeBatch,
  DocumentData,
} from 'firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import { IProgramAction } from 'types';
import { DocumentReference } from '@firebase/firestore';

interface IProgramActionsInterface {
  loading: boolean;
  programAction?: any;
  programActions?: any;
  programActionStatus?: string;
}

const initialState: IProgramActionsInterface = {
  loading: true,
};

export const programActionDocRef = (userId: string, actionId: string) =>
  doc(db, 'users', userId, 'programActions', actionId);

export const programActionsCollectionRef = (userId: string) =>
  collection(db, 'users', userId, 'programActions');

export const fetchProgramActionById = createAsyncThunk(
  'programActions/fetchBodyGoals',
  async (args: any) => {
    try {
      const { userId, actionId } = args;
      const docSnap = await getDoc(programActionDocRef(userId, actionId));

      return { id: docSnap.id, ...docSnap.data() };
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const listProgramActions = createAsyncThunk(
  'programActions/listProgramActions',
  async (userId: string) => {
    try {
      const querySnap = await getDocs(programActionsCollectionRef(userId));
      return querySnap.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
    } catch (e) {
      console.log('error', e);
    }
  },
);

interface IAddProgramAction {
  userId: string;
  activePlanId: string;
  areaId: string;
  actionToAdd: IProgramAction;
}

export const addProgramAction = createAsyncThunk(
  'programActions/addProgramAction',
  async (args: IAddProgramAction, APIThunk) => {
    try {
      const { dispatch } = APIThunk;
      const { userId, activePlanId, areaId, actionToAdd } = args;
      const draft = actionToAdd.draft;
      const newAction = {
        ...actionToAdd,
        planId: activePlanId,
        planAreaId: areaId,
        assignmentTime: Timestamp.now(),
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        draft: {
          ...draft,
          isNew: true,
          isDeleted: false,
        },
      };
      await addDoc(programActionsCollectionRef(userId), newAction);
      dispatch(listProgramActions(userId));
    } catch (e) {
      console.log('error', e);
    }
  },
);

interface IAddProgramActions {
  userId: string;
  activePlanId: string;
  planToUpdateId: string;
}

export const addProgramActions = createAsyncThunk(
  'programActions/addProgramActions',
  async (args: IAddProgramActions, APIThunk) => {
    try {
      const { dispatch } = APIThunk;
      const { userId, activePlanId, planToUpdateId } = args;
      const activePlanActions = await getDocs(
        query(programActionsCollectionRef(userId), where('planId', '==', activePlanId)),
      );
      await Promise.all(
        activePlanActions.docs.map((action) => {
          const actionToDuplicate = action.data();
          let status = actionToDuplicate.published?.status;
          status = status === 'new' ? 'existing' : status;
          let newAction;
          if (actionToDuplicate.published) {
            newAction = {
              ...actionToDuplicate,
              planId: planToUpdateId,
              draft: {
                ...actionToDuplicate.published,
                isNew: false,
                status: status,
              },
            };
            addDoc(programActionsCollectionRef(userId), newAction).then((addedDoc) => {
              updateDoc(addedDoc, {
                lastTracking: deleteField(),
                id: deleteField(),
              });
            });
          }
        }),
      );
      dispatch(listProgramActions(userId));
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const updateProgramAction = createAsyncThunk(
  'programActions/updateProgramAction',
  async (
    args: { actionId: string; action: IProgramAction; planId: string; userId: string },
    APIThunk,
  ) => {
    try {
      const { dispatch } = APIThunk;
      const { actionId, action, planId, userId } = args;
      const isReset = action.isReset;
      delete action['isReset'];
      updateDoc(programActionDocRef(userId, actionId), {
        ...action,
        planId,
        ...(isReset && { assignmentTime: Timestamp.now(), lastTracking: deleteField() }),
      });
      dispatch(listProgramActions(userId));
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const updateProgramActions = createAsyncThunk(
  'programActions/updateProgramActions',
  async (args: { userId: string; actions: IProgramAction[] }, APIThunk: any) => {
    try {
      const { userId, actions } = args;
      const { dispatch } = APIThunk;
      if (actions && userId) {
        await Promise.all(
          actions.map((action: IProgramAction) => {
            if (action.id) {
              const lastTracking =
                action.lastTracking && action.lastTracking.actionId === action.id
                  ? action.lastTracking
                  : undefined;
              updateDoc(programActionDocRef(userId, action.id), {
                ...action,
                published: {
                  ...(action.draft ?? action.published),
                },
                lastTracking: lastTracking ?? deleteField(),
                draft: deleteField(),
              });
            }
          }),
        );
        dispatch(listProgramActions(userId));
      }
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const programActionsSlice = createSlice({
  name: 'programActions',
  initialState,
  reducers: {
    addProgramAction: (state, action: PayloadAction<any>) => {
      state.programAction = action.payload;
    },
    addProgramActions: (state, action: PayloadAction<any>) => {
      state.programAction = action.payload;
    },
    setProgramActionStatus: (state, action: PayloadAction<any>) => {
      state.programActionStatus = action.payload;
    },
    updateAction: (state, action: PayloadAction<any>) => {
      state.programAction = action.payload;
    },
    resetActions: (state) => {
      state.programAction = undefined;
      state.programActions = undefined;
      state.programActionStatus = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgramActionById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProgramActionById.fulfilled, (state, action) => {
        state.loading = false;
        state.programAction = action.payload;
      })
      .addCase(listProgramActions.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) state.programActions = action.payload;
      });
  },
});

export const { setProgramActionStatus, updateAction, resetActions } = programActionsSlice.actions;

export default programActionsSlice.reducer;
