import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingTop: 11,
  paddingBottom: 11,
  marginTop: 30,
});

export const NameBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '28%',
});

export const EmailBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '25%',
});

export const PhoneBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '22%',
});

export const ShiftBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '20%',
});

export const ActionsBlock = styled('div')({
  width: '5%',
});

export const Text = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '150%',
  color: 'rgba(0, 0, 0, 0.87)',
});
