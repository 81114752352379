import { useCallback, useEffect, useState } from 'react';
import { db } from 'utils/firebase/firebaseInit';
import { setTypeOnboarding } from 'utils/data/surveys';
import { ISurveyTemplate, ISurveyQuestion, ISurvey, ESurveyType } from 'types';
import { useAppSelector } from '../index';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
  DocumentData,
} from 'firebase/firestore';

// TODO add try catch, handler errors and delete from components

export const useAdminSurveys = (userId?: string) => {
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const [surveysTemplateList, setSurveysTemplateList] = useState<ISurveyTemplate[]>([]);
  const [surveysList, setSurveysList] = useState<any[]>([]);

  const convertFromDocumentData = (data: DocumentData | undefined, id: string): ISurveyTemplate => {
    return {
      id: id,
      title: data?.title,
      questions: data?.questions,
      // startMessage: data?.startMessage,
      // endMessage: data?.endMessage,
      createdAt: data?.createdAt?.seconds,
      actionId: data?.actionId,
      onboardingType: data?.onboardingType,
    };
  };

  const loadSurveyTemplates = useCallback(async () => {
    const querySnapshot = await getDocs(collection(db, 'survey_templates'));
    setSurveysTemplateList(
      querySnapshot.docs.map((rawSurvey) =>
        convertFromDocumentData(rawSurvey.data(), rawSurvey.id),
      ),
    );
  }, []);

  // get all survey templates and survey actions
  useEffect(() => {
    loadSurveyTemplates().then();
  }, []);

  // get survey_template
  const getSurveyTemplate = useCallback(async (surveyId: string) => {
    const docSnap = await getDoc(doc(db, 'survey_templates', surveyId));
    return docSnap.data();
  }, []);

  // add survey_template
  const addSurveyTemplate = async (
    title: string,
    questions: ISurveyQuestion[],
    // startMessage: string,
    // endMessage: string,
    actionId?: string,
    type?: string | null,
  ) => {
    const docRef = await addDoc(collection(db, 'survey_templates'), {
      title,
      questions: questions.map((question, index) => ({
        ...question,
        isLastQuestion: index === questions.length - 1,
      })),
      // startMessage,
      // endMessage,
      actionId,
      createdAt: Timestamp.now(),
      ...(!!type && { onboardingType: type }),
    });

    return docRef.id;
  };

  // edit survey_template
  const editSurveyTemplate = (
    surveyId: string,
    title: string,
    questions: ISurveyQuestion[],
    // startMessage: string,
    // endMessage: string,
    actionId?: string,
    type?: string | null,
  ) => {
    return updateDoc(doc(db, 'survey_templates', surveyId), {
      title,
      questions: questions.map((question, index) => ({
        ...question,
        isLastQuestion: index === questions.length - 1,
      })),
      actionId,
      ...(!!type && { onboardingType: type }),
    });
  };

  // delete survey_template
  const deleteSurveyTemplate = async (surveyId: string) => {
    return deleteDoc(doc(db, 'survey_templates', surveyId));
  };

  // get surveys
  useEffect(() => {
    if (userId && userId.length) {
      getUserSurveys(userId).then();
    }
  }, [userId]);

  const getUserSurveys = useCallback(
    async (userId: string) => {
      const q = query(collection(db, 'surveys'), where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const result: any[] = [];
        querySnapshot.forEach((doc) => {
          result.push({ ...doc.data(), id: doc.id });
        });
        setSurveysList(
          result.sort((a: any, b: any) => b?.createdAt?.seconds - a?.createdAt?.seconds),
        );
      }
    },
    [userId],
  );

  // add surveys
  const addNewSurvey = useCallback(
    /*async ({ userId, title, startMessage, endMessage, stages, status }: Survey) => {*/
    async ({ userId, title, stages, status }: ISurvey) => {
      const docRef = await addDoc(collection(db, 'surveys'), {
        userId,
        title,
        /*        startMessage,
        endMessage,*/
        stages,
        status,
        userIdCoachFrom: coachData?.id,
        createdAt: Timestamp.now(),
      });
      return docRef.id;
    },
    [coachData],
  );

  // edit survey
  const editSurvey = (surveyId: string, data: any) => {
    const docRef = doc(db, 'surveys', surveyId);
    return updateDoc(docRef, data);
  };

  // activate onboarding
  const activateOnboarding = async (onboardingId: string) => {
    const batch = writeBatch(db);
    const onboardingRef = getDocs(collection(db, 'survey_templates'));

    onboardingRef.then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        const survey = doc.data();
        if (setTypeOnboarding(survey.onboardingType)) {
          batch.update(
            doc.ref,
            'onboardingType',
            onboardingId !== doc.id
              ? ESurveyType.ONBOARDING
              : survey.onboardingType === ESurveyType.ONBOARDING_ACTIVE
              ? ESurveyType.ONBOARDING
              : ESurveyType.ONBOARDING_ACTIVE,
          );
        }
      });
      return batch.commit();
    });
  };

  return {
    surveysTemplateList,
    getSurveyTemplate,
    addSurveyTemplate,
    editSurveyTemplate,
    deleteSurveyTemplate,
    surveysList,
    addNewSurvey,
    editSurvey,
    activateOnboarding,
  };
};
