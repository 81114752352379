import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getHandouts } from 'store/program/handoutSlice';
import {
  deleteFocusArea,
  displayAddModal,
  getFocusAreas,
  startEditFocusArea,
} from 'store/program/focusAreaSlice';
import FocusAreaHandout from './FocusAreaHandout';
import AddFocusAreaModal from './AddFocusAreaModal';

const FocusAreasContainer = () => {
  const dispatch = useAppDispatch();

  const [focusAreaToDelete, setFocusAreaToDelete] = React.useState();
  const [showConfirm, setShowConfirm] = React.useState(false);

  const { focusAreas, loading } = useAppSelector((state) => state.focusArea);

  React.useEffect(() => {
    dispatch(getFocusAreas());
    dispatch(getHandouts());
  }, []);

  const toggleConfirmModal = () => setShowConfirm(!showConfirm);

  const startDeleteFocusArea = (focusAreaId: any) => {
    setFocusAreaToDelete(focusAreaId);
    toggleConfirmModal();
  };

  const handleDeleteFocusArea = () => {
    dispatch(deleteFocusArea(focusAreaToDelete));
    toggleConfirmModal();
  };

  return (
    <Container sx={{ marginTop: 10 }}>
      <Card>
        <CardHeader
          title="List of available focus areas"
          action={
            <IconButton onClick={() => dispatch(displayAddModal())} size="large">
              <AddIcon />
            </IconButton>
          }
        />
        {loading && (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
        {!loading && (
          <CardContent>
            <List>
              {focusAreas &&
                focusAreas.length > 0 &&
                focusAreas.map((focusArea) => (
                  <React.Fragment key={focusArea.id}>
                    <ListItem>
                      <ListItemText
                        primary={focusArea.name}
                        secondary={<FocusAreaHandout handoutId={focusArea.handoutId} />}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => dispatch(startEditFocusArea(focusArea))}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => startDeleteFocusArea(focusArea.id)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
            </List>
          </CardContent>
        )}
      </Card>
      <ModalConfirm
        title="Delete focus area"
        note="Are you sure you want to delete this focus area?"
        isOpenConfirmModal={showConfirm}
        toggleOpenConfirmModal={toggleConfirmModal}
        handleSumbit={handleDeleteFocusArea}
      />
      <AddFocusAreaModal />
    </Container>
  );
};

export default FocusAreasContainer;
