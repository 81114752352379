import { styled } from '@mui/system';
import { Delete, DragIndicatorRounded, FileCopy } from '@mui/icons-material';
import { FormControl, Select, Typography } from '@mui/material';
import Input from '../../../../common/core/Input';

export const ItemWrapper = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '21px',
}));

export const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
  borderRadius: '8px',
  padding: '16px',
  marginBottom: '49px',
}));

export const RowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '16px',
}));

export const ColumnCentral = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
}));

export const ColumnRight = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 0,
}));

export const ColumnBottom = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  width: '100px',
}));

export const DragIcon = styled(DragIndicatorRounded)(({ theme }) => ({
  margin: '4px 7px',
  color: 'rgba(15, 15, 33, 0.2);',
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: '100%',
  marginTop: '0px',
  height: 'auto',
  '& .MuiInputBase-input': {
    height: '27px',
  },
}));

export const ActionIcons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const StyledFileCopy = styled(FileCopy)(({ theme }) => ({
  margin: '2px 2.5px',
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.2)',
  fontSize: '16px',
  marginRight: '10px',
}));

export const StyledDelete = styled(Delete)(({ theme }) => ({
  margin: '2px 2.5px',
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.2)',
  fontSize: '20px',
}));

export const StyledFormControlSelect = styled(FormControl)(({ theme }) => ({
  width: '100%',
  maxWidth: '196px',
  marginRight: '33px',
  '& .MuiInputLabel-formControl': {
    top: '-4px',
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '8px',
  '& .MuiOutlinedInput-input': {
    padding: '14.5px',
  },
}));

export const TypeBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '16px',
}));
