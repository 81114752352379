import { Select as MSelect, MenuItem as MMenuItem, SelectProps } from '@mui/material';

export const CompactSelect = ({
  options,
  ...props
}: {
  options: { value: string; label?: string }[];
} & Partial<SelectProps>) => {
  return (
    <MSelect
      variant="standard"
      disableUnderline
      style={{
        height: 24,
        background: '#F2F4F7',
        color: 'rgba(15, 15, 33, 0.6)',
        padding: '0px 3px 0px 8px',
        border: '1px solid #E4E7ED',
        borderRadius: 8,
      }}
      classes={{ root: 'text-sm font-medium focus:!bg-transparent !text-sm' }}
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }}
      {...props}
    >
      {options.map((option) => (
        <MMenuItem
          key={option.value}
          value={option.value}
          className="!text-sm !font-semibold !py-3 !px-5 !capitalize"
        >
          {option.label ?? option.value}
        </MMenuItem>
      ))}
    </MSelect>
  );
};
