import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

export const TitleBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
});

export const NoteBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  paddingTop: 10,
});

export const ButtonBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 24,
});

export const TitleText = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '150%',
  textAlign: 'center',
});

export const NoteText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '150%',
  textAlign: 'center',
});

export const ButtonSubmit = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 45,
  fontSize: 14,
  fontWeight: 600,
  backgroundColor: `${theme.palette.primary.main}!important`,
}));
