import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';
import { collection, getDocs, query, where } from '@firebase/firestore';
import { db } from '../../utils/firebase/firebaseInit';
import { limit, orderBy } from 'firebase/firestore';

interface SavvySurveyState {
  loading: boolean;
  survey: any;
}

const initialState: SavvySurveyState = {
  loading: false,
  survey: null,
};

export const getSurveyData = createAsyncThunk(
  'savvySurvey/getData',
  async (args: { email: string }) => {
    try {
      const { email } = args;
      const surveysRef = collection(db, 'savvy_surveys');
      const q = query(
        surveysRef,
        where('fields.userData.email_address', '==', email),
        orderBy('createdAt', 'desc'),
        limit(1),
      );
      const querySnapshot = await getDocs(q);
      const surveys = querySnapshot.docs.map((doc) => doc.data());
      const data: any = {};
      for (const survey of surveys) {
        const fields = Object.keys(survey.fields.userData);
        for (const field of fields) {
          if (!data[field]) {
            data[field] = survey.fields.userData[field];
          }
        }
      }
      return data;
    } catch (e) {
      console.log('error while loading savvy data', e);
      toast.error('Error fetching survey data');
    }
  },
);

export const savvySurveySlice = createSlice({
  name: 'savvySurvey',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSurveyData.fulfilled, (state, action) => {
        state.loading = false;
        state.survey = action.payload;
      });
  },
});

export default savvySurveySlice.reducer;
