import { styled } from '@mui/system';
import Button from '../../../../common/core/Button';
import { Typography } from '@mui/material';

export const MenuButtonList = styled(Button)({
  display: 'flex',
  justifyContent: 'space-around',
  textTransform: 'revert',
  borderRadius: 8,
  padding: '12px 28px',
  marginRight: 60,
});

export const MenuButtonListText = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

export const MenuWrapper = styled('div')({
  marginRight: 20,
  marginTop: 10,
  display: 'flex',
  justifyContent: 'flex-end',
});
