import { db } from 'utils/firebase/firebaseInit';
import { handleSurveyStatusChanges } from 'utils/firebase/firebaseTools';
import { ISurvey, EStatus, ISurveyExistent } from 'types';
import { useAppSelector } from '../index';
import { addDoc, serverTimestamp, collection, updateDoc, doc } from 'firebase/firestore';
import { toast } from 'sonner';

export const useChatSurveyUpdate = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  const createSurvey = async ({ userId, title, stages, status }: ISurvey): Promise<any> => {
    const surveyRef = await addDoc(collection(db, 'surveys'), {
      userId,
      title,
      /*        startMessage,
        endMessage,*/
      stages,
      status,
      userIdCoachFrom: coachData?.id,
      createdAt: serverTimestamp,
    });

    toast.promise(handleSurveyStatusChanges({ surveyId: surveyRef.id, status: EStatus.ASSIGNED }), {
      loading: 'Sending survey...',
      success: 'Survey was sent to user',
      error: 'Failed to send survey',
    });
  };

  const reactivateSurvey = async (survey: ISurveyExistent): Promise<any> => {
    // const { userId, title, startMessage, endMessage, stages } = survey;
    const { userId, title, stages } = survey;

    const stagesNew = stages.map((stage: any) => {
      const questions = stage.questions.map((question: any) => {
        const clone: any = Object.assign({}, question);
        delete clone.answer;
        return clone;
      });
      return { questions: questions };
    });

    const surveysRef = await addDoc(collection(db, 'surveys'), {
      userId,
      title,
      // startMessage: startMessage ?? '',
      // endMessage: endMessage ?? '',
      stages: stagesNew,
      status: EStatus.ASSIGNED,
      userIdCoachFrom: coachData?.id,
      createdAt: serverTimestamp,
    });

    toast.promise(handleSurveyStatusChanges({ surveyId: survey.id, status: EStatus.ASSIGNED }), {
      loading: 'Reactivating survey...',
      success: 'Survey was reactivated',
      error: 'Failed to reactivate survey',
    });
  };

  const editSurvey = async (surveyId: string, data: any): Promise<any> => {
    const surveyRef = doc(db, 'surveys', surveyId);
    return updateDoc(surveyRef, data);
  };

  return {
    createSurvey,
    reactivateSurvey,
    editSurvey,
  };
};
