import { styled } from '@mui/system';
import { Pagination } from '@mui/material';

export const StyledPagination = styled(Pagination)({
  color: 'red',

  '& .MuiPagination-ul': {
    '& li:not(:first-child):not(:last-child)': {
      '& button': {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#000000',
      },
    },
  },
  '& .Mui-selected': {
    backgroundColor: '#8587E5!important',
    color: '#fff !important',
  },
  '& :hover .Mui-selected': {
    backgroundColor: '#666666',
    color: '#fff !important',
  },
});
