import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'Manrope'].join(','),
  },
  status: {
    danger: '217 10 60',
    warn: '255 205 217',
    active: '234 234 251',
    inactive: '249 250 252',
    night: '80 69 199',
  },
  palette: {
    primary: {
      main: '#8587E5',
      light: '#CBCBF4',
    },
    secondary: {
      main: '#52CDB1',
    },
    error: {
      main: '#FF6D7D',
    },
  },
});

export default theme;
