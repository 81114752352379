import { styled } from '@mui/system';
import { Pagination, Typography } from '@mui/material';

export const PaginationWrapper = styled('div')({
  position: 'fixed',
  bottom: 40,
  display: 'flex',
  alignItems: 'center',
  marginTop: 40,
});

export const PaginationBlock = styled('div')({
  marginRight: 36,
});

export const StyledPagination = styled(Pagination)({
  '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)': {
    fontSize: 15,
    fontWeight: 700,
    color: '#000000',
  },
  '& .Mui-selected': {
    fontSize: 15,
    fontWeight: 700,
  },
});

export const PerPage = styled('div')({
  display: 'flex',
  cursor: 'pointer',
});

export const Text = styled(Typography)({
  marginRight: 16,
  fontWeight: 'bold',
});

export const Length = styled(Typography)({
  marginRight: 9,
  fontWeight: 'bold',
});

export const Icon = styled('div')({
  color: 'rgba(15, 15, 33, 0.6)',
});
