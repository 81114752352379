import { useCallback } from 'react';
import { db, storage } from 'utils/firebase/firebaseInit';
import { IFilesAttached, IMessageFileEdited } from 'types';
import { useAppSelector } from '../index';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';

export const useChatMessageFileEdit = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  return useCallback(
    async (message: IMessageFileEdited | null, filesList: IFilesAttached[]) => {
      if (message?.id) {
        const docRef = doc(db, 'messages', message?.id);
        const result = await getDoc(docRef);
        const data = result.exists() ? result.data() : null;
        const files = data?.files;
        const newFile = filesList[0];

        let removeFileUrl = '';

        files.forEach((file: any, index: number) => {
          if (file.name === message?.file?.name) {
            removeFileUrl = file.url;
            files[index].name = newFile.name;
            files[index].type = newFile.type;
            files[index].size = newFile.size;
            files[index].url = newFile.url;
            files[index].thumbnailUrl = '';
          }
        });

        await updateDoc(docRef, {
          files: files,
        });

        const fileRef = ref(storage, removeFileUrl);
        await deleteObject(fileRef);
      }
    },
    [coachData],
  );
};
