import { styled } from '@mui/system';

export const AppWrapper = styled('div')({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  minWidth: '100%',
  top: '70px',
});
