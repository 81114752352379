import { useNavigate } from 'react-router-dom';
import Avatar from 'common/core/Avatar';
import LogoutCoach, { IntranetCoach } from 'common/core/LogoutCoach';
import {
  AvatarBlock,
  Container,
  IntranetBlock,
  LeftBlock,
  NameBlock,
  NameText,
  RightBlock,
} from './styles';
import { useAppSelector } from 'hooks';
import { EAvatarSize, EUserRoles } from '@fitmate-coach/fitmate-types';

const ChatHeader = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const navigate = useNavigate();

  if (!coachData) {
    return null;
  }

  return (
    <>
      <Container>
        <LeftBlock
          onClick={() =>
            coachData.role === EUserRoles.COACH ? navigate('/coach-dashboard/myprofile') : ''
          }
        >
          <AvatarBlock>
            <Avatar
              imgSrc={coachData.avatarUrl}
              name={coachData.firstName + ' ' + coachData.lastName}
              size={EAvatarSize.s}
            />
          </AvatarBlock>
          <NameBlock>
            <NameText>
              {coachData.firstName} {coachData.lastName}
            </NameText>
          </NameBlock>
        </LeftBlock>
        <RightBlock>
          <LogoutCoach />
        </RightBlock>
      </Container>
      <IntranetBlock>{coachData.role === EUserRoles.COACH && <IntranetCoach />}</IntranetBlock>
    </>
  );
};

export default ChatHeader;
