import { styled } from '@mui/system';

export const Container = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const StyledDivider = styled('div')(({ theme }) => ({
  margin: '0 30px 0 30px',
}));
