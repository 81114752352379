import Button from '@mui/material/Button';
import { styled } from '@mui/system';

export const StyledButton = styled(Button)<{ color?: string }>(({ theme, color }) => ({
  // '&.MuiButton-root': {
  fontSize: 14,
  fontWeight: 500,
  minWidth: 142,
  marginLeft: 4,
  marginRight: 4,
  textTransform: 'none',
  marginTop: 9,
  // color: 'white',
  // },

  '&.MuiButton-outlined': {
    // color: 'rgba(15, 15, 33, 0.6)',
    borderColor: 'rgba(15, 15, 33, 0.20)',

    '&:hover': {
      borderColor: 'rgb(93, 94, 160)',
    },
  },

  '&.MuiButton-contained': {
    backgroundColor: theme.palette[color ?? 'primary'].main,
    color: 'white',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    color: 'white',
  },
}));
