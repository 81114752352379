import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Banner = styled('div')(({ theme, color }) => ({
  width: '100%',
  height: '40px',
  backgroundColor: color || theme.palette.secondary.main,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

export const BannerText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
}));

export const Icon = styled('span')({
  marginRight: '11px',
});
