import ChatMessagesList from '../ChatMessagesList';
import { IChatMessages } from 'types';

const ChatMessages = ({
  currentUserId,
  currentUserMessagesList,
  handleMessageEdited,
  handleMessageFileEdited,
  messageIdTagsCurrent,
  handleMessageIdTagsCurrent,
}: IChatMessages) => {
  return (
    <ChatMessagesList
      currentUserMessagesList={currentUserMessagesList}
      currentUserId={currentUserId}
      handleMessageEdited={handleMessageEdited}
      handleMessageFileEdited={handleMessageFileEdited}
      messageIdTagsCurrent={messageIdTagsCurrent}
      handleMessageIdTagsCurrent={handleMessageIdTagsCurrent}
    />
  );
};

export default ChatMessages;
