import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const ErrorInputText = styled('p')({
  fontSize: 14,
  fontWeight: 500,
  color: '#FF0000',
});

export const StyledTypography = styled(Typography)<{ hasOpacityError: boolean }>({
  fontSize: 14,
  fontWeight: 500,
  color: '#FF0000',
  // TODO: Is it working?
  hasOpacityError: {
    color: '#FF6D7D',
    fontWeight: 400,
  },
});
