import { ButtonContainer, StyledButton } from './styles';
import { IBottomButtons } from 'types';

const BottomButtons = ({ onHandleClose, onHandleSubmit }: IBottomButtons) => {
  return (
    <ButtonContainer>
      <StyledButton variant="contained" onClick={onHandleClose}>
        Edit
      </StyledButton>
      <StyledButton variant="outlined" onClick={onHandleSubmit}>
        Send anyway
      </StyledButton>
    </ButtonContainer>
  );
};

export default BottomButtons;
