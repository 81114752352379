import { EActionProgramSubStatus, EActionsProgramStatus, IProgramAction } from 'types';

/*
 * when all actions will have a status field,
 * review the need for this service
 */
export const serviceHandlingStatus = (programAction: IProgramAction) => {
  let actualStatus:
    | EActionsProgramStatus.CURRENT
    | EActionsProgramStatus.PAST
    | EActionsProgramStatus.COMPLETE
    | EActionsProgramStatus.EXISTING
    | EActionsProgramStatus.NEW;
  let actualIsNew: boolean;

  switch (programAction?.status) {
    case EActionsProgramStatus.CURRENT:
      actualStatus =
        programAction?.subStatus === EActionProgramSubStatus.NEW
          ? EActionsProgramStatus.NEW
          : EActionsProgramStatus.EXISTING;
      actualIsNew = programAction?.isNew;
      break;
    case EActionsProgramStatus.PAST:
      actualStatus = EActionsProgramStatus.PAST;
      actualIsNew = programAction?.isNew;
      break;
    case EActionsProgramStatus.COMPLETE:
      actualStatus = EActionsProgramStatus.COMPLETE;
      actualIsNew = programAction?.isNew;
      break;
    default:
      actualStatus = EActionsProgramStatus.CURRENT;
      actualIsNew = false;
  }

  return { actualStatus, actualIsNew };
};
