import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { getUserByStatus } from '../helper';

type UserMetricProps = {
  type?: string;
  title: string;
  className: string;
  needFunction?: boolean;
  calculation?: () => Promise<number>;
};

const UserMetric = ({ type, title, className, needFunction, calculation }: UserMetricProps) => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState(0);
  useEffect(() => {
    setLoading(true);
    if (needFunction && calculation) {
      calculation().then((data) => {
        setStat(data);
        setLoading(false);
      });
    } else if (type) {
      getUserByStatus(type).then((data) => {
        setStat(data);
        setLoading(false);
      });
    }
  }, [type, calculation]);

  return (
    <div className="bg-white border rounded-xl m-3 p-3 w-[200px] h-[150px] flex flex-col justify-center items-center">
      <p className="text-center">{title}</p>
      {!loading && <p className={`text-xl font-bold ${className}`}>{stat}</p>}
      {loading && <Skeleton variant="rounded" width={90} height={30} className="mt-3" />}
    </div>
  );
};

export default UserMetric;
