import { useState, useEffect, useCallback } from 'react';
import { db } from 'utils/firebase/firebaseInit';
import { IHandout } from 'types';
import { collection, getDocs } from 'firebase/firestore';

export const useProgramHandouts = () => {
  const [handouts, setHandouts] = useState<IHandout[]>([]);

  const loadHandouts = useCallback(async () => {
    const querySnapshot = await getDocs(collection(db, 'handouts'));
    if (!querySnapshot.empty) {
      setHandouts(
        querySnapshot.docs.map((doc) => {
          const { title, picture, blocks, categoryId } = doc.data();
          return {
            id: doc.id,
            title,
            picture,
            blocks,
            categoryId,
          };
        }),
      );
    }
  }, []);

  useEffect(() => {
    loadHandouts().then();
  }, []);

  return {
    handouts,
  };
};
