import { styled } from '@mui/system';
import Button from '@mui/material/Button';

export const AppWrapperContent = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'start',
});

export const AppWrapper = styled('div')({
  flexBasis: '100%',
  position: 'relative',
});

export const ButtonGroup = styled('div')({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: '12px 0 12px 0',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px -1px 0px #E4E7ED',
  justifyContent: 'flex-end',
  display: 'flex',
  gap: 8,
});

export const CancelBtn = styled(Button)({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'transparent',
  color: 'rgba(15, 15, 33, 0.6)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  boxShadow: 'none',
  borderRadius: 8,
  padding: '12px 36px',
  height: '44px !important',
  minWidth: 'revert',
  margin: 0,
});

export const CreateBtn = styled(Button)({
  padding: '12px 43px',
  marginRight: '28px !important',
  color: 'white',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});
