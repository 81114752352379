export enum ETimeTrackerParentTaskType {
  GENERAL = 'general',
  CLIENT = 'client',
}

export interface IResponseDataSaveTask {
  id: number;
  tid: number;
  workspace_id: number;
  project_id?: number | null;
  task_id?: number | null;
  billable: boolean;
  start: string;
  stop: string;
  duration: number;
  description?: string | null;
  tags: string[];
  tag_ids: number[];
  duronly: boolean;
  at: string;
  server_deleted_at?: string | null;
  user_id: number;
  uid: number;
  wid: number;
  pid: number;
}

export interface IDatePicker {
  label: string;
  defaultValue?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  inputProps?: any;
  className?: string;
  margin?: 'dense' | 'none' | 'normal';
  error?: boolean;
  helperText?: string;
}

export interface ICounter {
  durationInSec: number;
}

export interface IModalTimePicker {
  isOpenModal: boolean;
  toggleOpenModal: () => void;
  note?: string;
  valueInitial?: number;
  handleChange: (value: number) => void;
}

export interface IChatTimer {
  toggleApiToken: string;
}
