import { toast } from 'sonner';
import { ClientUser } from '@fitmate-coach/fitmate-types';

export function onCopyToClipboard(text: string): void {
  const textArea = document.createElement('textarea');
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}

export const handleCopyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.success('Copied to clipboard', {
    icon: '📋',
  });
};

export const handleCopyMessageId = (messageId: string) => {
  toast(`Message ID of the message is ${messageId}`, {
    action: {
      label: 'Copy',
      onClick: () => {
        navigator.clipboard.writeText(messageId);
        toast.success('Message ID copied to clipboard');
      },
    },
  });
};

export const handleCopyPaymentLinkWithoutTrial = (customer: ClientUser) => {
  const paymentLink = `https://buy.stripe.com/6oE3e87q6flT28ocNj?prefilled_email=${
    customer?.billingData?.email ?? customer?.email
  }`;
  toast(`Payment link is ${paymentLink}`, {
    action: {
      label: 'Copy',
      onClick: () => {
        navigator.clipboard.writeText(paymentLink);
        toast.success('Payment link copied to clipboard');
      },
    },
  });
};

export const handleCopyPaymentLinkWithTrial = (customer: ClientUser) => {
  const paymentLink = `https://buy.stripe.com/00gaGA39Q1v3aEU14a?prefilled_email=${
    customer?.billingData?.email ?? customer?.email
  }`;
  toast(`Payment link is ${paymentLink}`, {
    action: {
      label: 'Copy',
      onClick: () => {
        navigator.clipboard.writeText(paymentLink);
        toast.success('Payment link copied to clipboard');
      },
    },
  });
};
