import cn from 'mcn';

const badgeStyles = {
  new: 'border border-[#06A581] bg-[#52CDB1] text-white',
  existing: 'border border-[#06A581] bg-white',
  past: 'border border-[#5045C7] bg-[#5045C7] text-white',
  complete: 'border border-dark/60 bg-dark text-white',
  completed: 'border border-dark/60 bg-dark text-white',
  draft: 'border border-dark/60 bg-[#5045C7] text-white',
};

export const SmartGoalBadge = (props: { status: keyof typeof badgeStyles }) => {
  return (
    <span
      className={cn(
        'capitalize text-xs text-dark/80 px-1.5 py-0.5 rounded-md font-semibold',
        badgeStyles[props.status],
      )}
    >
      {props.status.match(/completed/) ? 'complete' : props.status}
    </span>
  );
};
