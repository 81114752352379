import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'common/core/Avatar';
import Input from 'common/core/Input';
import { useProfileAvatar } from 'hooks/auth/useProfileAvatar';
import { editProfile } from 'hooks/auth/editProfile';
import { phoneValidation } from 'utils/validation/phoneValidation';
import {
  AvatarBlock,
  AvatarImgBlock,
  AvatarLinkBlock,
  ButtonBlock,
  Container,
  InputBlock,
  StyledButton,
  Title2Block,
  Title2Text,
  UploadLinkText,
  Wrapper,
} from './styles';
import { useAppSelector } from 'hooks';
import { EAvatarSize } from '@fitmate-coach/fitmate-types';
import { toast } from 'sonner';

const ProfileForm = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [bio, setBio] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const { updateAvatar } = useProfileAvatar();
  const fileRef = useRef<any>(null);

  useEffect(() => {
    setFirstName(coachData?.firstName ?? '');
    setLastName(coachData?.lastName ?? '');
    setDisplayName(coachData?.displayName ?? '');
    setBio(coachData?.bio ?? '');
    setPhone(coachData?.phone ?? '');
  }, [coachData]);

  const handleClickFileUploader = () => {
    fileRef.current.click();
  };

  const chooseFileInUploader = async (e: any) => {
    await updateAvatar(e.target.files[0]);
    fileRef.current.value = '';
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(phoneValidation(e?.target?.value));
  };

  const handleSubmit = async () => {
    let isDataCorrect = true;
    setFirstNameError(firstName ? '' : 'Please fill firstname');
    setLastNameError(lastName ? '' : 'Please fill lastname');

    if (!firstName || !lastName) {
      isDataCorrect = false;
    }

    if (isDataCorrect && coachData) {
      toast.promise(editProfile(coachData.id, firstName, lastName, phone, displayName, bio), {
        loading: 'Saving profile...',
        success: 'Your profile was successfully changed.',
        error: 'Error occurred while saving data.',
      });
    }
  };

  return (
    <Wrapper>
      <Container>
        <AvatarBlock>
          <AvatarImgBlock>
            <Avatar
              imgSrc={coachData?.avatarUrl}
              name={firstName + ' ' + lastName}
              size={EAvatarSize.l}
              shape="circle"
            />
          </AvatarImgBlock>
          <AvatarLinkBlock>
            <UploadLinkText onClick={handleClickFileUploader}>
              {coachData?.avatarUrl ? 'Change photo' : 'Add photo'}
            </UploadLinkText>
            <input
              type="file"
              id="file"
              ref={fileRef}
              onChange={chooseFileInUploader}
              accept="image/*"
              style={{ display: 'none' }}
            />
          </AvatarLinkBlock>
        </AvatarBlock>
        <Title2Block>
          <Title2Text>Main Info</Title2Text>
        </Title2Block>
        <InputBlock>
          <Input
            error={!!firstNameError}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            value={firstName}
            label="First Name"
            helperText={firstNameError}
            type="text"
            fullWidth
          />
        </InputBlock>
        <InputBlock>
          <Input
            error={!!lastNameError}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            value={lastName}
            label="Last Name"
            helperText={lastNameError}
            type="text"
            fullWidth
          />
        </InputBlock>
        <InputBlock>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDisplayName(e.target.value)}
            value={displayName}
            label="Display Name"
            type="text"
            fullWidth
          />
        </InputBlock>
        <InputBlock>
          <Input
            onChange={handleChangePhone}
            value={phone}
            label="Phone number"
            type="text"
            fullWidth
          />
        </InputBlock>
        <InputBlock>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBio(e.target.value)}
            value={bio}
            label="Bio"
            type="text"
            fullWidth
            multiline
            rows={3}
          />
        </InputBlock>
        <ButtonBlock>
          <StyledButton onClick={handleSubmit} fullWidth={true}>
            Save
          </StyledButton>
        </ButtonBlock>
      </Container>
    </Wrapper>
  );
};

export default ProfileForm;
