import classnames from 'classnames';
import { TextField } from '@mui/material';
import { IDatePicker } from 'types';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    height: '38px!important',
    backgroundColor: '#fff',
  },
});

const DatePicker = ({
  label,
  defaultValue,
  value,
  onChange,
  fullWidth = false,
  inputProps = '',
  className = '',
  margin = 'normal',
  error = false,
  helperText = '',
}: IDatePicker) => {
  return (
    <StyledTextField
      id="date"
      variant="outlined"
      margin={margin}
      size="small"
      label={label}
      type="date"
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        ...inputProps,
        component: StyledTextField,
        classes: { input: classnames(className) },
      }}
      className={classnames(className)}
      style={{ color: error ? '#ED7680' : 'inherit' }}
    />
  );
};

export default DatePicker;
