import { styled } from '@mui/system';
import Button from '../../../../../../common/core/Button';
import { Menu, MenuItem, Typography } from '@mui/material';

export const MenuButtonList = styled(Button)({
  textTransform: 'revert',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  margin: 0,
  minWidth: 'revert',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
});

export const MenuButtonListText = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  color: 'rgba(15, 15, 33, 0.6)',
});

export const MenuWrapper = styled('div')({
  marginRight: 20,
  marginTop: 20,
  marginBottom: 20,
});

export const StyledMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    minWidth: 175,
    borderRadius: 7,
    marginTop: -145,
    boxShadow: '0px 7px 17px -6px rgba(23, 33, 48, 0.19)',
    border: '1px solid #E4E7ED',
    '& .MuiList-padding': {
      padding: '0 !important',
    },
  },
});

export const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E4E7ED',
  '&:last-child': {
    borderBottom: 'none',
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'revert',
  },
});
