import { styled } from '@mui/system';

export const AppSideBar = styled('div')({
  width: 300,
  minWidth: 300,
  padding: '0 32px',
  overflowY: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
