import { styled } from '@mui/system';
import { Container, Drawer, Grid, InputLabel, Paper } from '@mui/material';

export const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: 10,
}));

export const RightCta = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const StyledDrawer = styled(Drawer)({
  width: 380,
  flexShrink: 0,
});

export const StyledDrawerPaper = styled(Paper)({
  width: 480,
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

export const FormRoot = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
});

export const StyledInputLabel = styled(InputLabel)({
  marginTop: 10,
  marginBottom: 10,
});
