import { ActionsBlock, Container, EmailBlock, NameBlock, PhoneBlock, Text } from './styles';

const PartnerReferralsListHeader = () => {
  return (
    <>
      <Container>
        <NameBlock>
          <Text>First name and Last name</Text>
        </NameBlock>
        <EmailBlock>
          <Text>Email</Text>
        </EmailBlock>
        <PhoneBlock>
          <Text>Phone number</Text>
        </PhoneBlock>
        <ActionsBlock />
      </Container>
    </>
  );
};

export default PartnerReferralsListHeader;
