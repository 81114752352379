import { styled } from '@mui/system';
import { FormControl, Select } from '@mui/material';
import DatePicker from '../../../../../../common/core/DatePicker';

export const Wrapper = styled('div')(({ theme }) => ({}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const InputBlock = styled('div')(({ theme }) => ({
  marginBottom: 15,
}));

export const InputErrorBlock = styled('div')(({ theme }) => ({
  marginBottom: '60px !important',
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: 270,
}));

export const SelectHelper = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  color: '#000',
  paddingBottom: 8,
}));

export const Input = styled('div')(({ theme }) => ({
  margin: 0,
  minWidth: 240,
}));

export const DateField = styled(FormControl)(({ theme }) => ({
  margin: '10px 0',
  width: 270,
}));

export const StyledDatepicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
}));
