import { useState, useEffect } from 'react';
import { v4 } from 'uuid';
import { db } from 'utils/firebase/firebaseInit';
import { prepareField } from 'utils/format/objects';
import { inviteEmailNotificationReferralPartner } from 'utils/tools/emails';
import { getByRole } from 'api/firestores';
import { EUserRoles } from 'types';
// import { IAdminPartnerReferral } from '../../types';
import { addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { PartnerReferralUser } from '@fitmate-coach/fitmate-types';

export const useAdminPartnerReferrals = () => {
  const [partnerReferralList, setParnerReferralList] = useState<PartnerReferralUser[]>([]);

  useEffect(() => {
    getByRole(EUserRoles.PARTNER_REFERRAL).then((querySnapshot) => {
      setParnerReferralList(
        querySnapshot.docs.map((doc) => {
          const docData = doc.data() as PartnerReferralUser;
          const {
            firstName,
            lastName,
            phone,
            email,
            role,
            avatarUrl,
            isInviteAccepted,
            isActive,
            confirmId,
            createdAt,
          } = docData;

          return {
            id: doc.id,
            firstName,
            lastName,
            phone,
            email,
            role,
            avatarUrl,
            isInviteAccepted,
            isActive,
            confirmId,
            createdAt,
          };
        }),
      );
    });
  }, []);

  const createPartnerReferral = async (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    timezone: string,
  ) => {
    const confirmId = v4();

    const details: PartnerReferralUser = {
      firstName,
      lastName,
      email,
      phone,
      avatarUrl: '',
      role: EUserRoles.PARTNER_REFERRAL,
      ...prepareField({ timezone }),
      isInviteAccepted: false,
      isActive: true,
      confirmId,
      createdAt: serverTimestamp(),
      // userAssignment: null,
    };

    addDoc(collection(db, 'users'), details).then((record) => {
      inviteEmailNotificationReferralPartner(
        firstName,
        lastName,
        confirmId,
        email,
        record.id,
        phone,
      );
      afterUpdate(record.id, {
        firstName,
        lastName,
        email,
        phone,
        avatarUrl: '',
        role: EUserRoles.PARTNER_REFERRAL,
        isInviteAccepted: false,
        isActive: true,
        confirmId,
        createdAt: serverTimestamp(),
      });
    });
  };

  const afterUpdate = (userId: string, details: PartnerReferralUser) => {
    const otherPartnerReferrals = partnerReferralList.filter(
      (partnerReferral) => partnerReferral.id !== userId,
    );
    const currentPartnerReferral = partnerReferralList.find(
      (partnerReferral) => partnerReferral.id === userId,
    );

    setParnerReferralList([...otherPartnerReferrals, { ...currentPartnerReferral, ...details }]);
  };
  const editPartnerReferral = async (
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
  ) => {
    const details: Partial<PartnerReferralUser> = {
      firstName,
      lastName,
      email,
      phone,
    };

    const docRef = doc(db, 'users', userId);
    await updateDoc(docRef, details);
    afterUpdate(userId, {
      id: userId,
      ...details,
    } as PartnerReferralUser);
  };

  return {
    partnerReferralList,
    createPartnerReferral,
    editPartnerReferral,
  };
};
