import { IMessageSurveyData, EStatus } from 'types';

export const handleSurveyDataValue = (surveyData?: IMessageSurveyData) => {
  if (surveyData) {
    if (surveyData?.questionId) {
      return { isDeleted: true };
    } else {
      if (!surveyData?.status) {
        return { isSurvey: true, isDeleted: true };
      }
      if (surveyData?.status === EStatus.CANCELED) {
        return { isSurvey: true, isSurveyDeclined: true };
      }
      if (surveyData?.status === EStatus.COMPLETED) {
        return { isSurvey: true, isSurveyCompleted: true };
      }
      if (surveyData?.status === EStatus.ASSIGNED) {
        return { isSurvey: true, isSurveyAssigned: true };
      }
    }
  }
};
