import { styled } from '@mui/system';
import { Tab, Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  height: 56,
  borderBottom: '1px solid #E4E7ED',
});

export const LeftBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 200,
  height: '100%',
  paddingLeft: 30,
});

export const CenterBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  width: '100%',
  height: '100%',
});

export const RightBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '100%',
  paddingRight: 10,
});

export const BackText = styled(Typography)({
  fontSize: 16,
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
});

export const TabChosen = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  textTransform: 'none',
  color: theme.palette.primary.main,
}));

export const StyledTab = styled(Tab)({
  fontSize: 16,
  fontWeight: 400,
  textTransform: 'none',
  color: 'rgba(15, 15, 33, 0.4)',
  '& .Mui-selected': { ...TabChosen },
});
