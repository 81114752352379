import { Timestamp } from 'firebase/firestore';
import { IMessageReference, IResourceCategory } from 'types';

export interface ISort {
  text: string;
  query: {
    key: string;
    value: 'desc' | 'asc';
  };
}

export interface IFilter {
  text: string;
  checked: boolean;
  query: object;
}

export interface IFilteredContent extends IFilteredContentExtension {
  handleCategorySelected: (category: IResourceCategory | null) => void;
  selectedCategory: IResourceCategory | null;
  selectedTags: string[];
  handleChangeReferences?: (reference: IMessageReference) => void;
}

export interface IFilteredContentExtension {
  handleCategorySelected: (category: IResourceCategory | null) => void;
  selectedCategory: IResourceCategory | null;
  selectedTags: string[];
}

export function toTimestamp(value: any, defaultValue = 0): Timestamp {
  if (value instanceof Timestamp) {
    return value;
  }

  return Timestamp.fromMillis(defaultValue);
}
