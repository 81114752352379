import { styled } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Button from '../../../../../common/core/Button';

export const Title = styled(Typography)({
  fontWeight: 500,
  fontSize: '28px',
  marginBottom: '24px',
});

export const ListWrapper = styled('div')({
  maxHeight: '1000px',
  overflowY: 'scroll',
  marginBottom: '36px',
});

export const ItemWrapper = styled(Accordion)({
  backgroundColor: '#F9FAFC',
  border: 'none',
  boxShadow: 'none',
  marginBottom: '16px',
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
});

export const AccordionSummaryStyled = styled(AccordionSummary)({
  padding: '0px 32px',
  border: '1px solid #E4E7ED',
  borderRadius: '4px 4px 0px 0px',
  '& .MuiAccordionSummary-content': {
    margin: '14px 0',
  },
});

export const ItemHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '& .MuiButton-containedPrimary:hover': {
    backgroundColor: '#FFFFFF',
  },
});

export const ItemTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
});

export const ItemButton = styled(Button)({
  color: 'rgba(15, 15, 33, 0.6)',
  border: '1px solid rgba(15, 15, 33, 0.2)',
  borderRadius: '8px',
  backgroundColor: '#F9FAFC',
  fontWeight: 600,
  boxShadow: 'none',
  '&:disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'all',
  },
  '&:disabled:hover': {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
});

export const LoaderIcon = styled('span')({
  animation: '$rotating 2s linear infinite',
});

export const AccordionDetailsStyled = styled(AccordionDetails)({
  padding: '32px 36px',
});

export const AccordionQuestion = styled('div')({
  width: '100%',
});

export const ExpandArrowIcon = styled('span')({
  transform: 'rotate(180deg)',
});
