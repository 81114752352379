import { styled } from '@mui/system';
import { FormControl } from '@mui/material';

export const FilterWrapper = styled('div')<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 17,
  paddingLeft: 20,
  paddingTop: 20,
  paddingRight: 20,
  marginRight: 40,
  maxHeight: 'calc(60vh - 100px)',
  overflowY: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  ...(isSelected && {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: 20,
    paddingTop: 20,
  }),
}));

export const FormWrapper = styled(FormControl)(({ theme }) => ({
  maxHeight: 'calc(60vh - 90px)',
  overflowY: 'auto',
}));
