import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CenterBlock, Container, LeftBlock } from './styles';
import ChatAllClients from '../ChatAllClients';
import ChatControl from '../ChatControl';
import ChatThreadsList from '../ChatThreadsList';
import ChatEmpty from '../ChatEmpty';
import { fetchCoaches } from 'store/coaches/coachesSlice';
import { releaseCoachWatchingCustomer } from 'store/customers/watcherSlice';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { coaches } = useAppSelector((state) => state.coaches);
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const [isOpenFAQBlock] = useState<boolean>(false);
  const [isExpandedFAQBlock, setIsExpandedFAQBlock] = useState<boolean>(false);
  const [isSearchValueFromMessageUpdated, setIsSearchValueFromMessageUpdated] =
    useState<boolean>(false);
  const [isOpenAllClients, setIsOpenAllClients] = useState<boolean>(false);

  useEffect(() => {
    if (coaches.length === 0) dispatch(fetchCoaches());
  }, [coaches, dispatch]);

  useEffect(() => {
    setIsExpandedFAQBlock(false);
  }, [isOpenFAQBlock]);

  useEffect(() => {
    if (isSearchValueFromMessageUpdated) {
      const timer = setTimeout(() => {
        setIsSearchValueFromMessageUpdated(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isSearchValueFromMessageUpdated]);

  const handleOpenAllClients = () => {
    setIsOpenAllClients((prev) => !prev);
    dispatch(releaseCoachWatchingCustomer({ coachId: coachData?.id }));
  };

  return (
    <Container isToggleApiToken={!!coachData?.toggleApiToken}>
      {!isExpandedFAQBlock && (
        <LeftBlock>
          <ChatControl
            handleOpenAllClients={handleOpenAllClients}
            isOpenAllClients={isOpenAllClients}
          />
          <ChatThreadsList />
        </LeftBlock>
      )}
      <CenterBlock>
        {!isOpenAllClients && <ChatEmpty />}

        {isOpenAllClients && (
          <ChatAllClients
            handleOpenAllClients={handleOpenAllClients}
            isOpenAllClients={isOpenAllClients}
          />
        )}
      </CenterBlock>
    </Container>
  );
};

export default Dashboard;
