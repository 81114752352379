import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from 'utils/firebase/firebaseInit';
import { toast } from 'sonner';

export const useForgotPassword = () => {
  const navigate = useNavigate();

  return async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Reset password link sent to your email.');
      navigate('/login');
    } catch (e) {
      toast.error('Failed to send Reset password link to your email.');
    }
  };
};
