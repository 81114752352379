import { styled } from '@mui/system';
import Input from '../../../../../../../common/core/Input';

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

export const StyledInput = styled(Input)({
  width: '100%',
  margin: '0px !important',
  height: 'auto',
  paddingLeft: '0px',
  '& .MuiInputBase-input': {
    height: '27px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid rgba(15, 15, 33, 0.2)',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 'unset',
  },
});

export const Icon = styled('span')({
  position: 'absolute',
  left: '0',
  zIndex: 1,
  marginRight: '10px',
  color: 'rgba(15, 15, 33, 0.2);',
});

export const Close = styled('span')({
  position: 'absolute',
  right: '0',
  zIndex: 1,
  padding: '10px 0 10px 10px',
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.2)',
  fontSize: '16px',
});

export const AddOption = styled('div')<{ isDisabled: boolean }>(({ isDisabled }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '16px',
  color: 'rgba(15, 15, 33, 0.4)',
  ...(!isDisabled && {
    cursor: 'pointer',
  }),
}));

export const Pointer = styled('span')({
  cursor: 'pointer',
});
