import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')(() => ({
  padding: 10,
}));

export const HeaderBlock = styled('div')({
  margin: 0,
  backgroundColor: '#fff',
});

export const TitleText = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  color: '#0F0F21',
});

export const CloseButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

export const CloseIcon = styled('div')({
  color: '#000',
  fontSize: 22,
  fontWeight: 600,
});

export const ContentBlock = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const ActionsBlock = styled('div')({
  display: 'flex',
  backgroundColor: '#fff',
  padding: 5,
  justifyContent: 'center',
  paddingBottom: 20,
});
