import { Edit, Delete } from '@mui/icons-material';
import Menu from 'common/core/Menu';
import { StyledDeleteIcon, StyledMenuItem, SubMenu } from 'styles/dotsMenu';
import { IDotsMenuMain } from 'types';
import DeleteIcon from '@mui/icons-material/Delete';

const DotsMenu = ({
  anchorEl,
  toggleHandler,
  deleteClickHandler,
  editClickHandler,
  editTitle,
}: IDotsMenuMain) => {
  return (
    <Menu anchorEl={anchorEl} toggleMenu={toggleHandler} vertical="bottom">
      <SubMenu onClick={editClickHandler}>
        <StyledMenuItem>{editTitle}</StyledMenuItem>
        <Edit />
      </SubMenu>
      <SubMenu onClick={deleteClickHandler}>
        <Delete>Delete</Delete>
        <StyledDeleteIcon as={DeleteIcon} />
      </SubMenu>
    </Menu>
  );
};

export default DotsMenu;
