import UserBarChart from './stat/userBarChart';
import UserMetric from './stat/UserMetric';
import {
  getActiveOnboardings,
  getCoachOnShift,
  getMsgPerCoach,
  getOverDueEowReviews,
  getTotalTeams,
  getUpcomingEowReviews,
} from './helper';
import { EBannerType } from '@fitmate-coach/fitmate-types';

const StatsContainer = () => {
  return (
    <div className="bg-grey-100 min-h-screen flex flex-col">
      <div className="w-full flex justify-around">
        <UserMetric title={'Paid'} type={'active'} className="text-green-400" />
        <UserMetric title={'Trial'} type={'trial'} className="text-amber-300" />
        <UserMetric title={'Unpaid'} type={'unpaid'} className="text-amber-300" />
        <UserMetric title={'Overdue'} type={'past_due'} className="text-red-500" />
        <UserMetric title={'Cancelled'} type={'cancelled'} className="text-red-500" />
      </div>

      <div className="w-full flex justify-around">
        <UserMetric
          title={'Main coach(es)'}
          needFunction
          calculation={getTotalTeams}
          className=""
        />
        <UserMetric
          title={'Coach(es) on shift'}
          needFunction
          calculation={getCoachOnShift}
          className=""
        />
        <UserMetric
          title={'Msg / coach (yesterday)'}
          needFunction
          calculation={() => getMsgPerCoach('yesterday')}
          className=""
        />
        <UserMetric
          title={'Msg / coach (today)'}
          needFunction
          calculation={() => getMsgPerCoach('today')}
          className=""
        />
        <UserMetric
          title={'Msg / coach (this week)'}
          needFunction
          calculation={() => getMsgPerCoach('week')}
          className=""
        />
      </div>

      <div className="w-full flex justify-around">
        <UserMetric
          title={'Active onboarding'}
          needFunction
          calculation={getActiveOnboardings}
          className=""
        />
        <UserMetric
          title={'Upcoming EOW'}
          needFunction
          calculation={() => getUpcomingEowReviews(EBannerType.DAY_BEFORE_EOW)}
          className=""
        />
        <UserMetric
          title={'Active EOW'}
          needFunction
          calculation={() => getUpcomingEowReviews(EBannerType.ACTIVE_EOW)}
          className=""
        />
        <UserMetric
          title={'Overdue EOW'}
          needFunction
          calculation={getOverDueEowReviews}
          className=""
        />
        <div className="bg-white border rounded-xl m-3 p-3 w-[200px] h-[150px] flex flex-col justify-center items-center">
          <p className="text-center">Average EOW duration</p>
          <p className="text-xl font-bold">TODO</p>
        </div>
      </div>

      <div className="w-full flex justify-around">
        <div className="bg-white border rounded-xl m-3 p-3 w-1/2 flex flex-col justify-center items-center">
          <p className="font-bold">Daily new user</p>
          <UserBarChart />
        </div>

        <div className="bg-white border rounded-xl m-3 p-3 w-1/2 flex flex-col justify-start items-start">
          <p className="font-bold">Daily cancellation</p>
          <UserBarChart />
        </div>
      </div>

      <div className="w-full flex justify-around">
        <div className="bg-white border rounded-xl m-3 p-3 w-1/2 flex flex-col justify-center items-center">
          <p className="font-bold">Daily onboarding</p>
          <UserBarChart />
        </div>

        <div className="bg-white border rounded-xl m-3 p-3 w-1/2 flex flex-col justify-start items-start">
          <p className="font-bold">Daily EOW</p>
          <UserBarChart />
        </div>
      </div>
    </div>
  );
};

export default StatsContainer;
