import { ButtonBlock, Container, StyledButton } from './styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import { toggleAddBodyProfile } from 'store/customers/bodyProfileSlice';

const ChatProgramProfileFooter = () => {
  const dispatch = useAppDispatch();
  const { isReadOnly } = useAppSelector((state) => state.customerInfos);

  const handleAddBodyProfile = () => {
    dispatch(toggleAddBodyProfile());
  };

  return (
    <Container>
      <ButtonBlock>
        <StyledButton disabled={isReadOnly} onClick={handleAddBodyProfile}>
          Update measures
        </StyledButton>
      </ButtonBlock>
    </Container>
  );
};

export default ChatProgramProfileFooter;
