import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const TitleBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 11,
  '& .MuiButton-text': {
    textTransform: 'none',
    padding: '0 0 0 7px',
    color: '#8587E5',
  },
});

export const BtnLabel = styled(Typography)({
  marginRight: 4,
  fontSize: 14,
  fontWeight: 500,
});

export const WarningBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 20,
});

export const TitleText = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
});

export const WarningTitle = styled(Typography)({
  fontSize: 14,
  color: 'rgba(15, 15, 33, 0.4)',
  textAlign: 'center',
  marginTop: 4,
  maxWidth: 219,
});

export const TagsWrapper = styled('div')({
  display: 'flex',
  gap: 6,
  flexWrap: 'wrap',
  '& .MuiCheckbox-root': {
    display: 'none',
  },
  '& .MuiFormControlLabel-root': {
    margin: 0,
  },
  '& .MuiFormControlLabel-label': {
    padding: '6px 12px',
    borderRadius: 22,
    border: '1px solid rgba(15, 15, 33, 0.2)',
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(15, 15, 33, 0.4)',
  },
  '& .MuiFormControlLabel-root > .Mui-checked + .MuiTypography-root': {
    backgroundColor: ' #9FA1F2',
    border: '1px solid #8587E5',
    color: '#FFF',
  },
});

export const CategoryWrapper = styled('div')({
  padding: 16,
  borderRadius: 12,
  marginBottom: 13,
  display: 'flex',
  flexDirection: 'column',
  '&:last-child': {
    marginBottom: 0,
  },
  backgroundColor: '#F7F8FA',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiRadio-colorSecondary.Mui-checked': {
    color: 'rgba(15, 15, 33, 0.6)',
  },
});
