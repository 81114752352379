import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '../../../../../common/core/MenuItem';

export const SeparateSelectBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#E6EAF2',
  border: '1px solid #E4E7ED',
  borderRadius: 8,
  marginTop: 2,
  marginBottom: 7,
  cursor: 'pointer',
}));

export const SeparateSelectInfoBlock = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

export const SeparateSelectArrowBlock = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const SeparateSelectInfoText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: 'rgba(15, 15, 33, 0.6)',
}));

export const SeparateSelectArrowIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  color: 'rgba(15, 15, 33, 0.6)',
}));

export const SubMenu = styled(MenuItem)(({ theme }) => ({
  minWidth: 180,
}));

export const SubMenuText = styled(Typography)<{ isActive: boolean }>(({ theme, isActive }) => ({
  fontSize: 14,
  fontWeight: 500,
  ...(isActive && { color: theme.palette.primary.main }),
}));
