import { styled } from '@mui/system';

export const LeftBlockThreads = styled('div')({
  position: 'relative',
  height: '100%',
  overflowY: 'auto',
  msOverflowStyle: 'none' /* for Internet Explorer, Edge */,
  scrollbarWidth: 'none' /* for Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none' /* for Chrome, Safari, and Opera */,
  },
});
