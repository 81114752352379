import { db } from 'utils/firebase/firebaseInit';
import { doc, getDoc } from 'firebase/firestore';

export const useChatReference = () => {
  const getResourceById = async (id: string | undefined) => {
    if (id) {
      return getDoc(doc(db, 'resources', id));
    }
  };

  const getCategoryById = async (id: string | undefined) => {
    if (id) {
      return getDoc(doc(db, 'resources_categories', id));
    }
  };

  const getHandoutById = async (id: string | undefined) => {
    if (id) {
      return getDoc(doc(db, 'handouts', id));
    }
  };

  return {
    getResourceById,
    getCategoryById,
    getHandoutById,
  };
};
