import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, InputAdornment } from '@mui/material';
import { timezonesUSList } from 'config';
import ModalTimePicker from 'common/core/Modal/ModalTimePicker';
import Modal from 'common/core/Modal';
import InputError from 'common/core/InputError';
import { formatTime } from 'utils/format/datetime';
import { phoneValidation } from 'utils/validation/phoneValidation';
import {
  ButtonBlock,
  ButtonCancel,
  ButtonSubmit,
  Container,
  ElementBlock,
  ElementSideBlock,
  NoteBlock,
  NoteText,
  StyledInput,
  StyledSelect,
  StyledTimeIcon,
  TitleBlock,
  TitleText,
} from '../../CommonStyles';
import { ICoachesAddModal } from 'types';
import { checkDuplicateEmail } from '../../../../../utils/tools/emails';

const CoachesAddModal = ({
  isOpenAddModal,
  toggleOpenAddModal,
  handleCreateCoach,
}: ICoachesAddModal) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const [timeStarting, setTimeStarting] = useState<number>(0);
  const [timeFinishing, setTimeFinishing] = useState<number>(0);
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isOpenTimeStartModal, setIsOpenTimeStartModal] = useState<boolean>(false);
  const [isOpenTimeFinishingModal, setIsOpenTimeFinishingModal] = useState<boolean>(false);

  useEffect(() => {
    if (isOpenAddModal) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setFirstNameError('');
      setLastNameError('');
      setEmailError('');
    }
  }, [isOpenAddModal]);

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(phoneValidation(e?.target?.value));
  };

  const toggleOpenTimeStartModal = () => {
    setIsOpenTimeStartModal((prev) => !prev);
  };

  const toggleOpenTimeFinishingModal = () => {
    setIsOpenTimeFinishingModal((prev) => !prev);
  };

  const handleChangeTimeStarting = (value: number) => {
    setTimeStarting(value);
  };

  const handleChangeTimeFinishing = (value: number) => {
    setTimeFinishing(value);
  };

  const handleSubmit = async () => {
    setFirstNameError(firstName ? '' : 'Please fill First Name');
    setLastNameError(lastName ? '' : 'Please fill Last Name');
    setEmailError(email ? '' : 'Please fill Email');

    if (firstName && lastName && email) {
      const emailIsExist = await checkDuplicateEmail(email);
      emailIsExist
        ? setEmailError(emailIsExist ? 'This email already exists' : '')
        : handleCreateCoach(
            firstName,
            lastName,
            email,
            phone,
            timezone,
            timeStarting,
            timeFinishing,
          );
    }
  };

  return (
    <>
      <Modal widthMax={370} title="" open={isOpenAddModal}>
        <Container>
          <TitleBlock>
            <TitleText>Add new Coach</TitleText>
          </TitleBlock>
          <ElementBlock>
            <StyledInput
              error={!!firstNameError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
              value={firstName}
              label="First Name"
              inputProps={{
                'data-testid': 'input-first-name',
              }}
              helperText={firstNameError}
              type="text"
              fullWidth
            />
          </ElementBlock>
          <ElementBlock>
            <StyledInput
              error={!!lastNameError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
              value={lastName}
              label="Last Name"
              inputProps={{
                'data-testid': 'input-last-name',
              }}
              helperText={lastNameError}
              type="text"
              fullWidth
            />
          </ElementBlock>
          <ElementBlock>
            <StyledInput
              error={!!emailError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              value={email}
              label="Email"
              inputProps={{
                'data-testid': 'input-email',
              }}
              helperText={emailError}
              type="email"
              fullWidth
            />
          </ElementBlock>
          <ElementBlock>
            <StyledInput
              onChange={handleChangePhone}
              value={phone}
              label="Phone"
              inputProps={{
                'data-testid': 'input-phone',
              }}
              type="text"
              fullWidth
            />
          </ElementBlock>
          <NoteBlock>
            <NoteText>Work hours</NoteText>
          </NoteBlock>
          <ElementBlock>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Timezone</InputLabel>
              <StyledSelect
                // variant="standard"
                label="Timezone"
                inputProps={{
                  'data-testid': 'select-timezone',
                }}
                native
                onChange={(e: any) => setTimezone(e.target.value)}
                value={timezone}
              >
                <option aria-label="None" value="" key={0}></option>
                {timezonesUSList
                  .filter(({ isForCoach }) => isForCoach)
                  .map(({ value, label }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
              </StyledSelect>
              <InputError text="" />
            </FormControl>
          </ElementBlock>
          <ElementSideBlock>
            <StyledInput
              value={timeStarting ? formatTime(timeStarting) : ''}
              onClick={toggleOpenTimeStartModal}
              onChange={toggleOpenTimeStartModal}
              label="Starting time"
              helperText=""
              type="text"
              fullWidth
              sx={{ cursor: 'pointer', marginRight: 1 }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledTimeIcon />
                  </InputAdornment>
                ),
                'data-testid': 'input-starting-time',
              }}
            />
            {/*</ElementSideBlock>*/}
            {/*<ElementSideBlock>*/}
            <StyledInput
              value={timeFinishing ? formatTime(timeFinishing) : ''}
              onClick={toggleOpenTimeFinishingModal}
              onChange={toggleOpenTimeFinishingModal}
              label="Finishing time"
              helperText=""
              type="text"
              fullWidth
              sx={{ cursor: 'pointer' }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledTimeIcon />
                  </InputAdornment>
                ),
                'data-testid': 'input-finishing-time',
              }}
            />
          </ElementSideBlock>
          <ButtonBlock>
            <ButtonCancel onClick={toggleOpenAddModal} variant="outlined">
              Cancel
            </ButtonCancel>
            <ButtonSubmit onClick={handleSubmit}>Create</ButtonSubmit>
          </ButtonBlock>
        </Container>
      </Modal>
      {isOpenTimeStartModal && (
        <ModalTimePicker
          isOpenModal={isOpenTimeStartModal}
          toggleOpenModal={toggleOpenTimeStartModal}
          note="Select starting time"
          valueInitial={timeStarting}
          handleChange={handleChangeTimeStarting}
        />
      )}
      {isOpenTimeFinishingModal && (
        <ModalTimePicker
          isOpenModal={isOpenTimeFinishingModal}
          toggleOpenModal={toggleOpenTimeFinishingModal}
          note="Select finishing time"
          valueInitial={timeFinishing}
          handleChange={handleChangeTimeFinishing}
        />
      )}
    </>
  );
};

export default CoachesAddModal;
