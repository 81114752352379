import { styled } from '@mui/system';
import { Container } from '@mui/material';

export const Wrapper = styled(Container)(({ theme }) => ({
  marginTop: 10,
  marginBottom: 30,
  display: 'flex',
  padding: '0 12px 0 36px',
}));

export const LeftColumnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '50%',
}));
