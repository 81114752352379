import { styled, Theme } from '@mui/system';
import { Typography } from '@mui/material';

export const MessageContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
}));

export const MessageLeftContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  marginBottom: 10,
  alignSelf: 'flex-start',
}));

export const MessageRightContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  alignSelf: 'flex-end',
  marginBottom: 10,
}));

export const MessageBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 450,
  minWidth: 300,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 8,
  paddingBottom: 8,
}));

export const AvatarLeftBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  marginLeft: 14,
}));

export const MessageLeftBlock = {
  backgroundColor: 'rgba(197, 197, 254, 0.16)',
  marginLeft: 24,
  borderRadius: '12px 12px 12px 0px',
};

export const MessageRightBlock = {
  backgroundColor: '#D6F5EE',
  marginRight: 24,
  borderRadius: '12px 12px 0px 12px',
};

export const DateTextLeft = {
  color: 'rgba(15, 15, 33, 0.4) !important',
};

export const DateTextRight = {
  color: '#96BCB4 !important',
};

export const MessageBlockChosen = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: '#F2F4F7 !important',
}));

export const StyledMessageBlock = styled('div')<{ isCurrentUser: boolean; anchorEl: any }>(
  ({ isCurrentUser, anchorEl }) => ({
    ...(isCurrentUser ? MessageLeftBlock : MessageRightBlock),
    ...(anchorEl ? MessageBlockChosen : {}),
  }),
);

export const QuoteBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 6,
}));

export const QuoteLineBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  width: 2,
  minHeight: '100%',
  marginLeft: 12,
}));

export const QuoteLine = styled('div')(({ theme }: { theme: Theme }) => ({
  width: 2,
  height: '100%',
  backgroundColor: '#18997C',
  borderRadius: 4,
}));

export const QuoteMessageBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  width: '100%',
  marginLeft: 10,
}));

export const QuoteBodyText = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  color: '#0F0F21',
  maxWidth: '430px !important',
  wordWrap: 'break-word',
}));

export const BodyText = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  color: '#0F0F21',
  maxWidth: '450px !important',
  wordWrap: 'break-word',
}));

export const BodyTextEmojiBig = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: '60px !important',
  lineHeight: '60px !important',
}));

export const MessageBottomInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: 8,
}));

export const UserNameText = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
  width: '50%',
}));

export const DateText = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  fontSize: 12,
  fontWeight: 400,
  ...(isCurrentUser ? DateTextLeft : DateTextRight),
}));

export const FileContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const FileContainerLeft = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const FileContainerRight = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
}));

export const FileBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 204,
  marginTop: 6,
}));

export const FileBlockLeft = styled('div')(({ theme }: { theme: Theme }) => ({
  marginLeft: 24,
}));

export const FileBlockRight = styled('div')(({ theme }: { theme: Theme }) => ({
  marginRight: 24,
  backgroundColor: '#D6F5EE',
  borderRadius: '2px 12px 0px 12px',
}));

export const FileBlockChosen = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: '#F2F4F7 !important',
}));

export const FileInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: 6,
}));

export const FileInfoBlockLeft = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexGrow: 1,
  paddingLeft: 9,
}));

export const FileInfoBlockRight = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexGrow: 1,
  paddingRight: 9,
}));

export const FileImageBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

export const FileImage = styled('img')(({ theme }: { theme: Theme }) => ({
  marginTop: 8,
  marginBottom: 8,
  width: '100%',
  height: 'auto',
  maxWidth: 180,
  maxHeight: 360,
}));

export const FileImagePreview = styled('img')(({ theme }: { theme: Theme }) => ({
  marginTop: 8,
  marginBottom: 8,
  width: '100%',
  height: 'auto',
  maxWidth: 180,
  maxHeight: 121,
}));

export const FileImageIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  top: 45,
  left: 0,
  right: 0,
  margin: 'auto',
}));

export const FileImageText = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  bottom: 30,
  margin: 'auto',
  fontSize: 12,
  fontWeight: 500,
  color: '#FFFFFF',
  maxWidth: 120,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const UserNameReferenceText = {
  width: '100px !important',
};
export const UserNameFileText = styled('div')<{ isCurrentUser: boolean; hasResource: boolean }>(
  ({ isCurrentUser, hasResource }) => ({
    fontSize: 12,
    fontWeight: 400,
    width: 163,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...(isCurrentUser ? DateTextLeft : DateTextRight),
    ...(hasResource && UserNameReferenceText),
  }),
);

export const SharedMessage = styled('div')(({ theme }: { theme: Theme }) => ({
  marginRight: 28,
}));

export const SubMenu = styled('div')(({ theme }: { theme: Theme }) => ({
  minWidth: 150,
}));

export const SubMenuDelete = styled('div')(({ theme }: { theme: Theme }) => ({
  color: '#FF6D7D !important',
}));

export const HandoutDesc = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: '#47B39A',
  fontSize: 12,
  fontWeight: 600,
}));

export const HandoutContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  padding: 10,
  width: '254px !important',
}));

export const HandoutHeader = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
}));

export const HandoutTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: '#242928',
  fontSize: 14,
  lineHeight: '150%',
}));

export const HandoutIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  height: 15,
  width: 'auto',
  paddingRight: 5,
  color: '#47B39A',
}));

export const ReadIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: 12,
  padding: 0,
  margin: 0,
  marginLeft: 2,
}));

export const UnReadIcon = styled(ReadIcon)(({ theme }: { theme: Theme }) => ({
  ...ReadIcon,
  color: '#96BCB4',
}));

export const IsReadIcon = styled(ReadIcon)(({ theme }: { theme: Theme }) => ({
  ...ReadIcon,
  color: '#47B29A',
}));
