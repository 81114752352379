import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from 'common/core/Button';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { getDocs, query, where } from '@firebase/firestore';
import { collection, limit, orderBy } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getCompletion } from 'store/automation/aiSlice';
import { fetchCategory } from 'store/program/weekProgramSlice';
import { db } from 'utils/firebase/firebaseInit';
import { getGlp1AndDiabetes, getWeekNumberProgram } from 'utils/data/user';
import {
  FeedbackText,
  FeedbackTitle,
  StyledFormControl,
  StyledFormText,
} from '../DrawerFeedbackProgress/styles';
import { handleCopyToClipboard } from '../../../../../utils/tools/copyToClipboard';

const feedbackTypes = [
  { name: 'New goal - meals', value: '1gXJaqmrANkNt-z0Jz-1TzoZOBD2K5K4MIzlEogkW6Kg' },
  { name: 'New goal - nonmeals', value: '1qVXRBE9Z9vbuu4Ui9hqH2aFWCloPTEFxvTvRwWP4Y28' },
  { name: 'Existing goal - nonmeals', value: '1-OsaAw4niT0Bm-YpJgLi6jIDCMt51CZRpJLq_nr8nEU' },
  { name: 'Existing goal - meals', value: '1ZnILPlQYjlpmJ6FkLGPUHxATB-coDgJVHCwRe6G16E0' },
];

const ActionFeedbackForm = () => {
  const dispatch = useAppDispatch();

  const { customer } = useAppSelector((state) => state.customerInfos);
  const { message, actionId } = useAppSelector((state) => state.progressFeedback);
  const { programAction } = useAppSelector((state) => state.programActions);
  const { weeks, isNutrition, category } = useAppSelector((state) => state.weekProgram);
  const { loading, messages, feedbackLaunched, errorMsg } = useAppSelector(
    (state) => state.automationAi,
  );

  console.log('weeks', weeks);

  const [feedbackType, setFeedbackType] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [extraInfos, setExtraInfos] = useState('');
  const [nextDayTarget, setNextDayTarget] = useState('');
  const [nextDayDetails, setNextDayDetails] = useState('');
  const [mealSize, setMealSize] = useState('about right');

  useEffect(() => {
    if (message && actionId) {
      setAdditionalDetails(message.actionTracking?.details ?? message.body);
    }
  }, []);

  useEffect(() => {
    if (programAction?.categoryId) {
      const { categoryId } = programAction;
      if (categoryId) {
        dispatch(fetchCategory({ categoryId }));
      }
    }
  }, [programAction]);

  useEffect(() => {
    if (category) {
      const { status, subStatus } = programAction ?? {};
      let newOrExisting = '';
      if (status === 'current') {
        newOrExisting = subStatus === 'new' ? 'New goal' : 'Existing goal';
      }
      const mealsOrNonmeals = isNutrition ? 'meals' : 'nonmeals';

      setFeedbackType(`${newOrExisting} - ${mealsOrNonmeals}`);
    }
  }, [message?.id, category?.name]);

  const handleLaunchProgressFeedback = async () => {
    if (customer && programAction && weeks) {
      try {
        const ref = collection(db, 'messages');
        const querySnap = await getDocs(
          query(
            ref,
            where('replyToMessage.actionTracking.actionId', '==', actionId),
            where('userId', '==', customer.id),
            orderBy('createdAt', 'desc'),
            limit(5),
          ),
        );

        const recentAnswers = querySnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const generatedAnswers = recentAnswers.filter((answer: any) => answer.aiGeneratedMessage);
        const previousAnsers = generatedAnswers.map((ga: any) => ga.aiGeneratedMessage);

        const today = new Date();
        const payload = {
          feedbackType,
          client_name: customer.firstName,
          glp1_diabetes_status: getGlp1AndDiabetes(customer),
          weekNumber: getWeekNumberProgram(weeks, today),
          goalArea: programAction.published.goal,
          weekTarget: programAction.published.daysPerWeek,
          lastCount: programAction.lastTracking?.currentDay ?? 'not applicable',
          today,
          additionalDetails: additionalDetails.length > 0 ? additionalDetails : 'not applicable',
          extraInfos,
          nextDayTarget,
          nextDayDetails,
          mealSize,
          recentAnswers:
            previousAnsers.length > 0 ? previousAnsers.join('\n') : 'No previous answers',
          send_referral: customer.referralRequest?.coachSentRequest === true ? 'yes' : 'no',
        };

        dispatch(getCompletion(payload));
      } catch (e) {
        console.log('error trying to generate feedback', e);
      }
    }
  };

  return (
    <Container>
      {errorMsg && <Alert severity="error">An error occurred: {errorMsg}</Alert>}

      {!feedbackLaunched && (
        <Alert severity="info">
          Please fill this form in order to provide feedback to your customer regarding his/her
          progress.
          <br />
        </Alert>
      )}

      {!feedbackLaunched && (
        <Card>
          <CardContent>
            <div>
              <Typography>
                We have pre-selected the type of feedback you need based on customer data, please
                change it if not correct.
                <br />
                <b>Water</b> belongs to <b>non meal feedback</b>, please select non meal new or
                existing if needed.
              </Typography>
              <StyledFormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-label">Select type of feedback</InputLabel>
                <Select
                  // variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={feedbackType}
                  onChange={(e) => setFeedbackType(e.target.value as string)}
                >
                  {feedbackTypes.map((feedback) => (
                    <MenuItem key={feedback.value} value={feedback.value}>
                      {feedback.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormText>Here are the details given by the client :</StyledFormText>
              <TextField
                label="Details given by the client"
                multiline
                fullWidth
                value={additionalDetails}
                onChange={(e) => setAdditionalDetails(e.target.value)}
                variant="outlined"
                helperText="Coach to add any details about the progress"
              />

              <StyledFormText>
                You can describe the ingredients you see on the image or any other element you think
                is relevant to give a better feedback
              </StyledFormText>
              <TextField
                sx={{
                  margin: '10px 0',
                }}
                label="Extra infos"
                multiline
                fullWidth
                rows={2}
                variant="outlined"
                value={extraInfos}
                onChange={(e) => setExtraInfos(e.target.value)}
              />

              {['New goal - meals', 'New goal - nonmeals'].includes(feedbackType) && (
                <>
                  <StyledFormText>
                    Coach to lookup on the program when is the next day for this goal
                  </StyledFormText>
                  <StyledFormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Customer next day to hit target
                    </InputLabel>
                    <Select
                      // variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={nextDayTarget}
                      onChange={(e) => setNextDayTarget(e.target.value as string)}
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                    <FormHelperText>
                      Coach to find the info on either chat or program plan
                    </FormHelperText>
                  </StyledFormControl>
                </>
              )}
              {['New goal - meals', 'New goal - nonmeals'].includes(feedbackType) && (
                <>
                  <StyledFormText>
                    Any extra comment given by the client regarding his next time on this goal?
                  </StyledFormText>
                  <TextField
                    sx={{
                      margin: '10px 0',
                    }}
                    label="Details provided regarding that next day"
                    multiline
                    fullWidth
                    variant="outlined"
                    value={nextDayDetails}
                    onChange={(e) => setNextDayDetails(e.target.value)}
                  />
                </>
              )}
              {['New goal - meals', 'Existing goal - meals'].includes(feedbackType) && (
                <>
                  <StyledFormText>
                    You can change the meal size if you think this is not correct
                  </StyledFormText>
                  <StyledFormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-label">Meal size</InputLabel>
                    <Select
                      // variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mealSize}
                      onChange={(e) => setMealSize(e.target.value as string)}
                    >
                      <MenuItem value="about right">About right</MenuItem>
                      <MenuItem value="too small">Too small</MenuItem>
                      <MenuItem value="too big">Too big</MenuItem>
                    </Select>
                  </StyledFormControl>
                </>
              )}
            </div>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={handleLaunchProgressFeedback}>
              Start feedback
            </Button>
          </CardActions>
        </Card>
      )}

      {loading && (
        <div>
          <CircularProgress /> generating feedback...
        </div>
      )}

      {messages && messages.length > 0 && (
        <Card style={{ marginTop: 10 }}>
          <FeedbackTitle title="Progress feedback:" />
          <CardContent>
            <FeedbackText>{messages[messages.length - 1].content}</FeedbackText>
          </CardContent>
          <CardActions>
            <Tooltip title="Copy to clipboard">
              <IconButton
                onClick={() => handleCopyToClipboard(messages[messages.length - 1].content)}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
        </Card>
      )}
    </Container>
  );
};

export default ActionFeedbackForm;
