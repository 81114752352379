import { FiberManualRecord as CircleIcon } from '@mui/icons-material';
import { formatDateLastSeen } from 'utils/format/datetime';
import { getUserStatus } from 'utils/data/payment';
import {
  Container,
  DateText,
  EmailBlock,
  EmailText,
  Icon,
  NameBlock,
  PaidText,
  SavedText,
  StatusBlock,
  UserNameText,
} from './styles';
import { IChatAllClientsListItem } from 'types';
import { Link } from 'react-router-dom';

const ChatAllClientsListItem = ({ user, isSavedByCurrentCoach }: IChatAllClientsListItem) => {
  const userStatus = getUserStatus(
    user?.billingData?.status,
    user?.billingData.isPaid,
    user?.billingData.isReferralLinkEnabled,
  );

  return (
    <Link to={`/coach-dashboard/client/${user.id}`}>
      <Container>
        <NameBlock>
          <UserNameText>
            {user.firstName} {user.lastName}
          </UserNameText>
          <DateText>{user.lastSeenAt ? `Last seen ${formatDateLastSeen(user)}` : null}</DateText>
        </NameBlock>
        <EmailBlock>
          <EmailText>{user.email}</EmailText>
        </EmailBlock>
        <StatusBlock>
          <PaidText paidColor={userStatus?.color}>{userStatus?.labelShort}</PaidText>
          <Icon as={CircleIcon} />
          <SavedText>{isSavedByCurrentCoach ? 'saved' : 'unsaved'}</SavedText>
        </StatusBlock>
      </Container>
    </Link>
  );
};

export default ChatAllClientsListItem;
