import React from 'react';
import { AddSubTitle } from './styles';
import { EResourceBlockTypes, ISubtitle } from 'types';

const Subtitle = ({ handleBlocksChange, block, index }: ISubtitle) => {
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleBlocksChange((prev) => {
      const copy = [...prev];
      copy[index] = {
        id: block.id,
        body: `## ${event.target.value}`,
        url: '',
        type: EResourceBlockTypes.SUBTITLE,
      };
      return copy;
    });
  };

  return (
    <>
      <AddSubTitle
        placeholder="Add subtitle"
        onChange={handleTitleChange}
        value={block.body.substring(3)}
      />
    </>
  );
};

export default Subtitle;
