import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import WebIcon from '@mui/icons-material/Web';

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
  padding: 5,
});

export const WrapperLogoutIcon = styled('img')({
  width: 15,
  height: 15,
  paddingRight: 5,
  margin: '0 5px',
});

export const LogoutText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

export const WrapperIntranetLink = styled('a')({
  display: 'flex',
  textDecoration: 'none',
  fontFamily: 'Inter',
  fontSize: '14px',
  color: 'rgba(15, 15, 33, 0.4)',
});

export const WrapperIntranetIcon = styled(WebIcon)({
  fontSize: '16px',
  marginTop: '2px',
  marginLeft: '20px',
  marginRight: '5px',
  color: '#9798A1',
});
