import { styled } from '@mui/system';
import { Accordion, AccordionSummary } from '@mui/material';

export const StyledAccordion = styled(Accordion)({
  '& .MuiAccordionDetails-root': {
    border: 'none',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .MuiAccordionSummary-root': {
    border: 'none',
    paddingRight: 0,
    paddingLeft: 0,
  },
  '& .MuiCollapse-root .MuiAccordionSummary-root': {
    paddingLeft: 16,
  },
  '& .MuiCollapse-root .MuiAccordionSummary-root.Mui-expanded': {
    paddingLeft: 16,
    backgroundColor: '#EBEEF5 !important',
  },
  '& .MuiAccordionSummary-root ': {
    minHeight: 'revert',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '&.MuiPaper-root::before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  backgroundColor: '#F9FAFC !important',
  '& .MuiFormControlLabel-root': {
    margin: 0,
    paddingLeft: 30,
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#8587E5',
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 600,
      color: 'rgba(15, 15, 33, 0.6)',
    },
    '& .Mui-checked + .MuiTypography-body1': {
      color: '#8587E5',
    },
  },
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#F9FAFC !important',
  padding: 0,
  '&:hover': {
    backgroundColor: '#F2F4F7 !important',
  },
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.Mui-expanded': {
    marginLeft: 0,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 16,
    height: 20,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
});

export const StyledAccordionDetails = styled('div')({
  padding: 8,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  display: 'flex',
  flexDirection: 'column',
});

export const CategoryName = styled('div')({
  display: 'flex',
  fontWeight: 600,
  fontSize: 16,
  color: 'rgba(15, 15, 33, 0.6)',
});
