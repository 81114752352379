import { useAppDispatch } from '../../../hooks';
import { useEffect } from 'react';
import { setReadOnly } from '../../../store/customers/customerInfosSlice';
import ClientContentContainer from '../ClientContentContainer/ClientContentContainer';

function ClientReadOnly() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setReadOnly(true));

    return () => {
      dispatch(setReadOnly(false));
    };
  }, []);

  return <ClientContentContainer />;
}

export default ClientReadOnly;
