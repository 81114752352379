import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const HeaderBlock = styled('div')({
  display: 'flex',
  width: '100%',
});

export const TitleBlock = styled('div')({
  display: 'flex',
  flexGrow: 1,
});

export const TitleText = styled(Typography)({
  fontSize: 20,
  fontWeight: 700,
});
