import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clearQuotedMessage } from 'store/chat/quotedMessageSlice';
import {
  ActionTrackingDetails,
  ActionTrackingGoal,
  CloseIconBlock,
  Container,
  ContentBlock,
  LineBlock,
  LineDiv,
  MessageBlock,
  MessageText,
  ReplyIconBlock,
  StyledCloseIcon,
  Wrapper,
} from './styles';
import { ReplyIcon } from '../ChatEditFileMessage/styles';

const ChatReplyMessage = () => {
  const dispatch = useAppDispatch();
  const messageQuoted = useAppSelector((state) => state.messageQuoted);

  if (!messageQuoted.id) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <ReplyIconBlock>
          <ReplyIcon />
        </ReplyIconBlock>
        <LineBlock>
          <LineDiv />
        </LineBlock>
        <ContentBlock>
          <MessageBlock hasActionTracking={!!messageQuoted?.actionTracking}>
            <MessageText>
              {messageQuoted?.actionTracking ? (
                <div>
                  {messageQuoted?.actionTracking?.goal && (
                    <ActionTrackingGoal>{messageQuoted?.actionTracking?.goal}</ActionTrackingGoal>
                  )}
                  {messageQuoted?.actionTracking?.details && (
                    <ActionTrackingDetails>
                      {messageQuoted?.actionTracking?.details}
                    </ActionTrackingDetails>
                  )}
                </div>
              ) : (
                <ReactMarkdown
                  children={messageQuoted?.body || ''}
                  components={{ p: 'span' }}
                  remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                />
              )}
            </MessageText>
          </MessageBlock>
        </ContentBlock>
        <CloseIconBlock>
          <StyledCloseIcon onClick={() => dispatch(clearQuotedMessage())} />
        </CloseIconBlock>
      </Container>
    </Wrapper>
  );
};

export default ChatReplyMessage;
