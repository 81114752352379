import { styled } from '@mui/system';
import { Checkbox, FormControl, FormControlLabel, Select, Typography } from '@mui/material';
import Input from '../../../../../common/core/Input';

export const Question = styled('div')({
  padding: '27px',
  marginBottom: '24px',
  background: '#FFFFFF',
  boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
  borderRadius: '8px',
});

export const Row = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '24px',
  color: 'rgba(15, 15, 33, 0.4)',
  '&:last-child': {
    marginBottom: '0',
  },
});

export const LocalWrapper = styled('div')({
  width: '100%',
  display: 'flex',
});

export const Icon = styled('span')({
  marginRight: '11px',
  color: '#8587E5',
});

export const QuestionText = styled(Typography)({
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.87)',
});

export const EditIcon = styled('span')({
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.6)',
});

export const TypeTitle = styled(Typography)({
  marginRight: '35px',
});

export const AnswerTitle = styled(Typography)({
  marginRight: '9px',
});

export const AnswerValue = styled(Typography)({
  overflowWrap: 'anywhere',
});

export const VariantsListWrapper = styled(FormControl)({
  width: '100%',
});

export const VariantWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  paddingBottom: '12px',
  marginLeft: '0px !important',
  marginBottom: '12px',
  borderBottom: '1px solid #E4E7ED',
  '&:last-child': {
    borderBottom: 'none',
  },
});
export const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  display: 'flex',
  paddingBottom: '12px',
  marginLeft: '0px !important',
  marginBottom: '12px',
  borderBottom: '1px solid #E4E7ED',
  '&:last-child': {
    borderBottom: 'none',
  },
});

export const InputQuestion = styled(Input)({
  borderRadius: '8px',
});

export const SliderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  '& .MuiInputBase-input': {
    paddingBottom: '0',
    paddingTop: '0',
  },
  '& .MuiSelect-select:focus': {
    border: 'none',
    background: 'none',
  },
  '& .MuiInput-underline:before': {
    display: 'none',
  },
  '& .MuiInput-underline:after': {
    display: 'none',
  },
});

export const StyledSelect = styled(Select)({
  marginRight: '16px',
});
export const Item = styled('span')({
  marginRight: '16px',
});

export const VariantIcon = styled(Checkbox)({
  marginRight: '11px',
  padding: '0px',
  color: '#0F0F21',

  '&.Mui-checked': {
    color: '#8587E5',
  },
  '&.Mui-disabled': {
    color: 'rgba(15, 15, 33, 0.4)',
  },
});
