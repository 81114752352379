import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Button } from '../ChatProgramComponents';

export const Input = styled('div')(({ theme }) => ({
  margin: '0 20px 0 0',
  maxWidth: 240,
}));

export const InputBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '0 20px',
  gap: 8,
}));

export const SelectBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 55,
}));
