const ChatProgramNutritionHelp = () => {
  return (
    <>
      <h4>How this works</h4>
      <p>
        Nutritional Targets guide clients towards their weight loss goals by providing specific
        calorie, protein, and fiber benchmarks for different meal types. These targets, aiming for a
        steady and healthy weight loss of approximately 1lb per week, are displayed to the client on
        the Breakfast, Lunches & Dinners, Snacks, and Calories Focus Area pages in their app.
      </p>
      <h5>Modes Explained</h5>
      <h5>Baseline calorie target mode :</h5>
      <p>
        This mode automatically generates nutritional targets based on the client's initial sign-up
        data, including BMI and activity levels.
      </p>
      <h5>Refined calorie target mode:</h5>
      <p>
        Tailor the targets by inputting a calorie target agreed upon during end-of-week reviews or
        other conversations. Upon your input, the nutritional targets tables will dynamically update
        in the client app.
      </p>
    </>
  );
};

export default ChatProgramNutritionHelp;
