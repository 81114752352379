import React, { useState, useEffect } from 'react';
import { ArrowDropUpRounded } from '@mui/icons-material';
import { getDateFromUnix, getTimeFromUnix } from 'utils/format/datetime';
import { useChatSurveyUpdate } from 'hooks/chat/useChatSurveyUpdate';
import { EStatusText, EStatus, IChatSurveysHistoryItem } from 'types';
import {
  AccordionDetailsStyled,
  AccordionSummaryStyled,
  Date,
  HeaderWrapper,
  ItemButton,
  ItemTitle,
  ItemWrapper,
  RightSide,
} from './styles';
import Questions from '../ChatSurveysQuestions';

const ChatSurveysHistoryItem = ({
  survey,
  surveyIdCurrent,
  handleSurveyIdCurrent,
}: IChatSurveysHistoryItem) => {
  const { id: surveyId, status, stages, title, createdAt } = survey;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { reactivateSurvey } = useChatSurveyUpdate();

  useEffect(() => {
    setIsExpanded(surveyIdCurrent === surveyId);
  }, [surveyIdCurrent]);

  const handleChangeExpanded = () => {
    handleSurveyIdCurrent(surveyIdCurrent !== surveyId ? surveyId : '');
  };

  const handleReactivate = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    reactivateSurvey(survey);
    handleSurveyIdCurrent('');
  };

  return (
    <ItemWrapper
      expanded={isExpanded}
      onChange={handleChangeExpanded}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummaryStyled
        expandIcon={<ArrowDropUpRounded sx={{ transform: 'rotate(180deg)' }} />}
        aria-controls={title}
        id={surveyId}
      >
        <HeaderWrapper>
          <ItemTitle isDeclined={status === EStatus.CANCELED}>
            {title} - {EStatusText[status]}
          </ItemTitle>
          <RightSide>
            {status === EStatus.CANCELED && (
              <ItemButton onClick={(e) => handleReactivate(e)}>Reactivate</ItemButton>
            )}
            <Date>
              {`Sent at ${getTimeFromUnix(createdAt.seconds)} on 
                    ${getDateFromUnix(createdAt.seconds)}`}
            </Date>
          </RightSide>
        </HeaderWrapper>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        {stages.map(({ questions }, index) => (
          <Questions key={index} isEditable={false} questions={questions} status={status} />
        ))}
      </AccordionDetailsStyled>
    </ItemWrapper>
  );
};

export default ChatSurveysHistoryItem;
