import axios from 'axios';

const generateAccessToken = async () => {
  const res = await axios({
    method: 'POST',
    url: process.env.REACT_APP_TP_TOKEN_URL,
  });

  return res.data.access_token;
};

export const generateTrustPilotInvitationLink = async (email: string, name: string) => {
  const accessToken = await generateAccessToken();
  const res = await axios({
    method: 'POST',
    url: `https://invitations-api.trustpilot.com/v1/private/business-units/${process.env.REACT_APP_TP_ORGID}/invitation-links`,
    data: {
      email,
      name,
      locale: 'en-US',
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return res.data.url;
};
