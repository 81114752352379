import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Menu from 'common/core/Menu';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { useAdminActions } from 'hooks/admin/useAdminActions';
import { IActionListItem } from 'types';
import {
  ActionBodyText,
  ActionTitleBlock,
  ActionTitleIconBlock,
  ActionTitleText,
  BoxContainer,
  ContentBlock,
  StyledMoreVertIcon,
  SubMenu,
  SubMenuDelete,
  TopBlock,
} from './styles';

const ActionsListItem = ({ action, chooseEditAction }: IActionListItem) => {
  const { id: actionId, title, description, categoryId, habitType, sortingIndex } = action;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { deleteAction } = useAdminActions();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    chooseEditAction(actionId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deleteAction(actionId).then(() => {
      toggleOpenConfirmModal();
    });
  };

  return (
    <>
      <BoxContainer
        badgeContent={!categoryId && !habitType && !sortingIndex && 'old'} // delete after migration
        color={!categoryId && !habitType && !sortingIndex ? 'primary' : 'secondary'}
        hasNewItem={!!(categoryId && habitType && sortingIndex)}
      >
        <TopBlock>
          <ActionTitleBlock>
            <ActionTitleText>{title}</ActionTitleText>
          </ActionTitleBlock>
          <ActionTitleIconBlock>
            <IconButton onClick={toggleMenu} size="large">
              <StyledMoreVertIcon />
            </IconButton>
          </ActionTitleIconBlock>
        </TopBlock>
        <ContentBlock>
          <ActionBodyText>{description}</ActionBodyText>
        </ContentBlock>
      </BoxContainer>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <SubMenu onClick={handleEditAction} key="1">
          Edit
          <EditIcon />
        </SubMenu>
        <SubMenuDelete onClick={handleDeleteAction} key="2">
          Delete
          <DeleteIcon />
        </SubMenuDelete>
      </Menu>
      <ModalConfirm
        title="Do you want to delete this action?"
        note="You will not be able to undo this."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
    </>
  );
};

export default ActionsListItem;
