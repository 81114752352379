import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';

export const Container = styled('div')<{ isToggleApiToken?: boolean }>(({ isToggleApiToken }) => ({
  display: 'flex',
  // height: isToggleApiToken ? 'calc(100vh - 44px)' : '100vh',
  width: '100%',
}));

export const LeftBlock = styled('div')({
  width: 340,
  height: '100%',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
});

export const CenterBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  borderLeft: '1px solid #E4E7ED',
});

export const CenterTopBlock = styled('div')<{ isBannerShown?: boolean }>(({ isBannerShown }) => ({
  height: 123 + (isBannerShown ? 40 : 0),
  borderBottom: '1px solid #E4E7ED',
}));

export const CenterMainWrapperBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const RightFAQBlock = styled('div')<{ isToggleApiToken?: boolean }>(
  ({ isToggleApiToken }) => ({
    height: isToggleApiToken ? 'calc(100vh - (123px))' : 'calc(100vh - 123px)',
    backgroundColor: '#FFF',
    // width: '679px !important',
    // minWidth: '679px !important',
  }),
);

export const CenterMessagesContentBlock = styled('div')({
  borderTop: '1px solid #E4E7ED',
  display: 'flex',
  alignSelf: 'flex-end',
  width: '100%',
});

export const CenterMessagesAndTagsBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  overflowY: 'auto',
  flexGrow: 1,
});

export const CenterMessagesWrapperBlock = styled('div')<{
  isToggleApiToken: boolean;
  isBannerShown?: boolean;
}>(({ isToggleApiToken, isBannerShown }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: isToggleApiToken
    ? `calc(100vh - (${isBannerShown ? 123 + 44 + 40 : 123 + 44}px))`
    : `calc(100vh - ${isBannerShown ? 123 + 40 : 123}px)`, // 44px height timer component, 123px height chat header component,40 px height banner
  borderRight: '1px solid #E4E7ED',
}));

export const CenterMessagesBlock = styled('div')<{ isOpenBlock: boolean }>(() => ({
  flexGrow: 1,
  borderLeft: '1px solid #E4E7ED',
  overflowY: 'auto',
}));

export const CenterAddMessageBlock = styled('div')({
  borderLeft: 0,
});

export const CenterProgramBlock = styled('div')({
  height: 'auto',
  paddingTop: 20,
  paddingBottom: 20,
  border: '1px solid #E4E7ED',
  overflowY: 'auto',
  position: 'relative',
});

export const RightTagsBlock = styled('div')({
  width: 342,
  height: '100%',
  backgroundColor: '#FFF',
});

export const AttachmentBlock = styled('div')({
  maxHeight: '30vh',
  height: '100%',
  position: 'relative',
});

export const CloseBtn = styled(IconButton)({
  position: 'absolute',
  right: '15px',
  zIndex: 1,
});

// Continue with the rest of the components in a similar manner
