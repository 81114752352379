import { useMemo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FeatureFlags } from 'utils/data/flag';
import { IFeatureFlagsInfo } from 'types';
import Wrapper from '../Wrapper';
import { FeatureFlagsBlock } from '../styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function featureFlagsDataToOptions(data: typeof FeatureFlags) {
  return Object.entries(data).map(([dataKey, dataValue]) => ({
    ...dataValue,
    key: dataKey,
  }));
}
function featuresFlagsOptionsToData(options: { key: string | unknown; title?: string }[]) {
  const flags: string[] = [];

  options.forEach((option) => {
    flags.push(String(option.key));
  });
  return flags;
}

const allFlagsOptions = featureFlagsDataToOptions(FeatureFlags);

const FeatureFlagsInfo = ({ featureFlags, setFeatureFlags }: IFeatureFlagsInfo) => {
  const initialValue = useMemo(
    () =>
      featureFlags.map((flag) => {
        // FIXME/IDEA: check for flag existence in current app.?
        return { key: flag, title: FeatureFlags[flag as keyof typeof FeatureFlags].title };
      }),
    [featureFlags],
  );

  return (
    <Wrapper blockTitle="Feature flags">
      <FeatureFlagsBlock>
        {allFlagsOptions.map((option, index) => (
          <FormControlLabel
            label={option.title}
            key={`${option.title}${index}`}
            control={
              <Checkbox
                color="secondary"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={featureFlags.includes(option.key)}
                onChange={(_, newValue) => {
                  if (newValue === true && featureFlags.includes(option.key) === false) {
                    const newOptions = [...initialValue, option];
                    setFeatureFlags(featuresFlagsOptionsToData(newOptions));
                  }
                  if (newValue === false && featureFlags.includes(option.key) === true) {
                    const newOptions = initialValue.filter(
                      (prevOption) => prevOption.key !== option.key,
                    );
                    setFeatureFlags(featuresFlagsOptionsToData(newOptions));
                  }
                }}
              />
            }
          />
        ))}
      </FeatureFlagsBlock>
    </Wrapper>
  );
};

export default FeatureFlagsInfo;
