import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import './index.css';
import ContextProvider from './context/ContextProvider';
import Alerts from './common/core/Alerts';
import Router from './Router';
import { store } from './store';
import theme from './theme';
import { Toaster } from 'sonner';

interface ThemeStatusProps {
  danger: string;
  warn: string;
  active: string;
  inactive: string;
  night: string;
}

declare module '@mui/material/styles' {
  interface Theme {
    status: ThemeStatusProps;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status: ThemeStatusProps;
  }
}

Sentry.init({
  dsn: 'https://70cae9998e408db85eb372369f63e604@o4505163647549440.ingest.us.sentry.io/4507072984842240',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'fitmatecoach'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById('root')!);

// Enable React.StrictMode when https://github.com/atlassian/react-beautiful-dnd/issues/2350 will be fixed
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Toaster richColors expand position="bottom-left" />
              <Alerts>
                <App>
                  <Router />
                </App>
              </Alerts>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </ContextProvider>
    </BrowserRouter>
  </Provider>,
);
