import { createSlice } from '@reduxjs/toolkit';
import { IProgressFeedbackInterface } from 'types';

const initialState: IProgressFeedbackInterface = {
  loading: false,
  openedDrawer: false,
  showConfirmClose: false,
  simpleFeedback: false,
};

export const progressFeedbackSlice = createSlice({
  name: 'progressFeedback',
  initialState,
  reducers: {
    initFaq: (state, action) => {
      console.log('initFaq drawer');
      state.openedDrawer = true;
      state.simpleFeedback = true;
      state.message = action.payload.message;
    },
    initFeedback: (state, action) => {
      state.openedDrawer = true;
      state.message = action.payload.message;
      state.actionId = action.payload.actionId;
      state.simpleFeedback = false;
    },
    resetFeedback: (state) => {
      state.message = undefined;
      state.actionId = undefined;
      state.loading = false;
      state.openedDrawer = false;
      state.showConfirmClose = false;
      state.simpleFeedback = false;
    },
    initFeedbackOnMessage: (state, action) => {
      state.openedDrawer = true;
      state.message = action.payload.message;
      state.simpleFeedback = true;
    },
  },
});

export const { initFeedback, resetFeedback, initFeedbackOnMessage, initFaq } =
  progressFeedbackSlice.actions;

export default progressFeedbackSlice.reducer;
