import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')({
  paddingBottom: 0,
});

export const LabelNew = styled(Typography)({
  marginTop: '35px',
  paddingTop: '45px',
  paddingBottom: '15px',
  borderTop: '1px solid #E4E7ED',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
});
