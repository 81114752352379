import { styled } from '@mui/system';
import { AppBar, CardHeader, FormControl, Typography } from '@mui/material';

export const StyledAppBar = styled(AppBar)({
  height: '44px',
  padding: 0,
});

export const AppBarTitle = styled(Typography)({
  fontSize: 16,
  marginLeft: 10,
  position: 'relative',
  bottom: 8,
});

export const StyledFormControl = styled(FormControl)({
  margin: '10px 0',
});

export const FeedbackTitle = styled(CardHeader)({
  fontSize: 15,
});

export const FeedbackText = styled(Typography)({
  whiteSpace: 'pre-wrap',
  fontSize: 14,
});

export const StyledFormText = styled(Typography)({
  margin: '10px 0',
});
