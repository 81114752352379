import {
  DocumentReference,
  doc,
  onSnapshot,
  DocumentSnapshot,
  Timestamp,
} from '@firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';

export const getNewMessageUpdateRef = () =>
  doc(db, 'updates/message_latest') as DocumentReference<{ at: Timestamp }>;

export const getUserSearchIndexUpdateRef = () =>
  doc(db, 'updates/user_search') as DocumentReference<{ at: Timestamp }>;

export const onUserSearchIndexUpdate = (cb: (x: DocumentSnapshot<{ at: Timestamp }>) => void) =>
  onSnapshot(getNewMessageUpdateRef(), { next: cb });
