/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import { ICoachesState, ITeamSupportCoach } from 'types';
import { CoachUser } from '@fitmate-coach/fitmate-types';
import { toast } from 'sonner';
import { Base64 } from 'js-base64';

const initialState: ICoachesState = {
  coaches: [] as CoachUser[],
  teams: [] as any[],
  loading: true,
};

export const fetchCoaches = createAsyncThunk('coaches/fetchCoaches', async () => {
  const q = query(collection(db, 'users'), where('role', '==', 'coach'));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => {
    const data = doc.data();

    return {
      id: doc.id,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      avatarUrl: data.avatarUrl,
      role: data.role,
      timezone: data.timezone,
      shiftData: data.shiftData,
      timeStarting: data.timeStarting,
      timeFinishing: data.timeFinishing,
      isInviteAccepted: data.isInviteAccepted,
      isActive: data.isActive,
      confirmId: data.confirmId,
      createdAt: data.createdAt,
    };
  });
});

export const createTeam = createAsyncThunk(
  'coaches/createTeam',
  async ({
    mainCoach,
    supportiveCoaches,
    slackChannel,
    presentation,
    book10MinCall,
    book20MinCall,
    elevenLabsApiKey,
    elevenLabsVoice,
    videoPresentationUrl,
    actionSpecificGdriveUrl,
  }: {
    mainCoach: ITeamSupportCoach;
    supportiveCoaches: ITeamSupportCoach[];
    slackChannel?: string;
    presentation: string;
    book10MinCall?: string;
    book20MinCall?: string;
    elevenLabsApiKey?: string;
    elevenLabsVoice?: any;
    videoPresentationUrl?: string;
    actionSpecificGdriveUrl?: string;
  }) => {
    await addDoc(collection(db, 'teams'), {
      mainCoach: {
        id: mainCoach.id,
        firstName: mainCoach.firstName,
        lastName: mainCoach.lastName,
        avatarUrl: mainCoach.avatarUrl,
      },
      supportiveCoaches: supportiveCoaches.map((coach: ITeamSupportCoach) => ({
        id: coach.id,
        firstName: coach.firstName,
        lastName: coach.lastName,
        avatarUrl: coach.avatarUrl,
      })),
      ...(book10MinCall && { book10MinCall }),
      ...(book20MinCall && { book20MinCall }),
      ...(slackChannel && { slackChannel }),
      presentation,
      ...(elevenLabsApiKey && { elevenLabsApiKey: Base64.encode(elevenLabsApiKey) }),
      ...(elevenLabsVoice && {
        elevenLabsVoice: {
          name: elevenLabsVoice.name,
          id: elevenLabsVoice.voice_id,
        },
      }),
      ...(videoPresentationUrl && { videoPresentationUrl }),
      ...(actionSpecificGdriveUrl && { actionSpecificGdriveUrl }),
    });
  },
);

export const fetchTeams = createAsyncThunk('coaches/fetchTeams', async () => {
  const q = query(collection(db, 'teams'));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
});

export const updateTeam = createAsyncThunk(
  'coaches/updateTeam',
  async ({
    mainCoach,
    supportiveCoaches,
    editedTeam,
    slackChannel,
    presentation,
    book10MinCall,
    book20MinCall,
    elevenLabsApiKey,
    elevenLabsVoice,
    videoPresentationUrl,
    actionSpecificGdriveUrl,
  }: {
    mainCoach: CoachUser;
    supportiveCoaches: ITeamSupportCoach[];
    editedTeam: string;
    slackChannel?: string;
    presentation: string;
    book10MinCall?: string;
    book20MinCall?: string;
    elevenLabsApiKey?: string;
    elevenLabsVoice?: any;
    videoPresentationUrl?: string;
    actionSpecificGdriveUrl?: string;
  }) => {
    const teamRef = doc(db, 'teams', editedTeam);
    const update: any = {
      mainCoach: {
        id: mainCoach.id,
        firstName: mainCoach.firstName,
        lastName: mainCoach.lastName,
        avatarUrl: mainCoach.avatarUrl,
      },
      supportiveCoaches: supportiveCoaches.map((coach: ITeamSupportCoach) => ({
        id: coach.id,
        firstName: coach.firstName,
        lastName: coach.lastName,
        avatarUrl: coach.avatarUrl,
      })),
      ...(slackChannel && { slackChannel }),
      ...(book10MinCall && { book10MinCall }),
      ...(book20MinCall && { book20MinCall }),
      ...(slackChannel && { slackChannel }),
      presentation,
      ...(elevenLabsApiKey && { elevenLabsApiKey: Base64.encode(elevenLabsApiKey) }),
      ...(elevenLabsVoice && {
        elevenLabsVoice: {
          name: elevenLabsVoice.name,
          id: elevenLabsVoice.id ?? elevenLabsVoice.voice_id,
        },
      }),
      ...(videoPresentationUrl && { videoPresentationUrl }),
      ...(actionSpecificGdriveUrl && { actionSpecificGdriveUrl }),
    };

    try {
      await updateDoc(teamRef, update);
    } catch (e) {
      console.log('error updating team', e);
      console.log(elevenLabsVoice);
    }
  },
);

export const deleteTeam = createAsyncThunk('coaches/deleteTeam', async (args) => {
  // @ts-ignore
  const { id } = args;
  const teamRef = doc(db, 'teams', id);

  return deleteDoc(teamRef);
});

export const updateAssignmentRateForTeams = createAsyncThunk(
  'coaches/updateAssignmentRateForTeams',
  async (args: any) => {
    for (const team of Object.keys(args)) {
      const teamRef = doc(db, 'teams', team);
      const update = {
        assignmentRate: parseFloat(String(Number(args[team]) / 100)).toFixed(2),
      };
      try {
        await updateDoc(teamRef, update);
        toast.success('Assignment rate updated successfully');
      } catch (e) {
        console.log(e);
      }
    }
  },
);

export const coachesSlice = createSlice({
  name: 'coaches',
  initialState,
  reducers: {
    setEditTeam: (state, action) => {
      state.editedTeam = action.payload;
    },
    resetEditTeam: (state) => {
      state.editedTeam = undefined;
    },
    setDeleteTeam: (state, action) => {
      state.teamToDelete = action.payload;
    },
    resetDeleteTeam: (state) => {
      state.teamToDelete = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoaches.fulfilled, (state, action) => {
      state.coaches = action.payload;
    });
    builder.addCase(createTeam.fulfilled, (state) => {
      state.loading = true;
      toast.success('Team added successfully');
    });
    builder.addCase(createTeam.rejected, (state, action) => {
      console.log('impossible to add the team', action);
      toast.error('Impossible to add the team');
    });
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      state.loading = false;
      state.teams = action.payload;
    });
    builder.addCase(updateTeam.fulfilled, (state) => {
      state.loading = true;
      state.editedTeam = undefined;
      toast.success('Team updated successfully');
    });
    builder.addCase(deleteTeam.fulfilled, (state) => {
      state.loading = true;
      state.teamToDelete = undefined;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setEditTeam, resetEditTeam, setDeleteTeam, resetDeleteTeam } = coachesSlice.actions;

export default coachesSlice.reducer;
