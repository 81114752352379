import { IChatMessage } from 'types/chat';

export interface ISurveyTemplate {
  id: string;
  title: string;
  questions: ISurveyQuestion[];
  // startMessage: string;
  // endMessage: string;
  createdAt?: number; // TODO must be required
  actionId?: string;
  onboardingType?: ESurveyType.ONBOARDING | ESurveyType.ONBOARDING_ACTIVE;
}

export interface IChatSurveysActive {
  surveysList: ISurvey[];
}

export enum ESurveysQuestionTypes {
  CHECKBOX = 'checkbox',
  SLIDER = 'slider',
  TEXT = 'text',
  RADIO = 'radio',
  MULTI_TEXT = 'multi_text',
  MULTI_TEXT_WITH_UNITS = 'multi_text_with_units',
  TEXT_WITH_UNITS = 'text_with_units',
  DATE = 'date',
}

export enum EExtendsSurveysQuestionTypes {
  DATE = 'date',
}

export interface ISurveyAnswer {
  answers: string[];
  createdAt: number;
}

export interface ISlider {
  max: number;
  minDescription: string;
  maxDescription: string;
}

export interface ISliderScale {
  id: string;
  slider: ISlider;
  isDisabled: boolean;
  sliderHandler: (id: string, max: number, minDescription: string, maxDescription: string) => void;
}

export interface ITextFieldFormat {
  min?: number;
  max?: number;
  type: ETypeTextFieldFormat;
}

export enum ETypeTextFieldFormat {
  INTEGER = 'integer',
  FLOAT = 'float',
  STRING = 'string',
  FEET_INCH = 'feet_and_inch',
  TEXT_WITH_UNIT = 'text_with_units',
}

export interface IAnswerVariant {
  answer: string;
  answerId: string;
  nextQuestionId: string | null;
  error: boolean;
}

export interface ISurveyQuestion {
  id: string;
  title: { value: string; error: boolean };
  category: { value: string; error: boolean };
  question: { value: string; error: boolean };
  helpText?: { value: string; error: boolean };
  type: string;
  answerVariants: IAnswerVariant[];
  slider: ISlider;
  textFieldFormat: ITextFieldFormat | null;
  defaultNextQuestionId: string | null;
  answer?: ISurveyAnswer;
  tags: string[] | null;
  unit?: { value: string; error: boolean };
  isRequired?: { value: string; error: boolean };
  isLastQuestion?: boolean;
}

export interface ISurveyQuestionCoach {
  id: string;
  title: string;
  question: string;
  type: string;
  answerVariants: IAnswerVariant[];
  slider: ISlider;
  defaultNextQuestionId: string | null;
  answer?: ISurveyAnswer;
  unit?: string;
}

export enum EChatSurveyTabs {
  NEWSURVEY = 'newSurvey',
  HISTORY = 'history',
}

export interface ISurvey {
  id?: string;
  userId: string;
  title: string;
  // startMessage: string;
  // endMessage: string;
  stages: any[];
  createdAt?: any; // TODO must be required
  status: EStatus;
}

export interface ISurveyExistent extends ISurvey {
  id: string;
}

export enum EStatus {
  ASSIGNED = 'assigned',
  IN_PROGRESS = 'in_progress',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  APPROVED = 'approved',
}

export enum EStatusText {
  'assigned' = 'Assigned',
  'in_progress' = 'In progress',
  'canceled' = 'User declined the survey',
  'completed' = 'Completed by user',
  'approved' = 'Completed',
}

export interface IMessageSurveyData {
  surveyId: string;
  status?: string;
  questionId?: string;
  answers?: string[];
}

// this type only for internal use, only on WEB
export enum ESurveyType {
  ONBOARDING = 'onboarding',
  ONBOARDING_ACTIVE = 'onboarding_active',
  REGULAR = 'regular',
  ACTION = 'action',
}

export enum ESurveyTypeField {
  DATE_OF_BIRTH = 'client_info_dateOfBirth',
  PREGNANCY = 'client_info_pregnancy',
  HEIGHT = 'client_info_height',
  STRESS_LEVEL = 'client_info_stressLevel',
  PHYSICAL_ACTIVITY = 'client_info_physicalActivity',
  LEAN_PROTEIN_AT_BREAKFAST = 'client_info_proteinsBreakfast',
  LEAN_PROTEIN = 'client_info_proteinsLunches',
  SIMPLE_CARBS = 'client_info_simpleCarbs',
  FOOD_CRAVINGS = 'client_info_foodCravings',
}

export enum ESurveyTypeFieldLabel {
  DATE_OF_BIRTH = 'Date of birth',
  PREGNANCY = 'Pregnancy status',
  HEIGHT = 'Height',
  STRESS_LEVEL = 'Stress levels',
  PHYSICAL_ACTIVITY = 'Physical activity (hrs / week)',
  LEAN_PROTEIN_AT_BREAKFAST = 'Lean protein at breakfast',
  LEAN_PROTEIN = 'Lean protein (lunch & dinner)',
  SIMPLE_CARBS = 'Simple carbs (lunch & dinner)',
  FOOD_CRAVINGS = 'Food cravings',
}

export type TSurveyData = {
  surveyId: string;
  status?: 'canceled' | 'completed' | 'assigned' | 'in_progress';
  questionId?: string;
  answers?: string[];
};

export interface ISurveyDivider {
  title: string;
}

export enum EEditorSurveyAction {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
}

export interface ISurveyMarkdownEditor {
  initialValue: string;
  value: string;
  handleValue: (value: string) => void;
  maxLength: number;
  error: boolean;
  setError: (value: boolean) => void;
}

export interface ISurveyStyle {
  BOLD: boolean;
  ITALIC: boolean;
  UNDERLINE: boolean;
  [key: string]: boolean;
}

export interface ISurveyBreadCrumbs {
  handleCancel: () => void;
  type: string;
}

export interface ISurveysItem {
  surveysItem: ISurveyTemplate;
}

export interface ISurveyCheckbox {
  id: string;
  answerVariants: any[];
  optionHandler: (id: string, key: string, answer: string | boolean, answerId: string) => void;
  deleteHandler: (answerId: string) => void;
  addOptionHandler: (id: string) => void;
  isDisabled: boolean;
}

export interface ISurveyOption {
  block: ISurveyQuestion;
  textFieldFormat: ITextFieldFormat | null;
}

export interface ISurveyRadioButton {
  id: string;
  questionsList: ISurveyQuestion[];
  answerVariants: any[];
  optionHandler: (
    id: string,
    key: string,
    answer: string | boolean,
    answerId: string | null,
  ) => void;
  addOptionHandler: (id: string) => void;
  deleteHandler: (answerId: string) => void;
  isDisabled: boolean;
}

export interface IQuestionsSurvey {
  selectedSurveyActions: ISurveyTemplate[];
  questionsList: ISurveyQuestion[];
  listQuestionsCoach: ISurveyQuestion[];
  setListQuestionsCoach: (question: ISurveyQuestion[]) => void;
  inputHandler: (id: string, key: string, value: string, error: boolean) => void;
  questionHandler: (id: string, key: string, value: string | null) => void;
  optionHandler: (
    id: string,
    key: string,
    value: string | boolean,
    answerId: string | null,
  ) => void;
  addOptionHandler: (id: string) => void;
  deleteOptionHandler: (id: string, answerId: string) => void;
  sliderHandler: (id: string, max: number, minDescription: string, maxDescription: string) => void;
  handleAddBlock: () => void;
  handleDelete: (index: number) => void;
  copyClickHandler: (index: number) => void;
}

export interface IChatQuestionSurvey {
  indexStage?: number;
  isEditable: boolean;
  questions: ISurveyQuestionCoach[];
  status: string;
  suerveyId?: string;
  handleEditSurvey?: (
    surveyId: string,
    indexStage: number,
    questionId: string,
    value: string[],
  ) => void;
}

export interface ICheckboxComponentSurvey {
  indexStage?: number;
  data: ISurveyQuestionCoach;
  isEditable: boolean;
  suerveyId?: string;
  handleEditSurvey?: (
    surveyId: string,
    indexStage: number,
    questionId: string,
    value: string[],
  ) => void;
}

export interface ICheckboxSurveyValueAnswers {
  text: string;
  checked: boolean;
}

export interface IMultiTextSurvey {
  indexStage?: number;
  data: ISurveyQuestionCoach;
  isEditable: boolean;
  suerveyId?: string;
  handleEditSurvey?: (
    surveyId: string,
    indexStage: number,
    questionId: string,
    value: string[],
  ) => void;
}

export interface IRadioSurvey {
  indexStage?: number;
  data: ISurveyQuestionCoach;
  isEditable: boolean;
  suerveyId?: string;
  handleEditSurvey?: (
    surveyId: string,
    indexStage: number,
    questionId: string,
    value: string[],
  ) => void;
}

export interface ISliderSurvey {
  indexStage?: number;
  data: ISurveyQuestionCoach;
  isEditable: boolean;
  suerveyId?: string;
  handleEditSurvey?: (
    surveyId: string,
    indexStage: number,
    questionId: string,
    value: string[],
  ) => void;
}

export interface ITextSurvey {
  indexStage?: number;
  data: ISurveyQuestionCoach;
  isEditable: boolean;
  suerveyId?: string;
  handleEditSurvey?: (
    surveyId: string,
    indexStage: number,
    questionId: string,
    value: string[],
  ) => void;
}

export interface INextQuestion {
  id: string;
  nextQuestionId: string | null;
  questionHandler?: (id: string, type: string, value: string | null) => void | undefined;
  optionHandler?: (
    id: string,
    key: string,
    answer: string,
    answerId: string | null,
    error: boolean,
  ) => void | undefined;
  answerId?: string;
  blocks: ISurveyQuestion[];
  isRadio: boolean;
  error?: boolean;
  isDisabled?: boolean;
}

export interface IQuestionsBlock {
  block: ISurveyQuestion;
  blocks: ISurveyQuestion[];
  index: any;
  deleteClickHandler: (id: string, key: number) => void;
  copyClickHandler: (index: number) => void;
  inputHandler: (id: string, key: string, value: string, error: boolean) => void;
  questionHandler: (
    id: string,
    key: string,
    value:
      | string
      | boolean
      | ITextFieldFormat
      | ESurveysQuestionTypes
      | EExtendsSurveysQuestionTypes
      | null,
  ) => void;
  optionHandler: (
    id: string,
    key: string,
    value: string | boolean,
    answerId: string | null,
  ) => void;
  sliderHandler?: (id: string, max: number, minDescription: string, maxDescription: string) => void;
  addOptionHandler: (id: string) => void;
  deleteOptionHandler?: (id: string, answerId: string) => void;
  showDeleteButton?: boolean;
  typeOfSurvey: string;
  targetFieldHandler: (id: string, targetFieldValue: string) => void;
}

export interface ISurveyChip {
  message: IChatMessage;
}

export interface IChatSurveysHistory {
  surveysList: ISurveyExistent[];
  onboardingSurveys: ISurveyExistent[];
}

export interface IChatSurveysHistoryItem {
  survey: ISurveyExistent;
  surveyIdCurrent: string;
  handleSurveyIdCurrent: (value: string) => void;
}

export interface IChatSurveysTemplates {
  surveysTemplateList: ISurveyTemplate[];
  userId: string;
}

export interface IQuestion {
  index: number;
  id: string;
  question: ISurveyQuestion;
  nextQuestionList: ISurveyQuestion[];
  inputHandler: (id: string, key: string, value: string, error: boolean) => void;
  questionHandler: (id: string, key: string, value: string | null) => void;
  isDisabled: boolean;
  optionHandler: (
    id: string,
    key: string,
    value: string | boolean,
    answerId: string | null,
  ) => void;
  addOptionHandler: (id: string) => void;
  deleteOptionHandler: (id: string, answerId: string) => void;
  sliderHandler: (id: string, max: number, minDescription: string, maxDescription: string) => void;
  handleDelete: (index: number) => void;
  copyClickHandler: (index: number) => void;
}

export interface IQuestionsForActions {
  surveyListActions: ISurveyTemplate[];
  selectedSurveyActions: ISurveyTemplate[];
  setSelectedSurveyActions: (newValue: any) => void;
}
