import { useEffect, useState } from 'react';
import { default as axios } from 'axios';
import { remapData } from './profileEditServices';
import { IFilter, ISort, TClientSearchUser } from 'types';
import { toast } from 'sonner';

export const useGetUsersList = (
  usersPerPage: number,
  currentPage: number,
  sortValue: ISort,
  filtersValues: IFilter[],
  searchValue: string,
  allFilters: any = [{ role: ['user'] }],
) => {
  const [usersAllList, setUsersAllList] = useState<TClientSearchUser[]>([]);
  const [usersSize, setUsersSize] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    const url = `https://${process.env.REACT_APP_ELASTIC_APP_SEARCH_URL}/api/as/v1/engines/${process.env.REACT_APP_ELASTIC_APP_SEARCH_USERS_INDEX}/search`;

    const filters = filtersValues.filter((item) => item.checked).map((item) => item.query);

    const data = {
      query: searchValue,
      page: {
        size: usersPerPage,
        current: currentPage,
      },
      sort: [{ [sortValue.query.key]: sortValue.query.value }],
      filters: {
        all: allFilters,
        any: filters,
      },
      search_fields: {
        email: { weight: 10 },
        firstname: { weight: 9 },
        lastname: { weight: 8 },
      },
    };

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_ELASTIC_APP_SEARCH_SEARCH_TOKEN}`,
    };

    axios
      .post<any>(url, data, {
        headers,
      })
      .then((result) => {
        setUsersAllList(remapData(result.data.results));
        setUsersSize(result.data.meta.page.total_results);
        setTotalPages(result.data.meta.page.total_pages);
      })
      .catch((error) => {
        console.log(error);
        toast.error('Network error');
      });

    return () => {
      setUsersAllList([]);
    };
  }, [usersPerPage, currentPage, sortValue, searchValue, filtersValues]);

  return { usersAllList, usersSize, totalPages };
};
