import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const HandoutItem = styled('div')({
  display: 'flex',
  padding: 16,
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: 443,
  width: '100%',
  border: '1px solid #E4E7ED',
  boxShadow: '0px 2px 6px rgba(0, 2, 23, 0.075)',
  borderRadius: 12,
  cursor: 'pointer',
});

export const ThreeDots = styled(MoreVertIcon)({
  color: 'rgba(15, 15, 33, 0.4)',
  padding: 0,
});
