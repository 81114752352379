const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const convertToSeconds = (time: string) => {
  return time
    .concat(':00')
    .split(':')
    .map((number) => parseInt(number, 10))
    .reduce((acc, curr) => 60 * acc + curr)
    .toString();
};

export const convertToTime = (seconds: number) => {
  const time: any = parseFloat(String(seconds)).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  if (!seconds) {
    return '';
  }
  return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}`;
};

export const convertToMinutes = (seconds: string) => {
  return Math.floor(parseInt(seconds) / 60);
};

export const timeConvert = (minutesValue: number) => {
  const hours = minutesValue / 60;
  const roundedHours = Math.floor(hours);
  const minutes = (hours - roundedHours) * 60;
  const roundedMinutes = Math.round(minutes);
  if (minutesValue >= 60) {
    return `${roundedHours} h ${roundedMinutes} mins`;
  }
  return `${minutesValue} mins`;
};
