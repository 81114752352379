import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  height: 56,
  backgroundColor: '#F2F4F7',
});

export const IntranetBlock = styled('div')({
  backgroundColor: '#F2F4F7',
});

export const LeftBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
});

export const AvatarBlock = styled('div')({
  paddingLeft: 20,
  paddingRight: 10,
  cursor: 'pointer',
});

export const Avatar = styled('div')({
  width: 30,
  height: '30px !important',
});

export const NameBlock = styled('div')({
  width: '100%',
});

export const RightBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: 23,
});

export const NameText = styled(Typography)({
  fontSize: 15,
  fontWeight: 600,
  color: '#0F0F21',
  cursor: 'pointer',
});
