import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { getDocs, query, where } from 'firebase/firestore';
import { Typography } from '@mui/material';
import { getNewMessageUpdateRef, getUserCollectionRef } from 'api/firestores';
import { getClientUserStatus } from 'utils/data/user';
import { Danger, Warn } from './styles';
import { ClientUser, TFireBaseUser } from '@fitmate-coach/fitmate-types';

const ChatThreadsFilterNoCoachLabel = (p: { active: boolean }) => {
  const [refresh] = useDocumentData(getNewMessageUpdateRef());
  const [users, setUsers] = useState<TFireBaseUser[]>([]);
  const [newUsers, setNewUsers] = useState<TFireBaseUser[]>([]);

  useEffect(() => {
    let isMounted = true;

    getDocs(query(getUserCollectionRef(), where('billingData.status', '!=', 'cancelled'))).then(
      (querySnapshot) => {
        if (!isMounted) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const users: ClientUser[] = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        const filtered = users.filter((user) => !user.coachAssignment?.team);

        setUsers(filtered);
      },
    );

    return () => {
      isMounted = false;
    };
  }, [refresh?.at.toMillis()]);

  useEffect(() => {
    if (users && users.length > 0) {
      const filtered = users.filter((u) => u.partner === undefined && u.appVersion !== undefined);
      setNewUsers(filtered);
    }
  }, [users]);

  const statuses = newUsers.map((x) => getClientUserStatus(x));
  const danger = statuses?.reduce((a, b) => (b.status === 'danger' ? a + 1 : a), 0);
  const warn = statuses?.reduce((a, b) => (b.status === 'warn' ? a + 1 : a), 0);

  return (
    <div style={{ display: 'flex', gap: '.3rem', alignItems: 'center' }}>
      <Typography
        color={p.active ? 'primary' : 'initial'}
        style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(15, 15, 33, 0.6)' }}
      >
        New {newUsers.length > 0 ? `(${newUsers.length})` : ''}
      </Typography>
      {danger ? <Danger>{danger}</Danger> : null}
      {warn ? <Warn>{warn}</Warn> : null}
    </div>
  );
};

export default ChatThreadsFilterNoCoachLabel;
