import { createSlice } from '@reduxjs/toolkit';
import { IProgramActionsMessageInterface } from 'types';
import { excludeRegEx, httpRegex } from 'utils/format/chat';

const initialState: IProgramActionsMessageInterface = {
  message: '',
  needEditing: false,
};

export const messageAnalyzeSlice = createSlice({
  name: 'messageAnalyze',
  initialState,
  reducers: {
    analyzeExcludedCharactersMessage: (state, action) => {
      state.message = action.payload;
      // analyze remove http link
      state.needEditing = action.payload.replace(httpRegex, ' ').match(excludeRegEx) !== null;
    },
    resetAnalysis: (state) => {
      state.message = '';
      state.needEditing = false;
    },
  },
});

export const { analyzeExcludedCharactersMessage, resetAnalysis } = messageAnalyzeSlice.actions;

export default messageAnalyzeSlice.reducer;
