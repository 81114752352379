import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
  padding: '7px 0 7px 7px',
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const HeaderIcon = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.4)',
}));

export const TypographyWrapper = styled('div')(({ theme }) => ({
  marginBottom: '14px',
}));

export const ListWrapper = styled('ul')(({ theme }) => ({
  paddingLeft: 25,
  '& li': {
    fontSize: '14px',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: '12px',
}));

export const Text = styled('span')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '22px',
}));

export const Bold = styled('span')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: 600,
}));
