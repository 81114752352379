import { styled } from '@mui/system';
import { Chip, Typography } from '@mui/material';
import Button from 'common/core/Button';
import { EPaymentListType } from '../../../../types';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 123,
  backgroundColor: '#fff',
  margin: 0,
  padding: 0,
  borderLeft: '1px solid #E4E7ED',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: 10,
  fontWeight: 'bold',
}));

export const TopBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '60%',
}));

export const BottomBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '40%',
}));

export const TopLeftBlock = styled('div')(({ theme }) => ({
  marginRight: '15px',
}));

export const TopRightBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px',
}));

export const ClientInfoBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginLeft: 32,
}));

export const ClientInfoTopBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const ClientInfoBottomBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'row wrap',
  gap: '.5rem',
  width: '100%',
  marginTop: '.3rem',
}));

export const TabsBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  paddingLeft: 10,
  height: '100%',
}));

export const BottomIconsBlock = styled('div')(({ theme }) => ({
  marginRight: 17,
  marginLeft: 5,
}));

export const BottomIconsPlanner = styled('div')<{ isMyPlanDrawerOpen: boolean }>(
  ({ isMyPlanDrawerOpen, theme }) => ({
    marginLeft: 5,
    ...(isMyPlanDrawerOpen
      ? { color: theme.palette.primary.main }
      : { color: 'rgba(15, 15, 33, 0.4)' }),
  }),
);

export const ClientNameText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: '#0F0F21',
  whiteSpace: 'nowrap',
  marginTop: 12,
}));

export const LastSeenDateText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
}));

export const TabStyled = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  textTransform: 'none',
  color: 'rgba(15, 15, 33, 0.4)',
}));

export const TabChosen = styled('div')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  textTransform: 'none',
  color: '#8587E5',
}));

export const TabIndicator = styled('div')(({ theme }) => ({
  height: 4,
  backgroundColor: '#8587E5',
  borderRadius: '3px 3px 0px 0px',
}));

export const StatusBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: 20,
  whiteSpace: 'nowrap',
}));

export const ActiveStatus = styled(StatusBlock)(({ theme }) => ({
  cursor: 'pointer',
}));

const colorStatus = (status: string) => {
  switch (status) {
    case 'paid':
    case EPaymentListType.ACTIVE:
    case EPaymentListType.ACTIVE_REF:
      return '#24C09F';
    case EPaymentListType.UNPAID:
    case EPaymentListType.RETARGETING:
    case EPaymentListType.TRIAL:
      return '#FBB867';
    case EPaymentListType.CANCELLED:
      return '#FF405C';
    default:
      return '#8587E5';
  }
};

export const CancellationDate = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 600,
  color: '#FF405C',
}));

export const PaymentStatus = styled(Typography)<{ paymentStatus?: string }>(
  ({ paymentStatus }) => ({
    fontSize: 14,
    fontWeight: 600,
    color: colorStatus(paymentStatus!),
  }),
);

export const PaidText = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
}));

export const PaidStatus = styled('div')(({ theme }) => ({
  color: '#24C09F',
}));

export const UnpaidStatus = styled('div')(({ theme }) => ({
  color: '#FBB867',
}));

export const CancelledStatus = styled('div')(({ theme }) => ({
  color: '#FF405C',
}));

export const PaidIcon = styled('div')(({ theme }) => ({
  width: 18,
  height: 18,
  color: 'rgba(15, 15, 33, 0.4)',
  paddingLeft: 7,
}));

export const PaidIconActivated = styled('div')(({ theme }) => ({
  color: '#FF6D7D',
}));

export const ReadText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: 'rgba(15, 15, 33, 0.4)',
}));

export const ReadTextActivated = styled(ReadText)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const ReadIcon = styled('div')(({ theme }) => ({
  width: 20,
  height: 20,
  color: 'rgba(15, 15, 33, 0.4)',
  paddingLeft: 2,
}));

export const ReadIconActivated = styled(ReadIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SavedText = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: 'rgba(15, 15, 33, 0.4)',
}));

export const SavedTextActivated = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SavedIcon = styled('div')(({ theme }) => ({
  width: 20,
  height: 20,
  color: 'rgba(15, 15, 33, 0.4)',
  paddingLeft: 2,
}));

export const SavedIconActivated = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const BottomIcon = styled('svg')<{ isCopilotOpen?: boolean }>(
  ({ isCopilotOpen, theme }) => ({
    fontSize: 18,
    color: 'rgba(15, 15, 33, 0.4)',
    cursor: 'pointer',
    margin: '0 5px',
    ...(isCopilotOpen && { color: theme.palette.primary.main }),
  }),
);

export const BottomIconChosen = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const TagBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'space-between',
}));

export const TagChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  height: '25px',
  margin: '0 5px',
}));

export const TagChipMc = styled('div')(({ theme }) => ({
  backgroundColor: '#FF6D7D',
  color: '#fff',
  height: '25px',
  margin: '0 5px',
}));
