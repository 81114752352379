import { styled, Theme } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderLeft: '1px solid #E4E7ED',
}));

export const ContentBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 413,
}));

export const NoteBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: 12,
}));

export const IconBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const NoteText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: 'rgba(15, 15, 33, 0.6)',
  fontWeight: 600,
  lineHeight: '150%',
}));

export const IconArrow = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: 28,
  color: 'rgba(15, 15, 33, 0.4)',
}));
