import { useState } from 'react';
import { default as axios } from 'axios';
import { v4 } from 'uuid';
import { storage } from 'utils/firebase/firebaseInit';
import { IFilesAttached } from 'types';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

export const useFilesUpload = () => {
  const [filesList, setFilesList] = useState<IFilesAttached[]>([]);
  const [progress, setProgress] = useState<number>(0);

  const emptyFilesList = async () => {
    await setFilesList([]);
  };

  const uploadFiles = async (files: any[], destination: string) => {
    const promises: any[] = [];

    files.map((file: any) => {
      const fileNameParts = file.name.split('.');
      const fileNameNew = v4() + '.' + fileNameParts[fileNameParts.length - 1];

      const uploadRef = ref(storage, `${destination}/${fileNameNew}`);
      const uploadTask = uploadBytesResumable(uploadRef, file);
      promises.push(uploadTask);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
          throw error;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            const { id, name, size, type } = file;
            setFilesList((prevState) => [...prevState, { id, name, size, type, url }]);
          });
        },
      );
      return true;
    });

    Promise.all(promises).catch((error) => console.error(error));
  };

  // if you do not connect to the local database, you will get an CORS error
  const uploadHtml = async (url: string) => {
    return axios.get(url).then((result) => result.data);
  };

  const removeFile = async (url: string) => {
    const imageRef = ref(storage, url);
    await deleteObject(imageRef);
  };

  return {
    uploadFiles,
    progress,
    filesList,
    emptyFilesList,
    removeFile,
    uploadHtml,
  };
};
