import { styled } from '@mui/system';
import { FormControlLabel, MenuItem, Radio, Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '6px 16px 6px 12px',
  backgroundColor: '#fff',
});

export const RightBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const FilterWrapper = styled('div')({
  display: 'flex',
  cursor: 'pointer',
});

export const FilterIcon = styled('div')({
  color: 'rgba(15, 15, 33, 0.4)',
  marginRight: 11,
});

export const MenuFilter = styled('div')({
  padding: '16px',
  background: '#F9FAFC',
  border: '1px solid #E4E7ED',
  boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
  borderRadius: '8px',
});

export const StyledMenuItem = styled(MenuItem)({
  padding: '0px',
  marginBottom: '8px',
  cursor: 'initial',
  '&:hover': {
    backgroundColor: '#F9FAFC',
  },
  '&:last-child': {
    marginBottom: '0',
  },
});

export const MenuHeader = styled(StyledMenuItem)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '11px',
  borderBottom: '1px solid #E4E7ED',
});

export const FilterText = styled(Typography)({
  fontSize: '16px',
});

export const ResetText = styled(Typography)({
  fontWeight: 400,
  fontSize: '12px',
  color: '#FE8B98',
  cursor: 'pointer',
});

export const ItemRadio = styled(Radio)({
  padding: '0px 9px 0px 9px',
});

export const ThreadsFilterOptionElement = styled(FormControlLabel)({
  marginTop: 8,
});

export const ThreadsFilterOptionText = styled(Typography)<{ isOpen: boolean }>(({ isOpen }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: '#000000',
  lineHeight: '21px',
  ...(isOpen && {
    color: '#6B6ADE !important',
  }),
}));

export const FontFilter = styled(Typography)({
  fontWeight: 400,
});
