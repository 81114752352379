import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Button from '../../../common/core/Button';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled('div')({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 368,
  padding: 20,
  backgroundColor: '#fff',
});

export const LogoBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: 47,
  paddingBottom: 27,
  paddingLeft: 47,
  paddingRight: 47,
});

export const TitleBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingTop: 47,
  paddingBottom: 27,
  paddingLeft: 47,
  paddingRight: 47,
});

export const Logo = styled('img')({
  width: 120,
});

export const TitleText = styled('div')({
  fontWeight: 'bold',
  fontSize: 40,
  lineHeight: '48px',
  color: '#000000',
});

export const LoginBlock = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: 47,
  paddingRight: 47,
  paddingBottom: 10,
});

export const LoginText = styled(Typography)({
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '24px',
  color: '#000000',
});

export const Element = styled('div')({
  display: 'flex',
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 47,
  paddingRight: 47,
});

export const ButtonBlock = styled('div')({
  paddingTop: 50,
  paddingLeft: 47,
  paddingRight: 47,
});

export const ForgotBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 34,
  paddingLeft: 47,
  paddingRight: 47,
});

export const ForgotText = styled(Typography)({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '24px',
  color: '#000000',
});

export const ErrorText = styled('div')({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '20px',
  color: 'red',
});

export const LinkText = styled('div')({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '20px',
  cursor: 'pointer',
});

export const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
  color: 'black',
});

export const StyledButton = styled(Button)({
  height: 56,
  fontWeight: 500,
  fontSize: 20,
  borderRadius: 8,
  margin: 0,
  ':disabled': {
    opacity: 0.5,
  },
});
