import cn from 'mcn';
import React from 'react';
import { Modal } from '@mui/material';

const ModalDialog = (props: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: 'lg' | 'md';
}) => {
  const size = {
    md: 'max-w-[23rem]',
    lg: 'max-w-[30rem]',
  }[props.size ?? 'md'];

  return (
    <Modal {...props} className="flex items-center justify-center p-8">
      <div
        className={cn(
          'bg-white w-full p-8 text-center rounded-xl overflow-y-auto max-h-full',
          size,
        )}
      >
        {props.children}
      </div>
    </Modal>
  );
};

export default ModalDialog;
