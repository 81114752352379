import { memo, forwardRef } from 'react';
import { CustomContentProps, SnackbarContent } from 'notistack';
import { styled } from '@mui/system';

const defaultBackgroundColor = '#333333';

const Root = styled(SnackbarContent)(() => ({
  backgroundColor: defaultBackgroundColor,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: 1.43,
  letterSpacing: '0.01071em',
  color: '#fff',
  alignItems: 'center',
  padding: '6px 16px',
  borderRadius: '4px',
  boxShadow:
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
}));

const Message = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
  padding: '8px',
  borderRadius: 64,
});

const ThemeResponsiveSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, forwardedRef) => {
    const { message } = props;

    return (
      <Root ref={forwardedRef}>
        <Message>{message}</Message>
      </Root>
    );
  },
);

export default memo(ThemeResponsiveSnackbar);
