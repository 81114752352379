import { styled } from '@mui/system';
import Button from '../../../../../common/core/Button';

export const CancelBtn = styled(Button)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'transparent',
  color: 'rgba(15, 15, 33, 0.6)',
  '&:hover': {
    border: '1px solid #8587E5',
    backgroundColor: 'rgba(133, 135, 229, 0.04)',
  },
}));

export const CreateBtn = styled(Button)(({ theme }) => ({
  marginRight: '20px !important',
  color: 'white',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: 'rgb(93, 94, 160)',
  },
}));
