import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingTop: 11,
  paddingBottom: 11,
  marginTop: 30,
});

export const NameBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '31%',
});

export const GenderBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '16%',
});

export const LocationBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '16%',
});

export const TimezoneBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '16%',
});

export const DateBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '16%',
});

export const ActionsBlock = styled('div')({
  width: '5%',
});

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '150%',
  color: theme.palette.primary.main,
}));

export const BottomLineBlock = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#E4E7ED',
  marginTop: 5,
  marginBottom: 5,
});
