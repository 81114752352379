import { useEffect, useState } from 'react';
import { EUserRoles, IChildren } from 'types';
import Signin from 'components/Authentication';
import Admin from 'components/Admin';
import Coach from 'components/Coach';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './utils/firebase/firebaseInit';
import { useAppDispatch, useAppSelector } from './hooks';
import { getCoachData } from './store/coaches/coachAuthSlice';
import { redirect } from 'react-router-dom';
import { useAuthLogout } from './hooks/auth/useAuthLogout';
import { fetchLatestVersion, setVersionOutdated } from 'store/version/versionSlice';

const App = ({ children }: IChildren) => {
  const dispatch = useAppDispatch();
  const logout = useAuthLogout();
  const [user, loading, error] = useAuthState(auth as any); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const { version } = useAppSelector((state) => state.version);
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);

  useEffect(() => {
    if (user && coachData) {
      switch (coachData.role) {
        case EUserRoles.ADMIN:
          redirect('/teams');
          break;
        case EUserRoles.COACH:
          redirect('/coach-dashboard');
          break;
        case EUserRoles.PARTNER_REFERRAL:
          redirect('/partner-referral-dashboard');
          break;
        default:
          logout();
      }
    }
  }, [user, coachData]);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }
    if (!version) {
      dispatch(fetchLatestVersion());
    }
    if (version && typeof version === 'string') {
      if (!currentVersion) {
        console.log('setting current version', version);
        setCurrentVersion(version);
        return;
      }
      if (version !== currentVersion) {
        console.log('version updated! Please refresh', version, currentVersion);
        dispatch(setVersionOutdated(true));
      }
    }
  }, [version, user]);

  useEffect(() => {
    if (!coachData && user?.uid) {
      dispatch(getCoachData({ uid: user.uid }));
    }
  }, [coachData, user]);

  if (user && coachData?.role) {
    if (coachData.role === EUserRoles.ADMIN) {
      return <Admin>{children}</Admin>;
    }
    if (coachData.role === EUserRoles.COACH) {
      return <Coach>{children}</Coach>;
    }
  }

  return <Signin>{children}</Signin>;
};

export default App;
