import { useEffect, useReducer } from 'react';
import { format } from 'date-fns';
import { getTimezoneTime } from 'utils/format/datetime';

export const CurrentTime = (p: { timezone?: string; format?: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, refresh] = useReducer(() => ({}), {});
  useEffect(() => {
    const interval = setInterval(() => refresh(), 30_000);
    return () => clearInterval(interval);
  });
  return <>{format(getTimezoneTime(new Date(), p.timezone), p.format || 'HH:mm')}</>;
};
