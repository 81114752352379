import { styled } from '@mui/system';
import { Autocomplete } from '@mui/material';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const AutocompleteWrapper = styled('div')({
  position: 'relative',
  marginBottom: '24px',
});

export const StyledAutocomplete = styled(Autocomplete)({
  minWidth: '235px',
  width: '100%',
  maxWidth: 'max-content',
  marginRight: '24px',
  '& .MuiOutlinedInput-input': {
    padding: '1.5px 4px !important',
  },
  '& .MuiInputLabel-formControl': {
    top: '-8px',
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    paddingRight: 65,
  },
});

export const AddItem = styled('div')({
  width: '227px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '24px',
  background: '#FFFFFF',
  border: '1px dashed rgba(15, 15, 33, 0.2)',
  borderRadius: '8px',
  cursor: 'pointer',
});

export const Add = styled('span')({
  fontSize: '14px',
});

export const Clear = styled('span')({
  position: 'absolute',
  top: 11,
  right: 58,
  cursor: 'pointer',
  fontSize: 19,
  color: 'rgba(0, 0, 0, 0.54)',
});
