/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { FormControl, Select, SelectChangeEvent, Typography } from '@mui/material';
import { inactivityWarningTime, inactivityErrorTime, clientList, generalList } from 'config/timer';
import ModalInform from 'common/core/Modal/ModalInform';
import { ToggleApi } from 'api/toggle';
import {
  Container,
  NameTaskWrapper,
  RadioLabel,
  RadioStyle,
  RadioWrapper,
  RightSide,
  StyledButton,
  TaskListItem,
  TaskListWrapper,
  TextWrapper,
} from './styles';
import { IChatTimer, IModalInfo, ITask, ETimeTrackerParentTaskType } from 'types';
import Counter from './Counter';

// @ts-ignore
import task_worker_script from 'utils/webWorkers/timeWorker';
// @ts-ignore
import inactive_worker_script from 'utils/webWorkers/timeInactivWorker';
// @ts-ignore
import dead_worker_script from 'utils/webWorkers/deadTimeWorker';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getShiftStatusByCoachId } from 'store/customers/watcherSlice';
import { useLocation } from 'react-router-dom';
import { setIsTimeStarted } from 'store/coaches/coachAuthSlice';
import { ClientUser } from '@fitmate-coach/fitmate-types';
import { toast } from 'sonner';

// dead time is when the shift is started but no task is running
// inactivity time is when a task is running but no activity is detected

const taskWorker = new Worker(task_worker_script);
const inactiveWorker = new Worker(inactive_worker_script);
const deadTimerWorker = new Worker(dead_worker_script);

const ChatTimer = ({ toggleApiToken }: IChatTimer) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { coachData, isTimeStarted } = useAppSelector((state) => state.coachAuth);
  const [parentTask, setParentTask] = useState<string>(ETimeTrackerParentTaskType.CLIENT);
  const [currentTask, setCurrentTask] = useState<string>('');
  const [tasksList, setTasksList] = useState<ITask[]>(clientList);
  const [currentStartTime, setCurrentStartTime] = useState<string>('');
  const [durationInSec, setDurationInSec] = useState<number>(0);
  const [inactivityDurationInSec, setInactivityDurationInSec] = useState<number>(0);
  const [deadTimeInSec, setDeadTimeInSec] = useState<number>(0);
  const [deadTimeStart, setDeadTimeStart] = useState<string>('');
  const [modalInfo, setModalInfo] = useState<IModalInfo>({
    title: '',
    note: '',
  });
  const [isOpenInformModal, setIsOpenInformModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localCurrentUser, setLocalCurrentUser] = useState<ClientUser>();

  const { shiftStarted } = useAppSelector((state) => state.watcher);

  useEffect(() => {
    if (coachData?.id) {
      dispatch(getShiftStatusByCoachId({ coachId: coachData.id }));
    }
  }, [coachData?.id]);

  const handleTask = (event: SelectChangeEvent<unknown>) => {
    setCurrentTask(event.target.value as string);
  };

  const handleParentTask = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = (event.target as HTMLInputElement).value;
    setParentTask(currentValue);
    setCurrentTask('');
    if (currentValue === ETimeTrackerParentTaskType.CLIENT) {
      setTasksList(clientList);
    } else {
      setTasksList(generalList);
    }
  };

  useEffect(() => {
    if (location.pathname.match(/coach-dashboard\/client/) && customer?.id) {
      setLocalCurrentUser(customer);
    } else {
      if (isTimeStarted && parentTask === ETimeTrackerParentTaskType.CLIENT) {
        // we no longer are looking at a customer
        // @TODO: stop timer for current task if any
        // @TODO: start inactive timer
        // @TODO: Deadtime ?
        handleStopTask();
        toast.info('⏱️ Time tracking for previous client stopped', {
          duration: 2000, // 2 seconds
          closeButton: true,
        });
      }
    }
  }, [location, customer]);

  // control of the time of inactivity
  useEffect(() => {
    inactiveWorker.onmessage = ({ data: { timeInactivity } }) => {
      setInactivityDurationInSec(timeInactivity);
    };
    startInactivityTimer();
    return () => {
      taskWorker.postMessage({ isTimeStarted: false });
      inactiveWorker.postMessage({ isStartInactivityTimer: false });
      deadTimerWorker.postMessage({ isShiftStarted: false });
    };
  }, []);

  const stopInactivityTimer = () => {
    setInactivityDurationInSec(0);
    inactiveWorker.postMessage({ isStartInactivityTimer: false });
  };

  const startInactivityTimer = () => {
    inactiveWorker.postMessage({ isStartInactivityTimer: true });
  };
  // end control of the time of inactivity

  // control of the current task duration
  useEffect(() => {
    if (isTimeStarted) {
      taskWorker.postMessage({ isTimeStarted });
      taskWorker.onmessage = ({ data: { timeDuration } }) => {
        setDurationInSec(timeDuration);
      };
    }

    if (!isTimeStarted && durationInSec !== 0) {
      taskWorker.postMessage({ isTimeStarted: isTimeStarted });
      stopInactivityTimer();
      startInactivityTimer();
    }
  }, [isTimeStarted, durationInSec]);

  // control of the dead time
  useEffect(() => {
    if (shiftStarted && !isTimeStarted) {
      startDeadTimer();
    }

    if (!shiftStarted) {
      stopDeadTimer();
    }
  }, [shiftStarted, isTimeStarted]);

  const startDeadTimer = () => {
    setDeadTimeInSec(0);
    setDeadTimeStart(new Date().toISOString());
    deadTimerWorker.postMessage({ isShiftStarted: true });
    deadTimerWorker.onmessage = ({ data: { deadTime } }) => {
      setDeadTimeInSec(deadTime);
    };
  };

  const stopDeadTimer = () => {
    deadTimerWorker.postMessage({ isShiftStarted: false });
    if (deadTimeInSec > 0) {
      ToggleApi.saveTask(
        toggleApiToken,
        deadTimeStart,
        'Dead time',
        deadTimeInSec,
        tagBuilder(true),
      ).finally(() => {
        setDeadTimeInSec(0);
      });
    }
  };
  // end control of the dead time

  const handleStartTask = () => {
    if (!localCurrentUser && parentTask === ETimeTrackerParentTaskType.CLIENT) {
      setModalInfo({
        title: 'Tracking time against specific clients',
        note: 'Go to the client’s page so we know who you’re trying to track time against. Then click Start.',
      });

      setIsOpenInformModal(true);
      return false;
    }
    if (!currentTask) {
      setModalInfo({ title: 'No task selected', note: 'Select a task then press Start' });
      setIsOpenInformModal(true);
      return false;
    }
    if (!shiftStarted) {
      setModalInfo({
        title: 'Shift has not been started',
        note: 'Move the "My Shift" toggle to start, so that we know when you started your shift. Then click start.',
      });
      setIsOpenInformModal(true);
      return false;
    }
    if (deadTimeInSec > 0) {
      stopDeadTimer();
    }
    setCurrentStartTime(new Date().toISOString());
    dispatch(setIsTimeStarted(true));
    stopInactivityTimer();
  };

  const description = () => {
    const userName = `${localCurrentUser?.firstName ?? ''} ${localCurrentUser?.lastName ?? ''}`;
    return `${
      parentTask === ETimeTrackerParentTaskType.CLIENT ? userName : 'General'
    }, ${currentTask}`;
  };

  const tagBuilder = (isDeadTime: boolean): string[] => {
    const tagsList: string[] = [];
    if (isDeadTime) {
      tagsList.push('service tasks');
      tagsList.push('Dead time');
    }
    if (!isDeadTime) {
      tagsList.push(parentTask);
      if (
        ETimeTrackerParentTaskType.CLIENT === parentTask &&
        localCurrentUser?.id &&
        localCurrentUser?.email
      ) {
        tagsList.push(localCurrentUser?.email);
        tagsList.push(localCurrentUser?.id);
      }
    }

    return tagsList;
  };

  const handleStopTask = () => {
    setIsLoading(true);
    const task = tasksList.filter((task) => task.name === currentTask);

    ToggleApi.saveTask(
      toggleApiToken,
      currentStartTime,
      description(),
      durationInSec,
      tagBuilder(false),
      task[0].id[process.env.REACT_APP_ENV!],
    )
      .then(() => {
        dispatch(setIsTimeStarted(false));
        setDurationInSec(0);
        setParentTask(ETimeTrackerParentTaskType.CLIENT);
        setTasksList(clientList);
        setCurrentTask('');
        setCurrentStartTime('');
        setLocalCurrentUser(customer ?? undefined);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(() => {
        toast.error('Error, try again');
      });
  };

  const closeOpenInformModal = () => {
    setIsOpenInformModal(false);
    setModalInfo({ title: '', note: '' });
  };

  return (
    <>
      <Container
        isErrored={inactivityDurationInSec >= inactivityErrorTime}
        isWarning={
          inactivityDurationInSec > inactivityWarningTime &&
          inactivityDurationInSec < inactivityErrorTime
        }
      >
        {!isTimeStarted ? (
          <>
            <FormControl variant="standard">
              <RadioWrapper
                value={parentTask}
                onChange={handleParentTask}
                name="radio-buttons-main-task-group"
              >
                <RadioLabel
                  isActive={ETimeTrackerParentTaskType.CLIENT === parentTask}
                  value={ETimeTrackerParentTaskType.CLIENT}
                  control={<RadioStyle />}
                  label="Client"
                />
                <RadioLabel
                  isActive={ETimeTrackerParentTaskType.GENERAL === parentTask}
                  value={ETimeTrackerParentTaskType.GENERAL}
                  control={<RadioStyle />}
                  label="General"
                />
              </RadioWrapper>
            </FormControl>
            <TaskListWrapper variant="standard">
              <Select
                sx={{
                  color: 'white',
                }}
                // variant="standard"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  PaperProps: {
                    style: {
                      minWidth: 100,
                      maxWidth: 553,
                      width: 553,
                    },
                  },
                  variant: 'menu',
                }}
                value={currentTask ?? ''}
                onChange={handleTask}
                displayEmpty
              >
                <TaskListItem value="" disabled>
                  Select task
                </TaskListItem>
                {tasksList.map((task) => (
                  <TaskListItem key={task.name} value={task.name}>
                    {task.name}
                  </TaskListItem>
                ))}
              </Select>
            </TaskListWrapper>
          </>
        ) : (
          <TextWrapper>
            {parentTask === ETimeTrackerParentTaskType.CLIENT ? (
              <NameTaskWrapper>
                {localCurrentUser?.firstName ?? ''}
                &nbsp;
                {localCurrentUser?.lastName ?? ''}
                &nbsp;
              </NameTaskWrapper>
            ) : (
              <NameTaskWrapper>General&nbsp;</NameTaskWrapper>
            )}
            <Typography>{currentTask}</Typography>
          </TextWrapper>
        )}
        <RightSide>
          {isTimeStarted && <Counter durationInSec={durationInSec} />}
          <StyledButton
            variant="contained"
            sx={{
              ...(!isTimeStarted && {
                backgroundColor: `${'#F5ED38'}!important`,
                '&:hover': {
                  borderRadius: '4px',
                  color: 'rgba(15, 15, 33, 0.6)',
                  backgroundColor: `${'#F5ED38'}!important`,
                },
              }),
              ...(inactivityDurationInSec > inactivityWarningTime && {
                color: '#D90A3C',
                background: 'theme.palette.common.white',
                '&:hover': {
                  height: '30px',
                  borderRadius: '4px',
                  color: '#D90A3C',
                  background: 'theme.palette.common.white',
                },
              }),
            }}
            onClick={isTimeStarted ? handleStopTask : handleStartTask}
          >
            {isTimeStarted ? 'Stop' : 'Start'}
          </StyledButton>
        </RightSide>
      </Container>
      <ModalInform
        isOpenInformModal={isOpenInformModal}
        closeOpenInformModal={closeOpenInformModal}
        title={modalInfo.title}
        note={modalInfo.note}
        textSubmit="Ok got it"
      />
    </>
  );
};

export default ChatTimer;
