import { useNavigate } from 'react-router-dom';
import { MenuButtonList, MenuButtonListText, MenuWrapper } from './styles';

const HandoutsHeader = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/handouts/new');
  };

  return (
    <MenuWrapper>
      <MenuButtonList
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
      >
        <MenuButtonListText>Create handout</MenuButtonListText>
      </MenuButtonList>
    </MenuWrapper>
  );
};

export default HandoutsHeader;
