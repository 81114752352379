const ChatProgramWeekHelp = () => {
  return (
    <>
      <h4>How this works</h4>
      <p>
        "A 'Program Week' is a customizable period during which your client aims to achieve the set
        goals. While commonly a week, its duration can vary to suit individual needs.
      </p>
      <p>Here's what happens when you add a new Program Week:</p>
      <ul>
        <li>
          <span className="font-bold">Start and End Dates:</span> These auto-populate in the
          client's accessible calendar, marking the period and review date.
        </li>
        <li>
          <span className="font-bold">Locked Plans:</span> Previous weeks' plans become uneditable
          to maintain record integrity.
        </li>
        <li>
          <span className="font-bold">Duplicate Plan:</span> A copy of the last week' s plan will be
          generated for the newly added Program Week. After agreeing on new specific goals with your
          client, you can modify this plan for the new week and then publish it, allowing tracking
          of both new and ongoing goals.
        </li>
      </ul>
    </>
  );
};

export default ChatProgramWeekHelp;
