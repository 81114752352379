import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Container = styled('div')<{ containerBackground: string }>(
  ({ containerBackground }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 15,
    backgroundColor: containerBackground,
  }),
);

export const TopBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: 50,
  paddingTop: 16,
  borderTop: '1px solid #E4E7ED',
  borderLeft: '1px solid #E4E7ED',
  borderRight: '1px solid #E4E7ED',
  borderRadius: '8px 8px 0 0',
}));

export const ContentBlock = styled('div')<{ hasAction: boolean }>(({ theme, hasAction }) => ({
  display: 'flex',
  width: '100%',
  paddingBottom: 16,
  borderLeft: '1px solid #E4E7ED',
  borderRight: '1px solid #E4E7ED',
  ...(hasAction ? {} : ContentBottomBlock),
}));

export const ContentBottomBlock = styled('div')(({ theme }) => ({
  boxShadow: '0px 2px 6px rgba(0, 2, 23, 0.075)',
  borderBottom: '1px solid #E4E7ED',
  borderRadius: '0 0 8px 8px',
}));

export const Details1Block = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: '#fff',
  borderTop: '1px solid #E4E7ED',
  borderLeft: '1px solid #E4E7ED',
  borderRight: '1px solid #E4E7ED',
}));

export const Details2Block = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  paddingTop: 10,
  paddingBottom: 16,
  backgroundColor: '#fff',
  boxShadow: '0px 2px 6px rgba(0, 2, 23, 0.075)',
  border: '1px solid #E4E7ED',
  borderRadius: '0 0 8px 8px',
}));

export const ActionTitleBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingLeft: 16,
}));

export const ActionTitleIconBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  paddingRight: 20,
}));

export const ActionTitleText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: '#192926',
  lineHeight: '24px',
}));

export const ActionBodyText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#192926',
  opacity: 0.6,
  paddingLeft: 16,
  paddingRight: 16,
}));

export const DetailsText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: '#192926',
  paddingLeft: 16,
  paddingRight: 16,
}));

export const NewBlock = styled('div')<{
  chipBackground: string;
  chipBorder: string;
  color: string;
}>(({ theme, chipBackground, chipBorder, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 24,
  backgroundColor: chipBackground,
  marginRight: 16,
  borderRadius: 5,
  border: `1px solid ${chipBorder}`,
  color: color,
}));

export const NewText = styled(Typography)<{ color: string }>(({ theme, color }) => ({
  margin: '0 6px',
  fontSize: 12,
  fontWeight: 600,
  color: color,
}));
