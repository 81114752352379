import { styled } from '@mui/system';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '18px',
  borderBottom: '1px solid rgba(15, 15, 33, 0.2)',
}));

export const InputWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  borderRadius: '8px',
  '&:first-child': {
    marginBottom: '18px',
  },
}));

export const LinerScale = styled('div')(({ theme }) => ({
  '& p': {
    display: 'inline',
    marginRight: '26px',
  },
  '& .MuiInput-underline:before': {
    border: 'none',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    paddingRight: '30px',
  },
}));
