import React, { useState } from 'react';
import Input from 'common/core/Input';
import {
  ButtonBlock,
  Container,
  Element,
  ForgotBlock,
  ForgotText,
  LoginBlock,
  LoginText,
  Logo,
  LogoBlock,
  StyledButton,
  StyledNavLink,
  Wrapper,
} from '../ForgotPasswordContainer/styles';
import { IForgotPasswordForm } from 'types';
import logoImage from 'assets/icons/logo.svg';

const ForgotPasswordForm = ({ resetPassword }: IForgotPasswordForm) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(e.target.value ? '' : 'Please fill email');
  };

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = () => {
    if (email && isValidEmail(email)) {
      resetPassword(email);
    } else {
      setEmailError('Email is invalid');
    }
  };

  return (
    <Wrapper>
      <Container>
        <LogoBlock>
          <Logo src={logoImage} alt="" />
        </LogoBlock>
        <LoginBlock>
          <LoginText>Forgot password</LoginText>
        </LoginBlock>
        <Element>
          <Input
            error={!!emailError}
            onChange={handleChangeEmail}
            value={email}
            id="email"
            label="Email"
            helperText={emailError}
            type="email"
            fullWidth
          />
        </Element>
        <ButtonBlock>
          <StyledButton
            onClick={handleSubmit}
            fullWidth={true}
            disabled={email && isValidEmail(email) ? false : true}
          >
            Reset password
          </StyledButton>
        </ButtonBlock>
        <ForgotBlock>
          <StyledNavLink to="/login">
            <ForgotText>Return to login</ForgotText>
          </StyledNavLink>
        </ForgotBlock>
      </Container>
    </Wrapper>
  );
};

export default ForgotPasswordForm;
