import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Button from '../../../../common/core/Button';

export const Template = styled('div')(({ theme }) => ({
  flex: '50%',
  '&:first-child': {
    marginRight: '32px',
  },
}));

export const HeaderBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  marginRight: '36px',
  fontSize: 18,
  fontWeight: 600,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px 30px',
  fontWeight: 600,
  fontSize: 14,
  borderRadius: 8,
}));

export const ListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  padding: '26px 0',
}));
