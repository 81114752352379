import { styled, Theme } from '@mui/system';

export const Icon = styled('div')(({ theme }: { theme: Theme }) => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...theme.typography.body1,
  fontSize: '12px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  minWidth: '7px',
  padding: '0px 5px',
  height: '17px',
}));

export const Danger = styled(Icon)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'rgb(217, 10, 60)',
  color: 'white',
}));

export const Warn = styled(Icon)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'rgb(255, 235, 241)',
  margin: '0 4px',
}));

export const Live = styled(Icon)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'rgb(0, 0, 0, 255)',
  color: 'white',
  margin: '0 4px',
}));
