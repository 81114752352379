import {
  ActionsBlock,
  Container,
  EmailBlock,
  NameBlock,
  PhoneBlock,
  ShiftBlock,
  Text,
} from './styles';

const CoachesListHeader = () => {
  return (
    <>
      <Container>
        <NameBlock>
          <Text>First name and Last name</Text>
        </NameBlock>
        <EmailBlock>
          <Text>Email</Text>
        </EmailBlock>
        <PhoneBlock>
          <Text>Phone number</Text>
        </PhoneBlock>
        <ShiftBlock>
          <Text>Working hours</Text>
        </ShiftBlock>
        <ActionsBlock />
      </Container>
    </>
  );
};

export default CoachesListHeader;
