const ChatProgramNutritionAlert = () => {
  return (
    <>
      <h4>Why can’t I change this?</h4>
      <p>
        This client signed up before the Nutritional Targets feature was available. Baseline calorie
        target mode is only suitable for new sign-ups in their first few weeks.
      </p>
    </>
  );
};

export default ChatProgramNutritionAlert;
