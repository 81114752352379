import cn from 'mcn';

export const Card = ({
  className,
  children,
  warning,
  ...props
}: JSX.IntrinsicElements['div'] & { warning?: React.ReactNode }) => (
  <div
    {...props}
    className={cn('[box-shadow:_0px_5px_20px_0px_rgba(23,33,48,0.09)] rounded-2xl', className)}
  >
    {warning}
    <div className="p-5 w-full">{children}</div>
  </div>
);
