import { IFilesAttached } from 'types';
import previewImage from 'assets/images/file_image.png';
import iconImage from 'assets/icons/file_image.svg';
import previewAudio from 'assets/images/file_audio.png';
import iconAudio from 'assets/icons/file_audio.svg';
import previewVideo from 'assets/images/file_video.png';
import iconVideo from 'assets/icons/file_video.svg';
import previewDoc from 'assets/images/file_doc.png';
import iconDoc from 'assets/icons/file_doc.svg';

export const setDefaultPreview = (file: IFilesAttached) => {
  const fileType = file?.type?.split('/')[0] ?? '';
  let filePreview;
  let fileIcon;

  switch (fileType) {
    case 'image':
      filePreview = previewImage;
      fileIcon = iconImage;
      break;
    case 'audio':
      filePreview = previewAudio;
      fileIcon = iconAudio;
      break;
    case 'video':
      filePreview = previewVideo;
      fileIcon = iconVideo;
      break;
    default:
      filePreview = previewDoc;
      fileIcon = iconDoc;
  }

  return { fileType, filePreview, fileIcon };
};
