import { styled } from '@mui/system';

export const Container = styled('div')<{ isToggleApiToken: boolean }>(({ isToggleApiToken }) => ({
  display: 'flex',
  height: isToggleApiToken ? 'calc(100vh - 44px)' : '100vh',
  width: '100%',
}));

export const LeftBlock = styled('div')({
  width: 340,
  height: '100%',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
});

export const CenterBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  borderLeft: '1px solid #E4E7ED',
});

export const CenterTopBlock = styled('div')({
  height: 123,
  borderBottom: '1px solid #E4E7ED',
});

export const CenterMainWrapperBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const CenterMessagesWrapperBlock = styled('div')<{ isToggleApiToken: boolean }>(
  ({ isToggleApiToken }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: isToggleApiToken ? 'calc(100vh - (123px))' : 'calc(100vh - 123px)',
    borderRight: '1px solid #E4E7ED',
  }),
);

export const CenterMessagesBlock = styled('div')({
  flexGrow: 1,
  borderLeft: '1px solid #E4E7ED',
  overflowY: 'auto',
});

export const CenterMessagesAndTagsBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  overflowY: 'auto',
  flexGrow: 1,
});

export const CenterMessagesContentBlock = styled('div')({
  borderTop: '1px solid #E4E7ED',
  display: 'flex',
  alignSelf: 'flex-end',
  width: '100%',
});

export const CenterAddMessageBlock = styled('div')({
  borderLeft: 0,
});

export const CenterAddResourcesBlock = styled('div')({
  borderLeft: 0,
});

export const CenterProgramBlock = styled('div')({
  height: 'calc(100vh - 67px)',
  paddingTop: 20,
  paddingBottom: 20,
  border: '1px solid #E4E7ED',
  overflowY: 'auto',
  position: 'relative',
});

export const RightTagsBlock = styled('div')({
  width: 342,
  height: '100%',
  backgroundColor: '#FFF',
});

export const RightFAQBlock = styled('div')<{ isToggleApiToken: boolean }>(
  ({ isToggleApiToken }) => ({
    width: 342,
    minWidth: 342,
    height: isToggleApiToken ? 'calc(100vh - (123px))' : 'calc(100vh - 123px)',
    backgroundColor: '#FFF',
  }),
);

export const AttachmentBlock = styled('div')({
  maxHeight: '60vh',
  height: '100%',
  position: 'relative',
});

export const CloseBtn = styled('div')({
  position: 'absolute',
  right: '15px',
  zIndex: 1,
});
