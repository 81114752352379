import { styled } from '@mui/system';

export const Timer = styled('div')<{ isMaxDuration: boolean }>(({ theme, isMaxDuration }) => ({
  position: 'absolute',
  marginLeft: '22px',
  height: '30px',
  width: '52px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  backgroundColor: 'rgba(15, 15, 33, 0.4)',
  '& .MuiTypography-body1': {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.palette.common.white,
  },
  ...(isMaxDuration && TimerMaxDuration),
}));

export const TimerMaxDuration = styled('div')({
  backgroundColor: '#D90A3C',
});
