export interface IFilesAttached {
  url: string;
  name: string;
  size: number;
  type: string;
  thumbnailUrl?: string;
}

export interface ISelectOptionsItem {
  title: string;
  value: string | number;
  key?: string;
}

export enum EEditorAction {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  STRIKETHROUGH = 'STRIKETHROUGH',
}

export interface IThreadsFilter {
  label: string;
  value: 'read' | 'unread' | 'all';
  field: 'isMessagesUnRead' | 'cancelled' | 'all';
}

export enum IThreadsFilterValues {
  ALL = 'all',
  READ = 'read',
  UNREAD = 'unread',
}

export type TFile = {
  type: TFileType;
  url: string;
  size: number;
  name: string;
  thumbnailUrl?: null | string;
  id?: string;
};

export type TFileType =
  | 'audio/mpeg'
  | 'image/png'
  | 'image/jpeg'
  | 'image/webp'
  | 'video/quicktime'
  | 'video/mp4'
  | 'text/html'
  | 'audio/ogg'
  | 'image/svg+xml'
  | 'video/avi'
  | 'image/gif'
  | 'video/mpeg';

export type TReplyToMessage = {
  userIdFrom?: string;
  files?: File[] | null;
  userNameFrom?: string;
  id?: string;
  body?: string;
  isDeleted?: boolean;
  userAvatarUrlFrom?: null | string;
  userId?: string;
  virtualUserNameFrom?: string | null;
  virtualUserAvatarUrlFrom?: null | string;
  virtualUserIdFrom?: string | null;
};
