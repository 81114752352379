/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import Modal from 'common/core/Modal';
import { useAdminSurveys } from 'hooks/admin/useAdminSurveys';
import {
  textFieldValidation,
  inputData,
  outputData,
  allItemValid,
  emptyBlock,
  getTypeByLocation,
  fromEnumToArr,
  setTypeOnboarding,
} from 'utils/data/surveys';
import {
  AddQuestion,
  ButtonGroup,
  CancelBtn,
  CentralColumn,
  CreateBtn,
  ItemsWrapper,
  StyledInputTitle,
  TitleWrapper,
  Wrapper,
} from './styles';
import {
  EExtendsSurveysQuestionTypes,
  ISurveyQuestion,
  ESurveysQuestionTypes,
  ESurveyType,
  ESurveyTypeField,
  ITextFieldFormat,
  ETypeTextFieldFormat,
} from 'types';
import SelectAction from '../SelectAction';
import SurveyBreadCrumbs from '../SurveyBreadCrumbs';
import QuestionsBlock from '../QuestionBlock';
import {
  EFoodCravingsType,
  EPhysicalActivityType,
  EProteinsBreakfastType,
  EProteinsLunchesType,
  ESimpleCarbsType,
  EStressLevelType,
  PregnancyType,
} from '@fitmate-coach/fitmate-types';
import { toast } from 'sonner';

const SurveysAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCanselModalOpen, setIsCanselModalOpen] = useState<boolean>(false);
  const [blocks, setBlocks] = useState<ISurveyQuestion[]>([]);
  const [surveyTitle, setSurveyTitle] = useState<string>('');
  const [actionId, setActionId] = useState<string>('');
  const [actionError, setActionError] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<boolean>(false);
  const [validator, setValidator] = useState<boolean>(false);
  const [currentSurveyType, setCurrentSurveyType] = useState<string>('');

  const { addSurveyTemplate, getSurveyTemplate, editSurveyTemplate } = useAdminSurveys();

  const { surveyId } = useParams<{ surveyId?: string }>();

  useEffect(() => {
    if (surveyId) {
      getSurveyTemplate(surveyId).then(({ title, questions, actionId, onboardingType }: any) => {
        setBlocks(inputData(questions));
        setSurveyTitle(title);
        if (onboardingType) {
          setCurrentSurveyType(onboardingType);
        } else {
          setCurrentSurveyType(getTypeByLocation(location.pathname));
        }
        if (actionId) {
          setCurrentSurveyType(ESurveyType.ACTION);
          setActionId(actionId);
        }
      });
    } else {
      addBlock();
      setCurrentSurveyType(getTypeByLocation(location.pathname));
    }
  }, [surveyId]);

  useEffect(() => {
    setValidator(true);
  }, [blocks]);

  const addBlock = () => {
    setBlocks([...blocks, emptyBlock()]);
  };

  const isBlockValid = (data: ISurveyQuestion): boolean => {
    const { id, type, title, category, question, answerVariants } = data;

    const isTitleError: boolean = textFieldValidation(title.value);
    const isCategoryError: boolean = textFieldValidation(category.value);
    const isQuestionError: boolean = textFieldValidation(question.value);
    const isVariantsError: any[] = [];

    if (type === ESurveysQuestionTypes.CHECKBOX || type === ESurveysQuestionTypes.RADIO) {
      answerVariants.forEach((variant) => {
        optionHandler(id, 'error', textFieldValidation(variant.answer), variant.answerId);
        isVariantsError.push(!textFieldValidation(variant.answer));
      });
    }

    const isAllAnswerValid: boolean = allItemValid(isVariantsError);

    inputHandler(id, 'title', title.value, isTitleError);
    inputHandler(id, 'question', question.value, isQuestionError);

    if (setTypeOnboarding(currentSurveyType)) {
      inputHandler(id, 'category', category.value, isCategoryError);
      setValidator(!isTitleError && !isCategoryError && !isQuestionError && isAllAnswerValid);
      return !isTitleError && !isCategoryError && !isQuestionError && isAllAnswerValid;
    } else {
      setValidator(!isTitleError && !isQuestionError && isAllAnswerValid);
      return !isTitleError && !isQuestionError && isAllAnswerValid;
    }
  };

  const handleAddBlock = () => {
    const last = blocks[blocks.length - 1];
    const isValid: boolean = isBlockValid(last);

    if (isValid) {
      addBlock();
    } else {
      return false;
    }
  };

  const handleCancel = () => {
    if ((blocks.length > 0 && blocks[0].question.value !== '') || surveyTitle !== '') {
      setIsCanselModalOpen(true);
    } else {
      navigate('/surveys');
    }
  };

  const handleDelete = (id: string, key: number) => {
    setBlocks((prev) => prev.filter((item, index) => key !== index));
  };

  const handlerCopy = (copyIndex: number) => {
    const newBlocks = Array.from(blocks);
    const copyBlock = newBlocks[copyIndex];

    if (isBlockValid(copyBlock)) {
      newBlocks.splice(copyIndex + 1, 0, {
        ...copyBlock,
        id: v4(),
        textFieldFormat: null,
        ...((copyBlock.type === ESurveysQuestionTypes.RADIO ||
          copyBlock.type === ESurveysQuestionTypes.CHECKBOX) && {
          answerVariants: copyBlock.answerVariants.map((item) => ({
            ...item,
            answerId: v4(),
          })),
        }),
      });
      setBlocks(newBlocks);
    }
  };

  // for type, answerVariants, slider, defaultNextQuestionId
  const questionHandler = (
    id: string,
    key: string,
    value:
      | string
      | boolean
      | ITextFieldFormat
      | ESurveysQuestionTypes
      | EExtendsSurveysQuestionTypes
      | string[]
      | null,
    defaultValue?: any,
  ) => {
    setBlocks((block) =>
      block.map((item) => {
        if (item.id === id) {
          const valueNew: string | null = null;
          if (value === ESurveysQuestionTypes.RADIO && defaultValue) {
            item.answerVariants = fromEnumToArr(defaultValue).map((item: any) => {
              return {
                answerId: v4(),
                answer: item.value,
                nextQuestionId: null,
                error: false,
              };
            });
          }
          if (
            !defaultValue &&
            (value === ESurveysQuestionTypes.RADIO || value === ESurveysQuestionTypes.CHECKBOX)
          ) {
            item.answerVariants = [
              { answerId: v4(), answer: '', nextQuestionId: null, error: false },
              { answerId: v4(), answer: '', nextQuestionId: null, error: false },
            ];
          }
          if (value === ESurveysQuestionTypes.SLIDER) {
            item.slider = {
              max: 5,
              minDescription: '',
              maxDescription: '',
            };
          }

          return {
            ...item,
            [key]: valueNew ?? value,
          };
        }
        return item;
      }),
    );
  };

  // for target field
  const targetFieldHandler = (id: string, targetFieldValue: string) => {
    switch (targetFieldValue) {
      case '':
        questionHandler(id, 'textFieldFormat', null);
        questionHandler(id, 'tags', '');
        return false;
      case ESurveyTypeField.DATE_OF_BIRTH:
        questionHandler(id, 'type', EExtendsSurveysQuestionTypes.DATE);
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
      case ESurveyTypeField.PREGNANCY:
        questionHandler(id, 'type', ESurveysQuestionTypes.RADIO, PregnancyType);
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
      case ESurveyTypeField.HEIGHT:
        questionHandler(id, 'type', ESurveysQuestionTypes.TEXT);
        questionHandler(id, 'textFieldFormat', { type: ETypeTextFieldFormat.FEET_INCH });
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
      case ESurveyTypeField.STRESS_LEVEL:
        questionHandler(id, 'type', ESurveysQuestionTypes.RADIO, EStressLevelType);
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
      case ESurveyTypeField.PHYSICAL_ACTIVITY:
        questionHandler(id, 'type', ESurveysQuestionTypes.RADIO, EPhysicalActivityType);
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
      case ESurveyTypeField.LEAN_PROTEIN_AT_BREAKFAST:
        questionHandler(id, 'type', ESurveysQuestionTypes.RADIO, EProteinsBreakfastType);
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
      case ESurveyTypeField.LEAN_PROTEIN:
        questionHandler(id, 'type', ESurveysQuestionTypes.RADIO, EProteinsLunchesType);
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
      case ESurveyTypeField.SIMPLE_CARBS:
        questionHandler(id, 'type', ESurveysQuestionTypes.RADIO, ESimpleCarbsType);
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
      case ESurveyTypeField.FOOD_CRAVINGS:
        questionHandler(id, 'type', ESurveysQuestionTypes.RADIO, EFoodCravingsType);
        questionHandler(id, 'tags', [targetFieldValue]);
        break;
    }
  };

  // for title and question
  const inputHandler = (id: string, key: string, value: string, error: boolean) => {
    setBlocks((block) =>
      block.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [key]: { value: value, error: error },
          };
        }

        return item;
      }),
    );
  };

  const optionHandler = (
    id: string,
    key: string,
    value: string | boolean,
    answerId: string | null,
  ) => {
    setBlocks((block) =>
      block.map((item) => {
        if (item.id === id) {
          item.answerVariants.forEach((variant) => {
            if (variant.answerId === answerId) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              variant[key] = value;
            }
            return variant;
          });
        }
        return item;
      }),
    );
  };

  const addOptionHandler = (key: string) => {
    setBlocks((block) =>
      block.map((item) => {
        if (item.answerVariants) {
          const last = item.answerVariants[item.answerVariants.length - 1];
          if (item.id === key && last.answer !== '') {
            item.answerVariants.push({
              answerId: v4(),
              answer: '',
              nextQuestionId: null,
              error: false,
            });
          }
        }
        return item;
      }),
    );
  };

  const deleteOptionHandler = (id: string, answerId: string) => {
    setBlocks((block) =>
      block.map((item) => {
        if (item.id === id) {
          item?.answerVariants?.map((answer, index) => {
            if (answer.answerId === answerId) {
              item.answerVariants.splice(index, 1);
            }
            return item;
          });
        }
        return item;
      }),
    );
  };

  const sliderHandler = (
    id: string,
    max: number,
    minDescription: string,
    maxDescription: string,
  ) => {
    setBlocks((blocks) =>
      blocks.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            slider: { max, minDescription, maxDescription },
          };
        }
        return item;
      }),
    );
  };

  const handleSurveyTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleError(false);
    const value: string = e.target.value;
    setSurveyTitle(value);
  };

  const handleSubmit = async () => {
    const localValidator: any[] = [];

    blocks.forEach((block) => {
      localValidator.push(isBlockValid(block));
    });

    if (surveyTitle === '') {
      setTitleError(true);
      return false;
    }

    if (currentSurveyType === ESurveyType.ACTION && actionId === '') {
      setActionError(true);
      return false;
    }

    if (!titleError && !actionError && allItemValid(localValidator)) {
      const questions: any[] = outputData(blocks, setTypeOnboarding(currentSurveyType));
      if (surveyId) {
        await editSurveyTemplate(
          surveyId,
          surveyTitle,
          questions,
          actionId,
          setTypeOnboarding(currentSurveyType),
        )
          .then(() => {
            toast.success(actionId ? 'Question for actions are updated' : 'Survey is updated');
            navigate('/surveys');
          })
          .catch((error) => {
            toast.success('Edit survey template error');
            console.log(error);
          });
      } else {
        /* await addSurveyTemplate(surveyTitle, data, startMessage, endMessage, actionId)*/
        await addSurveyTemplate(
          surveyTitle,
          questions,
          actionId,
          setTypeOnboarding(currentSurveyType),
        )
          .then(() => {
            toast.success(
              currentSurveyType === ESurveyType.ACTION
                ? 'Questions for actions are added'
                : 'Survey is added',
            );
            navigate('/surveys');
          })
          .catch((error) => {
            toast.error('addSurveyTemplate error');
            console.log(error);
          });
      }
    }
  };

  const handleOnDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newBlocks = Array.from(blocks);
    const [removed] = newBlocks.splice(result.source.index, 1);
    newBlocks.splice(result.destination.index, 0, removed);
    setBlocks(newBlocks);
  };

  return (
    <>
      <Modal
        widthMax={320}
        title="Are you sure you want to cancel? The current progress will be lost."
        open={isCanselModalOpen}
        handleClose={() => setIsCanselModalOpen(false)}
        handleSubmit={() => navigate('/surveys')}
        textBtnClose="No"
        textBtnSubmit="Yes"
      />
      <Wrapper maxWidth="xl">
        <SurveyBreadCrumbs handleCancel={handleCancel} type={currentSurveyType} />
        <CentralColumn>
          <TitleWrapper>
            <StyledInputTitle
              error={titleError}
              helperText={titleError ? 'Please, fill the field' : ''}
              placeholder={
                currentSurveyType === ESurveyType.ACTION ? 'Untitled questions' : 'Untitled'
              }
              value={surveyTitle}
              onChange={(e) => handleSurveyTitle(e)}
              name="title"
              type="text"
            />
          </TitleWrapper>
          <div>
            {currentSurveyType === ESurveyType.ACTION && (
              <SelectAction
                actionError={actionError}
                setActionError={setActionError}
                actionId={actionId}
                setActionId={setActionId}
              />
            )}
          </div>
          {/*{type !== surveyType.ACTION && <Divider title='Start message' />}*/}
          {/*{type !== surveyType.ACTION && (*/}
          {/*  <MarkdownEditor*/}
          {/*    initialValue={startMessageInitial}*/}
          {/*    value={startMessage}*/}
          {/*    handleValue={setStartMessage}*/}
          {/*    maxLength={150}*/}
          {/*    error={isErrorStartMessage}*/}
          {/*    setError={setIsErrorStartMessage}*/}
          {/*  />*/}
          {/*)}*/}
          {/*{type !== surveyType.ACTION && <Divider title='Template' />}*/}
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(droppableProvided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
                return (
                  <ItemsWrapper
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    {blocks.map((block, index: number) => (
                      <QuestionsBlock
                        index={index}
                        key={block.id}
                        block={block}
                        blocks={blocks}
                        inputHandler={inputHandler}
                        questionHandler={questionHandler}
                        optionHandler={optionHandler}
                        addOptionHandler={addOptionHandler}
                        deleteOptionHandler={deleteOptionHandler}
                        sliderHandler={sliderHandler}
                        deleteClickHandler={handleDelete}
                        copyClickHandler={handlerCopy}
                        showDeleteButton={blocks.length > 1}
                        typeOfSurvey={currentSurveyType}
                        targetFieldHandler={targetFieldHandler}
                      />
                    ))}
                    {droppableProvided.placeholder}
                  </ItemsWrapper>
                );
              }}
            </Droppable>
          </DragDropContext>
          <AddQuestion variant="outlined" onClick={handleAddBlock}>
            Add question
          </AddQuestion>
          {/*{type !== surveyType.ACTION && <Divider title='End message' />}*/}
          {/*{type !== surveyType.ACTION && (*/}
          {/*  <MarkdownEditor*/}
          {/*    initialValue={endMessageInitial}*/}
          {/*    value={endMessage}*/}
          {/*    handleValue={setEndMessage}*/}
          {/*    maxLength={250}*/}
          {/*    error={isErrorEndMessage}*/}
          {/*    setError={setIsErrorEndMessage}*/}
          {/*  />*/}
          {/*)}*/}
        </CentralColumn>
      </Wrapper>
      <ButtonGroup>
        <CancelBtn variant="outlined" onClick={handleCancel}>
          Cancel
        </CancelBtn>
        <CreateBtn onClick={handleSubmit} disabled={!validator}>
          {surveyId ? 'Update' : 'Save'}
        </CreateBtn>
      </ButtonGroup>
    </>
  );
};

export default SurveysAdd;
