import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { collection, doc, getDoc, orderBy } from 'firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import { getDocs, query } from '@firebase/firestore';

interface IHandoutStateInterface {
  loading: boolean;
  handouts?: any[];
  selectedHandout?: any;
}

const initialState: IHandoutStateInterface = {
  loading: false,
  handouts: [],
};

export const getHandouts = createAsyncThunk('handout/getHandouts', async () => {
  try {
    const q = query(collection(db, 'handouts'), orderBy('title', 'asc'));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (e: any) {
    console.log(e);
  }
});

export const getHandoutById = createAsyncThunk('handout/getHandoutById', async (id: string) => {
  try {
    const docRef = doc(db, 'handouts', id);
    const docSnap = await getDoc(docRef);

    return { id: docSnap.id, ...docSnap.data() };
  } catch (e: any) {
    console.log(e);
  }
});

export const handoutSlice = createSlice({
  name: 'handout',
  initialState,
  reducers: {
    saveSelectedHandout: (state, action) => {
      state.selectedHandout = action.payload;
    },
    clearSelectedHandout: (state) => {
      state.selectedHandout = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHandouts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHandouts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getHandouts.fulfilled, (state, action) => {
        state.loading = false;
        state.handouts = action.payload;
      });
  },
});

export const { saveSelectedHandout, clearSelectedHandout } = handoutSlice.actions;

export default handoutSlice.reducer;
