import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const ResourceCardWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  cursor: 'pointer',
});

export const ResourceCardWrapperCoach = styled('div')<{
  isMessage: boolean;
  checkedResource: boolean;
}>(({ isMessage, checkedResource }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  padding: 10,
  borderRadius: 12,
  ...(isMessage && {
    maxWidth: 168,
  }),
  ...(checkedResource && {
    backgroundColor: '#F2F4F7',
  }),
}));

export const CheckedRecourseTitle = styled(Typography)({
  color: '#8587E5',
  fontWeight: 600,
  fontSize: 14,
});

export const ResourceCardHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  padding: 7,
  width: 'calc(100% - 14px)',
  zIndex: 1,
  '& .MuiRadio-root': {
    width: 20,
    height: 20,
    padding: 0,
    color: '#FFFFFF',
  },
});

export const ResourceCard = styled('figure')({
  position: 'relative',
  display: 'flex',
  borderRadius: 12,
  margin: 0,
  minHeight: 266,
  minWidth: 198,
  background: '#C5C5FE',
});

export const ResourceImg = styled('img')({
  width: 198,
  objectFit: 'cover',
  borderRadius: 12,
});

export const ResourceCardCoach = styled('figure')({
  position: 'relative',
  display: 'flex',
  borderRadius: 12,
  margin: 0,
  minHeight: 225,
  minWidth: 168,
  background: '#7EDDC7',
});

export const ResourceImgCoach = styled('img')({
  width: 168,
  objectFit: 'cover',
  borderRadius: 12,
});

export const ResourceCaption = styled('figcaption')({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  bottom: 0,
  width: 'calc(100% - 28px)',
  textAlign: 'center',
  padding: '45px 14px 14px 14px',
  background: 'linear-gradient(180deg, rgba(83, 76, 118, 0) 0%, rgba(34, 30, 51, 0.8) 100%)',
  opacity: 0.8,
  borderRadius: '0 0 12px 12px',
  '& .MuiTypography-root': {
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: 16,
  },
});

export const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  overflowWrap: 'break-word',
  maxWidth: 198,
});

export const TitleCoach = styled(Typography)<{ checkedId: boolean }>(({ checkedId }) => ({
  fontWeight: 600,
  fontSize: 14,
  overflowWrap: 'break-word',
  maxWidth: 168,
  ...(checkedId
    ? {
        color: '#8587E5',
      }
    : {
        overflowWrap: 'break-word',
        maxWidth: 198,
      }),
}));

export const MessageText = styled(Typography)({
  fontSize: '13px !important',
});

export const OpenBtn = styled('div')({
  borderRadius: 10,
  background: 'rgba(0, 0, 0, 0.5)',
  padding: '0 10px',
  lineHeight: '20px',
  '& .MuiTypography-root': {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 12,
  },
});
