import { styled } from '@mui/system';
import { OutlinedInput } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const Container = styled('div')({
  padding: '0 16px',
  backgroundColor: '#FFF',
});

export const SearchInput = styled(OutlinedInput)({
  borderRadius: 8,
  backgroundColor: '#F9FAFC',
  border: '1px solid #F2F4F7',
  height: 34,
  width: '100%',
  fontSize: 14,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiOutlinedInput-input': {
    border: 0,
  },
  '&:hover .MuiInputLabel-outlined': {
    border: 0,
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    border: 0,
  },
});

export const SearchInputIconButton = styled(IconButton)({
  padding: 8,
  paddingRight: 0,
});

export const SearchInputIcon = styled('div')({
  color: 'rgba(15, 15, 33, 0.4)',
  fontSize: 20,
});
