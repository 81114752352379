import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container, ContentBlock, IconArrow, IconBlock, NoteBlock, NoteText } from './styles';

const ChatEmpty = () => {
  return (
    <Container>
      <ContentBlock>
        <NoteBlock>
          <NoteText align="center">
            Click on the client name in the list on the left to open up the client chat
          </NoteText>
        </NoteBlock>
        <IconBlock>
          <IconArrow as={ArrowBackIcon} />
        </IconBlock>
      </ContentBlock>
    </Container>
  );
};

export default ChatEmpty;
