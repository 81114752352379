import { v4 } from 'uuid';
import { IAnswerVariant, ISurveyQuestion, ESurveysQuestionTypes, ESurveyType } from 'types';

export const inputData = (data: any[]) => {
  const variantConvert = (data: IAnswerVariant[]) => {
    return data.map((variant: IAnswerVariant) => ({
      ...variant,
      answerId: v4(),
      nextQuestionId: variant.nextQuestionId || null,
      error: false,
    }));
  };

  return data.map((item: any) => ({
    ...item,
    title: { value: item.title, error: false },
    category: { value: item.category, error: false },
    helpText: { value: item.helpText, error: false },
    question: { value: item.question, error: false },
    unit: { value: item.unit, error: false },
    isRequired: { value: item.isRequired, error: false },
    ...(item.answerVariants && { answerVariants: variantConvert(item.answerVariants) }),
  }));
};

export const outputData = (data: any[], typeOnboarding: string | null) => {
  return data.map(
    ({
      id,
      title,
      category,
      helpText,
      question,
      type,
      answerVariants,
      defaultNextQuestionId,
      slider,
      tags,
      textFieldFormat,
      unit,
      isRequired,
    }) => ({
      id,
      title: title.value,
      ...(!!typeOnboarding && { category: category.value }),
      ...(!!typeOnboarding && { helpText: helpText.value }),
      ...(!!tags && { tags: tags }),
      question: question.value,
      ...((type === ESurveysQuestionTypes.RADIO || type === ESurveysQuestionTypes.CHECKBOX) && {
        answerVariants: answerVariants.map((item: IAnswerVariant) => {
          return {
            answer: item.answer,
            ...(item.nextQuestionId && { nextQuestionId: item.nextQuestionId }),
          };
        }),
      }),
      ...(type === ESurveysQuestionTypes.SLIDER && {
        slider: {
          max: Number(slider.max),
          minDescription: slider.minDescription,
          maxDescription: slider.maxDescription,
        },
      }),
      type,
      textFieldFormat: textFieldFormat?.type
        ? {
            type: String(textFieldFormat?.type),
          }
        : null,
      defaultNextQuestionId: defaultNextQuestionId ? defaultNextQuestionId : null,
      unit: unit.value,
      isRequired: isRequired?.value ?? 'yes',
    }),
  );
};

export const textFieldValidation = (value: string) => value === '';

export const allItemValid = (arr: any[]): boolean => arr.every((element) => element === true);

export const emptyBlock = (): ISurveyQuestion => ({
  id: v4(),
  title: { value: '', error: false },
  category: { value: '', error: false },
  question: { value: '', error: false },
  helpText: { value: '', error: false },
  type: ESurveysQuestionTypes.TEXT,
  answerVariants: [
    { answerId: v4(), answer: '', nextQuestionId: null, error: false },
    { answerId: v4(), answer: '', nextQuestionId: null, error: false },
  ],
  slider: {
    max: 5,
    minDescription: '',
    maxDescription: '',
  },
  textFieldFormat: null,
  defaultNextQuestionId: null,
  tags: null,
  unit: { value: '', error: false },
  isRequired: { value: 'yes', error: false },
});

export const getLocationByType = (type: string): string => {
  if (type === ESurveyType.ACTION) return '/surveys/addTemplateAction';
  if (type === ESurveyType.ONBOARDING) return '/surveys/addOnboarding';
  if (type === ESurveyType.REGULAR) return '/surveys/addTemplate';
  return '/surveys/addTemplate';
};

export const getTypeByLocation = (url: string): string => {
  if (url === '/surveys/addTemplateAction') return ESurveyType.ACTION;
  if (url === '/surveys/addOnboarding') return ESurveyType.ONBOARDING;
  if (url === '/surveys/addTemplate') return ESurveyType.REGULAR;
  return ESurveyType.REGULAR;
};

export const buildTextForBreadcrumb = (type: string): string => {
  if (type === ESurveyType.ACTION) return 'Questions for Actions';
  if (type === ESurveyType.ONBOARDING) return 'Onboarding';
  if (type === ESurveyType.REGULAR) return 'Templates';
  return 'Templates';
};

export const fromEnumToArr = (data: {
  [key: string]: string;
}): {
  key: string;
  value: string;
  [key: string]: string;
}[] =>
  Object.keys(data).map((key: string) => ({
    key,
    value: data[key],
  }));

//
export const setTypeOnboarding = (type: string): string | null => {
  if (type === ESurveyType.ONBOARDING || type === ESurveyType.ONBOARDING_ACTIVE) {
    return type;
  }
  return null;
};
