import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDocs } from 'firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import { collection } from '@firebase/firestore';

const initialState: { loading: boolean; showAssignModal: boolean; teams: any } = {
  loading: false,
  showAssignModal: false,
  teams: [],
};

const teamCollectionRef = () => collection(db, 'teams');

export const getTeams = createAsyncThunk('team/getTeams', async () => {
  try {
    const querySnapshot = await getDocs(teamCollectionRef());

    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (e: any) {
    console.log(e);
    return [];
  }
});

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    toggleAssignModal: (state) => {
      state.showAssignModal = !state.showAssignModal;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
        state.loading = false;
      });
  },
});

export const { toggleAssignModal } = teamSlice.actions;

export default teamSlice.reducer;
