import { useEffect } from 'react';
import { ETypeMeal, Table } from '@fitmate-coach/fitmate-nutrition-table';
import {
  assignNutritionTargetsToCustomer,
  assignNutritionTargetsTypeToCustomer,
} from '../../../../../../store/customers/customerInfosSlice';
import { calculateAge } from '../../../../../../utils/format/datetime';
import { calculateNutritionsTarget } from '../utils';
import { useAppDispatch } from '../../../../../../hooks';
import { ClientUser, ENutritionCaloriesTarget, ESex } from '@fitmate-coach/fitmate-types';

interface BaselineProps {
  customer: ClientUser;
  weight: number;
}

function Baseline({ customer, weight }: BaselineProps) {
  const dispatch = useAppDispatch();

  // baseline effect
  useEffect(() => {
    if (customer.nutritionTargets || !weight || !customer.height || !customer.gender) {
      return;
    }

    dispatch(
      assignNutritionTargetsTypeToCustomer({
        id: customer.id,
        nutritionTargetsType: ENutritionCaloriesTarget.baseline_calorie_target,
      }),
    );

    dispatch(
      assignNutritionTargetsToCustomer({
        id: customer.id,
        nutritionTargets: {
          weight,
          height: customer.height,
          age: calculateAge(customer.dateOfBirth ?? 0),
          gender: customer.gender,
          caloriesTarget: calculateNutritionsTarget(customer, weight),
        },
      }),
    );
  }, [weight, customer, dispatch]);

  if (!customer.nutritionTargets) {
    return <></>;
  }

  return (
    <Table
      type={ENutritionCaloriesTarget.baseline_calorie_target}
      focusArea={[ETypeMeal.DailyTotal]}
      customerAge={customer.nutritionTargets.age!}
      customerHeight={customer.nutritionTargets.height!}
      customerSex={customer.nutritionTargets.gender as ESex}
      customerWeight={customer.nutritionTargets.weight!}
      lowIntensityCardioMinutes={customer.activities?.lowIntensityCardioMinutes ?? 0}
      moderateIntensityCardioMinutes={customer.activities?.moderateIntensityCardioMinutes ?? 0}
      vigorousIntensityCardioMinutes={customer.activities?.vigorousIntensityCardioMinutes ?? 0}
      resistanceTraining2Plus={customer.activities?.resistanceTraining2Plus ?? false}
    />
  );
}

export default Baseline;
