import { styled } from '@mui/system';

export const DivWrapper = styled('div')({
  backgroundColor: 'revert',
  position: 'relative',
  marginBottom: 12,
});

export const DivButtons = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
  position: 'absolute',
  right: -6,
  top: -6,
  zIndex: 1,
  visibility: 'hidden',
  ...(isVisible && {
    visibility: 'visible',
  }),
}));
