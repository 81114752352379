import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import ImageUploader from '../../../../../common/core/ImageUploader';

export const TitleBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 11,
  '& .MuiButton-text': {
    textTransform: 'none',
    padding: '0 0 0 7px',
    color: '#8587E5',
  },
});

export const TitleText = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
});

export const Thumb = styled(ImageUploader)({
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    width: 176,
    height: 236,
    justifyContent: 'flex-end',
  },
  '& .MuiTypography-root': {
    width: 'revert',
    marginBottom: 55,
    padding: 10,
  },
  '& .MuiButtonBase-root': {
    marginBottom: 10,
  },
});

export const CategoryWrapper = styled('div')({
  padding: 16,
  borderRadius: 12,
  marginBottom: 13,
  display: 'flex',
  flexDirection: 'column',
  '&:last-child': {
    marginBottom: 0,
  },
  backgroundColor: '#F7F8FA',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiRadio-colorSecondary.Mui-checked': {
    color: 'rgba(15, 15, 33, 0.6)',
  },
});
