import { useAdminResourceCategories } from 'hooks/admin/useAdminResourceCategories';
import { HeaderBlock, AppSideBar, TitleText, TitleBlock, MainBlock } from './styles';
import { IResourcesSideBar } from 'types';
import { ResourcesCategory } from './ResourcesCategory';

const Resources = ({
  handleCategorySelected,
  handleTagsSelected,
  currentCategoryId = '',
}: IResourcesSideBar) => {
  const { categoriesList } = useAdminResourceCategories();

  return (
    <AppSideBar>
      <HeaderBlock>
        <TitleBlock>
          <TitleText>Share resource</TitleText>
        </TitleBlock>
      </HeaderBlock>
      <MainBlock>
        {categoriesList.map((category) => (
          <ResourcesCategory
            category={category}
            level={0}
            handleCategorySelected={handleCategorySelected}
            currentCategoryId={currentCategoryId}
            handleTagsSelected={handleTagsSelected}
            key={category.id}
          />
        ))}
      </MainBlock>
    </AppSideBar>
  );
};

export default Resources;
