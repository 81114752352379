import { useState, useEffect, useCallback } from 'react';
import { IChatMessageTag } from 'types';
import { db } from 'utils/firebase/firebaseInit';
import { collection, onSnapshot, query, getDoc, doc, updateDoc, addDoc } from 'firebase/firestore';

export const useChatTags = (messageId: string) => {
  const [tagsList, setTagsList] = useState<string[]>([]);
  const [tagsPossibleList, setTagsPossibleList] = useState<IChatMessageTag[]>([]);

  useEffect(() => {
    const q = query(collection(db, 'messages_tags'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tagsPossibleListNew: IChatMessageTag[] = [];
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const { tag, isAddedByCoach, numberOfUsage } = docData;

        tagsPossibleListNew.push({
          tag,
          isAddedByCoach: isAddedByCoach ?? false,
          numberOfUsage: numberOfUsage ?? 0,
        });
      });
      setTagsPossibleList(tagsPossibleListNew);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setTagsList([]);
    getDoc(doc(db, 'messages', messageId)).then((docSnap) => {
      if (docSnap.exists() && docSnap.data().tags) {
        setTagsList(docSnap.data().tags);
      }
    });
  }, [messageId]);

  const addTag = useCallback(
    async (tag: string) => {
      const docRef = doc(db, 'messages', messageId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        let tagsNew: any[] = [];
        if (data.tags) {
          const index = data.tags.findIndex((item: string) => item === tag);
          // eslint-disable-next-line no-unsafe-optional-chaining
          tagsNew = index === -1 ? [...data.tags, tag] : data.tags;
        } else {
          tagsNew = [tag];
        }
        const details = {
          tags: tagsNew,
        };
        await updateDoc(docRef, details);
        setTagsList(tagsNew);
      }
    },
    [messageId],
  );

  const deleteTag = useCallback(
    async (tag: string) => {
      const docRef = doc(db, 'messages', messageId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        let tagsNew: any[] = [];
        if (data.tags) {
          tagsNew = data.tags.filter((item: string) => item !== tag);
        }
        const details = {
          tags: tagsNew,
        };
        await updateDoc(docRef, details);
        setTagsList(tagsNew);
      }
    },
    [messageId],
  );

  const createTag = useCallback(
    async (tag: string) => {
      const index = tagsPossibleList.findIndex((item: IChatMessageTag) => item.tag === tag);
      if (index === -1) {
        await addDoc(collection(db, 'messages_tags'), {
          tag,
          isAddedByCoach: true,
        });
      }
    },
    [tagsPossibleList],
  );

  return { tagsPossibleList, tagsList, addTag, deleteTag, createTag };
};
