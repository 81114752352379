import React, { useState, useEffect } from 'react';
import { useAdminResourceCategories } from 'hooks/admin/useAdminResourceCategories';
import { AppSideBar } from './styles';
import { INewResourcesSidebar, IResourceCategory } from 'types';
import TagAddModal from '../../ResourcesSidebar/ResourcesTagAddModal';
import ResourceCategories from '../ResourceCategories';
import ResourceTags from '../ResourceTags';
import ResourceThumbnail from '../ResourceThumbnails';

const NewResourcesSidebar = ({
  handleResourceDataChange,
  thumbnail,
  resourceData,
  handleSetIsLastCategoryHasChild,
}: INewResourcesSidebar) => {
  const [isOpenTagAddModal, setIsOpenTagAddModal] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<IResourceCategory | undefined>();
  const [currentCategoryId, setCurrentCategoryId] = useState<string>('');
  const { categoriesList, editCategoryTags } = useAdminResourceCategories();

  useEffect(() => {
    if (resourceData?.categoryId) {
      setCurrentCategoryId(resourceData?.categoryId);
    }
  }, [resourceData]);

  useEffect(() => {
    if (handleSetIsLastCategoryHasChild) {
      handleSetIsLastCategoryHasChild(
        !!(activeCategory?.children && activeCategory?.children?.length > 0),
      );
    }
  }, [activeCategory]);

  const toggleTagAddModal = () => {
    setIsOpenTagAddModal(!isOpenTagAddModal);
  };

  const handleTagAdded = (tag: string) => {
    if (activeCategory !== undefined && activeCategory.tags !== undefined) {
      const newTagArray = [...activeCategory.tags, tag];
      const categoryCopy = { ...activeCategory };
      categoryCopy.tags = [...activeCategory.tags, tag];
      editCategoryTags(activeCategory.id, newTagArray).then(() => {
        setActiveCategory(categoryCopy);
        toggleTagAddModal();
      });
    }
  };

  const handleActiveCategoryChange = (
    callback: React.SetStateAction<IResourceCategory | undefined>,
  ) => {
    setActiveCategory(callback);
  };

  const handleCurrentCategoryIdChange = (categoryId: string) => {
    setCurrentCategoryId(categoryId);
  };

  return (
    <AppSideBar>
      <TagAddModal
        isOpenAddModal={isOpenTagAddModal}
        toggleOpenAddModal={toggleTagAddModal}
        handleCreateTag={handleTagAdded}
        tags={activeCategory === undefined ? [] : activeCategory.tags}
      />

      <ResourceCategories
        categoriesList={categoriesList}
        handleActiveCategoryChange={handleActiveCategoryChange}
        currentCategoryId={currentCategoryId}
        handleCurrentCategoryIdChange={handleCurrentCategoryIdChange}
        handleResourceDataChange={handleResourceDataChange}
      />
      <ResourceTags
        tags={activeCategory?.tags === undefined ? [] : activeCategory.tags}
        toggleTagAddModal={toggleTagAddModal}
        handleResourceDataChange={handleResourceDataChange}
        activeCategory={activeCategory}
        currentTags={resourceData?.tags}
      />
      {thumbnail && (
        <ResourceThumbnail
          handleResourceDataChange={handleResourceDataChange}
          resourceData={resourceData}
        />
      )}
    </AppSideBar>
  );
};

export default NewResourcesSidebar;
