import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TChatSliceData, chatInit, chatNewMessages, chatUpdatedMessage } from 'store/chat';
import { handleSurveyDataValue } from '../service/chatMessagesService';
import { IFirestoresChatMessage } from 'types';
import { collection, onSnapshot, query, where, limit, orderBy } from 'firebase/firestore';
import { db } from '../../utils/firebase/firebaseInit';

export const useChatMessages = (userId?: string) => {
  const ref = useRef<HTMLDivElement>(null);
  const prev = useRef<TChatSliceData>({ docs: [], loading: false, end: false, count: 0 });
  const [latestId, setLatestId] = useState<IFirestoresChatMessage['id'] | undefined>(undefined);
  const dispatch = useAppDispatch();
  const shouldScroll = useRef(false);

  const data = useAppSelector((x) => {
    if (!userId) return undefined;
    const next = x.chat.data[userId];
    /*
      When new messages arrive after clicking 'load more'
      if the chat is scrolled to the top, scroll the chat down
      by 10 pixels which stops the chat auto scrolling to the
      top when the new messages are rendered above
    */
    if (
      prev.current &&
      prev.current.docs.length > 0 &&
      ref.current &&
      ref.current.scrollTop <= 10
    ) {
      ref.current.scrollTop = 10;
    }
    prev.current = next;
    return next;
  });

  // Get initial chat data
  useEffect(() => {
    if (!userId) return;
    shouldScroll.current = true;
    bottom();
    void dispatch(chatInit(userId));
  }, [userId]);

  const bottom = () => {
    if (ref.current) ref.current.scrollTop = ref.current.scrollHeight - ref.current.clientHeight;
  };

  useEffect(() => {
    if (data?.loading || !userId) return;
    const q = query(
      collection(db, 'messages'),
      where('userId', '==', userId),
      where('isDeleted', '==', false),
      limit(1),
      orderBy('createdAt', 'desc'),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs.length === 0) return;
      if (ref.current) {
        const difference =
          ref.current.scrollHeight - ref.current.clientHeight - ref.current.scrollTop;
        // Scroll to bottom if user isn't scrolled up intentionally
        if (difference < 200) shouldScroll.current = true;
      }

      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          if (change.doc.id === latestId) return;

          const message = { id: change.doc.id, ...change.doc.data() } as IFirestoresChatMessage;

          setLatestId(message.id);
          dispatch(
            chatNewMessages({
              docs: [message],
              userId,
            }),
          );
        }
        if (change.type === 'modified') {
          const message = { id: change.doc.id, ...change.doc.data() } as IFirestoresChatMessage;
          dispatch(
            chatUpdatedMessage({
              doc: message,
              userId,
            }),
          );
        }
      });
    });

    return () => unsubscribe();
  }, [data?.loading, latestId, userId]);

  // Handle scroll
  useEffect(() => {
    if (data?.loading || !shouldScroll.current || !ref.current) return;
    const t1 = setTimeout(() => {
      if (!ref.current) return;
      shouldScroll.current = false;
      ref.current.scrollTop = ref.current.scrollHeight - ref.current.clientHeight;
    }, 15);
    // Second more delayed for when messages have images that take time to load
    const t2 = setTimeout(() => {
      if (!ref.current) return;
      shouldScroll.current = false;
      ref.current.scrollTop = ref.current.scrollHeight - ref.current.clientHeight;
    }, 300);
    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
    };
  }, [data]);

  return {
    ref,
    bottom,
    docs: transform(data?.docs || []) as any[],
  };
};

const transform = (docs: IFirestoresChatMessage[]) =>
  docs
    .map((data) => {
      const surveyDataValue = handleSurveyDataValue(data?.surveyData);
      return {
        ...data,
        id: data.id,
        isFaqChecked: (data as any)?.faqData?.isChecked ?? false,
        ...surveyDataValue,
      };
    })
    .sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;
      return (
        a.createdAt.seconds +
        a.createdAt.nanoseconds / 1000000000 -
        (b.createdAt.seconds + b.createdAt.nanoseconds / 1000000000)
      );
    });
