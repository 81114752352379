import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Button from 'common/core/Button';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

export const TitleBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const NoteBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  paddingTop: 10,
});

export const ButtonBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  paddingTop: 24,
});

export const TitleText = styled(Typography)({
  fontSize: 18,
  fontWeight: 900,
  lineHeight: '32px',
});

export const NoteText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

export const ButtonCancel = styled(Button)({
  width: 133,
  height: 45,
  fontSize: 14,
  fontWeight: 700,
  margin: 4,
  color: 'rgba(15, 15, 33, 0.6)!important',
});

export const ButtonSubmit = styled(Button)({
  width: 133,
  height: 45,
  fontSize: 14,
  fontWeight: 700,
  margin: 4,
  backgroundColor: '#FF6D7D',
});
