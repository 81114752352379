/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { DragNDropColumns } from 'common/core/DragNDropColumns';
import { IProgramAction, TFocusArea } from 'types';
import { Button } from '../../ChatProgramComponents';
import UpdateMessage from '../ChatProgramUpdateMessage';
import PlanCard from '../ChatProgramPlanCardContainer';
import Column from '../ChatProgramColumn';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setProgramActionStatus, updateProgramActions } from 'store/program/programActionsSlice';
import {
  publishUpdatePlan,
  reOrderCard,
  setCurrentPlanStatus,
} from 'store/program/weekProgramSlice';
import { toast } from 'sonner';

export default function ChatProgramPlanColumns(props: {
  readonly disabled?: boolean;
  readonly actions?: IProgramAction[];
}) {
  const dispatch = useAppDispatch();
  const { customer, isReadOnly } = useAppSelector((state) => state.customerInfos);
  const { selectedPlan, currentPlanStatus, currentWeekPlan } = useAppSelector(
    (state) => state.weekProgram,
  );
  const { programActionStatus } = useAppSelector((state) => state.programActions);
  const userId = customer?.id;
  const { actions, disabled } = props;
  //const publishAllSmartGoals = usePublishAllSmartGoalsDrafts();

  useEffect(() => {
    let isDraft = false;
    if (currentWeekPlan) {
      isDraft = !!currentWeekPlan.draft;
    }
    if (actions) {
      actions.forEach((action) => {
        const { draft, published } = action;
        if (draft && published !== draft) {
          isDraft = true;
        }
      });
    }
    dispatch(setProgramActionStatus(isDraft ? 'draft' : 'published'));
  }, [actions, currentWeekPlan]);

  useEffect(() => {
    if (disabled) {
      dispatch(setCurrentPlanStatus('published'));
    } else if (!currentWeekPlan?.published?.plan) {
      dispatch(setCurrentPlanStatus('draft'));
    } else {
      dispatch(setCurrentPlanStatus('published'));
    }
  }, [selectedPlan, currentWeekPlan]);

  const onUpdate = async () => {
    if (disabled) return;
    if (userId) {
      dispatch(publishUpdatePlan({ weekPlan: currentWeekPlan, userId }));
    }
    if (actions && userId) {
      dispatch(
        updateProgramActions({
          userId,
          actions,
        }),
      );
      dispatch(setProgramActionStatus('published'));
    }
    dispatch(setCurrentPlanStatus('published'));
    toast.success('🙌 You have successfully published the plan.');
  };

  const reOrder = async (next: TFocusArea[]) => {
    if (!currentWeekPlan) return;
    if (currentWeekPlan && next && userId)
      dispatch(reOrderCard({ selectedPlan: currentWeekPlan.id, next, userId }));
  };

  return (
    <>
      <div className="w-full grid [grid-template:1fr_/_1fr_1fr_1fr] gap-[24px] px-[20px]">
        <DragNDropColumns
          items={currentWeekPlan?.draft?.plan ?? currentWeekPlan?.published?.plan ?? []}
          columns={['priority', 'obstacle', 'existing']}
          columnKey="category"
          disabled={disabled}
          onReorder={(next: TFocusArea[]) => {
            reOrder(next);
          }}
          item={PlanCard}
          column={Column}
        />
      </div>
      {currentWeekPlan && (
        <div className="p-5">
          <div className="flex justify-end w-full">
            <Button
              onClick={onUpdate}
              size="lg"
              variant={
                currentPlanStatus === 'published' && programActionStatus !== 'draft'
                  ? 'outlined'
                  : 'solid'
              }
              disabled={
                isReadOnly || (currentPlanStatus === 'published' && programActionStatus !== 'draft')
              }
            >
              {currentPlanStatus === 'published' ? 'Update plan' : 'Publish plan'}
            </Button>
          </div>
          <div className="flex justify-end w-full pt-1">
            <p className="text-dark/60 text-sm font-semibold">
              <UpdateMessage plan={currentWeekPlan} />
            </p>
          </div>
        </div>
      )}
    </>
  );
}
