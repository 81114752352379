import { styled } from '@mui/system';
import { IconButton, OutlinedInput } from '@mui/material';

export const SearchBlock = styled('div')({
  paddingTop: 9,
});

export const SearchInput = styled(OutlinedInput)({
  borderRadius: 8,
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(15, 15, 33, 0.4)',
  height: 34,
  fontSize: 14,
  padding: '23px 15px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiOutlinedInput-notchedOutline.Mui-focused': {
    border: 0,
  },
  '& .MuiOutlinedInput-input': {
    border: 0,
  },
  '&:hover .MuiInputLabel-outlined': {
    border: 0,
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    border: 0,
  },
});

export const SearchInputIconButton = styled('div')({
  padding: 8,
  paddingRight: 0,
});

export const SearchInputIcon = styled(IconButton)({
  color: 'rgba(15, 15, 33, 0.4)',
  fontSize: 20,
});
