import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Edit as EditIcon, InsertLink as InsertLinkIcon } from '@mui/icons-material';
import Menu from 'common/core/Menu';
import { inviteEmailNotificationReferralPartner } from 'utils/tools/emails';
import {
  ActionsBlock,
  BottomLineBlock,
  Container,
  EmailBlock,
  NameBlock,
  PhoneBlock,
  Text1,
} from './styles';
import { IPartnerReferralsListItem } from 'types';
import {
  StyledMenuIcon,
  SubMenu,
  Text2,
  Text2WithStatus,
} from '../../../CoachesContainer/CoachesList/CoachesListItem/styles';

const PartnerReferralsListItem = ({
  chooseEditPartnerReferral,
  item,
}: IPartnerReferralsListItem) => {
  const {
    uid: userId,
    firstName,
    lastName,
    phone,
    email,
    confirmId,
    isInviteAccepted,
    isActive,
  } = item;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEdit = () => {
    chooseEditPartnerReferral(userId!);
    setAnchorEl(null);
  };

  const handleResendInvitation = () => {
    inviteEmailNotificationReferralPartner(
      firstName!,
      lastName!,
      confirmId!,
      email!,
      userId!,
      phone!,
    );
    setAnchorEl(null);
  };

  return (
    <>
      <Container>
        <NameBlock>
          <div>
            <Text1>
              {firstName} {lastName}
            </Text1>
          </div>
          <div>
            <Text2WithStatus
              status={isActive ? (isInviteAccepted ? 'registered' : 'pending') : 'deactivated'}
            >
              {isActive ? (isInviteAccepted ? ' ' : 'Pending') : 'Deactivated'}
            </Text2WithStatus>
          </div>
        </NameBlock>
        <EmailBlock>
          <Text2>{email}</Text2>
        </EmailBlock>
        <PhoneBlock>
          <Text2>{phone}</Text2>
        </PhoneBlock>
        <ActionsBlock>
          <IconButton onClick={toggleMenu} size="large">
            <StyledMenuIcon />
          </IconButton>
        </ActionsBlock>
      </Container>
      <BottomLineBlock />
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <SubMenu onClick={handleEdit}>
          Edit Info
          <EditIcon sx={{ minWidth: 19 }} />
        </SubMenu>
        {!isInviteAccepted && isActive && (
          <SubMenu onClick={handleResendInvitation}>
            Resend invitation
            <InsertLinkIcon sx={{ minWidth: 190 }} />
          </SubMenu>
        )}
      </Menu>
    </>
  );
};

export default PartnerReferralsListItem;
