import { useContext, useEffect, useState } from 'react';
import { sort, filtersAllClientsDefault, USERS_PER_PAGE } from 'config';
import { useGetUsersList } from 'api/elastic/useProfileEdit';
import { TClientSearchUser, IFilter, ISort } from 'types';
import { Container, ListBlock } from '../../Coach/ChatContainer/ChatAllClients/styles';
import ChatAllClientsHeader from '../../Coach/ChatContainer/ChatAllClients/ChatAllClientsHeader';
import ChatAllClientsSearch from '../../Coach/ChatContainer/ChatAllClients/ChatAllClientsSearch';
import ChatAllClientsListPagination from '../../Coach/ChatContainer/ChatAllClients/ChatAllClientsListPagination';
import { Context } from '../../../context/Context';
import ChatAllClientsListHeader from '../../Coach/ChatContainer/ChatAllClients/ChatAllClientsListHeader';
import ClientsListItem from '../ClientsListItem';
import { isObjectEmpty } from '../../../utils/format/objects';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../utils/firebase/firebaseInit';

// @TODO: Refactorize and abstract, that's a copy of Coach/ChatContainer/ChatAllClients/ChatAllClients
const ClientsList = () => {
  const [user] = useAuthState(auth as any); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [usersPerPage, setUsersPerPage] = useState<number>(USERS_PER_PAGE);
  const [sortValue, setSortValue] = useState<ISort>(sort[0]);
  const [filtersValues, setFiltersValues] = useState<IFilter[]>(filtersAllClientsDefault);
  const [searchValue, setSearchValue] = useState<string>('');
  const savedUsers = useContext(Context).savedUsers;
  const { usersAllList, usersSize, totalPages } = useGetUsersList(
    usersPerPage,
    currentPage,
    sortValue,
    filtersValues,
    searchValue,
    [{ role: ['user'] }, { partnerreferral__uid: user?.uid }],
  );
  const [currentUsersPageList, setCurrentUsersPageList] = useState<TClientSearchUser[]>([]);

  useEffect(() => {
    setCurrentUsersPageList(usersAllList);
  }, [usersAllList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [usersPerPage, sortValue, searchValue, filtersValues]);

  return (
    <Container>
      <ChatAllClientsHeader currentPage={currentPage} />
      <ChatAllClientsSearch searchValue={searchValue} setSearchValue={setSearchValue} />
      <ListBlock>
        <ChatAllClientsListHeader
          usersSize={usersSize}
          sortValue={sortValue}
          setSortValue={setSortValue}
          filtersValues={filtersValues}
          setFiltersValues={setFiltersValues}
          isOpenAllClients={true}
        />
        {!!currentUsersPageList.length && (
          <ListBlock>
            {currentUsersPageList.map((user: TClientSearchUser) => (
              <ClientsListItem
                key={user.id}
                user={user}
                isSavedByCurrentCoach={!isObjectEmpty(savedUsers) ? savedUsers[user.id] : false}
              />
            ))}
          </ListBlock>
        )}
        {!!currentUsersPageList.length && (
          <ChatAllClientsListPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            usersPerPage={usersPerPage}
            setUsersPerPage={setUsersPerPage}
            totalPages={totalPages}
          />
        )}
      </ListBlock>
    </Container>
  );
};

export default ClientsList;
