import { styled } from '@mui/system';
import { FormControlLabel, Typography } from '@mui/material';

export const TitleBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 11,
  '& .MuiButton-text': {
    textTransform: 'none',
    padding: '0 0 0 7px',
    color: '#8587E5',
  },
});

export const TitleText = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
});

export const CategoryWrapper = styled('div')({
  padding: 16,
  borderRadius: 12,
  marginBottom: 13,
  display: 'flex',
  flexDirection: 'column',
  '&:last-child': {
    marginBottom: 0,
  },
  backgroundColor: '#F7F8FA',
  '& .MuiFormControlLabel-label': {
    fontSize: 14,
  },
  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,
  },
  '& .MuiNativeSelect-root': {
    backgroundColor: 'transparent',
    padding: '6px 14px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiNativeSelect-icon': {
    marginRight: 10,
  },
  '& .MuiRadio-colorSecondary.Mui-checked': {
    color: 'rgba(15, 15, 33, 0.6)',
  },
});

export const RadioCategory = styled(FormControlLabel)({
  '& .Mui-checked': {
    '& ~ span': {
      color: '#8488e5',
    },
  },
});
