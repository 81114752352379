import { styled } from '@mui/system';
import Input from '../../../../../common/core/Input';
import DatePicker from '../../../../../common/core/DatePicker';
import { Select, Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: 12,
  paddingRight: 12,
});

export const InputElementsBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const ButtonElementsBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: 10,
});

export const ColumnBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '33%',
  paddingRight: 15,
});

export const ColumnBlockPadding = styled('div')({
  paddingRight: 15,
});

export const InputBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 7,
  marginBottom: 7,
  width: 320,
  ':first-child': {
    marginTop: 0,
  },
});

export const DatePickerBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 15,
  marginBottom: 5,
  width: 320,
});

export const SelectBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 7,
  marginBottom: 7,
  ':first-child': {
    marginTop: 0,
  },
});

export const ButtonsBlock = styled('div')({
  marginRight: 15,
});

export const StyledInput = styled(Input)({
  '& .MuiFormLabel-root': {
    fontSize: 14,
    fontWeight: 400,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 8,
    },
  },
});
export const StyledDatePicker = styled(DatePicker)({
  '& .MuiFormLabel-root': {
    fontSize: 14,
    fontWeight: 400,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 8,
    },
  },
});

export const StyledInputAdornment = styled(Typography)({
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
});

export const InputTextarea = styled('div')({});

export const StyledSelect = styled(Select)({
  minWidth: 320,
  borderRadius: 8,
});

export const SelectHelper = styled('div')({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  color: '#000',
});
