import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';

const gradient = (value: string) =>
  `radial-gradient(circle at top left, rgb(${value} / 75%) 0%, rgb(${value} / 100%) 100%)`;

export const Container = styled('div')<{ status: keyof Theme['status'] }>(({ theme, status }) => ({
  borderRadius: '10px',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr 1fr 1fr',
  width: '60px',
  height: '60px',
  padding: '3px 6px',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fontFamily: theme.typography.body1.fontFamily,
  color:
    status === 'night' || status === 'danger'
      ? theme.palette.background.default
      : theme.palette.text.primary,
  boxSizing: 'border-box',
  lineHeight: 1,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  background: gradient(theme.status[status]),
}));

export const Top = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  fontSize: '11px',
});

export const Middle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '20px',
  width: '100%',
  padding: '2px 0px',
  textTransform: 'uppercase',
});

export const Bottom = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  fontSize: '11px',
});
