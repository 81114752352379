import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const HeaderBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  paddingBottom: 20,
}));

export const TitleBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
}));

export const MainBlock = styled('div')(({ theme }) => ({
  maxHeight: 'calc(100vh - 128px)',
  overflowY: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const MainCoachBlock = styled('div')(({ theme }) => ({
  maxHeight: 628,
}));

export const AddActionBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 0,
  width: 200,
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  paddingLeft: 18,
}));

export const AppSideBar = styled('div')(({ theme }) => ({
  minWidth: 340,
  backgroundColor: '#F9FAFC',
  paddingTop: 22,
}));
