import { styled } from '@mui/system';
import { FormControl, Select, Typography } from '@mui/material';

export const StyledSelect = styled(Select)({
  borderRadius: '8px',
  '& .MuiOutlinedInput-input': {
    padding: '14.5px 29px 14.5px 14.5px',
  },
});

export const LinkQuestionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Label = styled(Typography)({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  marginRight: '24px',
});

export const StyledFormControl = styled(FormControl)({
  width: '196px',
  '& .MuiInputLabel-formControl': {
    top: '-4px',
  },
});
