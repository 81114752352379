import cn from 'mcn';

export const Tooltip = (props: {
  children: React.ReactNode;
  anchor: React.ReactNode;
  offset?: number;
  size?: 'lg' | 'md';
}) => {
  const width = { lg: 32, md: 20 }[props.size ?? 'md'];
  const left = width * (props.offset ?? 0);

  return (
    <div className="relative group" tabIndex={0}>
      {props.anchor}
      <div
        style={{ width: `${width}rem`, left: `-${left}rem` }}
        className={cn(
          'absolute bg-white top-full mt-2 p-7 z-40 text-dark rounded-xl -left-32 [box-shadow:_0px_16px_48px_-9px_rgba(0,0,0,0.17)] hidden group-focus:block group-hover:block',
          '[&_h4]:font-bold [&_h4]:pb-3 [&_h4]:text-base',
          '[&_h5]:font-bold [&_h5]:text-sm [&_h5]:pt-3',
          '[&_p]:text-sm [&_p]:font-normal [&_p]:py-1',
          '[&_ul]:list-disc [&_ul]:pl-3',
          '[&_li]:text-sm [&_li]:font-normal [&_li]:py-1',
        )}
      >
        <div
          className={cn('w-4 h-4 ml-1 -mt-1 bg-white absolute top-0 z-50 rotate-45', '')}
          style={{ left: `${left}rem` }}
        />
        {props.children}
      </div>
    </div>
  );
};
