import { useMemo, useRef, useState } from 'react';
import ModalBackConfirm from 'common/core/Modal/ModalBackConfirm';

interface IUseConfirm {
  title: string;
  description: string;
  cancelButtonText: string;
  confirmButtonText: string;
}

export const useConfirm = (props: IUseConfirm) => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const prms = useRef({ resolve: () => {}, reject: () => {} });
  const dialog = useMemo(
    () => (
      <ModalBackConfirm
        open={open}
        onClose={() => setOpen(false)}
        onCancel={() => prms.current.reject()}
        onConfirm={() => prms.current.resolve()}
        {...props}
      />
    ),
    [open, props.title, props.description],
  );

  const confirm = () => {
    setOpen(true);
    return new Promise<void>((resolve, reject) => {
      prms.current = { resolve, reject };
    }).finally(() => setOpen(false));
  };
  return { dialog, confirm };
};
