import React, { useState, useEffect } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
import {
  CategoryName,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './styles';
import { IHandoutsCategories, IResourceCategory } from 'types';

export const HandoutsCategories = ({ category, resourceData }: IHandoutsCategories) => {
  const [children, setChildren] = useState<IResourceCategory[]>([]);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  useEffect(() => {
    setChildren(category.children);
  }, [category]);

  useEffect(() => {
    if (resourceData.categoryId) {
      const childId = children.filter((item) => item.id === resourceData.categoryId)[0];
      if (resourceData.categoryId === childId?.id) {
        setExpanded(true);
      }
    }
  }, [resourceData.categoryId]);

  const handleChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion expanded={expanded} onChange={handleChange}>
      <StyledAccordionSummary expandIcon={<ArrowRight sx={{ fontSize: '1.2rem' }} />}>
        <CategoryName>{category.name}</CategoryName>
      </StyledAccordionSummary>
      {children.length && (
        <StyledAccordionDetails>
          {children.map((child, index) => (
            <FormControlLabel
              key={`handoutsCategorie${index}`}
              value={child.id}
              control={<Radio checked={resourceData.categoryId === child.id} />}
              label={child.name}
            />
          ))}
        </StyledAccordionDetails>
      )}
    </StyledAccordion>
  );
};
