import { useContext } from 'react';
import { Context } from 'context/Context';
import { Container, FormBlock, TitleText } from './styles';
import { IWrapper } from 'types';

const Wrapper = ({ blockTitle, children, isTitleHasShortMargin }: IWrapper) => {
  const openedMealDrawer = useContext(Context).openedMealDrawer;

  return (
    <Container>
      {blockTitle && (
        <TitleText isTitleHasShortMargin={isTitleHasShortMargin}>{blockTitle}</TitleText>
      )}
      <FormBlock isOpenedMealDrawer={openedMealDrawer}>{children}</FormBlock>
    </Container>
  );
};

export default Wrapper;
