import { styled } from '@mui/system';
import { EAvatarSize } from '@fitmate-coach/fitmate-types';
import { Typography } from '@mui/material';

export const CustomImgAvatar = styled('img')<{ size: EAvatarSize; isShapeCircle: boolean }>(
  ({ size, isShapeCircle }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: size,
    height: size,
    backgroundColor: '#FFDA55',
    borderRadius: 8,
    objectFit: 'cover',
    ...(isShapeCircle && { borderRadius: '50% !important' }),
  }),
);

export const CustomDivAvatar = styled('div')<{ size: EAvatarSize; isShapeCircle: boolean }>(
  ({ size, isShapeCircle }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: EAvatarSize[size],
    height: EAvatarSize[size],
    backgroundColor: '#FFDA55',
    borderRadius: 8,
    objectFit: 'cover',
    ...(isShapeCircle && { borderRadius: '50% !important' }),
  }),
);

export const CustomTypography = styled(Typography)<{ size: EAvatarSize }>(({ size }) => ({
  fontSize: size === EAvatarSize.l ? 28 : size === EAvatarSize.m ? 17 : 14,
  fontWeight: 400,
  color: '#fff',
}));
