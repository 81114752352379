import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup, FormControl } from '@mui/material';
import SubcategoriesSelect from '../ResourcesSubcategoriesSelect';
import { CategoryWrapper, RadioCategory, TitleBlock, TitleText } from './styles';
import { IResourceCategories, IResourceCategory } from 'types';

const ResourceCategories = ({
  categoriesList,
  handleActiveCategoryChange,
  currentCategoryId = '',
  handleCurrentCategoryIdChange,
  handleResourceDataChange,
}: IResourceCategories) => {
  const [value, setValue] = useState<string>('');
  const [categoriesBranch, setCategoriesBranch] = useState<IResourceCategory[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    handleResourceDataChange((prev: any) => {
      if (currentCategoryId !== undefined) {
        return { ...prev, categoryId: (event.target as HTMLInputElement).value };
      }
    });
    handleCurrentCategoryIdChange((event.target as HTMLInputElement).value);
    handleActiveCategoryChange(findById(categoriesList, (event.target as HTMLInputElement).value)!);
  };

  const findParentOfChild = (
    categories: IResourceCategory[],
    id: string,
  ): IResourceCategory | null => {
    for (const category of categories) {
      for (const child of category.children) {
        if (child.id === id) {
          return category;
        }
      }

      const result: IResourceCategory | null = findParentOfChild(category.children, id);
      if (result) {
        return result;
      }
    }
    return null;
  };

  const findById = (categories: IResourceCategory[], id: string): IResourceCategory | null => {
    for (const category of categories) {
      if (category.id === id) {
        return category;
      }

      const result: IResourceCategory | null = findById(category.children, id);
      if (result !== null) {
        return result;
      }
    }
    return null;
  };

  useEffect(() => {
    if (currentCategoryId !== '') {
      let branch: IResourceCategory[] = [];
      let parent: IResourceCategory | null = findParentOfChild(categoriesList, currentCategoryId);
      if (parent !== null) {
        branch.push(parent!);
        branch.push(findById(categoriesList, currentCategoryId)!);
        while (parent) {
          parent = findParentOfChild(categoriesList, parent.id);
          if (parent) {
            branch = [parent, ...branch];
          }
        }
        setCategoriesBranch(branch);
        setValue(branch[0].id);
      } else {
        branch.push(findById(categoriesList, currentCategoryId)!);
        setCategoriesBranch(branch);
        setValue(branch[0].id);
      }
    }
  }, [currentCategoryId]);

  return (
    <CategoryWrapper>
      <TitleBlock>
        <TitleText>Category</TitleText>
      </TitleBlock>
      <FormControl variant="standard" component="fieldset">
        <RadioGroup aria-label="type" name="type1" value={value} onChange={handleChange}>
          {categoriesList.map((category) => (
            <RadioCategory
              key={category.name}
              value={category.id}
              control={<Radio key={category.id} />}
              label={category.name}
            />
          ))}
        </RadioGroup>
        {categoriesList
          .filter((category) => category.id === value)
          .map((category) => (
            <SubcategoriesSelect
              key={category.id}
              handleActiveCategoryChange={handleActiveCategoryChange}
              handleCurrentCategoryIdChange={handleCurrentCategoryIdChange}
              category={category}
              categoriesBranch={categoriesBranch}
              handleResourceDataChange={handleResourceDataChange}
            />
          ))}
      </FormControl>
    </CategoryWrapper>
  );
};

export default ResourceCategories;
