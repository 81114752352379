import { InputLabel } from '@mui/material';
import { Delete, FileCopy } from '@mui/icons-material';
import NextQuestion from 'common/core/NextQuestion';
import {
  ActionIcons,
  Copy,
  FixMargin,
  Icons,
  InputQuestion,
  QuestionTitle,
  Row,
  SelectQuestion,
  StyledSelect,
  TypeTitle,
  Wrapper,
} from './styles';
import { ESurveysQuestionTypes, IQuestion } from 'types';
import Checkbox from './Checkbox';
import RadioButton from './RadioButton';
import Slider from './Slider';
import Options from './Options';

const Question = ({
  index,
  id,
  question,
  nextQuestionList,
  inputHandler,
  questionHandler,
  isDisabled,
  optionHandler,
  addOptionHandler,
  deleteOptionHandler,
  sliderHandler,
  handleDelete,
  copyClickHandler,
}: IQuestion) => {
  const deleteHandler = (answerId: string) => {
    deleteOptionHandler(id, answerId);
  };

  return (
    <Wrapper>
      <div>
        <Row>
          <QuestionTitle>Title</QuestionTitle>
          <InputQuestion
            name="title"
            type="text"
            value={question.title.value}
            onChange={(e) => inputHandler(id, 'title', e.target.value, false)}
            disabled={isDisabled}
            fullWidth={true}
            error={question.title.error}
            helperText={question.title.error ? 'Please, fill the field' : ''}
            label={question.title.error ? 'Error' : ''}
            multiline
            placeholder={!question.title.error ? 'Type your title' : ''}
          />
          {!isDisabled && (
            <ActionIcons>
              <Copy as={FileCopy} onClick={() => copyClickHandler(index)} />
              <Icons as={Delete} onClick={() => handleDelete(index)} />
            </ActionIcons>
          )}
        </Row>
        <Row>
          <QuestionTitle>Question</QuestionTitle>
          <InputQuestion
            name="question"
            type="text"
            value={question.question.value}
            onChange={(e) => inputHandler(id, 'question', e.target.value, false)}
            disabled={isDisabled}
            fullWidth={true}
            multiline
            error={question.question.error}
            label={question.question.error ? 'Error' : ''}
            helperText={question.question.error ? 'Please, link question' : ''}
            placeholder={!question.question.error ? 'Type your question' : ''}
          />
        </Row>
        {isDisabled && (
          <Row>
            <QuestionTitle />
            <Options block={question} textFieldFormat={question.textFieldFormat} />
          </Row>
        )}
        <FixMargin>
          <Row>
            <TypeTitle>Type</TypeTitle>
            <SelectQuestion variant="outlined">
              <InputLabel htmlFor="type">Type of question</InputLabel>
              <StyledSelect
                // variant="standard"
                native
                onChange={(e) => questionHandler(id, 'type', e.target.value as string)}
                label="Type of question"
                value={question.type}
                disabled={isDisabled}
                inputProps={{
                  name: 'type',
                  id: 'type',
                }}
              >
                <option value={ESurveysQuestionTypes.CHECKBOX}>Checkbox</option>
                <option value={ESurveysQuestionTypes.SLIDER}>Linear Scale</option>
                <option value={ESurveysQuestionTypes.TEXT}>Text answer</option>
                <option value={ESurveysQuestionTypes.RADIO}>Radiobutton</option>
              </StyledSelect>
            </SelectQuestion>
          </Row>
          {isDisabled && (
            <NextQuestion
              id={question.id}
              nextQuestionId={question.defaultNextQuestionId}
              blocks={nextQuestionList}
              isRadio={false}
              isDisabled={true}
            />
          )}
        </FixMargin>
      </div>
      {question.type === ESurveysQuestionTypes.CHECKBOX && (
        <Checkbox
          id={question.id}
          answerVariants={question.answerVariants}
          optionHandler={optionHandler}
          deleteHandler={deleteHandler}
          addOptionHandler={addOptionHandler}
          isDisabled={isDisabled}
        />
      )}
      {question.type === ESurveysQuestionTypes.RADIO && (
        <RadioButton
          id={question.id}
          questionsList={nextQuestionList}
          answerVariants={question?.answerVariants}
          optionHandler={optionHandler}
          deleteHandler={deleteHandler}
          addOptionHandler={addOptionHandler}
          isDisabled={isDisabled}
        />
      )}
      {question.type === ESurveysQuestionTypes.SLIDER && (
        <Slider
          id={question.id}
          slider={question?.slider}
          isDisabled={isDisabled}
          sliderHandler={sliderHandler}
        />
      )}
    </Wrapper>
  );
};

export default Question;
