import { styled, Theme } from '@mui/system';
import TextField from '@mui/material/TextField';
import { Chip, Typography } from '@mui/material';
import Button from '../../../../common/core/Button';

export const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-beween',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

export const ContentBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexGrow: 1,
  marginTop: 30,
  paddingLeft: 20,
  paddingRight: 20,
}));
export const TitleBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
}));

export const AutocompleteBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: 20,
}));

export const SelectBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: 55,
  width: 277,
  border: '1px solid #E4E7ED',
  borderRadius: 8,
  backgroundColor: '#fff',
  boxShadow: '0px 7px 17px -6px rgba(23, 33, 48, 0.19)',
  zIndex: 2,
}));
export const SelectAddTagBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: 53,
  borderBottom: '1px solid #E4E7ED',
}));

export const SelectTagBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  paddingLeft: 14,
  paddingTop: 8,
  paddingBottom: 8,
}));

export const TagsChosenBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: 35,
  justifyContent: 'flex-start',
  width: '100%',
}));

export const TagsPossibleTitleBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: 20,
  marginBottom: 10,
  justifyContent: 'flex-start',
  width: '100%',
}));

export const TagsPossibleBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  marginBottom: 20,
  justifyContent: 'flex-start',
  width: '100%',
}));

export const ButtonBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexGrow: 0,
  width: '100%',
  marginBottom: 30,
}));
export const StyledInput = styled(TextField)(({ theme }) => ({
  borderRadius: 8,
  width: 277,
  height: 48,
  fontSize: 14,
  fontWeight: 400,
}));

export const SelectAddTagText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
  marginLeft: 16,
  marginRight: 16,
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: 37,
  maxWidth: 200,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderRadius: 22,
  fontWeight: 500,
  fontSize: 14,
  marginRight: 3,
  marginBottom: 3,
}));

export const SelectAddTagChip = styled(StyledChip)(({ theme }) => ({
  color: '#0F0F21',
  border: '1px solid #0F0F21',
  cursor: 'pointer',
}));

export const SelectTagText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.6)',
  cursor: 'pointer',
}));

export const TagsPossibleTitleText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
}));

export const ButtonDone = styled(Button)(({ theme }) => ({
  width: 282,
  height: 44,
  fontSize: 14,
  fontWeight: 600,
  borderRadius: 8,
}));

export const ChipChosen = styled(StyledChip)(({ theme }) => ({
  backgroundColor: '#0F0F21',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#0F0F21',
  },
}));

export const ChipPossible = styled(StyledChip)(({ theme }) => ({
  color: 'rgba(15, 15, 33, 0.4)',
  border: '1px solid rgba(15, 15, 33, 0.2)',
  cursor: 'pointer',
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  color: '#0F0F21',
}));
