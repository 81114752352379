import { useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import cn from 'mcn';
import { TFocusArea } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { addProgramAction, setProgramActionStatus } from 'store/program/programActionsSlice';
import { disableCard } from 'store/program/weekProgramSlice';
import { Menu, MenuItem } from '../../ChatProgramComponents';
import ChatProgramSmartGoalList from '../../ChatProgramSmartGoal/ChatProgramSmartGoalList';
import ChatProgramSmartGoalForm from '../../ChatProgramSmartGoal/ChatProgramSmartGoalForm';
import EditCoachNotes from '../ChatProgramEditCoachNotes';

export default function ChatProgramPlanCard(props: {
  readonly focusArea: TFocusArea;
  readonly disabled?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { weeks, currentWeekPlan } = useAppSelector((state) => state.weekProgram);
  const { customer } = useAppSelector((state) => state.customerInfos);
  const userId = customer?.id;
  const [menuOpen, setMenuOpen] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openGoalModal, setOpenGoalModal] = useState(false);
  const { focusArea } = props;

  const addAction = async (programActionInputs: any) => {
    if (userId && currentWeekPlan.id && programActionInputs) {
      const args = {
        userId,
        activePlanId: currentWeekPlan.id,
        areaId: focusArea.id,
        actionToAdd: programActionInputs,
      };

      dispatch(addProgramAction(args));
      dispatch(setProgramActionStatus('draft'));
      setOpenGoalModal(false);
    }
  };

  const disablePlanArea = () => {
    if (currentWeekPlan.id && weeks && userId) {
      dispatch(
        disableCard({
          focusArea,
          userId,
          currentWeekPlan,
        }),
      );
      dispatch(setProgramActionStatus('draft'));
    }
  };

  return (
    <div
      className={cn('rounded-lg mb-4 pb-0.5', [focusArea.disabled, 'bg-dark-2', 'bg-[#F4F4F5]'])}
    >
      <div className="flex justify-between w-full px-4 pt-3">
        <h5 className="font-bold text-dark/80">{focusArea.title}</h5>
        <div className="flex items-center gap-1">
          <div className="relative flex items-center">
            <button
              className="w-5 h-5 icon-[mdi--more-vert] "
              onClick={() => !props.disabled && setMenuOpen(!menuOpen)}
            />
            <Menu
              open={menuOpen}
              className="mt-6"
              horizontal={focusArea.category === 'existing' ? 'right' : 'left'}
            >
              <MenuItem
                onClick={() => {
                  disablePlanArea();
                  setMenuOpen(false);
                }}
              >
                {focusArea.disabled ? 'Enable' : 'Disable'} focus area
              </MenuItem>
              {!focusArea.disabled && (
                <MenuItem onClick={() => setOpenNotesModal(true)}>Edit coach notes</MenuItem>
              )}
              {!focusArea.disabled && (
                <MenuItem onClick={() => setOpenGoalModal(true)}>Add specific goal</MenuItem>
              )}
            </Menu>
          </div>
          <button className="w-5 h-5 icon-[mdi--drag-horizontal-variant] pointer-events-none"></button>
        </div>
      </div>
      <p className="text-sm text-dark/60 pt-1 px-4 pb-3">{focusArea.notes}</p>
      {!focusArea.disabled && (
        <ChatProgramSmartGoalList area={focusArea} disabled={props.disabled} />
      )}

      <Dialog
        className="flex items-center justify-center p-8"
        onClose={() => setOpenGoalModal(false)}
        open={openGoalModal}
      >
        <DialogContent>
          <ChatProgramSmartGoalForm
            onClose={() => setOpenGoalModal(false)}
            plan={focusArea}
            onSave={addAction}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        className="flex items-center justify-center p-8"
        onClose={() => setOpenNotesModal(false)}
        open={openNotesModal}
      >
        <DialogContent>
          <EditCoachNotes
            focusArea={focusArea}
            weeks={weeks}
            selectedPlan={currentWeekPlan?.id}
            userId={userId}
            onClose={() => setOpenNotesModal(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
