import { styled } from '@mui/system';
import { Accordion, AccordionDetails, FormGroup } from '@mui/material';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#F9FAFC !important',
  '& .MuiAccordionDetails-root': {
    border: 'none',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .MuiAccordionSummary-root': {
    border: 'none',
    paddingRight: 0,
    paddingLeft: 0,
  },
  '& .MuiAccordionSummary-root ': {
    minHeight: 'revert',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '&.MuiPaper-root::before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const SubCategoryList = styled(FormGroup)(({ theme }) => ({
  marginLeft: 0,
}));
