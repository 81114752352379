import { useEffect } from 'react';
import { onUserSearchIndexUpdate } from 'api/firestores';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clientSearchLoadMore, clientSearchRefresh } from 'store/client-search/clientSearchSlice';
import { fetchCoaches } from 'store/coaches/coachesSlice';
import Button from 'common/core/Button';
import { LeftBlockThreads } from './styles';
import ChatThreadsListItem from './ChatThreadsListItem';

const ChatThreadsList = () => {
  const { coaches } = useAppSelector((state) => state.coaches);
  const { results, end } = useAppSelector((state) => state.clientSearch);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (coaches.length === 0) dispatch(fetchCoaches());
  }, [coaches, dispatch]);

  useEffect(() => onUserSearchIndexUpdate(() => dispatch(clientSearchRefresh())), [dispatch]);

  return (
    <LeftBlockThreads>
      {results.map((user) => {
        return <ChatThreadsListItem user={user} key={user.id} />;
      })}
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}
      >
        {!end && (
          <Button variant="outlined" onClick={() => dispatch(clientSearchLoadMore())}>
            Load more
          </Button>
        )}
      </div>
    </LeftBlockThreads>
  );
};

export default ChatThreadsList;
