import { styled } from '@mui/system';
import { MenuItem, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const SurveyItem = styled('div')<{ isActive: boolean }>(({ isActive }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '316px',
  padding: '12px 13px',
  marginRight: '24px',
  marginBottom: '16px',
  border: '1px solid #E4E7ED',
  boxShadow: '0px 2px 6px rgba(0, 2, 23, 0.075)',
  borderRadius: 12,
  cursor: 'pointer',
  ...(isActive && { background: '#F8FFFD' }),
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  overflowWrap: 'anywhere',
}));

export const DotsIcon = styled(IconButton)(({ theme }) => ({
  padding: '0px',
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E4E7ED',
  fontSize: 14,
}));

export const Delete = styled('div')(({ theme }) => ({
  color: '#FF6D7D',
}));
