import { useEffect, useState } from 'react';
import { CircularProgress, SelectChangeEvent } from '@mui/material';
import semver from 'semver/preload';
import { IProgramAction } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { launchMigration } from 'store/customers/customerInfosSlice';
import { listProgramActions } from 'store/program/programActionsSlice';
import { fetchCurrentProgramWeek } from 'store/program/weekProgramSlice';
import { Button, Card, CompactSelect, Tooltip, Warning } from '../ChatProgramComponents';
import ChatProgramPlanColumns from './ChatProgramPlanColumns';
import ChatProgramPlanHelp from './ChatProgramPlanHelp';

export default function ChatProgramPlan() {
  const dispatch = useAppDispatch();
  const { programActions } = useAppSelector((state) => state.programActions);
  const { weeks, currentWeekPlan } = useAppSelector((state) => state.weekProgram);
  const { customer, isReadOnly } = useAppSelector((state) => state.customerInfos);
  const userId = customer?.id;
  const [actions, setActions] = useState<IProgramAction[]>();
  const [currentPlan, setCurrentPlan] = useState<string | undefined>();

  useEffect(() => {
    if (!weeks) return;
    if (userId && weeks.length > 0) {
      dispatch(fetchCurrentProgramWeek({ userId, currentPlanId: weeks[weeks.length - 1].id }));
      dispatch(listProgramActions(userId));
      setCurrentPlan(weeks[weeks.length - 1].id);
    }
  }, [userId, weeks]);

  useEffect(() => {
    if (programActions && currentWeekPlan?.id) {
      setActions(
        programActions.filter((action: IProgramAction) => action.planId === currentWeekPlan.id),
      );
    }
  }, [programActions, currentWeekPlan]);

  // Select the latest week when new is added or removed
  useEffect(() => {
    if (!weeks) return;
    if (weeks.length > 0 && userId) {
      dispatch(
        fetchCurrentProgramWeek({
          userId,
          currentPlanId: weeks[weeks.length - 1]?.id,
        }),
      );
      setCurrentPlan(weeks[weeks.length - 1]?.id);
    }
  }, [weeks, userId]);

  const customerUseOldApp = customer?.appVersion ? semver.lt(customer.appVersion, '2.0.0') : true;
  const userReadyForMigration =
    !customer?.featureFlags?.includes('new_program_tab') && !customerUseOldApp;

  // Disable editing if older plan is selected
  const disabled =
    weeks &&
    currentWeekPlan &&
    weeks.length > 0 &&
    weeks[weeks.length - 1].id !== currentWeekPlan.id;

  const handleRunMigration = () => {
    if (userId) {
      dispatch(launchMigration(userId));
    }
  };

  const setSelectedWeek = (e: SelectChangeEvent<unknown>) => {
    if (userId) {
      dispatch(fetchCurrentProgramWeek({ userId, currentPlanId: e.target.value as string }));
      setCurrentPlan(e.target.value as string);
    }
  };

  // Show warning message on card
  const warning = (() => {
    if (userReadyForMigration) {
      return (
        <Warning className="bg-[rgba(234,234,251,1)] rounded-t-2xl">
          Client now has the latest app so you can setup their new plan. When you’re ready to do
          this, press the button below to start to copy accross their goals to the new plan
          structure.
        </Warning>
      );
    }
    if (customer?.appVersion && customerUseOldApp) {
      return (
        <Warning className="bg-[rgba(254,243,251,1)] rounded-t-2xl">
          Client needs to update their app before they can be migrated to the new plan.
        </Warning>
      );
    }
    if (disabled) {
      return (
        <Warning className="bg-[rgba(254,243,228,1)] rounded-t-2xl">
          You’re viewing a previous week. You cannot make updates to this.
        </Warning>
      );
    }
    if (!currentWeekPlan) return null;
    if (!currentWeekPlan?.published?.plan) {
      return (
        <Warning className="bg-[rgba(234,234,251,1)] rounded-t-2xl">
          This plan hasn’t been published yet.
        </Warning>
      );
    }
    if (
      currentWeekPlan?.draft?.plan ||
      actions?.some((action) => action.draft && action.draft !== action.published)
    ) {
      return (
        <Warning className="bg-[rgba(234,234,251,1)] rounded-t-2xl">
          You have unpublished changes.
        </Warning>
      );
    }
  })();

  return (
    <Card warning={warning}>
      <div className="p-5 flex justify-between w-full items-center">
        <h4 className="font-bold text-xl flex gap-1.5">
          The plan
          <Tooltip
            size="lg"
            offset={0.2}
            anchor={<span className="icon-[mdi--info-outline] w-6 h-6 text-dark/40 mt-0.5" />}
          >
            <ChatProgramPlanHelp />
          </Tooltip>
        </h4>
        {weeks && (
          <CompactSelect
            value={currentPlan ?? ''}
            onChange={setSelectedWeek}
            options={
              weeks.map((weekProgram: any) => ({
                value: weekProgram.id,
                label: `Program week ${weekProgram.week}`,
              })) ?? []
            }
          />
        )}
      </div>

      <ChatProgramPlanColumns disabled={isReadOnly || disabled} actions={actions} />

      {!customer?.migration && userReadyForMigration && (
        <div className="p-5">
          <div className="flex justify-end w-full">
            <Button size="lg" variant="solid" onClick={handleRunMigration} disabled={isReadOnly}>
              Transfer goals to plan
            </Button>
          </div>
        </div>
      )}

      {customer?.migration && ['started', 'pending'].includes(customer?.migration) && (
        <div className="flex justify-center w-full">
          <CircularProgress /> Migrating plan to new system...
        </div>
      )}
    </Card>
  );
}
