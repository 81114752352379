import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  fetchBodyGoals,
  fetchBodyProfiles,
  toggleFrequencyModal,
} from 'store/customers/bodyProfileSlice';
import { Container } from './styles';
import ChatProgramProfileHeader from './ChatProgramProfileHeader';
import ChatProgramProfileView from './ChatProgramProfileView';
import ChatProgramProfileFooter from './ChatProgramProfileFooter';
import ChatProgramProfileEditLatestModal from './ChatProgramProfileEditLatestModal';
import ChatProgramProfileEditStartModal from './ChatProgramProfileEditStartModal';
import ChatProgramProfileEditGoalModal from './ChatProgramProfileEditGoalModal';
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Scale } from '@mui/icons-material';
import ChatProgramProfileFrequencyModal from './ChatProgramProfileFrequencyModal';

const ChatProgramProfile = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { goals, showEditStartBodyProfile, showEditGoal, showAddBodyProfile } = useAppSelector(
    (state) => state.bodyProfile,
  );
  useEffect(() => {
    if (customer?.id) {
      dispatch(fetchBodyGoals(customer.id));
      dispatch(fetchBodyProfiles(customer.id));
    }
  }, [customer]);

  return (
    <Container>
      <Alert
        icon={<Scale />}
        color={
          customer?.weighInFrequency && customer.weighInFrequency !== 'Not set'
            ? 'success'
            : 'error'
        }
        action={
          <IconButton size="small">
            <EditIcon onClick={() => dispatch(toggleFrequencyModal())} />
          </IconButton>
        }
      >
        {customer?.weighInFrequency && (
          <p>
            Weight Log Frequency: <b>{customer?.weighInFrequency}</b>
          </p>
        )}
        {!customer?.weighInFrequency && (
          <p>
            Weight Log Frequency: <b>Not set</b>
          </p>
        )}
      </Alert>
      <ChatProgramProfileHeader />
      <ChatProgramProfileView />
      <ChatProgramProfileFooter />
      <ChatProgramProfileEditLatestModal isOpenEditLatestModal={showAddBodyProfile} />
      <ChatProgramProfileEditStartModal isOpenEditStartModal={showEditStartBodyProfile} />
      <ChatProgramProfileEditGoalModal
        bodyProfileGoal={goals ? goals : null}
        isOpenEditGoalModal={showEditGoal}
      />
      <ChatProgramProfileFrequencyModal />
    </Container>
  );
};

export default ChatProgramProfile;
