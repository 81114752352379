import { CustomDivAvatar, CustomImgAvatar, CustomTypography } from './styles';
import { EAvatarSize, IAvatar } from '@fitmate-coach/fitmate-types';

const colors = ['#FA87B0', '#FFDA55', '#7EDDC7', '#87D1FA', '#FFBA94', '#FFBD72'];

const Avatar = ({ imgSrc = '', name = '', size = EAvatarSize.m, shape = 'rect' }: IAvatar) => {
  const stringToColor = (value: string) => {
    let hash = 0;
    if (value.length === 0) return hash.toString();
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
  };

  if (imgSrc) {
    return (
      <CustomImgAvatar
        size={size as EAvatarSize}
        isShapeCircle={shape === 'circle'}
        src={imgSrc}
        alt=""
      />
    );
  }

  const nameParts = name.split(' ');
  const letterParts = nameParts.map((item) => item.charAt(0));
  const letterPartsStrFiltered = letterParts.join('').slice(0, 2);

  return (
    <CustomDivAvatar
      size={size as EAvatarSize}
      isShapeCircle={shape === 'circle'}
      style={{ backgroundColor: stringToColor(name) }}
    >
      <CustomTypography size={size as EAvatarSize}>{letterPartsStrFiltered}</CustomTypography>
    </CustomDivAvatar>
  );
};

export default Avatar;
