import { styled } from '@mui/system';
import { AccordionSummary, FormControlLabel, Typography } from '@mui/material';
import MenuItem from '../../../../../common/core/MenuItem';

export const StyledAccordionSummary = styled(AccordionSummary)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#F9FAFC !important',
  padding: 0,
  '&:hover': {
    backgroundColor: '#F2F4F7 !important',
  },
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.Mui-expanded': {
    marginLeft: 0,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 8, // theme.spacing(1)
    height: 20,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
});

export const SubCategoryList = styled('div')({
  marginLeft: 0,
});

export const ActionTitleIconBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

export const MenuIcon = styled('img')({
  width: 24,
  height: 24,
  cursor: 'pointer',
});

export const SubMenu = styled(MenuItem)({
  minWidth: 190,
});

export const StyledIcon = styled('div')({
  color: 'rgba(15, 15, 33, 0.6)',
  cursor: 'pointer',
});

export const Delete = styled('div')({
  color: '#FF6D7D',
  fontSize: 14,
});

export const DeleteIcon = styled('div')({
  color: '#FF6D7D',
});

export const StyledTagItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 65,
  '&:hover': {
    backgroundColor: '#F2F4F7 !important',
  },
});

export const AddNewCategoryCTA = styled('div')({
  paddingRight: 40,
});

export const CategoryName = styled('div')<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  fontWeight: 600,
  fontSize: 16,
  color: 'rgba(15, 15, 33, 0.6)',
  ...(isSelected && {
    color: 'black',
  }),
}));

export const TagName = styled(FormControlLabel)({
  fontWeight: 500,
  fontSize: 14,
  color: 'rgba(15, 15, 33, 0.5)',
});

export const StyledMenuItem = styled(Typography)({
  fontSize: 14,
});

export const StyledArrow = styled('span')({
  fontSize: '1.2rem',
});

export const Hidden = styled('div')({
  visibility: 'hidden',
});
